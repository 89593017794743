import React from 'react';
import PropTypes from 'prop-types'
import './Button.scss'
import {
    SvgDB
} from '../index'

const Button = (props) => {

    const {
        text = '',
        className = '',
        fontSize = '',
        textTransform = 'none',
        iconRight = '',
        ...other
    } = props

    const styles = {
        fontSize: fontSize,
        textTransform: textTransform
    }

    return (
        <button className={`button-container ${className}`} style={styles} {...other}>
            {text.charAt(0) !== '#' ? text : <div className='color' style={{ background: text }}></div>}
            {SvgDB[iconRight] && SvgDB[iconRight]}
        </button>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

export default Button;