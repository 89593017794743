import React from 'react';
import './Note.scss'


const Note = (props) => {

    const {
        title = '',
        content = ''
    } = props

    return (
        <div className='note-p' dangerouslySetInnerHTML={{ __html: title + ' ' + content }}></div>
    );
}

export default Note; 