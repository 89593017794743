import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './MenuItem.scss'

import { useDispatch } from 'react-redux'

import { COUNTRY } from '../../../constants.js';

const MenuItem = (props) => {

  const {
      title = '',
      dropdown = [],
      className = '',
      productos = false,
      onClick = () => { },
      dispatchOnDropdownClick = () => { },
      setActive = () => { },
      query = '',
      ...other
  } = props

  const dispatch = useDispatch()

  const dropdowncontainer = useRef(null)

  const HandleClick = () => {
      dropdowncontainer.current.classList.toggle('active')
  }

  return (
      <div ref={dropdowncontainer} className={`menu-item-container ${className}`} {...other}>

          {dropdown[0] ?
              <>
                  <div className="menu-item-content dropdown" onClick={() => HandleClick()}>
                      <span>{title}</span>
                  </div>

                  <div className="item-dropdown-container">

                      {dropdown.map((el, index) => {
                          if (productos) {
                              return <div
                                key={(index + 1) * 8312}
                                className={`item-dropdown-item ${el.class && el.class}`}
                                onClick={() => setActive(null)}
                              >
                                  {el.categoria ?
                                    <Link to={`/${COUNTRY}/productos/${el.slug}`}>{el.nombre}</Link>
                                    :
                                    <span>{el.nombre}</span>
                                  }

                              </div>
                          } else {
                              return <div
                                  key={(index + 1) * 8382}
                                  className={`item-dropdown-item ${el.class && el.class}`}
                                  onClick={() => {
                                      dispatch(dispatchOnDropdownClick(
                                          {
                                            query: query,
                                            value: el.titulo,
                                            id: el.titulo,
                                          }
                                      ));
                                      setActive(null); HandleClick()
                                  }}>
                                  <span>{el.titulo}</span>
                              </div>
                          }

                      })}

                  </div>
              </>
              :
              <div className="menu-item-content" onClick={onClick}>
                  <span className="bold">{title}</span>
              </div>
          }

      </div >
  );
}

export default MenuItem;
