import React from 'react';
import './LoadingProduct.scss'

const LoadingProduct = () => {
    return (
        <div className="lpdt-container animate-filter">

            <div className="lpdt-content">

                <div className="lpdt__left">
                    <div className="lpdt-img"></div>
                </div>

                <div className="lpdt__right">
                    <div className="lpdt-title"></div>
                    <div className="lpdt-text"></div>
                    <div className="lpdt-text"></div>
                    <div className="lpdt-text"></div>
                </div>


            </div>


        </div>
    );
}

export default LoadingProduct;