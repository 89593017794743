import axiosClient from "./config/axiosClient";
import { categorias } from "../components/Data";
import { COUNTRY } from "../constants";
import { tutorialesAR } from "../productos-excluidos/tutorialesExcluidos";
import { excluirProductos } from "../productos-excluidos/filterProducts";


//// ACTIONS
const TUTORIALS_REQUEST = "TUTORIALS_REQUEST";
const TUTORIALS_ERROR = "TUTORIALS_ERROR";
const TUTORIALS_SUCCESS = "TUTORIALS_SUCCESS";
const TUTORIALS_HOME_SUCCESS = "TUTORIALS_HOME_SUCCESS";

const ADD_MORE_TUTORIALS_SUCCESS = "ADD_MORE_TUTORIALS_SUCCESS";
const ADD_MORE_TUTORIALS_REQUEST = "ADD_MORE_TUTORIALS_REQUEST";

// REDUCER
const initialState = {
  tutorialesHome: [],
  data: [],
  loading: false,
  message: "",
  total: 0,
  loadingmore: false,
};

export const tutorialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TUTORIALS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_MORE_TUTORIALS_REQUEST:
      return {
        ...state,
        loadingmore: true,
      };

    case TUTORIALS_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case TUTORIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        data: action.payload.tutoriales,
        total: action.payload.total,
      };

    case TUTORIALS_HOME_SUCCESS:
      return {
        ...state,
        tutorialesHome: action.payload.tutoriales,
        loading: false,
        message: "",
      };

    case ADD_MORE_TUTORIALS_SUCCESS:
      return {
        ...state,
        message: "",
        loadingmore: false,
        data: [...state.data, ...action.payload],
      };

    default:
      return state;
  }
};

const tutorialsquery = [
  "id",
  "nombre",
  "slug",
  "image_placeholder.title",
  "destacado",
  "image_placeholder.data.full_url",
  "producto.nombre",
  "producto.descripcion",
  "producto.slug",
  "producto.imagenes.directus_files_id.title",
  "producto.imagenes.directus_files_id.data.full_url",
  "producto.id",
  "producto.gpspharma",
  "producto.farmaonline",
  "producto.categoria.categorias_id.categoria",
  "producto.categoria.categorias_id.id",
  "producto.linea_de_producto.linea_de_producto_id.linea_de_producto",
  "link",
];

// ACTIONS
const tutorialsRequest = () => ({
  type: TUTORIALS_REQUEST,
});

const tutorialsError = (error) => ({
  type: TUTORIALS_ERROR,
  payload: error,
});

const tutorialsSuccess = (data) => ({
  type: TUTORIALS_SUCCESS,
  payload: data,
});

const tutorialsHomeSuccess = (data) => ({
  type: TUTORIALS_HOME_SUCCESS,
  payload: data,
});

const addMoreTutorialsSuccess = (data) => ({
  type: ADD_MORE_TUTORIALS_SUCCESS,
  payload: data,
});

const addMoreTutorialsRequest = () => ({
  type: ADD_MORE_TUTORIALS_REQUEST,
});

export function getTutorials(opciones = { limit: -1 }) {
  return async (dispatch) => {
    dispatch(tutorialsRequest());

    try {
      const url = `/items/tutorial?fields=${tutorialsquery.join(
        ","
      )}&meta=filter_count&limit=${opciones.limit}`;
      const urlLA = `/items/tutorial?fields=id,nombre,slug,image_placeholder.title,destacado,image_placeholder.data.full_url,producto.nombre,producto.descripcion,producto.slug,producto.imagenes.directus_files_id.title,producto.imagenes.directus_files_id.data.full_url,producto.id,producto.categoria.categorias_id.categoria,producto.categoria.categorias_id.id,producto.linea_de_producto.linea_de_producto_id.linea_de_producto,link&meta=filter_count&limit=${opciones.limit}`;

      // TODO:
      const reqUrl =
        COUNTRY === "uy" ||
        COUNTRY === "pe" ||
        COUNTRY === "cl" ||
        COUNTRY === "ec" ||
        COUNTRY === "py"
          ? urlLA
          : url;

      const res = await axiosClient.get(reqUrl);

      const tutorialesfinal = handleTutRes(res.data.data);

      // switch (COUNTRY) {
      //   case 'ar':
      //     videoTutoriales = excluirProductos(tutorialesfinal, tutorialesAR)
      //     break;
      
      //   default:
      //     break;
      // }

      dispatch(
        tutorialsSuccess({
          tutoriales: COUNTRY === 'ar' ? excluirProductos(tutorialesfinal, tutorialesAR)  : tutorialesfinal,
          total: res.data.meta.filter_count,
        })
      );
    } catch (e) {
      dispatch(tutorialsError("No se pudieron obtener los tutoriales"));
    }
  };
}

// Fetchea los tutoriales de la home
export function getHomeTutorials(opciones = {}) {
  return async (dispatch) => {
    dispatch(tutorialsRequest());

    try {
      const response = await axiosClient.get(
        `/items/tutoriales_home?fields=tutorial_left.*,tutorial_left.image_placeholder.*,tutorial_center.*,tutorial_center.image_placeholder.*,tutorial_right.*,tutorial_right.image_placeholder.*`
      );

      const tutorialLeft = response.data.data[0].tutorial_left;
      const tutorialCenter = response.data.data[0].tutorial_center;
      const tutorialRight = response.data.data[0].tutorial_right;
      const newTutorialesHome = [tutorialLeft, tutorialCenter, tutorialRight];

      if (tutorialLeft) {
        tutorialLeft.image_placeholder.nombre =
          tutorialLeft.image_placeholder.title;
        tutorialLeft.image_placeholder.src =
          tutorialLeft.image_placeholder.data.full_url;
      }

      if (tutorialCenter) {
        tutorialCenter.image_placeholder.nombre =
          tutorialCenter.image_placeholder.title;
        tutorialCenter.image_placeholder.src =
          tutorialCenter.image_placeholder.data.full_url;
      }

      if (tutorialRight) {
        tutorialRight.image_placeholder.nombre =
          tutorialRight.image_placeholder.title;
        tutorialRight.image_placeholder.src =
          tutorialRight.image_placeholder.data.full_url;
      }

      dispatch(
        tutorialsHomeSuccess({
          tutoriales: newTutorialesHome,
        })
      );
    } catch (error) {
      dispatch(
        tutorialsError(`Error fetcheando tutoriales de la home: ${error}`)
      );
    }
  };
}

export function filterTutorials(filter) {
  return async (dispatch) => {
    dispatch(tutorialsRequest());

    try {
      const newquery = [...tutorialsquery, ...filter];

      const res = await axiosClient.get(
        `/items/tutorial?fields=${newquery.join(",")}&meta=filter_count&limit=5`
      );

      const tutsdata = res.data.data;

      const tutorialesfinal = handleTutRes(tutsdata);

      dispatch(
        tutorialsSuccess({
          tutoriales: tutorialesfinal,
          total: res.data.meta.filter_count,
        })
      );
    } catch (e) {
      dispatch(tutorialsError("No se pudieron filtrar los tutoriales"));
    }
  };
}

export function loadMoreTutorials(filter, page) {
  return async (dispatch) => {
    dispatch(addMoreTutorialsRequest());

    try {
      const newquery = [...tutorialsquery, ...filter];

      const res = await axiosClient.get(
        `/items/tutorial?fields=${newquery.join(",")}&limit=5&page=${page}`
      );

      const tutsdata = res.data.data;

      const tutorialesfinal = handleTutRes(tutsdata);

      dispatch(addMoreTutorialsSuccess(tutorialesfinal));
    } catch (e) {
      dispatch(
        tutorialsError(
          "No se han encontrado productos que cumplan con esas caracteristicas."
        )
      );
    }
  };
}

export function getTutorial(id) {
  return async (dispatch) => {
    dispatch(tutorialsRequest());

    try {
      const res = await axiosClient.get(
        `/items/tutorial?filter[slug][eq]=${id}&fields=${tutorialsquery.join(
          ","
        )}&limit=1`
      );

      const resdestacados = await axiosClient.get(
        `/items/tutorial?fields=${tutorialsquery.join(
          ","
        )}&filter[destacado][eq]=1&filter[slug][neq]=${id}&meta=filter_count&limit=4`
      );

      resdestacados.data.data.unshift(res.data.data[0]);

      const tutorialesfinal = handleTutRes(resdestacados.data.data);

      dispatch(
        tutorialsSuccess({
          tutoriales: tutorialesfinal,
          total: resdestacados.data.meta.filter_count,
        })
      );
    } catch (e) {
      dispatch(tutorialsError("No se pudo obtener el tutorial"));
    }
  };
}

function handleTutRes(data) {
  const fixed = data.map((el) => {
    if (el.image_placeholder) {
      el.image_placeholder = {
        nombre: el.image_placeholder.title,
        src: el.image_placeholder.data.full_url,
      };
    }

    if (el.producto) {
      el.producto.categoria = el.producto.categoria.map((el) => {
        return {
          ...categorias[el.categorias_id.categoria],
        };
      });

      if (el.producto.imagenes) {
        el.producto.imagenes = el.producto.imagenes.map((image) => ({
          nombre: image.directus_files_id.title,
          src: image.directus_files_id.data.full_url,
        }));
      }
    }

    /* BORRAR EN PRODUCCION */
    //const links = ['https://www.youtube.com/embed/-EChFA_-zFE', "https://www.youtube.com/embed/5qap5aO4i9A"]
    //el.link = links[Math.round(Math.random() * 1)]
    /* BORRAR EN PRODUCCION */

    return el;
  });

  // Hack para que el tutorial de Brillo Extremo aparezca como el default -> 2/12 cambiado por Baseline
  const originalFirst = fixed[0];

 // console.log("original first: ", fixed);

  // Buscamos el tutorial de baseline (es más seguro esto que asumir que está en la última posición)
  // y lo ponemos en la primera posición
  for (let i = 0; i < fixed.length; i++) {
    const tutorial = fixed[i];

    if (tutorial.nombre === "Baseline" && COUNTRY !== "bo") {
      fixed[0] = tutorial;
      fixed[i] = originalFirst;
    }

    // if (COUNTRY === "bo" && tutorial.nombre === "Brillo Extremo") {
    //   fixed[0] = tutorial;
    //   // fixed[i] = originalFirst;

    //   if (tutorial.nombre === "Sin Amoniaco") {
    //     fixed[1] = tutorial;
    //   }

    //   if (tutorial.nombre === "Issue Keratin Kit") {
    //     fixed[2] = tutorial;
    //   }

    //   console.log("prueba : ", fixed);
    // }

    //console.log("prueba 2:", Object.values(tutorial));
  }

  // if (COUNTRY === "bo") {
  //   fixed[0] = "Brillo Extremo";
  //   fixed[1] = "Sin Amoniaco";
  //   fixed[2] = "Issue Keratin Kit";
  //   fixed[3] = "Issue Crazy Colors";
  //   fixed[4] = "Tratamientos Con Keratina";
  // }

  //console.log("prueba : ", fixed);

  return fixed;
}
