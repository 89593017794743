import React, { useEffect } from 'react';
import { scrollToTop } from '../../utils';
import Banner from './Banner';
import Form from './Form';


const Contacto = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Banner />
            <Form />
        </>
    );
}

export default Contacto;
