import React from "react";
import "./Producto.scss";

import { useHistory } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { COUNTRY } from "../../../constants";

const Producto = (props) => {
  const { push } = useHistory();

  const {
    imagen = "",
    nombre = "",
    slug,
    onSwipedLeft,
    onSwipedRight,
    ...other
  } = props;

  const swipeable = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    trackMouse: true,
  });

  
  return (
    <div
      className="producto-container"
      onClick={() => push(`/${COUNTRY}/productos/${slug}`)}
      {...swipeable}
      {...other}
    >
      <div className="producto-content">
        <div className={`producto-img-container`}>
          <div className="producto-add-btn-container">
            <div className={`producto-add-btn`}></div>
          </div>
          <div className="producto-img">
            <img loading="lazy" src={imagen.src} alt={imagen.nombre} />
          </div>
        </div>
        <p>{nombre}</p>
      </div>
    </div>
  );
};

export default Producto;
