import React, { useState, useEffect } from "react";
import "./ProdInfo.scss";
import { Button, ProdContent, ComprarPopUp } from "../../@ui";
import { getToneNameAndPrefix } from "../../../utils";
import {
  COUNTRY,
  eshops,
  urlProductUy,
  urlProductPy,
} from "../../../constants";

const TONOS_ORDER = {
  // negro: 0,
  negros: 0,
  //'negros_y_grises': 0, // FIXME: Borrame cuando esté corregido en el back
  rojo: 1,
  rojos: 2,

  castanos: 3,
  //'castano_y_cobre': 2, // FIXME: idem
  rubios: 4,
  aclarantes: 5,
  cenizas: 6,
  crazy: 7,
  total: 8,
};

const ProdInfo = (props) => {
  const {
    nombre = "",
    descripcion = "",
    links = {},
    categoria = {},
    tonosdecolor = {},
  } = props;

  //console.log("Tonos: ", tonosdecolor);

  const [comprar, setComprar] = useState(false);
  const [activecontent, setActiveContent] = useState(0);
  const [activetono, setActiveTono] = useState({});

  // En los productos de Uruguay la url del eshop varia según el tono del color
  useEffect(() => {
    if (COUNTRY === "uy" && activetono.nombre) {
      // Url especifica según el tono de color
      const urlProduct = urlProductUy.get(activetono.nombre);
      links.tata = `${links.baseUrlTata}${urlProduct ? urlProduct : ""}`;

      //console.log("prueba uruguaya:", urlProduct);
    }
  }, [activetono.nombre, links]);

  useEffect(() => {
    if (COUNTRY === "py" && activetono.nombre) {
      // idem uy pero py
      const urlProductpy_export = urlProductPy.get(activetono.nombre);
      links.salonmarket = `${links.baseUrlSalonmarket}${
        urlProductpy_export ? urlProductpy_export : ""
      }`;

      //console.log("prueba paraguaya:", urlProductpy_export);
    }
  }, [activetono.nombre, links]);

  // console.log(activetono.nombre);
  // console.log("links salon market: ", links.salonmarket);

  const [tono, setTono] = useState("");
  const activeTonoText = activetono.nombre
    ? getToneNameAndPrefix(activetono.nombre)
    : { name: "", prefix: "" };

  useEffect(() => {
    // Mostrar el primer tono activo que tenga contenido
    const tonositerable = Object.keys(tonosdecolor);
    for (let i = 0; i < tonositerable.length; i++) {
      if (tonosdecolor[tonositerable[i]].length > 0) {
        // imagenes
        setTono(tonositerable[i]);

        // active tono
        setActiveTono(tonosdecolor[tonositerable[i]][0]);
        break;
      }
    }
  }, [tonosdecolor]);

  // Keys de color ordenadas de acuerdo a TONOS_ORDER
  const tonosOrderedKeys = Object.keys(tonosdecolor).sort(
    (a, b) => TONOS_ORDER[a] - TONOS_ORDER[b]
  );

  //console.log("links", Object.keys(links));

  const eshopsEmpty = (links) => {
    let isEmpty = true;
    Object.keys(links).forEach((link) => {
      isEmpty = isEmpty && !links[link];
    });

    return isEmpty;
  };

  return (
    <>
      <ComprarPopUp
        productEshops={links}
        active={comprar}
        setActive={setComprar}
      />
      <div className="prodindv-info-container">
        <div className="info-firstpart-container">
          <h1>{nombre}</h1>
          {!eshopsEmpty(links) && (
            <Button
              onClick={() => setComprar(true)}
              text="COMPRAR AHORA"
              className={`big ${categoria.clase}`}
            />
          )}
        </div>

        <ProdContent
          name="Descripción"
          setActiveContent={setActiveContent}
          state={activecontent}
          index={0}
          className="descripcion"
        >
          <div className="prodindv-description-container">
            <p dangerouslySetInnerHTML={{ __html: descripcion }} />
          </div>
        </ProdContent>

        {tonosdecolor.total <= 0 || !tonosdecolor[tono] ? (
          ""
        ) : (
          <ProdContent
            name="Carta de colores"
            setActiveContent={setActiveContent}
            state={activecontent}
            index={1}
          >
            <div className="prodinv-colores-container">
              <div className="colores-firstpart-container">
                <span>Selecciona el tono</span>

                {tonosdecolor.crazy.length >= 1 ? (
                  ""
                ) : (
                  <div className="color-options-container">
                    {/* eslint-disable-next-line array-callback-return */}
                    {tonosOrderedKeys.map((key) => {
                      if (tonosdecolor[key].length > 0)
                        return (
                          <div
                            key={key}
                            className={`color-option-item ${
                              tono === key && "active"
                            }`}
                            onClick={() => setTono(key)}
                          >
                            {tonosdecolor[key][0].tonos_de_color}
                          </div>
                        );
                    })}
                  </div>
                )}
                <div className="color-display-container">
                  {tonosdecolor[tono].map((el) => (
                    <div
                      key={(el.id + 1) * 9100}
                      className={`color-display-item
                                            ${
                                              activetono.id === el.id &&
                                              "active"
                                            }`}
                      onClick={() => setActiveTono(el)}
                    >
                      <img src={el.imagen.src} alt={el.imagen.nombre} />
                    </div>
                  ))}
                </div>
              </div>

              <div className="colores-secondpart-container">
                {COUNTRY === "ar" || COUNTRY === "uy" || COUNTRY === "py" ? (
                  <span>¡Mirá como te va a quedar!</span>
                ) : (
                  <span>¡Mira como te va a quedar!</span>
                )}

                {activetono && (
                  <div className="color-results-container">
                    <div className="color-results-main">
                      <div className="main-item">
                        <img
                          src={activetono.imagen.src}
                          alt={activetono.imagen.nombre}
                        />
                        <p>
                          {activeTonoText.name}
                          <sup>{activeTonoText.prefix}</sup>
                        </p>
                      </div>
                    </div>

                    {/* {activetono.iniciales &&
                      activetono.iniciales.length > 0 &&
                      activetono.finales &&
                      activetono.finales.length > 0 && (
                        <div className="color-results-other">
                          <div className="other-firstpart">
                            <p className="mobile-title">Tu color inicial</p>
                            <div className="imgs-container">
                              {activetono.iniciales.map((el) => (
                                <div key={el.id} className="other-item">
                                  <img src={el.src} alt={el.nombre} />
                                </div>
                              ))}
                            </div>
                            <p className="desktp-title">Tu color inicial</p>
                          </div> */}

                    <div className="other-arrows">
                      {activetono.iniciales.map((el, i) => {
                        // eslint-disable-next-line array-callback-return
                        if (!activetono.finales[i]) return;
                        return (
                          <div
                            key={(1 + i) * 100}
                            className="other-arrows-item"
                          ></div>
                        );
                      })}
                    </div>

                    {/* <div className="other-secondpart">
                            <p className="mobile-title">Resultados finales</p>

                            <div className="imgs-container">
                              {activetono.finales.map((el) => (
                                <div key={el.id} className="other-item">
                                  <img src={el.src} alt={el.nombre} />
                                </div>
                              ))}
                            </div>
                            <p className="desktp-title">Resultados finales</p>
                          </div> */}
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
            {/* </div> */}
          </ProdContent>
        )}
      </div>
    </>
  );
};

export default ProdInfo;
