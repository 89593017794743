import axios from "axios";
import axiosClient from "./config/axiosClient";
import { categorias } from "../components/Data";
import { sanitizeName } from "../utils";
import { EMAIL_MICROSERVICE_URL, COUNTRY } from "../constants";
//import { eshops } from "../constants";
// ACTIONS
const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
const PRODUCTS_ERROR = "PRODUCTS_ERROR";
const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";

const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

const GET_FILTERED_PRODUCTS_REQUEST = "GET_FILTERED_PRODUCTS_REQUEST";
const GET_FILTERED_PRODUCTS_ERROR = "GET_FILTERED_PRODUCTS_ERROR";
const GET_FILTERED_PRODUCTS_SUCCESS = "GET_FILTERED_PRODUCTS_SUCCESS";

const FILTERED_PRODUCT_REQUEST = "FILTERED_PRODUCT_REQUEST";
const ADD_FILTERED_PRODUCT_SUCCESS = "ADD_FILTERED_PRODUCT_SUCCESS";

const SORT_FILTERED_SUCCESS = "SORT_FILTERED_SUCCESS";

// REDUCER
const initialState = {
  data: [],
  filteredproducts: [],
  loading: false,
  loadingmore: false,
  loadingfiltered: false,
  loadingproduct: false,
  message: "",
  messageproduct: "",
  currentproduct: {},
  total: 0,
  getProductsStatus: "idle"
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        getProductsStatus: "loading"
      };
    case GET_FILTERED_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingfiltered: true
      };

    case GET_FILTERED_PRODUCTS_ERROR:
      return {
        ...state,
        loadingfiltered: false,
        message: action.payload
      };

    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        loadingproduct: true,
        messageproduct: ""
      };

    case GET_PRODUCT_ERROR:
      return {
        ...state,
        loadingproduct: false,
        messageproduct: action.payload
      };

    case PRODUCTS_ERROR:
      return {
        ...state,
        message: action.payload,
        loading: false,
        getProductsStatus: "fail"
      };

    case PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        getProductsStatus: "success"
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        getProductsStatus: "success"
      };

    case GET_FILTERED_PRODUCTS_SUCCESS:
      return {
        ...state,
        filteredproducts: action.payload.productos,
        total: action.payload.total,
        loadingfiltered: false
      };

    case GET_PRODUCT_SUCCESS:
      const newCurrentproduct = action.payload;

      if (!newCurrentproduct.imagen) {
        newCurrentproduct.imagen = newCurrentproduct.imagenes[0];
      }

      const { data } = state;
      const newData = data.slice();

      // Actualizar producto que fue enriquecido
      for (let i = 0; i < newData.length; i++) {
        const currProd = newData[i];

        if (currProd.slug === newCurrentproduct.slug) {
          newData[i] = newCurrentproduct;
          break;
        }
      }

      return {
        ...state,
        data: newData,
        currentproduct: newCurrentproduct,
        loadingproduct: false,
        messageproduct: "",
        getProductsStatus: "success"
      };

    case ADD_FILTERED_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingmore: false,
        filteredproducts: [...state.filteredproducts, ...action.payload]
      };

    case FILTERED_PRODUCT_REQUEST:
      return {
        ...state,
        loadingmore: true
      };

    case SORT_FILTERED_SUCCESS:
      return {
        ...state,
        filteredproducts: action.payload
      };

    default:
      return state;
  }
};

const productosquery = [
  "nombre",
  "imagenes.directus_files_id.title",
  "imagenes.directus_files_id.data.full_url",
  "slug",
  "linea_de_producto.linea_de_producto_id.linea_de_producto",
  "id",
  "tipo_de_producto",
  "tonos_de_color.tonos_de_color_id.tonos_de_color",
  "destacado",
  "categoria.categorias_id.id",
  "categoria.categorias_id.categoria",
  "orden_custom",
  "orden_menu_kits",
  "vistas",
  "ultimo",
  "status"
];

const productoIndividualQuery = [
  "categoria.categorias_id.id",
  "categoria.categorias_id.categoria",
  "descripcion",
  "id",
  "nombre",
  "slug",
  "tipo_de_producto",
  "tonos_de_color.tonos_de_color_id.id",
  "tonos_de_color.tonos_de_color_id.nombre",
  "tonos_de_color.tonos_de_color_id.imagen.title",
  "tonos_de_color.tonos_de_color_id.tonos_de_color",
  "tonos_de_color.tonos_de_color_id.imagen.data.full_url",
  "tonos_de_color.tonos_de_color_id.sort",
  "imagenes.directus_files_id.title",
  "imagenes.directus_files_id.data.full_url",
  "productos_relacionados.producto_relacionado_id.imagenes.directus_files_id.title",
  "productos_relacionados.producto_relacionado_id.imagenes.directus_files_id.data.full_url",
  "productos_relacionados.producto_relacionado_id.nombre",
  "productos_relacionados.producto_relacionado_id.categoria.categorias_id.categoria",
  "productos_relacionados.producto_relacionado_id.id",
  "productos_relacionados.producto_relacionado_id.slug",
  "tutorial.id",
  "tutorial.nombre",
  "tutorial.link",
  "tutorial.image_placeholder.title",
  "tutorial.image_placeholder.data.full_url",
  "modo_de_uso.modo_de_uso_id.id",
  "modo_de_uso.modo_de_uso_id.nombre",
  "modo_de_uso.modo_de_uso_id.descripcion",
  "modo_de_uso.modo_de_uso_id.tabs.aplicacion_id.id",
  "modo_de_uso.modo_de_uso_id.tabs.aplicacion_id.nombre",
  "modo_de_uso.modo_de_uso_id.tabs.aplicacion_id.descripcion",
  "modo_de_uso.modo_de_uso_id.tabs.aplicacion_id.aclaraciones.*",
  "modo_de_uso.modo_de_uso_id.imagen.id",
  "modo_de_uso.modo_de_uso_id.imagen.title",
  "modo_de_uso.modo_de_uso_id.imagen.data.full_url",
  "modo_de_uso.modo_de_uso_id.aclaraciones.aclaraciones_de_aplicacion_id.tipo",
  "modo_de_uso.modo_de_uso_id.aclaraciones.aclaraciones_de_aplicacion_id.id",
  "modo_de_uso.modo_de_uso_id.aclaraciones.aclaraciones_de_aplicacion_id.descripcion",
  "linea_de_producto.linea_de_producto_id.id",
  "linea_de_producto.linea_de_producto_id.linea_de_producto",
  "tonos_de_color.tonos_de_color_id.iniciales.directus_files_id.id",
  "tonos_de_color.tonos_de_color_id.iniciales.directus_files_id.title",
  "tonos_de_color.tonos_de_color_id.iniciales.directus_files_id.data.full_url",
  "tonos_de_color.tonos_de_color_id.finales.directus_files_id.id",
  "tonos_de_color.tonos_de_color_id.finales.directus_files_id.title",
  "tonos_de_color.tonos_de_color_id.finales.directus_files_id.data.full_url",
  "destacado",
  "orden_custom",
  "orden_menu_kits",
  "vistas",
  "ultimo",
  "status"
];//.concat(eshops[COUNTRY]);

// ACTIONS
const productsRequest = () => ({
  type: PRODUCTS_REQUEST
});

const productsError = error => ({
  type: PRODUCTS_ERROR,
  payload: error
});

const productsSuccess = () => ({
  type: PRODUCTS_SUCCESS
});

const getProductsSuccess = data => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: data
});

const getProductSuccess = data => ({
  type: GET_PRODUCT_SUCCESS,
  payload: data
});

const getFilteredProductsSuccess = data => ({
  type: GET_FILTERED_PRODUCTS_SUCCESS,
  payload: data
});

const addFilteredProductsSuccess = data => ({
  type: ADD_FILTERED_PRODUCT_SUCCESS,
  payload: data
});

const filteredProductsRequest = () => ({
  type: FILTERED_PRODUCT_REQUEST
});

const sortFilteredSuccess = data => ({
  type: SORT_FILTERED_SUCCESS,
  payload: data
});

const getProductRequest = () => ({
  type: GET_PRODUCT_REQUEST
});

const getProductError = msg => ({
  type: GET_PRODUCT_ERROR,
  payload: msg
});

const getFilteredRequest = () => ({
  type: GET_FILTERED_PRODUCTS_REQUEST
});

const getFilteredError = msg => ({
  type: GET_FILTERED_PRODUCTS_ERROR,
  payload: msg
});

export function getProducts() {
  return async (dispatch, getState) => {
    const { productos } = getState();

    // Si ya disparamos esta función antes, cancelar request
    if (productos.getProductsStatus !== "idle") {
      return;
    }

    dispatch(productsRequest());

    try {
      const res = await axiosClient.get(
        `/items/producto?fields=${productosquery.join(",")}`
      );

      const productosdata = res.data.data;
      productosdata.sort((a, b) => a.orden_custom - b.orden_custom);
      const productosfinal = HandleProductsRes(productosdata);

      dispatch(productsSuccess());
      dispatch(getProductsSuccess(productosfinal));
    } catch (e) {
      dispatch(
        productsError(
          "No se han podido obtener los productos, prueba de nuevo mas tarde."
        )
      );
    }
  };
}

export function getProduct(id, categoria = "") {
  return async (dispatch, getState) => {
    const state = getState();

    const { data } = state.productos;

    if (data.length > 0) {
      // Si el producto ya está enriquecido, despachar inmediatamente getProductSuccess sin hacer
      // el request de nuevo
      for (let i = 0; i < data.length; i++) {
        const currProd = data[i];

        if (currProd.slug === id) {
          if (isFullProduct(currProd)) {
            dispatch(getProductSuccess(currProd));
            return;
          }
        }
      }
    }

    dispatch(getProductRequest());

    // try {
    // const res = await axiosClient.get(`/items/producto/${id}?fields=${query.join(',')}`)
    const res = await axiosClient.get(
      `/items/producto?filter[slug][eq]=${id}&fields=${productoIndividualQuery.join(",")}`
    );

    //console.log("res: ", res);

    if (!res.data.data[0]) {
      throw new Error("No data found");
    }
    // const productdata = res.data.data
    const productdata = res.data.data[0];

    const productofinal = HandleProductRes(productdata);

    if (categoria && productofinal.categoria[0].slug !== categoria) {
      throw new Error();
    }
    // Se ordenan los tonos de colores
    Object.keys(productofinal.tonos_de_color).forEach(tono => {
      typeof productofinal.tonos_de_color[tono] === "object" &&
        productofinal.tonos_de_color[tono].sort((a, b) => {
          // const aConSubversion =
          //   a.nombre.length > 4 && a.nombre[a.nombre.length - 2] === "-";
          // const bConSubversion =
          //   b.nombre.length > 4 && b.nombre[b.nombre.length - 2] === "-";
          // const aFormated =
          //   a.nombre[3] === undefined ||
          //   a.nombre[3] === "-" ||
          //   a.nombre[3] === "A"
          //     ? a.nombre.slice(0, 2) + 0 + a.nombre.slice(2)
          //     : a.nombre;
          // const bFormated =
          //   b.nombre[3] === undefined ||
          //   b.nombre[3] === "-" ||
          //   b.nombre[3] === "A"
          //     ? b.nombre.slice(0, 2) + 0 + b.nombre.slice(2)
          //     : b.nombre;
          // if (aConSubversion && bConSubversion) {
          //   return aFormated.slice(0, 5) + 0 + aFormated.slice(5) >
          //     bFormated.slice(0, 5) + 0 + bFormated.slice(5)
          //     ? 1
          //     : -1;
          // }
          // if (aConSubversion) {
          //   return aFormated.slice(0, 5) + 0 + aFormated.slice(5) > bFormated
          //     ? 1
          //     : -1;
          // }
          // if (bConSubversion) {
          //   return aFormated > bFormated.slice(0, 5) + 0 + bFormated.slice(5)
          //     ? 1
          //     : -1;
          // }
          return a.nombre > b.nombre ? 1 : -1;
        });
    });

    dispatch(productsSuccess());
    dispatch(getProductSuccess(productofinal));

    const productoId = res.data.data[0].id;

    // Incrementar vistas
    try {
      axios
        .post(`${EMAIL_MICROSERVICE_URL}/incrementarVistas`, {
          productoId
        })
        .catch(error => {
          //console.log(error); //loguea el error
        });
      // } catch (error) {
      //   console.error(
      //     `Error intentando incrementar vistas de producto con id ${productoId}: ${error}`
      //   );
      // }
    } catch (e) {
      console.log(e);
      dispatch(
        getProductError(
          "No se han podido obtener el producto, prueba de nuevo mas tarde."
        )
      );
    }
  };
}

export function filterProducts(filter) {
  return async dispatch => {
    dispatch(getFilteredRequest());

    try {
      const newquery = [...productosquery, ...filter];

      const res = await axiosClient.get(
        `/items/producto?fields=${newquery.join(",")}&meta=filter_count&limit=6`
      );

      const productosdata = res.data.data;

      const productosfinal = HandleProductsRes(productosdata);

      dispatch(productsSuccess());
      dispatch(
        getFilteredProductsSuccess({
          productos: productosfinal,
          total: res.data.meta.filter_count
        })
      );
    } catch (e) {
      dispatch(
        getFilteredError(
          "No se han encontrado productos que cumplan con esas caracteristicas."
        )
      );
    }
  };
}

export function addFilteredProducts(filter, page) {
  return async dispatch => {
    dispatch(filteredProductsRequest());

    try {
      const newquery = [...productosquery, ...filter];

      const res = await axiosClient.get(
        `/items/producto?fields=${newquery.join(",")}&limit=6&page=${page}`
      );

      const productosdata = res.data.data;

      const productosfinal = HandleProductsRes(productosdata);

      dispatch(productsSuccess());
      dispatch(addFilteredProductsSuccess(productosfinal));
    } catch (e) {
      dispatch(
        productsError(
          "No se han encontrado productos que cumplan con esas caracteristicas."
        )
      );
    }
  };
}

export function sortFilteredProducts(e) {
  return (dispatch, getState) => {
    const selectdic = {
      "Más relevantes": "relevante",
      "Lo más visto": "vistas",
      Recomendados: "destacado"
    };

    let selected;
    if (typeof e === "object" && e !== null) {
      selected = e.target.children[e.target.selectedIndex].text;
    } else {
      selected = e;
    }

    if (selected === "Ordenar por") return;

    const mobileselectedlabel = document
      .querySelectorAll(".select-container")[1]
      .querySelector("span");

    if (mobileselectedlabel) mobileselectedlabel.innerText = selected;

    const state = getState().productos.filteredproducts;

    const sorted = state.sort(
      (a, b) => (a[selectdic[selected]] < b[selectdic[selected]] ? 1 : -1)
    );

    dispatch(sortFilteredSuccess(sorted));
  };
}

function HandleProductRes(producto) {
  producto.modo_de_uso = HandleImages(
    producto.modo_de_uso,
    "modo_de_uso_id.imagen"
  );
  producto.imagenes = HandleImages(producto.imagenes, "directus_files_id");
  producto.tonos_de_color = HandleImages(
    producto.tonos_de_color,
    "tonos_de_color_id.imagen"
  );

  const tonos_de_color = {
    // Negro: [],
    //"Negros Y Grises": [], // FIXME: Borrame cuando esté corregido en el back
    Negros: [],
    Rubios: [],
    Castaños: [],
    //"Castaños Y Cobre": [], // FIXME: idem
    Crazy: [],
    Rojo: [],
    Rojos: [],
    Aclarantes: [],
    Cenizas: []
  };

  producto.tonos_de_color.forEach(el => {
    if (el.iniciales && el.iniciales.length > 0) {
      el.iniciales = HandleImages(el.iniciales, "directus_files_id");
      el.iniciales.sort(
        (a, b) =>
          parseInt(a.nombre.charAt(0), 10) - parseInt(b.nombre.charAt(0), 10)
      );
    }

    if (el.finales && el.finales.length > 0) {
      el.finales = HandleImages(el.finales, "directus_files_id");
      el.finales.sort(
        (a, b) =>
          parseInt(a.nombre.charAt(0), 10) - parseInt(b.nombre.charAt(0), 10)
      );
    }

    if (tonos_de_color[el.tonos_de_color])
      tonos_de_color[el.tonos_de_color].push(el);
  });

  producto.tonos_de_color = {
    // negro: tonos_de_color["Negro"],
    //negros_y_grises: tonos_de_color["Negros Y Grises"], // FIXME: Borrame cuando esté corregido en el back
    negros: tonos_de_color["Negros"],
    rubios: tonos_de_color["Rubios"],
    rojo: tonos_de_color["Rojo"],
    rojos: tonos_de_color["Rojos"],
    castanos: tonos_de_color["Castaños"],
    //castano_y_cobre: tonos_de_color["Castaños Y Cobre"], // FIXME: idem
    crazy: tonos_de_color["Crazy"],
    aclarantes: tonos_de_color["Aclarantes"],
    cenizas: tonos_de_color["Cenizas"]
  };

  producto.tonos_de_color.total =
    // producto.tonos_de_color.negro.length +
    //producto.tonos_de_color.negros_y_grises.length + // FIXME: idem
    producto.tonos_de_color.negros.length +
    producto.tonos_de_color.rubios.length +
    producto.tonos_de_color.rojo.length +
    producto.tonos_de_color.rojos.length +
    producto.tonos_de_color.castanos.length +
    producto.tonos_de_color.cenizas.length +
    //producto.tonos_de_color.castano_y_cobre.length + // FIXME: idem
    producto.tonos_de_color.crazy.length;

  producto.productos_relacionados = producto.productos_relacionados.filter(
    prod => prod.producto_relacionado_id
  );

  producto.productos_relacionados = producto.productos_relacionados.map(el => {
    el.producto_relacionado_id.imagen = HandleImage(
      el.producto_relacionado_id.imagenes[0].directus_files_id
    );

    el.producto_relacionado_id.categoria = el.producto_relacionado_id.categoria.map(
      el => {
        return {
          ...categorias[el.categorias_id.categoria]
        };
      }
    );

    return el.producto_relacionado_id;
  });

  if (producto.tutorial)
    producto.tutorial.image_placeholder = HandleImage(
      producto.tutorial.image_placeholder
    );

  producto.categoria = producto.categoria.map(el => {
    return {
      ...categorias[el.categorias_id.categoria]
    };
  });

  /* BORRAR EN PRODUCCION */
  //producto.tutorial = {}
  //producto.tutorial.link = 'https://www.youtube.com/embed/-EChFA_-zFE'
  /* BORRAR EN PRODUCCION */

  return producto;
}

const HandleImages = (arrayfield, location) => {
  const paths = location.split(".");

  return arrayfield.map(el => {
    let item = el;

    for (let i = 0; i < paths.length; i++) {
      item = item[paths[i]];
    }

    if (paths[1]) {
      el[paths[0]][paths[1]] = HandleImage(item);
    } else {
      el[paths[0]] = HandleImage(item);
    }

    return el[paths[0]];
  });
};

const HandleImage = image => {
  if (!image) return null;
  return {
    nombre: image.title,
    src: image.data.full_url,
    id: image.id ? image.id : Math.floor(Math.random() * 1000)
  };
};

const HandleProductsRes = products => {
  return products.map(el => {
    if (el.imagenes[0]) {
      el.imagen = HandleImage(el.imagenes[0].directus_files_id);
      el.imagenes = [];
    }

    el.categoria = el.categoria.map(cat => {
      return {
        ...categorias[cat.categorias_id.categoria]
      };
    });

    // test values
    //el.vistas = Math.floor(Math.random() * 1000)
    //el.relevante = Math.floor(Math.random() * 1000)

    return el;
  });
};

export const filterCategories = (data, catbannerdata) =>
  Object.values(
    catbannerdata ? catbannerdata : categorias
  ).filter(categoria => {
    const a = data.filter(product => {
      for (let i = 0; i < product.categoria.length; i++) {
        const currCategoryName = product.categoria[i].nombre;
        if (currCategoryName === categoria.nombre) {
          return true;
        }
      }
      return false;
    });
    return a.length > 0;
  });

// Devuelve los productos filtrados
export const selectFilteredProducts = state => {
  const { data } = state.productos;
  const {
    categoria,
    lineasProductos,
    tiposProductos,
    tonosColor,
    ordenar
  } = state.filtros;

  let filteredProducts = data.slice();

  // Filtrar por categoría
  if (categoria !== "productos") {
    filteredProducts = data.filter(product => {
      for (let i = 0; i < product.categoria.length; i++) {
        const currCategoryName = product.categoria[i].nombre;

        if (currCategoryName === categoria) {
          return true;
        }
      }
      return false;
    });
  }

  // Filtrar por líneas de productos
  if (lineasProductos.length > 0) {
    filteredProducts = filteredProducts.filter(product => {
      for (let i = 0; i < lineasProductos.length; i++) {
        const currFiltroLineaProducto = lineasProductos[i];

        for (let k = 0; k < product.linea_de_producto.length; k++) {
          const currLineaProducto =
            product.linea_de_producto[k].linea_de_producto_id.linea_de_producto;

          if (currLineaProducto === currFiltroLineaProducto) {
            return true;
          }
        }
      }
      return false;
    });
  }

  // Filtrar por tipos de productos
  if (tiposProductos.length > 0) {
    filteredProducts = filteredProducts.filter(product => {
      for (let i = 0; i < tiposProductos.length; i++) {
        const currFiltroTipoProducto = tiposProductos[i];

        if (product.tipo_de_producto === currFiltroTipoProducto) {
          return true;
        }
      }
      return false;
    });
  }

  // Filtrar por tonos de color
  if (tonosColor.length > 0) {
    filteredProducts = filteredProducts.filter(product => {
      for (let i = 0; i < tonosColor.length; i++) {
        const currFiltroTonoColor = tonosColor[i];

        for (let k = 0; k < product.tonos_de_color.length; k++) {
          const currTonoColor =
            product.tonos_de_color[k].tonos_de_color_id.tonos_de_color;

          if (currTonoColor === currFiltroTonoColor) {
            return true;
          }
        }
      }
      return false;
    });
  }

  // Filtrar por ordenamiento
  switch (ordenar) {
    case "Más relevantes":
      // FIXME: Definir cómo sería esto. Actualmente me parece que el orden por defecto que se muestra (
      // filtrando por categoría cuando se está en la ruta /productos/:categoria) se ajusta bien al
      // criterio de relevancia. Anteriormente el filtro de 'relevancia' era un int con un valor mock para
      // testear, pero creo que no tiene sentido, porque la relevancia no puede ser un número fijo ya que
      // varía con cada search (i.e. lo que es 'más relevante' para un search no lo es para otro search).
      break;

    case "Lo más visto":
      // Los que más vistas tienen van antes, i.e. el orden es de mayor a menor
      filteredProducts = filteredProducts.sort((a, b) => b.vistas - a.vistas);
      break;

    case "Recomendados":
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a.destacado && !b.destacado) {
          return -1;
        }

        if (b.destacado && !a.destacado) {
          return 1;
        }

        return 0;
      });
      break;

    default:
      break;
  }

  return filteredProducts;
};

// Devuelve los productos filtrados por `text`. El criterio de match es que el nombre del producto
// debe contener `text` como substring. Si `text` es nulo o la string vacía '', devuelve un array vacío.
export const selectTextFilteredProducts = text => {
  return state => {
    if (!text || text === "") {
      return [];
    }

    // Eliminar acentos y demás diacríticos para que el search sea más flexible
    const sanitizedText = sanitizeName(text);

    const { data } = state.productos;

    return data.filter(producto => {
      // Special-case de "Kit Decolorante + Tonalizador"; lo ideal sería agregarle la línea
      // "Sin amoníaco" en el back y acá buscar también las líneas del producto para hacer un
      // match, pero me parece que no quieren que se vea dentro de esa categoría en el menú
      // de productos. Thus, este hack.
      if (
        sanitizedText === "sin amoniaco" &&
        producto.nombre === "Kit Decolorante + Tonalizador"
      ) {
        return true;
      }

      return sanitizeName(producto.nombre).includes(sanitizedText);
    });
  };
};

export const selectGetProductsStatus = state => {
  return state.productos.getProductsStatus;
};

// Devuelve un producto por slug
export const selectProductBySlug = slug => {
  return state => {
    const { data } = state.productos;

    const filtered = data.filter(producto => producto.slug === slug);

    if (filtered.length === 0) {
      return null;
    }

    return filtered[0];
  };
};

// Devuelve true si el producto es un producto full (i.e. si tenemos toda la data del backend), false caso
// contrario
export const isFullProduct = product => {
  // El campo que chequeamos no tiene nada de especial; lo *importante* es que sea un campo que está en
  // el query `productoIndividualQuery` pero *no* en el query `productosquery`
  return typeof product.gpspharma !== "undefined";
};

// Devuelve los "últimos productos", i.e. los productos que tienen ultimo=true en el backend. `limit` indica
// opcionalmente cuántos productos últimos se quiere seleccionar, si no se pasa ningún valor, se devuelven
// todos los productos últimos
export const selectLatestProducts = (limit = -1) => {
  return state => {
    const { data } = state.productos;

    let latestProducts = data.filter(product => product.ultimo);

    if (limit !== -1) {
      return latestProducts.slice(0, limit);
    }

    return latestProducts;
  };
};
