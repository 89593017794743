import React, { useState, useEffect } from "react";
import "./Tutoriales.scss";
import { useDispatch, useSelector } from "react-redux";
import { getHomeTutorials } from "../../../redux/tutorialesDuck";
import ProdTut from "../../../components/ProductoIndividual/ProdTut/index";
import useResizeWidth from "../../../hooks/useResizeWidth";
import { BREAKPOINT } from "../../../constants";
import { Carousel } from "3d-react-carousal";
import "./customCarousel.scss";

const LEFT = 0;
const RIGHT = 1;
//const OTHER = 2;

const Tutoriales = () => {
  const rizeWidth = useResizeWidth();
  const [currentTutorial] = useState(1);

  // Índices de los otros dos tutoriales que acompañan al principal. otherTutorials[LEFT] es el índice
  // del izquierdo (i.e. data[otherTutorials[LEFT]] es el tutorial izquierdo), otherTutorials[RIGHT] es el
  // índice del derecho
  const [otherTutorials] = useState([0, 2]);

  const dispatch = useDispatch();
  const { tutorialesHome } = useSelector((state) => state.tutoriales);


  useEffect(() => {
    dispatch(getHomeTutorials());
  }, [dispatch]);

  const containerMinHeight =
    rizeWidth >= BREAKPOINT.DESKTOP_SM ? "660px" : "325px";

  return (
    <div style={{ minHeight: containerMinHeight }}>
      <Carousel
        slides={[
          <ProdTut
            tutorial={tutorialesHome[currentTutorial]}
            titleAlign="center"
            borderBottom="none"
          />,
          <ProdTut
            tutorial={tutorialesHome[otherTutorials[LEFT]]}
            titleAlign="center"
            borderBottom="none"
          />,
          <ProdTut
            tutorial={tutorialesHome[otherTutorials[RIGHT]]}
            titleAlign="center"
            borderBottom="none"
          />,
        ]}
      />
    </div>
  );
};

export default Tutoriales;
