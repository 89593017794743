import React, { useState, useEffect } from "react";
import "./ProdImgs.scss";

const ENABLE_MORE_IMGS = false;

const ProdImgs = ({ imagenes = [], loading }) => {
  const [activeimage, setActiveImage] = useState(imagenes[0]);
  const [images, setImages] = useState(imagenes.slice(1));

  const handleImage = (i) => {
    const newactiveimg = images[i];
    setImages(images.map((el, indx) => (indx === i ? activeimage : el)));
    setActiveImage(newactiveimg);
  };

  useEffect(() => {
    setImages(imagenes.slice(1));
    setActiveImage(imagenes[0]);
  }, [imagenes]);

  // console.log(activeimage.nombre === "Rsz Aconhydrax");

  //   const aconHydraxStyles = {
  //     img: {
  //       width: "140px",
  //       height: "450px",
  //       objectFit: "fill",
  //     },
  //   };

  return (
    <>
      {imagenes && (
        <div className="prodindv-imgs-container">
          {ENABLE_MORE_IMGS && (
            <div className="prodindv-moreimgs-container">
              {images.length > 0 &&
                images.map((el, i) => (
                  <div
                    onClick={() => handleImage(i)}
                    key={el.nombre}
                    className="moreimgs-item-container"
                  >
                    <img src={el.src} alt={el.nombre} />
                  </div>
                ))}
            </div>
          )}

          <div className="prodindv-mainimg-container">
            {activeimage && (
              <div className="mainimg-container">
                <img
                  src={activeimage.src}
                  alt={activeimage.nombre}
                  className={
                    activeimage.nombre === "Rsz Aconhydrax"
                      ? "acondicionador-hydrax"
                      : ""
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProdImgs;
