import React, { useRef } from 'react';
import './BannerTut.scss';
import {
    SvgDB
} from '../../@ui';
import { useHistory } from 'react-router-dom';
import { COUNTRY } from '../../../constants';


const BannerTut = () => {

    const { push } = useHistory();
    const input = useRef(null);

    const HandleSubmit = e => {
        e.preventDefault();

        let value = input.current.value;

        if (!value) {
            value = '';
        }

        if (!input && !value) {
            return;
        }

        push({
            pathname: '/tutoriales',
            search: `?query=${value}`,
            state: { search: value }
        });
    }


    return (
        <div className="tut-banner-container">
            <div className="tut-banner-content">
                <h1>¡{COUNTRY === 've'? 'Aprende' : 'Aprendé'} todas las técnicas!</h1>
                <p>Te enseñamos a usar nuestros productos</p>
                <div className="banner-input-container">
                    <form onSubmit={HandleSubmit}>
                        <input
                            type="text"
                            placeholder="Busca el producto aquí"
                            ref={input}
                        />
                        <button className="input-svg-container">
                            {SvgDB['lupa']}
                        </button>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default BannerTut;
