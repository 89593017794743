

//filtra los productos que no estan comentados
export const excluirProductos = (products=[], countryProducts=[]) => {
  let productosCountry = [];
  products.forEach(products => {
    countryProducts.forEach(prod => {
      if (products.slug === prod) {
        productosCountry.push(products)
      }
    })
  });

  return productosCountry;
}