import React, { useEffect } from 'react';
import { scrollToTop } from '../../utils';
import { PageContent, Filtros } from '../@ui';
import { filtrosdatatutoriales as filtrosdata } from '../Data'
import BannerTut from './BannerTut';
import MainTut from './MainTut';


const TutorialesPage = ({ curcategory = '', query = '', id = 0, state = {} }) => {

    useEffect(() => {
        scrollToTop();
    });


    return (
        <>

            <BannerTut />


            <PageContent>
                <Filtros
                    curcategory={curcategory}
                    filtros={filtrosdata.filtros}
                    ordenar={false}
                />
                <MainTut
                    query={query}
                    id={id}
                    state={state}
                />
            </PageContent>


        </>
    );
}

export default TutorialesPage;