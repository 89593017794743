import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Note } from '../../../@ui';
import './CarItem2.scss'


const CarItem2 = (props) => {

    const {
        posicion = 0,
        pasos = [],
        aclaraciones = [],
        image = {},
        index,
        categoria = {},
        titulo = '',
        descripcion = '',
        onSwipedLeft,
        onSwipedRight,
    } = props

    const swipeable = useSwipeable({
        onSwipedLeft,
        onSwipedRight,
        trackMouse: true,
    });

    const [activetab, setActiveTab] = useState(0)

    return (
        <div {...swipeable} className="caritem2-container"
            style={{ transform: `translateX(-${posicion * 100}%)`, userSelect: 'none' }}>

            <div className="caritem2-ill-container car-item-container1">
                {image ?
                    <img src={image.src} alt={image.nombre} />
                    :
                    <div className={`ill-item ${categoria.clase}`}></div>
                }
            </div>

            <div
                className="caritem2-info-container car-item-container2"
                style={{ display: `${posicion !== index ? 'none' : 'block'}` }}>
                <h4>{titulo}</h4>

                {descripcion && <div className="opt-descr" dangerouslySetInnerHTML={{ __html: descripcion }}></div>}

                <div className="caritem2-tabs-container">

                    {pasos.map((el, i) => (
                        <div key={el.aplicacion_id.id}>
                            {i > 0 ?
                                <span>|</span>
                                : ''
                            }
                            <div
                                className={`tab-item ${activetab === i && 'active'}`}
                                onClick={() => setActiveTab(i)}
                            >
                                <h6 dangerouslySetInnerHTML={{ __html: el.aplicacion_id.nombre }}></h6>
                            </div>
                        </div>
                    ))}


                </div>

                <div className="caritem-p-content">
                    {pasos[activetab] &&
                        <>
                            <div className="caritem-maincontent-container" dangerouslySetInnerHTML={
                                { __html: pasos[activetab].aplicacion_id.descripcion }}></div>

                            {pasos[activetab].aplicacion_id.aclaraciones.map(el => (
                                <Note
                                    key={el.aclaraciones_de_aplicacion_id.id}
                                    title={el.aclaraciones_de_aplicacion_id.tipo}
                                    content={el.aclaraciones_de_aplicacion_id.descripcion}
                                />
                            ))}
                        </>
                    }
                    {aclaraciones && aclaraciones.map(el => (
                        <Note
                            key={el.aclaraciones_de_aplicacion_id.id}
                            title={el.aclaraciones_de_aplicacion_id.tipo}
                            content={el.aclaraciones_de_aplicacion_id.descripcion}
                        />
                    ))}
                </div>


            </div>

        </div>
    );
}

export default CarItem2;