import React, { useState } from 'react';
import './ProdUso.scss';
import CarItem1 from './CarItem1';
import CarItem2 from './CarItem2';
import { ButtonCarrousel } from '../../@ui';


const ProdUso = ({ data = [], categoria = {} }) => {

    const [posicion, setPosicion] = useState(1)

    const HandlePosition = n => {
        if (n > data.length) return;
        if (n < 1) return;

        setPosicion(n);
    }

    return (
        <div className="uso-container page-padding">

            <h5>Modo de uso</h5>

            <div className="uso-carrouselheader-container">
                <div className="carrouselheader-line">
                    <div
                        className={`carrouselheader-progress-line ${categoria.clase}`}
                        style={{ width: `${(posicion * 100) / data.length}%` }}
                    ></div>
                </div>
                <div className="carrouselheader-items-container">

                    <div className="carrouselheader-number-container">
                        <span className={`${categoria.color}`}>0{posicion}</span>
                        <span>|</span>
                        <span>0{data.length}</span>
                    </div>

                    <div className="carrouselheader-buttons-container">
                        <div
                            className={`carrouselheader-button-item ${posicion > 1 && categoria.clase}`}
                            onClick={() => HandlePosition(posicion - 1)}
                        >
                            <div className="arrow white left"></div>
                        </div>
                        <div
                            className={`carrouselheader-button-item ${posicion < data.length && categoria.clase}`}
                            onClick={() => HandlePosition(posicion + 1)}
                        >
                            <div className="arrow white right"></div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="uso-carrousel-container">
                {data.map((el, i) => {
                    if (el.tabs.length <= 0) {
                        return <CarItem1
                            key={el.id * 9200}
                            posicion={posicion - 1}
                            descripcion={el.descripcion}
                            titulo={el.nombre}
                            image={el.imagen}
                            categoria={categoria}
                            onSwipedLeft={() => HandlePosition(posicion + 1)}
                            onSwipedRight={() => HandlePosition(posicion - 1)}
                        />
                    } else {
                        return <CarItem2
                            key={el.id * 9300}
                            posicion={posicion - 1}
                            pasos={el.tabs}
                            aclaraciones={el.aclaraciones}
                            descripcion={el.descripcion}
                            titulo={el.nombre}
                            image={el.imagen}
                            index={i}
                            categoria={categoria}
                            onSwipedLeft={() => HandlePosition(posicion + 1)}
                            onSwipedRight={() => HandlePosition(posicion - 1)}
                        />
                    }
                })}

            </div>


            <div className="uso-mobilecar-buttons">
                {[...Array(data.length).keys()].map((el) => (
                    <ButtonCarrousel
                        key={(el + 1) * 600}
                        className={`${categoria.color} button-item`}
                        onClick={() => HandlePosition(el + 1)}
                        active={posicion === el + 1}
                    />
                )
                )}

            </div>

        </div>
    );
}

export default ProdUso;
