import React from 'react';
import './SearchMsg.scss'


const SearchMsg = ({ search = '' }) => {
    return (
        <div className="searchmgs-container">
            <span>Resultados para: {search}</span>
        </div>
    );
}

export default SearchMsg;