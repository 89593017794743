import { useState, useEffect } from 'react';


// Hook para obtener el tamaño actual de la ventana. Cuando el tamaño de la ventana
// cambia, el valor devuelto se actualiza.
const useResizeWidth = () => {

  const [value, setState] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleWindowSizeChange = () => {
    setState(window.innerWidth);
  }

  return value;
}

export default useResizeWidth;
