import React from 'react';
import './ButtonCarrousel.scss'

const ButtonCarrousel = (props) => {

    const {
        active = false,
        className = '',
        ...other
    } = props

    return (
        <button className={`button-item ${className} ${active ? 'active' : 'inactive'}`} {...other}></button>
    );
}

export default ButtonCarrousel;