import React, { useState } from 'react';
import './ProdMobileImgs.scss'
import {
    ButtonIcon
} from '../../@ui'


const ProdMobileImgs = ({ imagenes = [] }) => {

    const [transform, setTransform] = useState(0)


    return (
        <div className="prodmobileimgs-container">

            <div className="prodmobileimgs-content">

                <div className="prodmobileimgs-car-container">

                    {imagenes.map((el, i) => (
                        <div
                            style={{ transform: `translate(-${transform * 100}%)` }}
                            key={(i + 1) * 500}
                            className="mobimg-container">
                            <img src={el.src} alt={el.nombre} />
                        </div>
                    ))}

                </div>

                <div className="prodmobileimgs-total-container">
                    <span>{imagenes.length} FOTO{imagenes.length > 1 ? 'S' : ''}</span>
                </div>


                {imagenes.length > 1 ?
                    <div className="prodmobileimgs-buttons-container">
                        <ButtonIcon
                            icon='flecha'
                            className='bg-white-primary'
                            onClick={() => setTransform(transform - 1 < 0 ? imagenes.length - 1 : transform - 1)}
                        />
                        <ButtonIcon
                            icon='flecha'
                            className='bg-white-primary'
                            onClick={() => setTransform(transform + 1 > imagenes.length - 1 ? 0 : transform + 1)}
                        />
                    </div>
                    : ''}


            </div>


        </div>
    );
}

export default ProdMobileImgs;