import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./static/sass/app.scss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/config/store";
import { COUNTRY, AVAILABLE_COUNTRIES } from "./constants";

if (!AVAILABLE_COUNTRIES.includes(window.location.pathname.split("/")[1])) {
  window.location.href = "/" + COUNTRY + window.location.pathname;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
