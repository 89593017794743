import React from 'react';
import styles from './Veil.module.scss';


// Componente para "velar" el background (i.e. ponerle un overlay negro semi-transparente).
// Si `enabled` es true, el velo se activa; caso contrario no se activa.
const Veil = ({ enabled }) => {
  return (
    <>
      {enabled && <div className={styles.veil}/>}
    </>
  );
}

export default Veil;
