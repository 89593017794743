//los slugs comentados son los tutoriales que no aparecen

export const tutorialesAR = [
  "sin-amoniaco",
  "issue-crazy-colors",
  "issue-3d-gloss",
  // "issue-keratin-kit",
  "kit-decolorante-tonalizador",
  // "mascara-con-keratina",
  // "mascara-de-brillo",
  "kit-decolorante-issue",
  // "tratamientos-con-keratina",
  // "tratamiento-de-brillo-issue-3d-gloss",
  "brillo-extremo",
  "eco_keratin"
];