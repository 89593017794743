import React from 'react';
import './VerMas.scss'
import { Button } from '../index';



const VerMas = (props) => {


    const {
        className = '',
        ...other
    } = props

    return (
        <div className={`ver-mas-container ${className}`} {...other}>
            <Button
                text='VER MÁS'
                className='bg-grey-primary long medium' />
        </div>
    );
}

export default VerMas;