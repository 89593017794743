import { addFilteredProducts, filterProducts } from "./productosDuck";
import { filterTutorials, loadMoreTutorials } from "./tutorialesDuck";

// ACTIONS
const ADD_FILTER_SUCCESS = 'ADD_FILTER_SUCESS';
const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';

const REMOVE_FILTER_SUCCESS = 'REMOVE_FILTER_SUCCESS'
const REMOVE_ALL_FILTERS_SUCCESS = 'REMOVE_ALL_FILTERS_SUCCESS'

const REMOVE_ALL_FILTERS_DATA_SUCCESS = 'REMOVE_ALL_FILTERS_DATA'

const FILTER_QUERY = 'FILTER_QUERY'
const FILTER_ERROR = 'FILTER_ERROR';
const FILTER_BAD_REQUEST = 'FILTER_BAD_REQUEST'

const LOAD_MORE_PRODUCTS_SUCCESS = 'LOAD_MORE_PRODUCTS_SUCCESS'

const FILTER_SEARCH_REQUEST = 'FILTER_SEARCH_REQUEST'
const FILTER_SEARCH_SUCCESS = 'FILTER_SEARCH_SUCCESS'

const ADD_CATEGORIA_PRODUCTO = 'ADD_CATEGORIA_PRODUCTO';
const REMOVE_CATEGORIA_PRODUCTO = 'REMOVE_CATEGORIA_PRODUCTO';

const ADD_LINEA_PRODUCTO = 'ADD_LINEA_PRODUCTO';
const REMOVE_LINEA_PRODUCTO = 'REMOVE_LINEA_PRODUCTO';

const ADD_TIPO_PRODUCTO = 'ADD_TIPO_PRODUCTO';
const REMOVE_TIPO_PRODUCTO = 'REMOVE_TIPO_PRODUCTO';

const ADD_TONO_COLOR = 'ADD_TONO_COLOR';
const REMOVE_TONO_COLOR = 'REMOVE_TONO_COLOR';

const SET_CATEGORIA = 'SET_CATEGORIA';
const SET_ORDENAR = 'SET_ORDENAR';
const SET_CATEGORIAS_PRODUCTOS = 'SET_CATEGORIAS_PRODUCTOS';
const SET_LINEAS_PRODUCTOS = 'SET_LINEAS_PRODUCTOS';
const SET_TIPOS_PRODUCTOS = 'SET_TIPOS_PRODUCTOS';
const SET_TONOS_COLOR = 'SET_TONOS_COLOR';

const RESET_FILTERS = 'RESET_FILTERS';


// REDUCER
const initialState = {
    loading: false,
    message: '',
    filtrosactivos: [],

    // Tab de categoría, i.e. "productos" (cuando ningún tab está activo), "Coloración",
    // "Complementos", "Kits" o "Cuidado del cabello"
    categoria: '',

    // Categorías de productos
    categoriasProductos: [],

    // Líneas de productos
    lineasProductos: [],

    // Tipos de productos
    tiposProductos: [],

    // Tonos de color
    tonosColor: [],

    // Ordenamiento, i.e. 'Más relevantes', 'Lo más visto', 'Recomendados'
    ordenar: '',

    page: 1,
    search: ''
}

export const filtrosReducer = (state = initialState, action) => {

    switch (action.type) {

        case FILTER_QUERY:
            return {
                ...state,
                loading: true,
                message: '',
                search: ''
            }


        case ADD_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: '',
                filtrosactivos: [...state.filtrosactivos, action.payload],
                page: 1,
            }

        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: '',
                categoria: action.payload,
                page: 1,
            }

        case FILTER_ERROR: {
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        }

        case FILTER_BAD_REQUEST:
            return {
                ...state,
                loading: false,
                message: ''
            }


        case REMOVE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: '',
                filtrosactivos: action.payload,
                page: 1,
            }

        case REMOVE_ALL_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: '',
                filtrosactivos: [],
                page: 1
            }

        case LOAD_MORE_PRODUCTS_SUCCESS:
            return {
                ...state,
                page: state.page + 1,
                loading: false,
                message: '',
            }

        case FILTER_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                message: '',
                page: 1,
                filtrosactivos: [],
                categoria: {},
                search: ''
            }

        case FILTER_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                message: '',
                search: action.payload,
                page: 1,
            }

        case REMOVE_ALL_FILTERS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                filtrosactivos: [],
                categoria: {},
                page: 1
            }

        case ADD_LINEA_PRODUCTO:
            {
                const newLineasProductos = state.lineasProductos.slice();
                newLineasProductos.push(action.payload);

                return {
                    ...state,
                    lineasProductos: newLineasProductos,
                };
            }

        case REMOVE_LINEA_PRODUCTO:
            {
                const newLineasProductos = state.lineasProductos.slice();
                const index = newLineasProductos.indexOf(action.payload);

                if (index > -1) {
                    newLineasProductos.splice(index, 1);
                }

                return {
                    ...state,
                    lineasProductos: newLineasProductos,
                };
            }

        case ADD_TIPO_PRODUCTO:
            {
                const newTiposProductos = state.tiposProductos.slice();
                newTiposProductos.push(action.payload);

                return {
                    ...state,
                    tiposProductos: newTiposProductos,
                };
            }

        case REMOVE_TIPO_PRODUCTO:
            {
                const newTiposProductos = state.tiposProductos.slice();
                const index = newTiposProductos.indexOf(action.payload);

                if (index > -1) {
                    newTiposProductos.splice(index, 1);
                }

                return {
                    ...state,
                    tiposProductos: newTiposProductos,
                };
            }

        case ADD_TONO_COLOR:
            {
                const newTonosColor = state.tonosColor.slice();
                newTonosColor.push(action.payload);

                return {
                    ...state,
                    tonosColor: newTonosColor,
                };
            }

        case REMOVE_TONO_COLOR:
            {
                const newTonosColor = state.tonosColor.slice();
                const index = newTonosColor.indexOf(action.payload);

                if (index > -1) {
                    newTonosColor.splice(index, 1);
                }

                return {
                    ...state,
                    tonosColor: newTonosColor,
                };
            }

        case ADD_CATEGORIA_PRODUCTO:
            {
                const newCategoriasProductos = state.categoriasProductos.slice();
                newCategoriasProductos.push(action.payload);

                return {
                    ...state,
                    categoriasProductos: newCategoriasProductos,
                };
            }

        case REMOVE_CATEGORIA_PRODUCTO:
            {
                const newCategoriasProductos = state.categoriasProductos.slice();
                const index = newCategoriasProductos.indexOf(action.payload);

                if (index > -1) {
                    newCategoriasProductos.splice(index, 1);
                }

                return {
                    ...state,
                    categoriasProductos: newCategoriasProductos,
                };
            }

        case SET_CATEGORIA:
            return {
                ...state,
                categoria: action.payload,
            };

        case SET_ORDENAR:
            return {
                ...state,
                ordenar: action.payload,
            };

        case SET_CATEGORIAS_PRODUCTOS:
            return {
                ...state,
                categoriasProductos: [action.payload],

                // Reiniciamos los otros filtros porque la idea es que sólo el último filtro
                // clickeado tenga efecto
                lineasProductos: [],
                tiposProductos: [],
                tonosColor: [],
            };

        case SET_LINEAS_PRODUCTOS:
            return {
                ...state,
                lineasProductos: [action.payload],

                // Reiniciamos los otros filtros
                categoriasProductos: [],
                tiposProductos: [],
                tonosColor: [],
            };

        case SET_TIPOS_PRODUCTOS:
            return {
                ...state,
                tiposProductos: [action.payload],

                // Reiniciamos los otros filtros
                categoriasProductos: [],
                lineasProductos: [],
                tonosColor: [],
            };

        case SET_TONOS_COLOR:
            return {
                ...state,
                tonosColor: [action.payload],

                // Reiniciamos los otros filtros
                categoriasProductos: [],
                lineasProductos: [],
                tiposProductos: [],
            };

        case RESET_FILTERS:
            return {
                ...state,
                categoriasProductos: [],
                lineasProductos: [],
                tiposProductos: [],
                tonosColor: [],
                ordenar: '',
            };

        default:
            return state;
    }
}


// ACTIONS
const filterQuery = () => ({
    type: FILTER_QUERY
})

const filterError = (msg) => ({
    type: FILTER_ERROR,
    payload: msg
})

const filterBadRequest = () => ({
    type: FILTER_BAD_REQUEST
})

/*
const addFilterSuccess = (data) => ({
    type: ADD_FILTER_SUCCESS,
    payload: data
})
*/

const addCategorySuccess = data => ({
    type: ADD_CATEGORY_SUCCESS,
    payload: data
})

/*
const removeFilterSuccess = (nuevosfiltros) => ({
    type: REMOVE_FILTER_SUCCESS,
    payload: nuevosfiltros
})
*/

const removeAllFiltersSuccess = () => ({
    type: REMOVE_ALL_FILTERS_SUCCESS
})

const loadMoreProductsSuccess = () => ({
    type: LOAD_MORE_PRODUCTS_SUCCESS
})

const filterSearchRequest = () => ({
    type: FILTER_SEARCH_REQUEST
})

const filterSearchSuccess = (value) => ({
    type: FILTER_SEARCH_SUCCESS,
    payload: value
})

const removeAllFilterDataSuccess = () => ({
    type: REMOVE_ALL_FILTERS_DATA_SUCCESS
})

const addLineaProducto = (value) => ({
    type: ADD_LINEA_PRODUCTO,
    payload: value,
});

const removeLineaProducto = (value) => ({
    type: REMOVE_LINEA_PRODUCTO,
    payload: value,
})

const addTipoProducto = (value) => ({
    type: ADD_TIPO_PRODUCTO,
    payload: value,
});

const removeTipoProducto = (value) => ({
    type: REMOVE_TIPO_PRODUCTO,
    payload: value,
});

const addTonoColor = (value) => ({
    type: ADD_TONO_COLOR,
    payload: value,
});

const removeTonoColor = (value) => ({
    type: REMOVE_TONO_COLOR,
    payload: value,
});

const addCategoriaProducto = (value) => ({
    type: ADD_CATEGORIA_PRODUCTO,
    payload: value,
});

const removeCategoriaProducto = (value) => ({
    type: REMOVE_CATEGORIA_PRODUCTO,
    payload: value,
});

const _setCategoria = (categoria) => ({
    type: SET_CATEGORIA,
    payload: categoria,
});

const _setOrdenar = (ordenar) => ({
    type: SET_ORDENAR,
    payload: ordenar,
});

const setCategoriasProductos = (categoriasProductos) => ({
    type: SET_CATEGORIAS_PRODUCTOS,
    payload: categoriasProductos,
});

const setLineasProductos = (lineasProductos) => ({
    type: SET_LINEAS_PRODUCTOS,
    payload: lineasProductos,
});

const setTiposProductos = (tiposProductos) => ({
    type: SET_TIPOS_PRODUCTOS,
    payload: tiposProductos,
});

const setTonosColor = (tonosColor) => ({
    type: SET_TONOS_COLOR,
    payload: tonosColor,
});

const _resetFilters = () => ({
    type: RESET_FILTERS,
    payload: null,
});


export function resetFilters() {
    return async (dispatch, getState) => {
        dispatch(_resetFilters());
    }
}

export function setFilter(filtro) {

    return async (dispatch, getState) => {
        // Despachar acción apropiada para cada tipo de filtro
        if (filtro.query.startsWith('producto.categoria')) {
            dispatch(setCategoriasProductos(filtro.value));
        }

        if (filtro.query.startsWith('producto.linea_de_producto') || filtro.query.startsWith('linea_de_producto')) {
            dispatch(setLineasProductos(filtro.value));
        }

        if (filtro.query.startsWith('tipo_de_producto')) {
            dispatch(setTiposProductos(filtro.value));
        }

        if (filtro.query.startsWith('tonos_de_color')) {
            dispatch(setTonosColor(filtro.value));
        }
    }
}

export function addFilter(filtro) {

    return async (dispatch, getState) => {
        // Checkear checkbox
        if (filtro.element) {
            HandleCheckboxState(filtro.element, true);
        }

        // Despachar acción apropiada para cada tipo de filtro
        if (filtro.query.startsWith('linea_de_producto') || filtro.query.startsWith('producto.linea_de_producto')) {
            dispatch(addLineaProducto(filtro.value));
        }

        if (filtro.query.startsWith('tipo_de_producto')) {
            dispatch(addTipoProducto(filtro.value));
        }

        if (filtro.query.startsWith('tonos_de_color')) {
            dispatch(addTonoColor(filtro.value));
        }

        if (filtro.query.startsWith('producto.categoria')) {
            dispatch(addCategoriaProducto(filtro.value));
        }
    }
}

export function addCategory(categoria) {

    return async (dispatch, getState) => {
        dispatch(filterQuery())

        try {
            const state = getState().filtros

            // Crear query
            const finalquery = getQuery([...state.filtrosactivos, categoria])

            // dispatch filter products
            await dispatch(filterProducts(finalquery))

            dispatch(addCategorySuccess(categoria))


        } catch (e) {
            dispatch(filterError('Hubo un error agregando categoria'))
        }

    }
}


export function removeFilter(filtro) {

    return async (dispatch, getState) => {
        if (filtro.element) {
            HandleCheckboxState(filtro.element, false);
        }

        if (filtro.query.startsWith('linea_de_producto') || filtro.query.startsWith('producto.linea_de_producto')) {
            dispatch(removeLineaProducto(filtro.value));
        }

        if (filtro.query.startsWith('tipo_de_producto')) {
            dispatch(removeTipoProducto(filtro.value));
        }

        if (filtro.query.startsWith('tonos_de_color')) {
            dispatch(removeTonoColor(filtro.value));
        }

        if (filtro.query.startsWith('producto.categoria')) {
            dispatch(removeCategoriaProducto(filtro.value));
        }
    }
}


export function removeAllFilters() {

    return async (dispatch, getState) => {
        dispatch(filterQuery())

        try {
            const state = getState().filtros

            if (state.filtrosactivos.length <= 0) {
                dispatch(filterBadRequest())
                return;
            }

            const finalquery = getQuery([state.categoria])

            await HandleFilterRequest(finalquery, dispatch)

            state.filtrosactivos.forEach(el => {
                if (el.element)
                    HandleCheckboxState(el.element, false)
            })

            dispatch(removeAllFiltersSuccess())

        } catch (e) {
            dispatch(filterError('Hubo un error borrando filtros'))
        }

    }

}

export function removeAllFilterData() {
    return async (dispatch, getState) => {
        dispatch(filterQuery())

        try {
            const state = getState().filtros

            if (state.filtrosactivos.length <= 0 && state.categoria === {}) {
                dispatch(filterBadRequest())
                return;
            }

            if (state.filtrosactivos.length > 0)
                state.filtrosactivos.forEach(el => {
                    if (el.element)
                        HandleCheckboxState(el.element, false)
                })

            dispatch(removeAllFilterDataSuccess())

        } catch (e) {
            dispatch(filterError('Hubo un error borrando la data de los filtros'))
        }

    }
}

export function loadMoreProducts() {

    return async (dispatch, getState) => {
        // dispatch(filterQuery())

        try {

            const { location: { pathname } } = window

            const state = getState().filtros

            const finalquery = getQuery([...state.filtrosactivos, state.categoria])

            if (pathname.startsWith('/productos')) {
                await dispatch(addFilteredProducts(finalquery, state.page + 1))
            } else if (pathname.startsWith('/tutoriales')) {
                await dispatch(loadMoreTutorials(finalquery, state.page + 1))
            }

            dispatch(loadMoreProductsSuccess())

        } catch (e) {
            dispatch(filterError('Hubo un error cargando mas productos'))
        }

    }


}

export function setCategoria(categoria) {
    return async (dispatch) => {
        dispatch(_setCategoria(categoria));
    };
}

export function setOrdenar(ordenar) {
    return async (dispatch) => {
        dispatch(_setOrdenar(ordenar));
    }
}

export function searchFilter(filtro) {

    return async dispatch => {
        dispatch(filterSearchRequest())

        try {

            const finalquery = getQuery([filtro])

            await HandleFilterRequest(finalquery, dispatch)

            dispatch(filterSearchSuccess(filtro.value))

        } catch (e) {
            dispatch(filterError('Hubo un error buscando mas productos'))

        }

    }
}



const getQuery = (filtersarray) => {
    const filters = {}

    // eslint-disable-next-line array-callback-return
    filtersarray.map(el => {
        // eslint-disable-next-line array-callback-return
        if (!el.query || el.id === 'productos') return;


        // eslint-disable-next-line array-callback-return
        if (el.value === 'Todas' || el.value === 'Todos los productos') return


        if (!filters[el.query]) {
            filters[el.query] = ''
        }
        filters[el.query] += filters[el.query] ? `,${el.value}` : `${el.value}`;
    })

    return Object.keys(filters).map((el) => {

        let operator;
        if (el === 'producto.nombre' || el === 'nombre') {
            operator = 'contains'
        } else if (el.startsWith('categoria')) {
            operator = 'eq'
        } else {
            operator = 'in'
        }

        return `&filter[${el}][${operator}]=${filters[el]}`
    })
}

const HandleCheckboxState = (chbx, checked) => {
    if (checked) {
        chbx.checked = true
        chbx.classList.add('checked')
    } else {
        chbx.checked = false
        chbx.classList.remove('checked')
    }
}

const HandleFilterRequest = async (finalquery, dispatch) => {
    const { location: { pathname } } = window

    if (pathname.startsWith('/productos')) {
        await dispatch(filterProducts(finalquery))
    } else if (pathname.startsWith('/tutoriales')) {
        await dispatch(filterTutorials(finalquery))
    }
}

export const selectCategoria = (state) => {
    return state.filtros.categoria;
}

export const selectOrdenar = (state) => {
    return state.filtros.ordenar;
}
