import React from 'react';
import './Select.scss'

const Select = (props) => {


    const {
        title = '',
        ...other
    } = props


    return (
        <div className="select-container" {...other}>

            <span>{title}</span>

            <div className="select-icon-container">
                <div className="select-icon"></div>
            </div>

        </div>
    );
}

export default Select;