import React from 'react';
import PropTypes from 'prop-types';
import './Typography.scss';

const Typography = props => {
    const {
        size = '14px',
        className = '',
        children
    } = props;

    const style = {
        fontSize: size,
    };

    return (
        <span className={`text-info ${className}`} style={style}>
            {children}
        </span>
    );
};

Typography.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
};

export default Typography;
