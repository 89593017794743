import React from 'react';
import { useSwipeable } from 'react-swipeable';

const CarItem1 = (props) => {

    const {
        posicion = 0,
        titulo = '',
        descripcion = '',
        image = {},
        categoria = {},
        onSwipedLeft,
        onSwipedRight,
    } = props

    const swipeable = useSwipeable({
        onSwipedLeft,
        onSwipedRight,
        trackMouse: true,
    });

    return (
        <div {...swipeable} className="caritem1-container" style={{ transform: `translateX(-${posicion * 100}%)` }}>

            <div className="caritem1-ill-container car-item-container1">
                {image ?
                    <img src={image.src} alt={image.nombre} />
                    :
                    <div className={`ill-item ${categoria.clase}`}></div>
                }
            </div>

            <div className="caritem1-info-container car-item-container2">
                <h4>{titulo}</h4>
                <p style={{ userSelect: 'none' }} dangerouslySetInnerHTML={{ __html: descripcion }}></p>
            </div>

        </div>
    );
}

export default CarItem1;