/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./ProductoIndividual.scss";
import {
  CategoriasBanner,
  Producto,
  ButtonCarrousel,
  LoadingProduct,
  Message,
} from "../@ui";
import ProdImgs from "./ProdImgs";
import ProdInfo from "./ProdInfo";
import ProdUso  from "./ProdUso";
import ProdMobileImgs from "./ProdMobileImgs";

import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../redux/productosDuck";
import ProdTut from "./ProdTut";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils";

import { COUNTRY, eshops } from "../../constants.js";

const ProductoIndividual = ({ id, curcategory }) => {
  const [transition, setTransition] = useState(0);
  const dispatch = useDispatch();
  const { loadingproduct, messageproduct, currentproduct } = useSelector(
    (state) => state.productos
  );

  // Obtener producto
  useEffect(() => {
    dispatch(getProduct(id, curcategory));
  }, [curcategory, dispatch, id]);

  // Scrollear hasta arriba
  useEffect(() => {
    scrollToTop();
  }, []);

  // categoria actual
  const currentcategory = currentproduct.categoria
    ? currentproduct.categoria[0]
    : {};

  // CARGANDO...
  if (
    loadingproduct ||
    (Object.keys(currentproduct).length === 0 && !messageproduct)
  ) {
    return <LoadingProduct />;
  }

  // ERROR
  if (!loadingproduct && messageproduct) {
    return (
      <>
        <div className="mt-100"></div>
        <Message message={messageproduct} />
        <div className="mb-100"></div>
      </>
    );
  }

  let links = {};
  eshops[COUNTRY].forEach((eshop) => {
    links[eshop] = currentproduct[eshop];
    if (eshop === "tata") {
      links.baseUrlTata = currentproduct[eshop];
    }

    if (eshop === "salonmarket") {
      links.baseUrlSalonmarket = currentproduct[eshop];
    }
  });

  return (
    <>
      {!loadingproduct &&
        !messageproduct &&
        Object.keys(currentproduct).length > 0 && (
          <>
            <CategoriasBanner
              className="hidden-mob"
              categoriaproducto={currentcategory.slug}
            />

            <div className="page-padding">
              <div className="prodindv-links-container">
                {/* MOBILE CURRENT CATEGORY */}
                <div className="mobile-link">
                  <Link to={`/${COUNTRY}/productos`}>
                    <span className="arrow-left">Volver</span>
                  </Link>
                  <div className="current-cat-banner">
                    <h5 className={currentcategory.clase}>
                      {currentcategory.nombre}
                    </h5>
                  </div>
                </div>

                <div className="desktop-links">
                  <Link
                    className="before"
                    to={`/${COUNTRY}/productos/${currentproduct.categoria[0].slug}`}
                  >
                    {currentcategory.nombre}
                  </Link>
                  <div className="arrow right before"></div>
                  <a href="#">{currentproduct.nombre}</a>
                </div>
              </div>

              <div className="prodindv-content-container">
                <div className="mobile-product-title">
                  <h4>{currentproduct.nombre}</h4>
                </div>
                <ProdMobileImgs imagenes={currentproduct.imagenes} />
                <ProdImgs
                  loading={loadingproduct}
                  imagenes={currentproduct.imagenes}
                />
                <ProdInfo
                  nombre={currentproduct.nombre}
                  descripcion={currentproduct.descripcion}
                  links={links}
                  categoria={currentcategory}
                  tonosdecolor={currentproduct.tonos_de_color}
                />
              </div>
            </div>

            <div className="prodindv-uso-container">
              {currentproduct.modo_de_uso && (
                <ProdUso
                  data={currentproduct.modo_de_uso}
                  categoria={currentcategory}
                />
              )}
            </div>

            {currentproduct.tutorial && (
              <ProdTut tutorial={currentproduct.tutorial} />
            )}

            {currentproduct.productos_relacionados &&
              currentproduct.productos_relacionados.length > 0 && (
                <div className="prodindv-relacionados-container page-padding">
                  <span className="medium color-dark-primary">
                    Productos relacionados
                  </span>
                  <div className="relacionados-content">
                    {currentproduct.productos_relacionados.map((el, i) =>
                      i >= 3 ? (
                        ""
                      ) : (
                        <Producto
                          imagen={el.imagen}
                          nombre={el.nombre}
                          id={el.id}
                          key={el.id}
                          categoria={el.categoria[0]}
                          slug={el.slug}
                          style={{
                            transform: `translate(-${transition * 100}%)`,
                          }}
                          onSwipedLeft={() => {
                            if (
                              transition <
                              currentproduct.productos_relacionados.length - 1
                            ) {
                              setTransition(transition + 1);
                            }
                          }}
                          onSwipedRight={() => {
                            if (transition > 0) {
                              setTransition(transition - 1);
                            }
                          }}
                        />
                      )
                    )}
                  </div>

                  {currentproduct.productos_relacionados.length >= 3 && (
                    <div className="prodinv-carbuttons">
                      {[...Array(3).keys()].map((el) => (
                        <ButtonCarrousel
                          key={(el + 1) * 9000}
                          className="color-grey-primary button-item"
                          onClick={() => setTransition(el)}
                          active={transition === el}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
          </>
        )}
    </>
  );
};

export default ProductoIndividual;
