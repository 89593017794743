import { useState, useEffect } from "react"

const createMessage = (texto) => {
    const message = `
    <div class="popmessage">
        ${texto}
    </div>
    `

    return (new DOMParser().parseFromString(message, "text/html")).body.firstChild
}

export const PopMessage = () => {
    const [message, setMessage] = useState('')

    let animating = false
    useEffect(() => {

        if (animating) return;
        if (!message) return;

        const newmessage = createMessage(message)

        document.querySelector('body').appendChild(newmessage)

        const popup = document.querySelector('.popmessage')

        if (!popup) return

        // eslint-disable-next-line react-hooks/exhaustive-deps
        animating = true

        setTimeout(() => {
            popup.classList.add('active')
        }, 50)


        setTimeout(() => {
            popup.classList.remove('active')
            setTimeout(() => {
                popup.parentElement.removeChild(popup)
                setMessage('')
                animating = false
            }, 300)
        }, 1000)

    }, [message])


    return setMessage

}