import React, { useEffect } from "react";
import "./ComprarPopUp.scss";

import { eshopsByCountry } from "../../Data/index.js";

import { COUNTRY } from "../../../constants.js";

const ComprarPopUp = (props) => {
  const { active = false, setActive } = props;

  useEffect(() => {
    if (active) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [active]);

  const HandleClick = (e) => {
    if (e.target.classList.contains("comprar-container")) {
      setActive(false);
    }
  };

  let eshops = eshopsByCountry[COUNTRY];

  const { productEshops = false } = props;
  if (productEshops) {
    eshops = eshops.map((shop) => {
      shop.url = productEshops[shop.name];
      return shop;
    });
  }

  //console.log("eshops: ", eshops);
  eshops = eshops.filter((eshop) => eshop.url);

  //console.log(eshops);

  return (
    <>
      {!active ? (
        ""
      ) : (
        <div className="comprar-container" onClick={HandleClick}>
          <div className="comprar-content">
            <div
              className="cross comprar-cross-container"
              style={{
                float: "right",
                height: "40px",
                width: "40px",
              }}
              onClick={() => setActive(false)}
            />

            <span>Elegí un comercio y finaliza tu compra</span>

            {/* console.log(COUNTRY) */}
            {/* console.log('pais:' + COUNTRY === 'cl') */}

            <div className="comprar-options-container">
              {eshops.map((eshop, index) => (
                <>
                  <a href={eshop.url} target="_blank" rel="noopener noreferrer">
                    <img
                      className={`${COUNTRY === "cl" ? "retailer-chile" : ""} ${
                        COUNTRY === "uy" ? "retailer-uruguay" : ""
                      } ${COUNTRY === "py" ? "retailer-paraguay" : ""} ${
                        COUNTRY === "ec" ? "retailer-ecuador" : ""
                      } `}
                      style={eshop.extraStyles}
                      src={eshop.img}
                      alt="gps-pharma-logo"
                    />
                  </a>
                  <div className="comprar-options-separator"></div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ComprarPopUp;
