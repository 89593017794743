import React from 'react';
import './ProdContent.scss'

const ProdContent = (props) => {

    const {
        children,
        state = false,
        name = '',
        index,
        setActiveContent,
        className = ''
    } = props

    return (
        <div className='prodcontent-container'>
            <div
                className="prodcontent-title-container"
                onClick={() => setActiveContent(index === state ? Infinity : index)}
            >
                <h5>{name}</h5>
                <span>{state === index ? '-' : '+'}</span>
            </div>
            <div className={`prodcontent-content ${className} ${state === index && 'active'}`}>
                {children}
            </div>
        </div>
    );
}

export default ProdContent;