import React, { useState } from 'react';
import './Productos.scss';
import {
    SectionTitle,
    Producto,
    ButtonCarrousel,
    Button,
    LoadingProducts,
    Message
} from '../../@ui';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useProducts from '../../../hooks/useProducts';

import { COUNTRY } from '../../../constants';

const Productos = () => {

    const { push } = useHistory();
    const [transition, setTransition] = useState(0);
    const { message, loading } = useSelector(state => state.productos);
    const {data, productsData } = useProducts();

    return (
        <>
            <SectionTitle
                title='Los productos más buscados'
                btnClassName='border-green-dark color-green-dark large bold hover-invert after-green-dark'
                btnText='Ver todos los productos'
                textTransform='uppercase'
                onClick={() => push(`/${COUNTRY}/productos`)}
                />
            {loading && <LoadingProducts cantidad={4} />}
            {!loading && message && <Message message={message} />}
            {!loading && productsData.length > 0 &&
                <>
                    <div className="iproductos-container page-padding">

                        <div className="iproductos-content flex">
                            {productsData.slice(0, 4).map((el, i) => {
                                return (
                                    <Producto
                                        imagen={el.imagen}
                                        nombre={el.nombre}
                                        id={el.id}
                                        key={el.id}
                                        categoria={el.categoria[0]}
                                        slug={el.slug}
                                        style={{ transform: `translate(-${transition * 120}%)` }}
                                        onSwipedLeft={() => {
                                            if (transition < productsData.slice(0, 4).length - 2) {
                                                setTransition(transition + 1);
                                            }
                                        }}
                                        onSwipedRight={() => {
                                            if (transition > 0) {
                                                setTransition(transition - 1);
                                            }
                                        }}
                                />);
                            })}
                        </div>

                        <div className="iproductos-carbuttons-container">
                            {[...Array(3).keys()].map((el) => (
                                <ButtonCarrousel
                                    key={(el + 1) * 10}
                                    className='color-grey-primary button-item'
                                    onClick={() => setTransition(el)}
                                    active={transition === el}
                                />
                            ))}
                        </div>

                    </div>
                    <div className="iproductos-button-container ">
                        <Button
                            text='Ver todos los productos'
                            className='border-green-dark color-green-dark large bold hover-invert after-green-dark'
                            textTransform='uppercase'
                            onClick={() => push(`/${COUNTRY}/productos`)}
                        />
                    </div>
                </>
            }

        </>
    );
}

export default Productos;
