import React from "react";
import "./Footer.scss";
import { SvgDB } from "../../@ui";
import { Link } from "react-router-dom";

import { COUNTRY } from "../../../constants.js";
import AR from "./footer_img/argentina.png";
import BOL from "./footer_img/bolivia.png";
import CL from "./footer_img/chile.png";
import PAR from "./footer_img/paraguay.png";
import PE from "./footer_img/peru.png";
import UY from "./footer_img/uruguay.png";
import EC from "./footer_img/ecuador.png";
import VE from './footer_img/vene.png';
import flecha from "./footer_img/flecha.svg";

const socialNetworks = {
  ar: {
    instagram: "https://www.instagram.com/issuecolor/?hl=es-la",
    facebook: "https://www.facebook.com/issuecolor/",
    youtube: "https://www.youtube.com/issuecolor/",
  },
  pe: {
    instagram: "https://www.instagram.com/issuecolorperu/",
    facebook:
      "https://www.facebook.com/Issue-Color-Per%C3%BA-106223618260346/?_rdc=2&_rdr",
  },
  cl: {
    instagram: "https://www.instagram.com/issuecolorchile/?hl=es-la",
    facebook: "https://www.facebook.com/Issue-Color-Chile-111710447688715",
  },
  uy: {
    instagram: "https://www.instagram.com/issuecoloruy",
    facebook: "https://www.facebook.com/IssueUruguay",
  },
  bo: {
    instagram: "https://www.instagram.com/issuecolorbolivia/",
    facebook: "https://www.facebook.com/ISSUE-Bolivia-104029271630276",
    youtube: "https://www.youtube.com/issuecolor/",
  },
  ec: {
    instagram: "https://www.instagram.com/issuecolorecuador/",
    facebook: "https://www.facebook.com/issuecolorec",
    youtube: "https://www.youtube.com/issuecolor/",
  },
  py: {
    instagram: "https://www.instagram.com/issue_paraguay/",
    facebook:
      "https://www.facebook.com/issuecolor/?brand_redir=132912750090874",
    youtube: "https://www.youtube.com/issuecolor/",
  },
  ve: {
    instagram: "https://www.instagram.com/issuevenezuela/",
    facebook: "https://www.facebook.com/issuevenezuela-286647693176645/",
    whatsapp: "https://wa.me/584248384954"

  },
};
const derechosConsumidor = {
  ar: "https://www.argentina.gob.ar/produccion/defensadelconsumidor",
};

const contact = {
  ar: "0800 444 7783",
  pe: "994117904",
  cl: "24387900",
  uy: "(598) 24872939",
  ve: "58-424-838-4954",
};

const Footer = () => {
  return (
    <div id="footer">
      <div className="brand footer-padding">
        <div className="logo">
          <div className="logo-svg-container">{SvgDB["logo_white"]}</div>
        </div>
        <div className="copyright">
          <p>© 2022 Issue - Todos los derechos reservados V.5.0 ®</p>
        </div>
      </div>
      <div className="mobile-line-separator"></div>

      <div className="footer-section footer-desktop-section">
        <div className="footer-section-content footer-links-content hidden-mob">
          <ul>
            <div className="dropdown" style={{"zIndex": 999}}>
              <li className="dropbtn">
                Pais
                <img src={flecha} width="10" height="10" alt="flecha" />
              </li>

              <div className="dropdown-content">
                <a href={"/ar"}>
                  <img src={AR} width="20" height="25" alt="arg" /> AR
                </a>
                <a href="/bo">
                  <img src={BOL} width="20" height="25" alt="bol" /> BO
                </a>
                <a href="/cl">
                  <img src={CL} width="20" height="25" alt="cl" /> CL
                </a>
                <a href="/ec">
                  <img src={EC} width="20" height="17" alt="ec" /> EC
                </a>
                <a href="/py">
                  <img src={PAR} width="20" height="25" alt="py" /> PY
                </a>
                <a href="/pe">
                  <img src={PE} width="20" height="25" alt="pe" /> PE
                </a>
                <a href="/uy">
                  <img src={UY} width="20" height="25" alt="uy" /> UY
                </a>
                <a href="/ve">
                  <img src={VE} width="20" height="17" alt="ve" /> VE
                </a>
              </div>
            </div>
            <li>
              <Link to={`/${COUNTRY}/nuestra-historia`}>Nuestra historia</Link>
            </li>
            <li>
              <Link to={`/${COUNTRY}/preguntas-frecuentes`}>
                Preguntas frecuentes
              </Link>
            </li>
            <br />
          </ul>
        </div>
      </div>

      <div className="footer-section footer-padding footer-contact-redes" style={{'display': 'block'}}>
        {contact[COUNTRY] && (
          <>
            <div className="footer-section-content contact-content">
              <Link to={`/${COUNTRY}/contacto`}>
                <h4>Contacto</h4>
              </Link>
              <div style={{ 'marginTop':'5px' }}>
                <span>{contact[COUNTRY]}</span>
              </div>
            </div>

            {
              COUNTRY === 've' &&
                <div
                  className="footer-section-content contact-content contact"

                >
                  <h4>Whatsapp</h4>
                  <div className="footer-section-logos" style={{ 'marginTop':'5px' }}>
                    {
                      socialNetworks[COUNTRY]["whatsapp"] &&
                      <a
                        href={socialNetworks[COUNTRY]["whatsapp"]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{'display': 'inline-flex'}}
                      >
                        <span>{SvgDB["whatsapp"]}</span>
                        <span style={{'marginLeft': '.3em'}}>{contact[COUNTRY]}</span>
                      </a>
                    }
                  </div>
              </div>
            }

          </>
        )}

        <div className="footer-section-content footer-redes">
          {Object.keys(socialNetworks[COUNTRY]).length > 0 && (
            <>
              <h4>¡{COUNTRY === "ve"? 'Siguenos' : 'Seguinos'} en nuestras redes!</h4>
              <div className="footer-section-logos" style={{ 'marginTop':'5px' }} >
                {socialNetworks[COUNTRY]["facebook"] && (
                  <>
                    <a
                      href={socialNetworks[COUNTRY]["facebook"]}
                      target="__blank"
                      rel="noopener"
                    >
                      {SvgDB["facebook"]}
                    </a>
                  </>
                )}
                {socialNetworks[COUNTRY]["instagram"] && (
                  <>
                    <div className="footer-logo-line"></div>
                    <a
                      href={socialNetworks[COUNTRY]["instagram"]}
                      target="__blank"
                      rel="noopener"
                    >
                      {SvgDB["instagram"]}
                    </a>
                  </>
                )}
                {/*TODO eliminar despues, youtube no entra en ningun pais  */}
              </div>
              <div className="footer-issuecolorecuador">
                {COUNTRY === "ec" && <h4>@issuecolorecuador</h4>}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="footer-section footer-padding footer-contact-redes">

      </div>

      <div className="mobile-line-separator"></div>

      <div className="footer-section footer-padding">
        <div className="footer-section-content footer-links-content hidden-desk">
          <ul>
            <li>
              <Link to={`/${COUNTRY}/nuestra-historia`}>Nuestra historia</Link>
            </li>
            <li>
              <Link to={`/${COUNTRY}/preguntas-frecuentes`}>
                Preguntas frecuentes
              </Link>
            </li>
            {COUNTRY !== "cl" && COUNTRY !== "uy" && (
              <li>
                <Link to={`/${COUNTRY}/lo-ultimo`}>Lo último</Link>
              </li>
            )}
          </ul>
        </div>
        <div className="footer-section-content footer-links-content">
          <ul>
            {/*TODO No va mas en ningun pais*/}
            <li>
              <Link to={`/${COUNTRY}/aviso-legal`}>Aviso legal</Link>
            </li>
            <li>
              <Link to={`/${COUNTRY}/politica-privacidad`}>
                Política de privacidad
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
