import React, { useEffect } from 'react';
import ProductList from './ProductList';
import Banner from './Banner';
import { CategoriasBanner, Filtros, PageContent } from '../@ui';
import { filtrosdataproductos as filtrosdata } from '../Data';
import { searchFilter, setCategoria } from '../../redux/filtrosDuck';
import { useDispatch } from 'react-redux';
import { scrollToTop } from '../../utils';

import { COUNTRY } from '../../constants.js'

const slugtovalue = {
    'productos': 'productos',
    'coloracion': "Coloración",
    'complementos': 'Complementos',
    'kits': 'Kits',
    'cuidado-del-cabello': "Cuidado del cabello"
};


const Productos = (props) => {

    const {
        curcategory,
        state,
        query,
        location
    } = props;

    let queryCleaned = null;

    if (query) {
        queryCleaned = query.split('=')[1];
    }

    const curpath = location.pathname;
    const dispatch = useDispatch();
    const currentCategory = slugtovalue[curcategory];

    // Actualizar filtro de categoría
    useEffect(() => {
        dispatch(setCategoria(currentCategory));
    }, [currentCategory, dispatch]);

    useEffect(() => {
        if ((state && state.search) || query) {
            const value = state ? state.search : query.split('=')[1];

            dispatch(searchFilter({
                query: 'nombre',
                value,
                id: value
            }));
        }
    }, [curcategory, dispatch, query, state]);

    // Scrollear hasta arriba en primer render
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>

            <Banner curcategory={curcategory} />

            {curpath === `${COUNTRY}/productos` ?
                <CategoriasBanner />
                :
                <CategoriasBanner className="hidden-mob" />
            }


            <PageContent>
                <Filtros
                    curcategory={curcategory}
                    filtros={filtrosdata.filtros}
                    curpath={curpath}
                />
                <ProductList category={currentCategory} textFilter={queryCleaned} />
            </PageContent>

        </>
    );
}

export default Productos;
