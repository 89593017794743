import React from "react";
import InfoContainer from "../InfoContainer/InfoContainer";

import { COUNTRY } from "../../constants.js";

const AvisoLegal = () => {
  const contentByCountry = {
    ar: (
      <>
        <p>
          Laboratorio Cuenca S.A. (“LC”) ofrece productos de cuidado de la piel
          y del cabello. La utilización de sus productos cosméticos, son
          controlados y regulados por los organismos gubernamentales de cada uno
          de los países en que se comercializan. En la República Argentina, a
          través de la Administración Nacional de Medicamentos, Alimentos y
          Tecnología Médica (“ANMAT”).
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso, que ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud. Los sitios Web de
          LC son tan sólo un servicio informativo; al igual que cualquier otro
          servicio, y que a pesar de los esfuerzos realizados por LC, es posible
          que la información allí registrada se encuentre desactualizada con el
          paso del tiempo. Por lo tanto, nada de lo que allí se informa debe ser
          tomado por Usted como consejo o recomendación médica. Es importante
          que, en relación con su caso particular, Usted dependa únicamente del
          asesoramiento y del consejo de un profesional de la salud. LC no se
          hace responsable de que la información que figura en sus sitios Web
          sea totalmente exacta y exhaustiva, ni del uso que de ella pueda
          hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva GARANTÍA
          DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS, PERO SIN
          LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE COMERCIALIZACIÓN,
          SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO INDEBIDO.
        </p>
        <p>
          A través de los sitios Web de LC, Usted puede conectarse con otros
          sitios Web, incluidos los sitios Web de las afiliadas de LC en otros
          países. LC no puede responsabilizarse de la información que Usted
          pueda obtener en sitios Web de terceros y que no se encuentren bajo su
          tutela. Aunque LC trata de facilitar el enlace con sitios Web de
          terceros que cumplan con todos los requisitos y normas legales
          pertinentes, así como las normas de LC, ha de entenderse que el
          contenido de sitios Web de propiedad de terceros puede variar sin el
          conocimiento previo de LC. Es por ello, que LC no puede ni debe
          responsabilizarse, ni aceptar ningún tipo de responsabilidad, por la
          información que se proporcione ni de las opiniones que se expresen en
          los sitios Web de propiedad de terceros. Nada de los expuesto en los
          sitios Web de LC constituye una promoción de los productos
          comercializados por LC.
        </p>
      </>
    ),
    pe: (
      <>
        <p>
          Laboratorio Cuenca S.A. (“LC”) ofrece productos de cosmética capilar,
          cuidado del cabello y de la piel. La utilización de sus productos
          cosméticos es controlada y regulada por los organismos gubernamentales
          de cada uno de los países en que se comercializan. En la República del
          Perú, a través de la Dirección General de Medicamentos, Insumos y
          Drogas (“DIGEMID”). En virtud de las normas existentes al respecto, es
          preciso que, ante cualquier duda que Usted tenga respecto con la
          utilización de los productos LC, consulte a un profesional de la
          salud. Los sitios Web de LC son tan sólo un servicio informativo; al
          igual que cualquier otro servicio, y que a pesar de los esfuerzos
          realizados por LC, es posible que la información allí registrada se
          encuentre desactualizada con el paso del tiempo. Por lo tanto, nada de
          lo que allí se informa debe ser tomado por Usted como consejo o
          recomendación médica. Es importante que, en relación con su caso
          particular, Usted dependa únicamente del asesoramiento y del consejo
          de un profesional de la salud. LC no se hace responsable de que la
          información que figura en sus sitios Web sea totalmente exacta y
          exhaustiva, ni del uso que de ella pueda hacerse.
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso, que ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud. Los sitios Web de
          LC son tan sólo un servicio informativo; al igual que cualquier otro
          servicio, y que a pesar de los esfuerzos realizados por LC, es posible
          que la información allí registrada se encuentre desactualizada con el
          paso del tiempo. Por lo tanto, nada de lo que allí se informa debe ser
          tomado por Usted como consejo o recomendación médica. Es importante
          que, en relación con su caso particular, Usted dependa únicamente del
          asesoramiento y del consejo de un profesional de la salud. LC no se
          hace responsable de que la información que figura en sus sitios Web
          sea totalmente exacta y exhaustiva, ni del uso que de ella pueda
          hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva GARANTÍA
          DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS, PERO SIN
          LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE COMERCIALIZACIÓN,
          SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO INDEBIDO.
        </p>
        <p>
          Usted puede descargar material de este sitio web a su propio riesgo.
          LC no garantiza que dichos materiales estén libres de virus, gusanos,
          troyanos u otros códigos destructivos. Es su responsabilidad exclusiva
          implementar salvaguardias para proteger su sistema informático y los
          datos, y usted es responsable de los costos totales de cualquier
          servicio, reparaciones o correcciones necesarias como resultado del
          uso de este sitio web. El nombre y el logotipo de Godrej son marcas
          registradas. Todas las demás marcas comerciales, nombres de productos
          y nombres de empresas o logotipos utilizados en este sitio web son
          propiedad de sus respectivos propietarios. No se concede ningún
          permiso o licencia (ya sea expresa o implícita) con respecto al uso de
          tales marcas registradas, presentaciones, nombres de productos, logos
          o títulos, y tal uso puede constituir una violación de los derechos
          del titular. A través de los sitios Web de LC, Usted puede conectarse
          con otros sitios Web, incluidos los sitios Web de las afiliadas de LC
          en otros países. LC no puede responsabilizarse de la información que
          Usted pueda obtener en sitios Web de terceros y que no se encuentren
          bajo su tutela. Aunque LC trata de facilitar el enlace con sitios Web
          de terceros que cumplan con todos los requisitos y normas legales
          pertinentes, así como las normas de LC, ha de entenderse que el
          contenido de sitios Web de propiedad de terceros puede variar sin el
          conocimiento previo de LC. Es por ello, que LC no puede ni debe
          responsabilizarse, ni aceptar ningún tipo de responsabilidad, por la
          información que se proporcione ni de las opiniones que se expresen en
          los sitios Web de propiedad de terceros. Los derechos de autor sobre
          el contenido y las descargas de este sitio web son propiedad de de LC,
          del Grupo Godrej o terceros. Todos los derechos reservados. Usted es
          responsable de obedecer todas las leyes de derechos de autor
          aplicables. Le permitimos hacer copias de este sitio web como actos
          incidentales necesarios durante su visualización del mismo, y puede
          tomar una impresión para su uso personal de la mayor parte del sitio
          web que sea razonable para fines privados. Todo otro uso está
          estrictamente prohibido. No puede enmarcar este sitio ni vincular a
          una página que no sea la página de inicio sin nuestro permiso expreso
          por escrito. Nada de los expuesto en los sitios Web de LC constituye
          una promoción de los productos comercializados por LC.
        </p>
      </>
    ),
    cl: (
      <>
        <p>
          <b>Aviso legal</b>
          <br /> <br />
          <b>Definición:</b>
          <br />
          Se entenderá en este documento por Grupo Godrej las empresas
          “LABORATORIO CUENCA S.A.”, con domicilio en Empedrado 2435, Ciudad
          Autónoma de Buenos Aires, C1417GIC; y “COSMÉTICA NACIONAL S.A.”, con
          domicilio en domicilio en Vargas Fontecilla 3830, Comuna de Quinta
          Normal, Santiago, Región Metropolitana, Chile.
          <br />
          <br />
          <b>Descargo de responsabilidad y garantía</b>
          <br />
          Aunque <b>Grupo Godrej</b> hace todo lo posible por incluir en este
          sitio web información precisa y actualizada, no garantizamos en modo
          alguno, ya sea expresa o implícitamente, la exactitud ni la totalidad
          de la información proporcionada en él y renunciamos a cualquier
          responsabilidad por la utilización de este sitio o sitios enlazados a
          éste. <b>Grupo Godrej</b> puede cambiar este sitio en cualquier
          momento sin previo aviso pero no asume la responsabilidad de
          actualizarlo. Todos los usuarios acuerdan que cualquier acceso o
          utilización de este sitio web o de cualquier sitio enlazado a éste y
          sus contenidos corre de su cuenta y riesgo. Ni Grupo Godrej ni
          cualquier otra parte implicada en la creación, la producción o la
          presentación de este sitio web o de cualquier otro sitio enlazado a
          éste serán responsable de cualquier daño que sea consecuencia de su
          acceso, utilización o imposibilidad de utilizar este sitio web o
          cualquier sitio web enlazado a éste, o de cualquier error u omisión en
          el contenido de éstos.
          <br />
          <br />
          <b>No oferta y no promoción</b>
          <br />
          La información proporcionada en este sitio web no constituye una
          oferta ni una promoción para la adquisición o la eliminación de
          relaciones comerciales o transacciones de ningún valor del Grupo
          Godrej. Los inversores no deben basarse en esta información para sus
          decisiones de inversión.
          <br />
          <br />
          <b>Información de cara al futuro</b>
          <br />
          Esta página puede contener información de cara al futuro. Esta
          información está sujeta a múltiples incertidumbres importantes, entre
          ellas factores científicos, empresariales, económicos y financieros, y
          por lo tanto los resultados reales pueden diferir mucho de los aquí
          presentados.
          <br />
          <br />
          <b>Enlaces</b>
          <br />
          Los enlaces a páginas de terceros se proporcionan solamente para la
          comodidad del usuario. No expresamos opinión alguna sobre el contenido
          de ninguna página de terceros y renunciamos expresamente a cualquier
          responsabilidad por la información contenida en las páginas de
          terceros y por la utilización que se haga de ésta.
          <br />
          <br />
          <b>Marcas de fábrica</b>
          <br />
          Todas las marcas de fábrica mencionadas son propiedad de un miembro
          del Grupo Godrej o un miembro del Grupo Godrej tiene autorización para
          utilizarlas.
          <br />
          <br />
          <b>Copyright</b>
          <br />
          El sitio web del <b>Grupo Godrej</b> y los datos que éste contiene o a
          los que éste hace referencia tienen simplemente propósitos
          informativos. Queda completamente prohibida cualquier reproducción,
          retransmisión u otra utilización de éstos. Deberá solicitarse permiso
          para reproducir cualquier información contenida en este sitio web al
          administrador del sitio de Grupo Godrej.
          <br />
          <br />
          <br />
          <br />
        </p>
      </>
    ),
    uy: (
      <>
        <p>
          Laboratorio Cuenca S.A. (“LC”) ofrece productos de cosmética capilar,
          cuidado del cabello y de la piel. La utilización de sus productos
          cosméticos es controlada y regulada por los organismos gubernamentales
          de cada uno de los países en que se comercializan. En la República
          Oriental del Uruguay, a través del Departamento de Alimentos,
          Cosméticos, Domisanitarios y Otros del Ministerio de Salud Pública.
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso que, ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud.
        </p>
        <p>
          Los sitios Web de LC son tan sólo un servicio informativo; al igual
          que cualquier otro servicio, y que a pesar de los esfuerzos realizados
          por LC, es posible que la información allí registrada se encuentre
          desactualizada con el paso del tiempo. Por lo tanto, nada de lo que
          allí se informa debe ser tomado por Usted como consejo o recomendación
          médica.
        </p>
        <p>
          Es importante que, en relación con su caso particular, Usted dependa
          únicamente del asesoramiento y del consejo de un profesional de la
          salud. LC no se hace responsable de que la información que figura en
          sus sitios Web sea totalmente exacta y exhaustiva, ni del uso que de
          ella pueda hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva GARANTÍA
          DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS, PERO SIN
          LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE COMERCIALIZACIÓN,
          SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO INDEBIDO.
        </p>
        <p>
          Usted puede descargar material de este sitio web a su propio riesgo.
          LC no garantiza que dichos materiales estén libres de virus, gusanos,
          troyanos u otros códigos destructivos. Es su responsabilidad exclusiva
          implementar salvaguardias para proteger su sistema informático y los
          datos, y usted es responsable de los costos totales de cualquier
          servicio, reparaciones o correcciones necesarias como resultado del
          uso de este sitio web.
        </p>
        <p>
          El nombre y el logotipo de Godrej son marcas registradas. Todas las
          demás marcas comerciales, nombres de productos y nombres de empresas o
          logotipos utilizados en este sitio web son propiedad de sus
          respectivos propietarios. No se concede ningún permiso o licencia (ya
          sea expresa o implícita) con respecto al uso de tales marcas
          registradas, presentaciones, nombres de productos, logos o títulos, y
          tal uso puede constituir una violación de los derechos del titular.
        </p>
        <p>
          A través de los sitios Web de LC, Usted puede conectarse con otros
          sitios Web, incluidos los sitios Web de las afiliadas de LC en otros
          países.
        </p>
        <p>
          LC no puede responsabilizarse de la información que Usted pueda
          obtener en sitios Web de terceros y que no se encuentren bajo su
          tutela.
        </p>
        <p>
          Aunque LC trata de facilitar el enlace con sitios Web de terceros que
          cumplan con todos los requisitos y normas legales pertinentes, así
          como las normas de LC, ha de entenderse que el contenido de sitios Web
          de propiedad de terceros puede variar sin el conocimiento previo de
          LC. Es por ello, que LC no puede ni debe responsabilizarse, ni aceptar
          ningún tipo de responsabilidad, por la información que se proporcione
          ni de las opiniones que se expresen en los sitios Web de propiedad de
          terceros.
        </p>
        <p>
          Los derechos de autor sobre el contenido y las descargas de este sitio
          web son propiedad de de LC, del Grupo Godrej o terceros. Todos los
          derechos reservados. Usted es responsable de obedecer todas las leyes
          de derechos de autor aplicables. Le permitimos hacer copias de este
          sitio web como actos incidentales necesarios durante su visualización
          del mismo, y puede tomar una impresión para su uso personal de la
          mayor parte del sitio web que sea razonable para fines privados. Todo
          otro uso está estrictamente prohibido. No puede enmarcar este sitio ni
          vincular a una página que no sea la página de inicio sin nuestro
          permiso expreso por escrito.
        </p>
        <p>
          Nada de los expuesto en los sitios Web de LC constituye una promoción
          de los productos comercializados por LC.
        </p>
      </>
    ),
    ec: (
      <>
        <b>AVISO LEGAL</b>
        <br />
        <br />
        <p>
          Laboratorio Cuenca S.A. (“LC”) ofrece productos de cuidado de la piel
          y del cabello. La utilización de sus productos cosméticos, son
          controlados y regulados por los organismos gubernamentales de cada uno
          de los países en que se comercializan. En la República Ecuador, a
          través de la Agencia de Regulación, Control y Vigilancia Sanitaria
          (“ARCSA”).
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso, que ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud. Los sitios Web de
          LC son tan sólo un servicio informativo; al igual que cualquier otro
          servicio, y que a pesar de los esfuerzos realizados por LC, es posible
          que la información allí registrada se encuentre desactualizada con el
          paso del tiempo. Por lo tanto, nada de lo que allí se informa debe ser
          tomado por Usted como consejo o recomendación médica. Es importante
          que, en relación con su caso particular, Usted dependa únicamente del
          asesoramiento y del consejo de un profesional de la salud. LC no se
          hace responsable de que la información que figura en sus sitios Web
          sea totalmente exacta y exhaustiva, ni del uso que de ella pueda
          hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva GARANTÍA
          DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS, PERO SIN
          LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE COMERCIALIZACIÓN,
          SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO INDEBIDO.
        </p>
        <p>
          A través de los sitios Web de LC, Usted puede conectarse con otros
          sitios Web, incluidos los sitios Web de las afiliadas de LC o
          distribuidores autorizados en otros países. LC no puede
          responsabilizarse de la información que Usted pueda obtener en sitios
          Web de terceros y que no se encuentren bajo su tutela. Aunque LC trata
          de facilitar el enlace con sitios Web de terceros que cumplan con
          todos los requisitos y normas legales pertinentes, así como las normas
          de LC, ha de entenderse que el contenido de sitios Web de propiedad de
          terceros puede variar sin el conocimiento previo de LC. Es por ello,
          que LC no puede ni debe responsabilizarse, ni aceptar ningún tipo de
          responsabilidad, por la información que se proporcione ni de las
          opiniones que se expresen en los sitios Web de propiedad de terceros.
          Nada de los expuesto en los sitios Web de LC constituye una promoción
          de los productos comercializados por LC.
        </p>
      </>
    ),
    bo: (
      <>
        <b>AVISO LEGAL</b>
        <br />
        <br />
        <p>
          AVISO LEGAL Este aviso legal (“Aviso Legal”) en conjunto con la
          política de privacidad (“Política de Privacidad”) componen los
          términos y condiciones de uso (“Términos y Condiciones”) del portal
          web
          <a href="www.issuecolor.com">www.issuecolor.com</a> (en adelante, la
          “Web”). Al acceder o utilizar esta Web, usted (en adelante “Usted” o
          el “Usuario”) acepta los términos del presente Aviso Legal y de los
          Términos y Condiciones destinados a proteger sus derechos y a
          informarlo sobre los términos de uso de la Web y la información en
          ella contenida.
        </p>
        <p>
          Laboratorio Cuenca S.A. (“LC”) informa a través de la Web sobre sus
          productos de cuidado de la piel y del cabello. La utilización de sus
          productos cosméticos, son controlados y regulados por los organismos
          gubernamentales de cada uno de los países en que se comercializan. En
          la República Argentina, a través de la Administración Nacional de
          Medicamentos, Alimentos y Tecnología Médica (“ANMAT”) y en Bolivia, a
          través de la Agencia Estatal de Medicamentos y Tecnologías en Salud
          (“AGEMED”), bajo los cuales se hayan registrados y habilitados los
          distribuidores autorizados para la comercialización y venta de estos
          productos.
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso, que ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud. Los sitios Web de
          LC son tan sólo un servicio meramente informativo , al igual que
          cualquier otro servicio, y que a pesar de los esfuerzos realizados por
          LC, es posible que la información allí registrada se encuentre
          desactualizada con el paso del tiempo. Por lo tanto, nada de lo que
          allí se informa debe ser tomado por Usted como consejo o recomendación
          médica. Es importante que, en relación con su caso particular, Usted
          dependa únicamente del asesoramiento y del consejo de un profesional
          de la salud. LC no se hace responsable de que la información que
          figura en sus sitios Web sea totalmente exacta y exhaustiva, ni del
          uso que de ella pueda hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva
          <b>
            {" "}
            GARANTÍAS DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS,
            PERO SIN LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE
            COMERCIALIZACIÓN, SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO
            INDEBIDO.
          </b>
        </p>
        <p>
          A través de los sitios Web de LC, Usted puede conectarse con otros
          sitios Web, actuando la Web y LC únicamente cómo un proveedor de
          enlace. Entre estos sitios Web estarán incluidos los sitios Web de las
          afiliadas de LC en otros países o los sitios Web de terceros que se
          dediquen, por su cuenta y riesgo, a comercializar los productos
          descriptos en la Web.
        </p>
        <p>
          LC no se responsabiliza de la información que Usted pueda obtener en
          sitios Web de terceros y que no se encuentren bajo su tutela. Aunque
          LC trata de facilitar el enlace con sitios Web de terceros que cumplan
          con todos los requisitos y normas legales pertinentes, así como las
          normas de LC, ha de entenderse que el contenido de sitios Web de
          propiedad de terceros puede variar sin el conocimiento previo de LC.
          Es por ello, que LC no puede ni se responsabiliza, ni acepta ningún
          tipo de responsabilidad, por la información que se proporcione ni de
          las opiniones que se expresen en los sitios Web de propiedad de
          terceros. Nada de los expuesto en los sitios Web de LC constituye una
          promoción u oferta en venta de los productos comercializados por LC.
        </p>
        <p>
          En caso de ser necesario el contacto con LC, el Usuario podrá dirigir
          sus consultas informativas y únicamente respecto al contenido o
          servicios proveídos por esta Web, y no respecto a las páginas de
          terceros o su contenido cuyo enlace se incluya en la Web, al siguiente
          contacto:
        </p>
        <ul>
          <li>{"  "}Denominación social: Laboratorio Cuenca S.A.</li>
          <br />
          <li>
            {"  "}Dirección electrónica de contacto: info.argentina@godrejcp.com
          </li>
          <br />
          <li>
            {"  "}Dirección electrónica de contacto: info.argentina@godrejcp.com
          </li>
          <br />
          <li>
            {"  "}Domicilio: Empedrado 2435, Ciudad Autónoma de Buenos Aires,
            República Argentina.
          </li>
        </ul>
      </>
    ),
    py: (
      <>
        <b>AVISO LEGAL</b>
        <br />
        <br />
        <p>
          Este aviso legal (“Aviso Legal”) en conjunto con la política de
          privacidad (“Política de Privacidad”) componen los términos y
          condiciones de uso (“Términos y Condiciones”) del portal web
          <a href="www.issuecolor.com">www.issuecolor.com</a> (en adelante, la
          “Web”). Al acceder o utilizar esta Web, usted (en adelante “Usted” o
          el “Usuario”) acepta los términos del presente Aviso Legal y de los
          Términos y Condiciones destinados a proteger sus derechos y a
          informarlo sobre los términos de uso de la Web y la información en
          ella contenida.
        </p>
        <p>
          Laboratorio Cuenca S.A. (“LC”) informa a través de la Web sobre sus
          productos de cuidado de la piel y del cabello. La utilización de sus
          productos cosméticos, son controlados y regulados por los organismos
          gubernamentales de cada uno de los países en que se comercializan. En
          la República Argentina, a través de la Administración Nacional de
          Medicamentos, Alimentos y Tecnología Médica (“ANMAT”) y en la
          República del Paraguay, a través de la Dirección Nacional de
          Vigilancia Sanitaria (“DINAVISA”), bajo los cuales se hayan
          registrados y habilitados los distribuidores autorizados para la
          comercialización y venta de estos productos.
        </p>
        <p>
          En virtud de las normas existentes al respecto, es preciso, que ante
          cualquier duda que Usted tenga respecto con la utilización de los
          productos LC, consulte a un profesional de la salud. Los sitios Web de
          LC son tan sólo un
          <b> servicio meramente informativo</b>, al igual que cualquier otro
          servicio, y que a pesar de los esfuerzos realizados por LC, es posible
          que la información allí registrada se encuentre desactualizada con el
          paso del tiempo. Por lo tanto, nada de lo que allí se informa debe ser
          tomado por Usted como consejo o recomendación médica. Es importante
          que, en relación con su caso particular, Usted dependa únicamente del
          asesoramiento y del consejo de un profesional de la salud. LC no se
          hace responsable de que la información que figura en sus sitios Web
          sea totalmente exacta y exhaustiva, ni del uso que de ella pueda
          hacerse.
        </p>
        <p>
          La información que figura en los sitios Web de LC no conlleva
          <b>
            {" "}
            GARANTÍAS DE NINGUNA CLASE, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS,
            PERO SIN LIMITARSE A ELLAS, LAS INHERENTES A SU CAPACIDAD DE
            COMERCIALIZACIÓN, SU IDONEIDAD PARA UN FIN CONCRETO Y SU USO
            INDEBIDO.
          </b>
        </p>
        <p>
          A través de los sitios Web de LC, Usted puede conectarse con otros
          sitios Web, actuando la Web y LC únicamente cómo un proveedor de
          enlace. Entre estos sitios Web estarán incluidos los sitios Web de las
          afiliadas de LC en otros países o los sitios Web de terceros que se
          dediquen, por su cuenta y riesgo, a comercializar los productos
          descriptos en la Web.
        </p>
        <p>
          LC no se responsabiliza de la información que Usted pueda obtener en
          sitios Web de terceros y que no se encuentren bajo su tutela. Aunque
          LC trata de facilitar el enlace con sitios Web de terceros que cumplan
          con todos los requisitos y normas legales pertinentes, así como las
          normas de LC, ha de entenderse que el contenido de sitios Web de
          propiedad de terceros puede variar sin el conocimiento previo de LC.
          Es por ello, que LC no puede ni se responsabiliza, ni acepta ningún
          tipo de responsabilidad, por la información que se proporcione ni de
          las opiniones que se expresen en los sitios Web de propiedad de
          terceros. Nada de los expuesto en los sitios Web de LC constituye una
          promoción u oferta en venta de los productos comercializados por LC.
        </p>
        <p>
          A los efectos de la Ley Nº 4868 de Comercio Electrónico de la
          República del Paraguay y atendiendo que LC y la Web tienen un fin
          meramente informativo y de Proveedor de Enlace, en los términos de la
          ley mencionada, y no se dedican a la comercialización de los productos
          a los consumidores finales, los Usuarios en caso de ser consumidores
          de estos productos en Paraguay deberán dirigirse a los distribuidores
          o comerciantes que actúen cómo Proveedores de Bienes y Servicios de
          estos productos en sus países respectivos. A tal efecto, se solicita
          al Usuario que se dirija a los datos de contacto de cada página web
          independiente que se dedique a la comercialización de los productos a
          los efectos de la referida ley y de hacer valer sus derechos de
          consumidor o usuario.
        </p>
        <p>
          En caso de ser necesario el contacto con LC, el Usuario podrá dirigir
          sus consultas informativas y únicamente respecto al contenido o
          servicios proveídos por esta Web, y no respecto a las páginas de
          terceros o su contenido cuyo enlace se incluya en la Web, al siguiente
          contacto:
          <ul>
            <li> Denominación social: Laboratorio Cuenca S.A.</li>
            <li>
              {" "}
              Dirección electrónica de contacto: info.argentina@godrejcp.com
            </li>
            <li> Número de teléfono de contacto: +54 9 11 4501 9900</li>
            <li>
              {" "}
              Domicilio: Empedrado 2435, Ciudad Autónoma de Buenos Aires,
              República Argentina.
            </li>
          </ul>
        </p>
        <p>
          En la República del Paraguay la entidad de control competente para el
          control de los productos cuya información se provee es la Dirección
          Nacional de Vigilancia Sanitaria (“DINAVISA”) cuyos datos de contacto
          y mayor información pueden ser encontrados en el siguiente link
          <a href="https://www.mspbs.gov.py/dnvs">
            https://www.mspbs.gov.py/dnvs
          </a>
          . La autoridad de aplicación a los efectos de defensa al consumidor
          bajo la Ley Nº 1334 “De Defensa del Consumidor y del Usuario” es la
          Secretaría de Defensa al Consumidor (“SEDECO”) cuyos datos de contacto
          y mayor información pueden ser encontrados en el siguiente link
          <a href="http://www.sedeco.gov.py/">http://www.sedeco.gov.py/</a>.
        </p>
      </>
    ),
  };

  return <InfoContainer content={contentByCountry[COUNTRY]} />;
};

export default AvisoLegal;
