import React, { useEffect } from 'react';
import Button from './Button';
import Layout from '../Layout';
import BannerCategory from './BannerCategory';
import Producto from './Producto';
import Typography from './Typography';
import { scrollToTop } from '../../utils';


const Demo = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Layout>
            <div style={{ margin: '50px 0px' }} className='flex'>
                <Typography
                    size='25px'
                    className='bold color-green-strong'
                >
                    HOLA!
                </Typography>
            </div>
            <div style={{ margin: '50px 0px' }} className='flex'>
                <Producto
                    bgClassName='bg-green-strong'
                    img=''
                    name='Polvo decolorante Keratin'
                />
                <Producto
                    bgClassName='bg-green-light'
                    img=''
                    name='Polvo decolorante Keratin'
                />
                <Producto
                    bgClassName='bg-red-light'
                    img=''
                    name='Polvo decolorante Keratin'
                />
            </div>
            <div style={{ margin: '50px 0px' }}>
                <BannerCategory
                    // imgs=''
                    title='Lorem ipsum dolor sit amet consectetur adipisicing elit. '
                    subtitle='Subtitulo'
                />
            </div>
            <BannerCategory
                title='Nuestros Productos'
                gradient={['#EFEFEF', '#E5E5E5']}
                titleClassName='color-grey-primary medium'
                descrClassName='color-grey-primary'
                line={true}
                description='Con PRE y POST color con Aloe Vera cuida tu pelo antes, durante y después de la tintura.'
            >
                <img src={require('../../static/img/productosimgsbanner/prodbanner1.png')} alt="" />
            </BannerCategory>


            <div className='flex' style={{ width: '100vw', height: '100vh', margin: '50px 0px', padding: '50px' }}>
                <Button
                    text='Comprar Ahora'
                    className='bg-green-strong big'
                    fontSize='14px'
                    textTransform='uppercase'
                />

                <Button
                    text='Todos los productos Aquí'
                    className='bg-white-primary color-dark-primary large'
                    fontSize='16px'
                    iconRight='flecha'
                />

                <Button
                    text='Ver Mas'
                    className='border-green-strong color-green-strong large'
                    fontSize='12px'
                    textTransform='uppercase'
                />
            </div>
        </Layout>
    );
}

export default Demo;