import React, { useEffect, useState } from 'react';
import './ProductList.scss'
import {
    Producto,
    VerMas,
    LoadingProducts,
    SearchMsg,
    Message
} from '../../@ui';
import { useSelector, useDispatch } from 'react-redux'
import { loadMoreProducts, selectOrdenar, setOrdenar } from '../../../redux/filtrosDuck';
import {
    selectFilteredProducts,
    selectGetProductsStatus,
    selectTextFilteredProducts,
} from '../../../redux/productosDuck';


const ProductList = ({ category, textFilter = null }) => {

    const dispatch = useDispatch()

    const {
      message,
      total,
      loadingmore,
    } = useSelector(state => state.productos);

    const {
      page,
      search
    } = useSelector(state => state.filtros);

    const getProductsStatus = useSelector(selectGetProductsStatus);
    const loadingfiltered = getProductsStatus === 'loading';
    const filteredproducts = useSelector(selectFilteredProducts);
    const textFilteredProducts = useSelector(selectTextFilteredProducts(textFilter));
    const filtroOrdenar = useSelector(selectOrdenar);
    const [ productos, setProductos ] = useState([]);

    let orderedProducts;

    if (textFilteredProducts.length > 0) {
      orderedProducts = textFilteredProducts.slice();
    } else {
      orderedProducts = filteredproducts.slice();
    }

    
    //Quitando productos que no se venden en estos paises
    useEffect(() => {
      setProductos(orderedProducts.filter( product => product.status !== "draft" ))
     
    }, [filteredproducts]);


    // Sobreescribimos el orden a mano si no hay activado ningún filtro de orden
    if (filtroOrdenar === '') {
        // Si la categoría es "Kits", ordenamos por orden_menu_kits en vez de orden_custom
        if ( category === 'Kits' ) {
            orderedProducts = orderedProducts.sort((a, b) => a.orden_menu_kits - b.orden_menu_kits);
        } else {
            // Ordenamos los Products por orden_custom; los que no tienen orden_custom (orden_custom = null) vienen
            // después
            orderedProducts = orderedProducts.sort((a, b) => {
                if (a.orden_custom !== null && b.orden_custom !== null) {
                    return a.orden_custom - b.orden_custom;
                }

                if (a.orden_custom !== null && b.orden_custom === null) {
                    return -1;
                }

                return 0;
            });
        }
    } else {
      return;
    }


    /* NO SE ENCONTRO PRODUCTO FILTRANDO */
    if (!loadingfiltered && !message && !filteredproducts[0] && !textFilteredProducts[0]) {
        return (
            <>
                {search && <SearchMsg search={search} />}
                <Message message={'No se encontraron productos relacionados con tu búsqueda'} />
            </>
        )
    }

    /* CARGANDO */
    if (loadingfiltered) {
        return <LoadingProducts />
    }

    /* SI HUBO UN ERROR */
    if (!loadingfiltered && message) {
        return <Message message={message} />
    }

    return (
        <>
            {/* SI BUSCO PRODUCTO */}
            {search && !message &&
                <>
                    <SearchMsg search={search} />
                    {orderedProducts[0] &&
                        <div className='mb-50'></div>
                    }
                </>
            }
            {!loadingfiltered && orderedProducts[0] &&
                <div className="product-list-container">

                    <div className="product-list-filter-container">
                        {/* <div className="arrow bottom"></div>
                        <select onChange={(e) => dispatch(setOrdenar(e.target.value))}>
                            <option value="">Ordenar por</option>
                            <option value="Más relevantes">Más relevantes</option>
                            <option value="Lo más visto">Lo más visto</option>
                            <option value="Recomendados">Recomendados</option>
                        </select> */}
                    </div>

                    <div className="product-list-list-container">
                      {
                         productos.map( productos => (
                          <Producto
                            imagen={productos.imagen}
                            nombre={productos.nombre}
                            id={productos.id}
                            key={productos.id}
                            categoria={productos.categoria[0]}
                            slug={productos.slug}
                          />
                        ))

                      }
                    </div>
                    {(total / 6) > page && !loadingmore &&
                        <VerMas onClick={() => dispatch(loadMoreProducts())} />
                    }
                    {loadingmore && <LoadingProducts />}
                </div>
            }
        </>
    );
}

export default ProductList;
