import { useEffect, useState } from 'react';
import { getProducts } from '../redux/productosDuck';
import { useDispatch, useSelector } from 'react-redux';

// Hook para productos. Si no fueron obtenidos, los obtiene.
const useProducts = () => {

  const dispatch = useDispatch();
  
  const { getProductsStatus, data } = useSelector(state => state.productos);
  const [ productsData, setProductsData ] = useState([]);
  const [ show, setShow ] = useState(false);

  
  
  useEffect(() => {
    setProductsData(data.filter( product => product.status !== "draft" ))
  }, [data])
  
  
  useEffect(() => {

    if (getProductsStatus === 'idle') {
      dispatch(getProducts());
    }
    
  }, [dispatch, getProductsStatus]);

  return {
    productsData,
    data,
    show,
  };
}

export default useProducts;
