import React, { useState, useEffect } from 'react';
import './MainTut.scss';
import {
    SvgDB,
    Button,
    ButtonIcon,
    ComprarPopUp,
    SearchMsg,
    Message,
    LoadingTutoriales,
    PopMessage
} from '../../@ui'
import Video from './Video';

import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getTutorials } from '../../../redux/tutorialesDuck';
import { sanitizeName, scrollTo } from '../../../utils';
import useResizeWidth from '../../../hooks/useResizeWidth';
import { BREAKPOINT } from '../../../constants';

import { COUNTRY } from '../../../constants';

const MainTut = ({ query, id, state }) => {
    const { push } = useHistory();
    const [buypopup, setBuyPopup] = useState(false);

    const setMessage = PopMessage();

    const dispatch = useDispatch();
    const { data, message, loading } = useSelector(state => state.tutoriales);
    const {
        search,
        categoriasProductos,
        lineasProductos
    } = useSelector(state => state.filtros);

    const [tutindx, setTutIndx] = useState(0);
    const [activetut, setActiveTut] = useState({});
    const [tutoriales, setTutoriales] = useState([]);

    const [iframeloaded, setIframeLoaded] = useState(false);

    const [filteredTutoriales, setFilteredTutoriales] = useState([]);

    const rizeWidth = useResizeWidth();

    const infoContainerStyle = rizeWidth <= BREAKPOINT.MOBILE_LG ?
        { overflow: 'auto', maxHeight: '60px' } :
        { overflow: 'auto', maxHeight: '180px', marginBottom: '30px' };

    const setPopMessage = PopMessage();

    // Poner el primer tutorial como activo una vez que los tutoriales fueron fetcheados
    useEffect(() => {

        if (data[0]) {
            // Ponemos como activo el primer tutorial
            setActiveTut(data[0]);

            // Índice del tutorial activo
            setTutIndx(0);


            // `tutoriales` tiene el resto de los tutoriales
            setTutoriales(data.slice(1));
                        
        } else {
            setActiveTut({})
            setTutIndx(0)
            setTutoriales([])
        }

        // si hubo un error
        if (!loading && message)
            setMessage(message)

    }, [data, loading, message, setMessage]);


    // Carga inicial: Obtener tutoriales y despachar query, if any
    useEffect(() => {
        if (data.length === 0) {
            dispatch(getTutorials());
        }
    }, [data.length, dispatch, id, query, state]);


    // Cambiaron los filtros: Actualizar lista de tutoriales filtrados
    useEffect(() => {
        if (data.length === 0) {
            return;
        }

        const allTutoriales = data;

        // Filtramos los tutoriales por categoría y línea de producto
        const filteredTutoriales = allTutoriales.filter((tutorial) => {
            let passedCategoriaFilter = false;

            // Filtramos solamente si la categoría "Todas" *no* está seleccionada. Si no hay
            // *ninguna* categoría seleccionada (ni siquiera "Todas"), tampoco filtramos por
            // categoría.
            if (categoriasProductos.indexOf('Todas') === -1 && categoriasProductos.length > 0) {
                // Filtrar por categoría: Al menos una de las categorías del producto del tutorial
                // tiene que estar en las categorías del filtro
                for (let i = 0; i < tutorial.producto?.categoria.length; i++) {
                    const currCategoria = tutorial.producto.categoria[i];
    
                    if (categoriasProductos.indexOf(currCategoria.nombre) !== -1) {
                        passedCategoriaFilter = true;
                        break;
                    }
                }
    
                if (!passedCategoriaFilter) {
                    return false;
                }
            }

            let passedLineaFilter = true;

            // Solamente filtramos por línea si hay al menos una seleccionada en el filtro
            if (lineasProductos.length > 0) {
                passedLineaFilter = false;

                // Filtrar por línea de producto: Al menos una de las líneas de producto del tutorial
                // tiene que estar en las líneas de producto del filtro
                for (let i = 0; i < tutorial.producto?.linea_de_producto.length; i++) {
                    const currLineaProducto = tutorial.producto.linea_de_producto[i].linea_de_producto_id.linea_de_producto;

                    if (lineasProductos.indexOf(currLineaProducto) !== -1) {
                        passedLineaFilter = true;
                        break;
                    }
                }
    
                return passedLineaFilter;
            }

            let passedQueryFilter = true;

            // Solamente filtramos por nombre si nos pasaron una query
            if (query && query !== '') {
                const sanitizedQuery = sanitizeName(query.split('=')[1]);
                const sanitizedTutorialName = sanitizeName(tutorial.nombre);
                const sanitizedProductName = sanitizeName(tutorial.producto.nombre);

                passedQueryFilter = sanitizedTutorialName.includes(sanitizedQuery) ||
                    sanitizedProductName.includes(sanitizedQuery);
            }

            return passedQueryFilter;
        });

        setFilteredTutoriales(filteredTutoriales);
    }, [categoriasProductos, data, lineasProductos, query]);


    // Actualizar tutorial abierto y lista de tutoriales cuando cambia la lista de tutoriales
    // filtrados
    useEffect(() => {
        if (filteredTutoriales.length === 0) {
            setActiveTut({});
            setTutIndx(0);
            setTutoriales([]);
        } else {
            // Si nos pasaron un id de un tutorial en particular y está en los filtrados,
            // mostramos ese como primero
            if (id !== 0) {
                for (let i = 0; i < filteredTutoriales.length; i++) {
                    const currTuto = filteredTutoriales[i];
                    
                    if (currTuto.slug === id) {
                        const tmp = filteredTutoriales[0];
                        filteredTutoriales[0] = currTuto;
                        filteredTutoriales[i] = tmp;
                        break;
                    }
                }
            }

            setActiveTut(filteredTutoriales[0]);
            setTutIndx(0);
            setTutoriales(filteredTutoriales.slice(1));
        }
    }, [data, filteredTutoriales, id]);


    const HandleButtonClick = dir => {
        let newindx = tutindx + dir
        let newitem = data[newindx]

        if (!newitem && dir === -1) {
            newindx = data.length - 1
            newitem = data[newindx]
        } else if (!newitem && dir === 1) {
            newindx = 0
            newitem = data[newindx]
        }

        const newactivetut = data[newindx]

        setTutIndx(newindx)
        setActiveTut(newactivetut)

        ChangeVideo(newactivetut)

        setTutoriales(data.filter((el, i) => i !== newindx))
    }

    const HandleVideoClick = (newtut) => {
        const newindex = data.findIndex(el => el.id === newtut.id)

        setTutIndx(newindex)
        setActiveTut(newtut)

        ChangeVideo(newtut)

        scrollTo('tutoriales-nombre-tutorial-header', 100);

        setTutoriales(data.filter((el, i) => i !== newindex))
    }

    const ChangeVideo = (newtut) => {
        setIframeLoaded(false)
        document.querySelector('.youtube-iframe').src = newtut.link
    }


    const HandleShareClick = (id) => {
        const textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = `${window.location.host}/tutoriales/${id}`;

        document.body.appendChild(textArea)
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');

            setPopMessage('Link Copiado!')

        } catch (err) {
            setPopMessage('No se pudo copiar el link :(')
        }

        document.body.removeChild(textArea);
    }



    /* CARGANDO */
    if (loading) {
        return <LoadingTutoriales />
    }

    /* NO SE ENCONTRO TUTORIAL FILTRANDO */
    if (!loading && !message && !data[0]) {
        return (
            <>
                {search && <SearchMsg search={search} />}
                <Message message={'No se encontraron tutoriales relacionados con tu búsqueda'} />
            </>
        )
    }


    return (
        <>
            {/* SI BUSCO PRODUCTO */}
            {search && !message &&
                <>
                    <SearchMsg search={search} />
                    {data[0] &&
                        <div className='mb-50'></div>
                    }
                </>
            }
            {
                (!activetut.nombre && tutoriales.length === 0) &&

                <Message message={'No se encontraron tutoriales relacionados con tu búsqueda'} />
            }

            {!loading && Object.keys(activetut).length > 0 &&
                <>
                    {activetut.producto &&
                        <ComprarPopUp
                            gpspharma={activetut.producto.gpspharma}
                            farmaonline={activetut.producto.farmaonline}
                            setActive={setBuyPopup}
                            active={buypopup}
                        />
                    }

                    <div className="maintut-container">
                        <div className="maintut-content">

                            <div className="maintut-title-containter">
                                <h1 id="tutoriales-nombre-tutorial-header">{activetut.nombre}</h1>
                                <a href={activetut.url}>
                                    <div
                                        className="title-links-container"
                                        onClick={() => HandleShareClick(activetut.slug)}>
                                        <span>Compartir video</span>
                                        <div className="title-links-svg-container">
                                            {SvgDB['share']}
                                        </div>

                                    </div>
                                </a>
                            </div>

                            <div className="maintut-yt-container">

                                <div className="yt-video-content">
                                    <div className="video">
                                        {activetut.image_placeholder && !iframeloaded &&
                                            <img
                                                src={activetut.image_placeholder.src}
                                                alt={activetut.image_placeholder.nombre}
                                            />
                                        }
                                        <div className="iframe-container">
                                            <iframe
                                                title={activetut.link}
                                                onLoad={() => setIframeLoaded(true)}
                                                width="100%" height="100%" className="youtube-iframe" src={activetut.link} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </div>

                                    <div className="yt-controllers-container">
                                        <ButtonIcon
                                            icon='flecha'
                                            className='bg-white-primary'
                                            onClick={() => HandleButtonClick(-1)}
                                        />
                                        <ButtonIcon
                                            icon='flecha'
                                            className='bg-white-primary'
                                            onClick={() => HandleButtonClick(1)}
                                        />
                                    </div>
                                </div>


                                {activetut.producto &&
                                    <div className="yt-video-info">

                                        <div className="yt-info-img-container">
                                            <img src={activetut.producto.imagenes[0].src} alt={activetut.producto.imagenes[0].nombre} />
                                        </div>

                                        <div className="yt-info-container">
                                            <div dangerouslySetInnerHTML={{
                                                __html: activetut.producto.descripcion
                                            }} style={infoContainerStyle} />

                                            <div className="yt-info-btns-container">
                                                <Button
                                                    text='COMPRAR'
                                                    className='bg-green-light fixed-size medium'
                                                    onClick={() => setBuyPopup(true)}
                                                />

                                                <Button
                                                    text='VER PRODUCTO'
                                                    className='fixed-size medium color-green-light border-green-light'
                                                    onClick={() => push(`/${COUNTRY}/productos/${activetut.producto.slug}`)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>

                            {
                                tutoriales.length > 0 &&

                                <div className="maintut-videos-container">

                                    <h5>Videos destacados</h5>

                                    <div className="maintut-videos-content">
                                        {tutoriales.map((el, i) => {

                                            //console.log(`\nel[${i}]: ${JSON.stringify(el)}`);

                                            return (
                                                <Video
                                                    key={el.id * 5100}
                                                    slug={el.slug}
                                                    bg={el.producto ? el.producto.categoria[0].clase : ''}
                                                    nombre={el.nombre}
                                                    placeholder={el.image_placeholder}
                                                    onClick={() => HandleVideoClick(el)}
                                                    HandleShareClick={HandleShareClick}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default MainTut;