import React, { Fragment } from "react";
import "./MenuProductos.scss";
import { Button, LoadingMenu } from "../../../@ui";
import { Link } from "react-router-dom";

import { COUNTRY } from "../../../../constants.js";

const MenuProductos = (props) => {
  const { menuitems, completedmenu, setMenuProductos } = props;

  const openMenuProductos = () => {
    setMenuProductos(true);
  };

  const closeMenuProductos = () => {
    setMenuProductos(false);
  };

  return (
    <div
      className="menu-productos-container"
      onMouseEnter={openMenuProductos}
      onMouseLeave={closeMenuProductos}
    >
      {!completedmenu && <LoadingMenu />}
      {completedmenu && menuitems && (
        <div className="menu-productos-content">
          {menuitems.map((list, index) => (
            <Fragment key={index}>
              {list.productos.length > 0 && (
                <div key={(index + 1) * 6000} className="menu-list-container">
                  <h5>
                    <Link
                      onClick={closeMenuProductos}
                      to={`/${COUNTRY}/productos/${list.slug}`}
                    >
                      
                      {list.nombre}
                    </Link>
                  </h5>
                  <ul>
                    {list.productos.map((el, i) => (
                      <li
                        key={(i + 1) * 6100}
                        className={el.class ? el.class : ""}
                      >
                        {el.id ? (
                          <Link
                            onClick={closeMenuProductos}
                            to={`/${COUNTRY}/productos/${el.slug}`}
                          >
                            {el.nombre}
                          </Link>
                        ) : (
                          <span>{el.nombre}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Fragment>
          ))}

            <div
              className={`menu-img-container ${
                COUNTRY === "pe" ? "bg-img-peru" : ""
              } ${COUNTRY === "bo" ? "bg-img-bol" : ""} ${
                COUNTRY === "ec" ? "bg-img-ec" : ""
              } ${COUNTRY === "cl" ? "bg-img-cl" : ""}
              ${COUNTRY === "py" ? "bg-img-py" : ""}`}
            >
              <Link onClick={closeMenuProductos} to={`/${COUNTRY}/productos`}>
                <Button
                  text="Todos los productos Aquí"
                  className="bg-white-primary color-dark-primary large-sm button-peru"
                  iconRight="flecha"
                />
              </Link>
            </div>
          </div>

      )}
    </div>
  );
};

export default MenuProductos;
