import React from 'react';
import './ButtonIcon.scss'
import {
    SvgDB
} from '../index'

const ButtonIcon = (props) => {


    const {
        icon = '',
        className = '',
        ...other
    } = props

    return (
        <button className={`buttonicon ${className}`} {...other}>
            {SvgDB[icon] && SvgDB[icon]}
        </button>
    );
}

export default ButtonIcon;