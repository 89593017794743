import React, { useState, useEffect } from 'react';
import './Filtros.scss'
import { FiltrosList, FiltrosActivos, Select, FilterMenu, CategoriasMobile } from '../index.js';

import { filtrosordenarpor } from '../../Data'

import { sortFilteredProducts } from '../../../redux/productosDuck';
import { removeAllFilterData, setFilter } from '../../../redux/filtrosDuck';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux'

import { COUNTRY } from '../../../constants.js';

const Filtros = (props) => {

    const {
        filtros = [],
        ordenar = true,
        curpath = '',
        curcategory = ''
    } = props

    const [filtermenu, setFilterMenu] = useState()


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(removeAllFilterData())
    }, [dispatch])

    return (
        <>

            <FilterMenu
                title='Filtrar por'
                menuitems={filtros}
                active={filtermenu === 0}
                setActive={setFilterMenu}
                dispatchOnDropdownClick={setFilter}
            />

            <FilterMenu
                title='Ordenar por'
                menuitems={filtrosordenarpor}
                active={filtermenu === 1}
                setActive={setFilterMenu}
                dispatchOnClick={sortFilteredProducts}
            />

            <div className={`filtros-container ${curpath === '/productos' && 'productos'}`}>
                {curpath === "/productos" &&
                    <div className="mobile-label hidden-desk">
                        <span>Todos los Productos</span>
                        <CategoriasMobile className='productos' />
                    </div>
                }
                {/* {curpath !== "/productos" && curpath !== "/tutoriales" && */}
                {/^(\/productos\/)(.{3,})/.test(curpath) &&
                    <>
                        <div className="allproducts-container hidden-desk">
                            <Link to={`/${COUNTRY}/productos`}>Todos los Productos</Link>
                        </div>
                        <CategoriasMobile curcategory={curcategory} />
                    </>
                }

                <div className={`filtros-mobile-container ${curpath === '/productos' && 'productos'}`}>
                    <Select
                        title='Filtrar por'
                        onClick={() => setFilterMenu(0)}
                    />

                    {ordenar ?
                        <Select title='Ordenar por' onClick={() => setFilterMenu(1)} /> :
                        <div className='marginr-auto'> </div>
                    }

                </div>

                <FiltrosActivos />

                <div className="filtros-desktop-container">
                    <span className='filtros-title'>Filtrar por:</span>

                    {filtros && filtros.map((el, i) => (
                        <FiltrosList
                            key={(i + 1) * 7222}
                            title={el.title}
                            filtros={el.filtrosnames[COUNTRY]}
                            query={el.query}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default Filtros;