import { COUNTRY } from "../../constants";

import gpsfarmaLogo from "../../static/img/eshopdropdown/eshop2.png";
import farmaonlineLogo from "../../static/img/eshopdropdown/eshop3.png";
import perufarmaLogo from "../../static/img/eshopdropdown/perufarma.png";
import liquimaxLogo from "../../static/img/eshopdropdown/liquimax.png";
import sallyLogo from "../../static/img/eshopdropdown/sally.png";
import farmashopLogo from "../../static/img/eshopdropdown/farmashop.png";
import tataLogo from "../../static/img/eshopdropdown/tata.png";
import salonmarketLogo from "../../static/img/eshopdropdown/salonmarket.png";
import frecuentoLogo from "../../static/img/eshopdropdown/micomisariato.png"; // logo es mi comisariato pero eshop es frecuento
import medicityLogo from "../../static/img/eshopdropdown/MEDICITY.jpg";
import gloriaSaltosLogo from "../../static/img/eshopdropdown/GLORIA_SALTOS.jpg";

export const filtrosordenarpor = [
  { title: "Más relevantes" },
  { title: "Lo más visto" },
  { title: "Recomendados" },
];

const linea_de_producto = {
  query: "linea_de_producto.linea_de_producto_id.linea_de_producto",
  title: "Línea de producto",
  filtrosnames: {
    ar: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      // { titulo: "3D Gloss" }, ya no se encuentra disponible
      { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      { titulo: "Ablandador De Canas" },
      { titulo: "Brillo Extremo" },
      { titulo: "Issue Tratamientos" },
    ],
    pe: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      { titulo: "3D Gloss" },
      { titulo: "Crazy Colors" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Oxidante En Crema" },
      { titulo: "Sin Amoníaco" },
      { titulo: "Baseline" },
      { titulo: "Ablandador De Canas" },
    ],
    cl: [
      { titulo: "Baseline" },
      { titulo: "Crazy Colors" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
    ],
    uy: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      // { titulo: "3D Gloss" }, TODO no va mas en Bolivia
      { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      { titulo: "Ablandador De Canas" },
    ],
    bo: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      // { titulo: "3D Gloss" }, TODO no va mas en Bolivia
      { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      //{ titulo: "Ablandador De Canas" },
      { titulo: "Brillo Extremo" },
    ],
    ec: [
      { titulo: "Keratin" },
      { titulo: "Issue Sachet" },
      // { titulo: "3D Gloss" },
      // { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      { titulo: "Ablandador De Canas" },
      // { titulo: "Brillo Extremo" },
    ],
    py: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      { titulo: "3D Gloss" },
      { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      //{ titulo: "Ablandador De Canas" },
      // { titulo: "Brillo Extremo" },
      { titulo: "Shampoo y Acondicionador" },
    ],
    ve: [
      { titulo: "Keratin" },
      { titulo: "Baseline" },
      { titulo: "3D Gloss" },
      { titulo: "Sin Amoníaco" },
      { titulo: "Crazy Colors" },
      { titulo: "Kit Decolorante" },
      { titulo: "Polvo Decolorante" },
      { titulo: "Activador En Crema" },
      { titulo: "Ablandador De Canas" },
      { titulo: "Brillo Extremo" },
    ],
  },
};

export const filtrosdataproductos = {
  filtros: [
    { ...linea_de_producto },
    {
      query: "tipo_de_producto",
      title: "Tipo de producto",
      filtrosnames: {
        ar: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
        ],
        pe: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
        ],
        cl: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
        ],
        uy: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Tratamientos" },
        ],
        bo: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
        ],
        ec: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
        ],
        py: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
          { titulo: "Shampoo y Acondicionador" },
        ],
        ve: [
          { titulo: "Coloración Permanente" },
          { titulo: "Coloración Sin Amoniaco" },
          { titulo: "Coloración Semi Permanente" },
          { titulo: "Complementos" },
          { titulo: "Kits" },
          { titulo: "Tratamientos" },
        ],
      },
    },
    {
      query: "tonos_de_color.tonos_de_color_id.tonos_de_color",
      title: "Tonos de color",
      filtrosnames: {
        ar: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
          { titulo: "Cenizas" },
        ],
        pe: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
        ],
        cl: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
        ],
        uy: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
        ],
        bo: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
        ],
        ec: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
        ],
        py: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
        ],
        ve: [
          // { titulo: "Negro" },
          //{ titulo: 'Negros Y Grises' }, // FIXME: Borrame cuando esté corregido en back
          { titulo: "Negros" },
          { titulo: "Castaños" },
          //{ titulo: 'Castaños Y Cobre' }, // FIXME: idem
          { titulo: "Rubios" },
          { titulo: "Rojo" },
          { titulo: "Rojos" },
          { titulo: "Aclarantes" },
          { titulo: "Cenizas" },
        ],
      },
    },
  ],
};

export const eshopsByCountry = {
  ar: [
    {
      name: "gpspharma",
      url: "https://www.gpsfarma.com/ebrandshop-issue?utm_source=gpsfarma&utm_medium=ebrand&utm_campaign=issue",
      img: gpsfarmaLogo,
      extraStyles: {},
    },
    {
      name: "farmaonline",
      url: "https://www.farmaonline.com/landing-issue",
      img: farmaonlineLogo,
      extraStyles: {},
    },
  ],
  pe: [
    {
      name: "perufarma",
      url: "https://www.perufarma.com.pe/",
      img: perufarmaLogo,
      extraStyles: {},
    },
  ],
  cl: [
    {
      name: "sallybeauty",
      url: "https://www.sallybeauty.cl/coloracion/issue",
      img: sallyLogo,
      extraStyles: {},
    },
    {
      name: "liquimax",
      url: "https://www.liquimax.cl/?s=issue",
      img: liquimaxLogo,
      extraStyles: {},
    },
  ],
  uy: [
    {
      name: "tata",
      url: "https://www.tata.com.uy/issue",
      img: tataLogo,
      extraStyles: {},
    },
    {
      name: "farmashop",
      url: "https://tienda.farmashop.com.uy/catalogsearch/result/?q=issue",
      img: farmashopLogo,
      extraStyles: {},
    },
  ], // aca agrego ICT-2 retailers: farmashop y tata
  bo: [],
  ec: [
    {
      name: "medicity",
      url: "https://www.farmaciasmedicity.com/", //TODO pedir URL
      img: medicityLogo,
      extraStyles: {},
    },
    {
      name: "gloriasaltos",
      url: "https://www.gloriasaltos.com/", //TODO pedir URL
      img: gloriaSaltosLogo,
      extraStyles: {},
    },
    {
      name: "frecuento",
      url: "https://www.frecuento.com/busqueda/?q=issue&category=", //TODO pedir URL
      img: frecuentoLogo,
      extraStyles: {},
    },
  ],
  py: [
    {
      name: "salonmarket",
      url: "http://salonmarket.com.py/producto",
      img: salonmarketLogo,
      extraStyles: {},
    },
  ],
  ve: [
    // {
    //   name: "salonmarket",
    //   url: "http://salonmarket.com.py/producto",
    //   img: salonmarketLogo,
    //   extraStyles: {},
    //},
  ],
};

export const filtrosdatatutoriales = {
  filtros: [
    {
      query: "producto.categoria.categorias_id.categoria",
      title: "Categorías",
      filtrosnames: {
        ar: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#029DE0", titulo: "Complementos" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        pe: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        cl: [{ titulo: "Todas" }, { color: "#58916D", titulo: "Coloración" }],
        uy: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        bo: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#029DE0", titulo: "Complementos" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        ec: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#029DE0", titulo: "Complementos" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        py: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#029DE0", titulo: "Complementos" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
        ve: [
          { titulo: "Todas" },
          { color: "#58916D", titulo: "Coloración" },
          { color: "#029DE0", titulo: "Complementos" },
          { color: "#FABF19", titulo: "Kits" },
          { color: "#EE7F1C", titulo: "Cuidado del cabello" },
        ],
      },
    },
    {
      ...linea_de_producto,
      query: "producto." + linea_de_producto.query,
    },
  ],
};

export const categorias = {
  Coloración: {
    nombre: "Coloración",
    slug: "coloracion",
    clase: "bg-orange-light",
    color: "color-orange-light",
  },
  Complementos: {
    nombre: "Complementos",
    slug: "complementos",
    clase: " bg-green-strong",
    color: "color-green-strong",
  },
  Kits: {
    nombre: "Kits",
    slug: "kits",
    clase: " bg-yellow-light",
    color: "color-yellow-light",
  },
  "Cuidado del cabello": {
    nombre: "Cuidado del cabello",
    slug: "cuidado-del-cabello",
    clase: "bg-green-light",
    color: "color-green-light",
  },
};

export const categoriasroutes = Object.values(categorias).map((el) => el.slug);

export const bannerContent = {
  ar: [
    {
      nombre: "Issue Eco Keratina",
      nombreColor: "color-dark-secondary",
      descripcion: `Coloración en crema con tratamiento MIX y POST color.
      Con keratina vegetal, más resistencia y más restauración.`,
      descripcionColor: 'color-dark-secondary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner_Eco_Keratina_2023.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner_Eco_Keratina_2023.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner_Eco_Keratina_2023.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner_Eco_Keratina_2023.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner_Eco_keratina_mobile_2023.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner_Eco_keratina_mobile_2023.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/kit-eco-keratina`,
        bg: "bg-yellow",
        color_text:'color-dark-secondary'
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Tratamientos Capilares",
      nombreColor: 'color-dark-secondary',
      descripcion: `Llegaron los nuevos tratamientos capilares Issue. Están creados con ingredientes naturales, son Paraben Free y Cruelty Free`,
      descripcionColor: 'color-dark-secondary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Tratamientos_Desktop.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Tratamientos_Desktop.webp"),
        ipad: require("../../static/img/bannershome/iPad/Tratamientos_Desktop.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Tratamientos_Desktop.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Tratamientos_mobile.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Tratamientos_mobile.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        // link: `/${COUNTRY}/productos/kit-eco-keratina`,
        bg: "bg-yellow",
        color_text:'color-dark-secondary'
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Comprá online",
      descripcion: `Encontrá todos nuestros productos en los diferentes shops online y comprá sin moverte de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner_compra_online_2023.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner_compra_online_2023.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner_compra_online_2023.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner_compra_online_2023.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner_compra_online_mobile_2023.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner_compra_online_mobile_2023.webp"),
      },
      boton: { nombre: "COMPRÁ", link: "", bg: "bg-yellow", color_text:'color-dark-secondary' },
    },
    {
      nombre: "Teñite en casa.",
      nombreColor: "color-dark-secondary",
      descripcion: `Tenemos un producto para cada tipo de pelo. Encontrá el que va con vos y
            conseguí colores brillantes y duraderos a un precio que te conviene.`,
      descripcionColor: 'color-dark-secondary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner_teñite_en_casa_2023.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner_teñite_en_casa_2023.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner_teñite_en_casa_2023.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner_teñite_en_casa_2023.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner_teñite_encasa_mobile_2023.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner_teñite_encasa_mobile_2023.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-yellow",
        color_text:'color-dark-secondary'
      },
    },
    {
      nombre: "Issue Sin Amoníaco",
      descripcion: `Descubrí nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner-Sin-Amoniaco_desktop.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner-Sin-Amoniaco_desktop.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner-Sin-Amoniaco_desktop.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner-Sin-Amoniaco_desktop.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner-Sin-Amoniaco_mobile.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner-Sin-Amoniaco_mobile.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
        bg: "bg-green-light",
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Kit Decolorante + Tonalizadores Issue",
      nombreColor: 'color-white',
      descripcion: `Querés colores de tendencia? Ahora tenés con qué! Armá looks a tu medida con nuestro Kit Decolorante.`,
      descripcionColor: 'color-white',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner-Kit-Decolorante_Desktop.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner-Kit-Decolorante_Desktop.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner-Kit-Decolorante_Desktop.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner-Kit-Decolorante_Desktop.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner-Kit-Decolorante_Mobile.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner-Kit-Decolorante_Mobile.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/issue-kit-decolorante-tonalizador`,
        bg: "bg-dodger-blue",
      },
      gpspharma:
        "https://www.gpsfarma.com/ebrandshop/issue/looks-de-tendencia.html",
      farmaonline: "https://www.farmaonline.com/lookstendenciaissue",
    },
    {
      nombre: "Issue Crazy Colors",
      nombreColor:'color-dark-secondary',
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos seis tonos para
            que juegues con tu pelo.`,
      descripcionColor: 'color-dark-secondary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner-Crazy-Colors.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner-Crazy-Colors.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner-Crazy-Colors.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner-Crazy-Colors.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner-Crazy-Colors_Mobile.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner-Crazy-Colors_Mobile.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple",
      }
    },
    {
      nombre: "Kit Ultra Brillo Extremo",
      nombreColor:'color-dark-secondary',
      descripcionColor: 'color-dark-secondary',
      descripcion: `Ahora podés lucir tu color con un brillo deslumbrante!  Nuestra coloración Kit Ultra Brillo Extremo con aceite de Argán refuerza la fibra capilar durante la coloración. `,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Banner-Brillo-extremo_desktop.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Banner-Brillo-extremo_desktop.webp"),
        ipad: require("../../static/img/bannershome/iPad/Banner-Brillo-extremo_desktop.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Banner-Brillo-extremo_desktop.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Banner-Brillo-extremo_mobile.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Banner-Brillo-extremo_mobile.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-black",
      }
    },
    
  ],
  pe: [
    {
      nombre: "Issue Sin Amoníaco",
      descripcion: `Descubre nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.jpg"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/GPS_-1903-x-8671.webp'),
        ipad: require("../../static/img/bannershome/iPad/banner-sin-amoniaco.jpg"),
        // ipadWebp: require('../../static/img/bannershome/iPad/GPS_-800x1066px1.webp'),
        mobile: require("../../static/img/bannershome/Mobile/banner-sin-amoniaco.jpg"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/GPS_-450x800px1.webp'),
      },
      boton: {
        nombre: "Conoce más",
        link: "/pe/productos/coloracion-en-crema-sin-amoniaco",
        bg: "bg-green-strong",
      },
      nombreColor: null,
    },
    {
      nombre: "Compra online",
      descripcion: `Encuentra todos nuestros productos en los diferentes shops online y compra sin moverte de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/banner_compraonline_peru.jpg"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/GPS_-1903-x-8671.webp'),
        ipad: require("../../static/img/bannershome/iPad/banner_compraonline_peru_ipad.jpg"),
        // ipadWebp: require('../../static/img/bannershome/iPad/GPS_-800x1066px1.webp'),
        mobile: require("../../static/img/bannershome/Mobile/banner_compraonline_mobile_peru.jpg"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/GPS_-450x800px1.webp'),
      },
      boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
      nombreColor: null,
    },
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos cuatro tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      gpspharma:
        "https://www.gpsfarma.com/ebrandshop/issue/colores-fantasia.html",
      farmaonline: "https://www.farmaonline.com/colorfantasiaissue",
      nombreColor: "black",
      descripcionColor: "black",
    },
    {
      nombre: "Tiñete en casa ",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encuentra el que más te guste y consigue colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/coloracion`,
        bg: "bg-purple-dark",
      },
    },
  ],
  cl: [
    {
      nombre: "Compra online",
      descripcion: `Encuentra todos nuestros productos en los diferentes shops online y compra sin moverte de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/BANNER_CHILE_DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/BANNER_CHILE_DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER_CHILE_TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER_CHILE_TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER_CHILE_MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER_CHILE_MOBILE.webp"),
      },
      boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
    },
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos seis tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        //desktopWebp: require("../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp"),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        //ipadWebp: require("../../static/img/bannershome/iPad/cc_-800x1066px2.webp"),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        //mobileWebp: require("../../static/img/bannershome/Mobile/cc_450x800px2.webp"),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      nombreColor: "black",
      descripcionColor: "black",
    },

    {
      nombre: "Tiñete en casa",
      descripcion: `Tenemos un producto para cada tipo de cabello.
      Encuentra el que más te guste y consigue colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
      },
      boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
    },
  ],
  uy: [
    {
      nombre: "Issue Eco Keratina",
      descripcion: `Coloración en crema con tratamiento MIX y POST color.
      Con keratina vegetal, más resistencia y más restauración.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/issueEcoKeratina1920x867.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/issueEcoKeratina1920x867.webp"),
        ipad: require("../../static/img/bannershome/iPad/issueEcoKeratina1920x867.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/issueEcoKeratina1920x867.webp"),
        mobile: require("../../static/img/bannershome/Mobile/issueEcoKeratinaMobile1080x1920.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/issueEcoKeratinaMobile1080x1920.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/kit-eco-keratina`,
        bg: "bg-yellow",
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Issue Sin Amoníaco",
      descripcion: `Descubrí nuestra nueva coloración permanente creada con ingredientes
            naturales. Ideal para cubrir canas.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER-SIN AMONIACO-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER-SIN-AMONIACO-MOBILE.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
        bg: "bg-green-dark",
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Kit Decolorante + tonalizadores Issue",
      descripcion: `Querés colores de tendencia? Ahora tenés con qué! Armá looks a tu medida con nuestro Kit Decolorante.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/BANNER-BK-DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/BANNER-BK-DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER-BK-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER-BK-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER-BK-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER-BK-MOBILE.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/issue-kit-decolorante-tonalizador`,
        bg: "bg-green-strong",
      },
      gpspharma:
        "https://www.gpsfarma.com/ebrandshop/issue/looks-de-tendencia.html",
      farmaonline: "https://www.farmaonline.com/lookstendenciaissue",
    },
    {
      nombre: "Comprá online",
      descripcion: `Encontrá todos nuestros productos en los diferentes shops online y comprá sin moverte de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/GPS_-1903-x-8671.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/GPS_-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/GPS_1066x-800.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/GPS_1066x-800.webp"),
        mobile: require("../../static/img/bannershome/Mobile/GPS_-450x800px1.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/GPS_-450x800px1.webp"),
      },
      boton: { nombre: "COMPRÁ", link: "", bg: "bg-green-strong" },
    },
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos seis tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      nombreColor: "black",
      descripcionColor: "black",
    },
    {
      nombre: "Teñite en casa.",
      descripcion: `Tenemos un producto para cada tipo de pelo. Encontrá el que va con vos y
            conseguí colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-green-strong",
      },
    },
    {
      nombre: "Teñite en casa.",
      nombreColor: "color-dark-secondary",
      descripcion: `Tenemos un producto para cada tipo de pelo. Encontrá el que va con vos y
            conseguí colores brillantes y duraderos a un precio que te conviene.`,
      descripcionColor: 'color-dark-secondary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Coloracion_Desktop.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Coloracion_Desktop.webp"),
        ipad: require("../../static/img/bannershome/iPad/Coloracion_iPad_800x1066px1.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Coloracion_iPad_800x1066px1.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Coloracion_Mobile2.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Coloracion_Mobile2.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-black",
      },
    },
  ],
  bo: [
    {
      nombre: "Issue Sin Amoníaco",
      descripcion: `Descubre nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER-SIN AMONIACO-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER-SIN-AMONIACO-MOBILE.webp"),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
        bg: "bg-green-dark",
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    // {
    //   nombre: "Kit Decolorante + tonalizadores Issue",
    //   descripcion: `Querés colores de tendencia? Ahora tenés con qué! Armá looks a tu medida con nuestro Kit Decolorante.`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/BK_-800x1066px2.png"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/BK_-800x1066px2.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/BK_-450x800px2.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/BK_-450x800px2.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCE MÁS",
    //     link: `/${COUNTRY}/productos/issue-kit-decolorante-tonalizador`,
    //     bg: "bg-green-strong",
    //   },
    //   gpspharma:
    //     "https://www.gpsfarma.com/ebrandshop/issue/looks-de-tendencia.html",
    //   farmaonline: "https://www.farmaonline.com/lookstendenciaissue",
    // }, // TODO este no va mas en bolivia
    // {
    //   nombre: "Compra online",
    //   descripcion: `Encuentra todos nuestros productos en los diferentes shops online y compra sin moverte de tu casa!`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/GPS_-1903-x-8671.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/GPS_-1903-x-8671.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/GPS_-800x1066px1.png"),
    //     //ipadWebp: require("../../static/img/bannershome/iPad/GPS_-800x1066px1.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/GPS_-450x800px1.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/GPS_-450x800px1.webp"),
    //   },
    //   boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
    // },// TODO este no va mas en bolivia
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos cuatro tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      nombreColor: "black",
      descripcionColor: "black",
    },
    {
      nombre: "Tinturate en casa.",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encuentra el que va contigo y consigue colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.webp"),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-green-strong",
      },
    },
    {
      nombre: "Tinturate en casa.",
      nombreColor: "black",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encuentra el que va contigo y consigue colores brillantes y duraderos a un precio que te conviene.`,
      descripcionColor: "black",
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/DESKTOP_TENITECASA_BOL.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/DESKTOP_TENITECASA_BOL.webp"),
        ipad: require("../../static/img/bannershome/iPad/TABLET_TENITECASA_BOL.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/TABLET_TENITECASA_BOL.webp"),
        mobile: require("../../static/img/bannershome/Mobile/MOBILE_TENITECASA_BOL.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/MOBILE_TENITECASA_BOL.webp"),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-black",
      },
    },
    {
      nombre: "Coloracion",
      descripcion: `La coloración en crema Issue, con su exclusiva fórmula,
      combina colores radiantes de larga duración, con 100% de cobertura de canas. Conoce nuestras líneas permanentes, semi permanente & fantasía`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
      },
      boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
    },
  ],
  ec: [
    // {
    //   nombre: "Issue Sin Amoníaco",
    //   descripcion: `Descubrí nuestra nueva coloración permanente creada con ingredientes
    //         naturales. Ideal para cubrir canas.`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/Sin-amoniaco_-1903-x-8671.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/Sin-amoniaco_-1903-x-8671.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/Sin-amoniaco_-800x1066px1.png"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/Sin-amoniaco_-800x1066px1.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/Sin-amoniaco_-450x800px1.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/Sin-amoniaco_-450x800px1.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
    //     bg: "bg-green-dark",
    //   },
    //   gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
    //   farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    // },
    // {
    //   nombre: "Kit Decolorante + tonalizadores Issue",
    //   descripcion: `Querés colores de tendencia? Ahora tenés con qué! Armá looks a tu medida con nuestro Kit Decolorante.`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/BK_-800x1066px2.png"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/BK_-800x1066px2.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/BK_-450x800px2.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/BK_-450x800px2.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos/issue-kit-decolorante-tonalizador`,
    //     bg: "bg-green-strong",
    //   },
    //   gpspharma:
    //     "https://www.gpsfarma.com/ebrandshop/issue/looks-de-tendencia.html",
    //   farmaonline: "https://www.farmaonline.com/lookstendenciaissue",
    // },
    {
      nombre: "Compra online",
      descripcion: `Encuentra todos nuestros productos en los diferentes shops online y compra sin moverte
      de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/compra-online-ec.jpg"),
        //desktopWebp: require("../../static/img/bannershome/Desktop/GPS_-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/compra-online-ecuador-tablet.jpg"),
        //ipadWebp: require("../../static/img/bannershome/iPad/GPS_-800x1066px1.webp"),
        mobile: require("../../static/img/bannershome/Mobile/compra-online-ecuador-mobile.jpg"),
        //mobileWebp: require("../../static/img/bannershome/Mobile/GPS_-450x800px1.webp"),
      },
      boton: { nombre: "COMPRA", link: "", bg: "bg-green-strong" },
    },
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos cuatro tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCE MAS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      nombreColor: "black",
      descripcionColor: "black",
    },
    {
      nombre: "Tinturate en casa.",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encuentra el que va contigo y consigue
      colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
      },
      boton: {
        nombre: "CONOCE MAS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-green-strong",
      },
    },
    // {
    //   nombre: "Teñite en casa.",
    //   nombreColor: "black",
    //   descripcion: `Tenemos un producto para cada tipo de pelo. Encontrá el que va con vos y
    //         conseguí colores brillantes y duraderos a un precio que te conviene.`,
    //   descripcionColor: "black",
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/productos.jpg"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/productos.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/productos.jpg"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/productos.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/productos.jpg"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/productos.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos`,
    //     bg: "bg-black",
    //   },
    // },
    // {
    //   nombre: "Coloracion",
    //   descripcion: `????`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
    //   },
    //   boton: { nombre: "COMPRÁ", link: "", bg: "bg-green-strong" },
    // },
  ],
  py: [
    // {
    //   nombre: "Issue Sin Amoníaco",
    //   descripcion: `Descubrí nuestra nueva coloración permanente creada con ingredientes
    //         naturales. Ideal para cubrir canas.`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/Sin-amoniaco_-1903-x-8671.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/Sin-amoniaco_-1903-x-8671.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/Sin-amoniaco_-800x1066px1.png"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/Sin-amoniaco_-800x1066px1.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/Sin-amoniaco_-450x800px1.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/Sin-amoniaco_-450x800px1.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
    //     bg: "bg-green-dark",
    //   },
    //   gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
    //   farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    // },
    // {
    //   nombre: "Kit Decolorante + tonalizadores Issue",
    //   descripcion: `Querés colores de tendencia? Ahora tenés con qué! Armá looks a tu medida con nuestro Kit Decolorante.`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.png"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/BK_-1903-x-8672.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/BK_-800x1066px2.png"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/BK_-800x1066px2.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/BK_-450x800px2.png"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/BK_-450x800px2.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos/issue-kit-decolorante-tonalizador`,
    //     bg: "bg-green-strong",
    //   },
    //   gpspharma:
    //     "https://www.gpsfarma.com/ebrandshop/issue/looks-de-tendencia.html",
    //   farmaonline: "https://www.farmaonline.com/lookstendenciaissue",
    // },
    {
      nombre: "Comprá online",
      descripcion: `Encuentra todos nuestros productos en los diferentes shops online y compra sin moverte de tu casa!`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/BANNER_CHILE_DESKTOP.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/BANNER_CHILE_DESKTOP.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER_CHILE_TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER_CHILE_TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER_CHILE_MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER_CHILE_MOBILE.webp"),
      },
      boton: { nombre: "COMPRÁ", link: "", bg: "bg-green-strong" },
    },
    {
      nombre: "Issue Crazy Colors",
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos cuatro tonos para
            que juegues con tu pelo.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      },
      nombreColor: "black",
      descripcionColor: "black",
    },
    {
      nombre: "Tiñete en casa ",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encuentra el que más te guste y consigue colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
        // desktopWebp: require('../../static/img/bannershome/Desktop/cc_-1903-x-8673.webp'),
        ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
        // ipadWebp: require('../../static/img/bannershome/iPad/cc_-800x1066px2.webp'),
        mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
        // mobileWebp: require('../../static/img/bannershome/Mobile/cc_450x800px2.webp'),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-green-strong",
      },
    },
    // {
    //   nombre: "Teñite en casa.",
    //   nombreColor: "black",
    //   descripcion: `Tenemos un producto para cada tipo de pelo. Encontrá el que va con vos y
    //         conseguí colores brillantes y duraderos a un precio que te conviene.`,
    //   descripcionColor: "black",
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/productos.jpg"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/productos.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/productos.jpg"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/productos.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/productos.jpg"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/productos.webp"),
    //   },
    //   boton: {
    //     nombre: "CONOCÉ MÁS",
    //     link: `/${COUNTRY}/productos`,
    //     bg: "bg-black",
    //   },
    // },
    // {
    //   nombre: "Coloracion",
    //   descripcion: `????`,
    //   imagenes: {
    //     desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
    //     desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
    //     ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
    //     ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
    //     mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
    //     mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
    //   },
    //   boton: { nombre: "COMPRÁ", link: "", bg: "bg-green-strong" },
    // },
  ],
  ve: [
    {
      nombre: "Issue Sin Amoníaco",
      descripcion: `Descubre nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/banner-sin-amoniaco.webp"),
        ipad: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/BANNER-SIN-AMONIACO-TABLET.webp"),
        mobile: require("../../static/img/bannershome/Mobile/BANNER-SIN AMONIACO-MOBILE.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/BANNER-SIN-AMONIACO-MOBILE.webp"),
      },
      boton: {
        nombre: "CONOCE MÁS",
        link: `/${COUNTRY}/productos/kit-issue-sin-amoniaco`,
        bg: "bg-green-dark",
      },
      gpspharma: "https://www.gpsfarma.com/ebrandshop/issue/sin-amoniaco.html",
      farmaonline: "https://www.farmaonline.com/sinamoniacoissue",
    },
    {
      nombre: "Issue Crazy Colors",
      nombreColor:'color-white-primary',
      descripcion: `Animate a los colores fantasía! Si te pinta cambiar, tenemos seis tonos para
            que juegues con tu cabello.`,
      descripcionColor: 'color-white-primary',
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/pe-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/pe-800x1066.png"),
        //ipadWebp: require("../../static/img/bannershome/iPad/pe-800x1066.webp"),
        mobile: require("../../static/img/bannershome/Mobile/pe-450x800.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/pe-450x800.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos/crazy-colors-sachet`,
        bg: "bg-purple-dark",
      }
    },
    {
      nombre: "Teñite en casa.",
      descripcion: `Tenemos un producto para cada tipo de cabello. Encontrá el que va con vos y
            conseguí colores brillantes y duraderos a un precio que te conviene.`,

      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.png"),
        desktopWebp: require("../../static/img/bannershome/Desktop/Coloracion_-1903-x-8671.webp"),
        ipad: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.png"),
        ipadWebp: require("../../static/img/bannershome/iPad/Coloracion_-800x1066px1.webp"),
        mobile: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.png"),
        mobileWebp: require("../../static/img/bannershome/Mobile/Coloracion_-450x800px1.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-green-strong",
      }
    },
    {
      nombre: "Tinturate en casa.",
      descripcion: `Tenemos un producto para cada tipo de cabello.
      Encuentra el que va contigo y consigue colores brillantes y duraderos a un precio que te conviene.`,
      imagenes: {
        desktop: require("../../static/img/bannershome/Desktop/productos.jpg"),
        desktopWebp: require("../../static/img/bannershome/Desktop/productos.webp"),
        ipad: require("../../static/img/bannershome/iPad/productos.jpg"),
        ipadWebp: require("../../static/img/bannershome/iPad/productos.webp"),
        mobile: require("../../static/img/bannershome/Mobile/productos.jpg"),
        mobileWebp: require("../../static/img/bannershome/Mobile/productos.webp"),
      },
      boton: {
        nombre: "CONOCÉ MÁS",
        link: `/${COUNTRY}/productos`,
        bg: "bg-black",
      }
    },
    {
        nombre: "Coloracion",
        descripcion: `La coloración en crema Issue, con su exclusiva fórmula, combina colores radiantes de larga duración,
        con 100% de cobertura de canas. Conoce nuestras líneas permanentes, semi permanente & fantasía`,
        imagenes: {
          desktop: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.jpg"),
          desktopWebp: require("../../static/img/bannershome/Desktop/PE-BANNER-KERATIN-DESKTOP.webp"),
          ipad: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.jpg"),
          ipadWebp: require("../../static/img/bannershome/iPad/PE-BANNER-KERATIN-TABLET.webp"),
          mobile: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.jpg"),
          mobileWebp: require("../../static/img/bannershome/Mobile/PE-BANNER-KERATIN-MOBILE.webp"),
        },
        boton: {
          nombre: "COMPRÁ",
          link: `/${COUNTRY}/productos/coloracion`,
          bg: "bg-green-strong"
        },
      },

  ],
};
