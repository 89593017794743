import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Chat } from '../@ui'


const CHAT_ENABLED = false;


const Layout = ({ children }) => {

    return (
        <>
            <Header />
            {CHAT_ENABLED && <Chat />}
            {children}
            <Footer />
        </>
    );
}

export default Layout;