import React from 'react';
import './Thanks.scss'

const Thanks = () => {
    return (
        <div className="thanks-container">
            <div className="thanks-content">
                <div className="thanks-text-container">
                    <h4>¡Gracias por contactar con nosotros! </h4>
                    <p>Nos pondremos en contacto a la brevedad.</p>
                    <span>Saludos.</span>
                </div>
                <div className="line-container">
                    <div className="actual-line"></div>
                </div>
            </div>
        </div>
    );
}

export default Thanks;