import React, { useEffect } from 'react';
import './FilterMenu.scss'
import MenuItem from '../MenuItem';
import SvgDB from '../Svg';

import { useDispatch } from 'react-redux'

import { COUNTRY } from '../../../constants';

const FilterMenu = (props) => {

    const {
        active = false,
        title = '',
        menuitems = [
            {
                query: '',
                title: '',
                filtrosnames: []
            }
        ],
        setActive,
        dispatchOnClick = null,
        dispatchOnDropdownClick = null
    } = props

    const dispatch = useDispatch()


    useEffect(() => {

        if (active) {
            document.querySelector('body').style.overflow = "hidden"
        } else {
            document.querySelector('body').style.overflow = "auto"
        }

    }, [active])




    return (
        <div className={`filtermenu-container ${active && 'active'}`}>
            <div className="filtermenu-content">

                <div className="header-container">
                    <div className="header-svg-container" onClick={() => setActive(null)}>
                        {SvgDB['flecha']}
                    </div>

                    <h4>{title}</h4>
                </div>

                {dispatchOnDropdownClick ?
                    menuitems.map((el, i) => (
                        <MenuItem
                            key={(i + 1) * 5343}
                            dropdown={el.filtrosnames[COUNTRY]}
                            title={el.title}
                            dispatchOnDropdownClick={dispatchOnDropdownClick}
                            setActive={setActive}
                            query={el.query}
                        />
                    ))

                    :
                    menuitems.map((el, i) => (
                        <MenuItem
                            key={(i + 1) * 5112}
                            dropdown={el.filtrosnames}
                            title={el.title}
                            onClick={() => { setActive(null); dispatch(dispatchOnClick(el.title)) }}
                        />
                    ))

                }


            </div>
        </div >
    );
}

export default FilterMenu;