import React from 'react';
import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from './ImageModalVideo.module.scss';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import useResizeWidth from '../../hooks/useResizeWidth';
import { BREAKPOINT } from '../../constants';


// Componente que muestra una imagen y despliega un video modal cuando la misma es clickeada.
// `src` y `alt` son los atributos correspondientes del tag <img>, `videoId` es el ID del
// video a mostrar (por ejemplo, para el link 'https://www.youtube.com/watch?v=V3SEjP8oOZo',
// el `videoId` correspondiente es `V3SEjP8oOZo`); `channel` indica dónde está hosteado el
// video y defaultea a `youtube`.
const ImageModalVideo = ({ src, alt, videoId, channel = 'youtube' }) => {

  const rizeWidth = useResizeWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const onImgClick = () => {
    setIsModalOpen(true);
  }

  const playBtnSize = rizeWidth > BREAKPOINT.TABLET ? '40px' : '30px';

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={src} alt={alt} onClick={onImgClick} />
        <div className={styles.btnContainer}>
          <FaPlay size={playBtnSize} color="white" cursor="pointer" />
        </div>
      </div>
      <ModalVideo
        channel={channel}
        isOpen={isModalOpen}
        videoId={videoId}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default ImageModalVideo;
