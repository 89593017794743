import React, { useState } from "react";
import "./ProdTut.scss";

const ProdTut = ({ tutorial = {}, titleAlign = "", borderBottom = "" }) => {
  const [iframeloaded, setIframeLoaded] = useState(false);
  //console.log("tutorial: ", tutorial.nombre);

  const tutorialUrls = tutorial.link ? tutorial.link.split(',') : [];

  return (
    <>
      <div className="prodtut-container page-padding" style={{ borderBottom }}>
        <div className="prodtut-content">
          <div className="prodtut-top">
            {tutorial && (
              <h5 className="prodtut-name" style={{ textAlign: titleAlign }}>
                Tutorial - <span>"{tutorial.nombre}"</span>
              </h5>
            )}
          </div>

          <div className="prodtut-main">
            {tutorialUrls.map((url, index) => (
              <React.Fragment key={index}>
                <a target="_blank" href={url.trim()} rel="noopener noreferrer">
                  <div className="prodtut-video-container">
                    <div className="video">
                      {tutorial?.image_placeholder && !iframeloaded && (
                        <img
                          src={tutorial?.image_placeholder.src}
                          alt={tutorial?.image_placeholder.nombre}
                        />
                      )}
                      <div className="iframe-container">
                        {tutorial && (
                          <iframe
                            title={tutorial?.link}
                            onLoad={() => setIframeLoaded(true)}
                            width="100%"
                            height="100%"
                            className="youtube-iframe"
                            src={url.trim()}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
                {index < tutorialUrls.length - 1 && <br />} {}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProdTut;
