import React from 'react';
import './Input.scss';


const Input = (props) => {

    const {
        name = '',
        type = '',
        value = null,
        updateValue = null,
    } = props

    const onChange = (event) => {
        if (updateValue) {
            updateValue(event.target.value);
        }
    }

    return (
        <div className="inp-container">
            <input
                onChange={onChange}
                type={type}
                name={name}
                placeholder=' '
                id={name}
                value={value}
            />
            <label htmlFor={name}>{name}</label>
        </div>
    );
}

export default Input;
