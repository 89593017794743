import React from 'react';


// Dropdown con provincias Argentinas.
const ProvinciaSelect = ({ updateValue }) => {

  return (
    <select onChange={(e) => updateValue(e.target.value)}>
      <option>* Provincia</option>
      <option>Buenos Aires</option>
      <option>Capital Federal</option>
      <option>Catamarca</option>
      <option>Chaco</option>
      <option>Chubut</option>
      <option>Córdoba</option>
      <option>Corrientes</option>
      <option>Entre Ríos</option>
      <option>Formosa</option>
      <option>Jujuy</option>
      <option>La Pampa</option>
      <option>La Rioja</option>
      <option>Mendoza</option>
      <option>Misiones</option>
      <option>Neuquén</option>
      <option>Río Negro</option>
      <option>Salta</option>
      <option>San Juan</option>
      <option>San Luis</option>
      <option>Santa Cruz</option>
      <option>Santa Fe</option>
      <option>Santiago del Estero</option>
      <option>Tierra del Fuego</option>
      <option>Tucumán</option>
    </select>
  );
}

export default ProvinciaSelect;
