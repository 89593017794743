import React from 'react';
import './Message.scss'

const Message = ({ message = '' }) => {
    return (
        <div className="message-container">
            <span>{message}</span>
        </div>
    );
}

export default Message;