import React from 'react';
import './Banner.scss';
import bannerImg from '../../../static/img/contacto/banner_contacto.png';


const Banner = () => {

    return (
        <div className="cont-banner-container">

            <div style={{ width: '100%', overflow: 'hidden' }}>
                <img src={bannerImg} alt="banner-contacto" />
            </div>

            <div className="cont-banner-content">

                <div className="cont-banner-align">

                    <div className="cont-banner-top">
                        <h1>Contacto</h1>
                    </div>

                    <div className="cont-banner-bottom">
                        <h5>Comunicate con nosotros</h5>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Banner;