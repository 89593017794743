import React from 'react';
import { Link } from 'react-router-dom';
import './BannerCategory.scss'

// import '../../../static/img/productosimgsbanner/prodbanner1.png'

import { COUNTRY } from '../../../constants.js';

const BannerCategory = (props) => {

    const {
        title = '',
        line = false,
        subtitle = '',
        description = '',
        titleClassName = '',
        descrClassName = '',
        imagenes = {}
    } = props


    return (
        <div className="banner-category-container">
            <div className="banner-category-images-container">
                <picture>
                    <source media="(min-width: 620px)" srcSet={imagenes.desktopWebp} type="image/webp" />
                    <source media="(min-width: 620px)" srcSet={imagenes.desktop} />
                    <source srcSet={imagenes.mobileWebp} type="image/webp" />
                    <img src={imagenes.mobile} alt={title} />
                </picture>
            </div>
            <div className="banner-category-content">
                {subtitle && <Link to={`/${COUNTRY}/productos`}><span className='arrow-left'>{subtitle}</span></Link>}
                <h4 className={titleClassName} style={{ "font-weight": "700" }}>{title}</h4>
                {line && <div className='banner-category-line'></div>}
                {description && <p className={descrClassName} style={{ "font-weight": "600" }}>{description}</p>}
            </div>
        </div>
    );
}

export default BannerCategory;