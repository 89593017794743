import React, { useEffect } from 'react';
import { scrollToTop } from '../../utils';
import styles from './InfoContainer.module.scss';


// Componente para mostrar info en una caja gris. Hecho para páginas del tipo de política de privacidad,
// aviso legal, etc.
const InfoContainer = ({ content }) => {

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        {content}
      </div>
    </div>
  );
}

export default InfoContainer;
