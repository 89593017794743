import React from 'react';
import './SectionTitle.scss'
import Button from '../Button';

const SectionTitle = (props) => {

    const {
        title = '',
        btnClassName = '',
        btnText = '',
        ...other
    } = props

    return (
        <div className="section-title">
            <h3 className='bold'>{title}</h3>
            <Button
                text={btnText}
                className={btnClassName}
                {...other}
            />
        </div>
    );
}

export default SectionTitle;