import React, { useEffect } from 'react';
import { BREAKPOINT } from '../../constants';
import useResizeWidth from '../../hooks/useResizeWidth';
import { scrollToTop } from '../../utils';
import styles from './NuestraHistoria.module.scss';


// Cada una de las secciones de la historia
const StorySection = ({ year, title, content, style = { } }) => {

  return (
    <div className={styles.storySectionContainer} style={style}>
      <h1>{year}</h1>
      <h2>{title}</h2>
      {content}
    </div>
  );
}


const NuestraHistoria = () => {

  const rizeWidth = useResizeWidth();

  useEffect(() => {
    scrollToTop();
  }, []);

  // En desktop, correr un poco de lugar los elementos
  const offsetStyle = rizeWidth < BREAKPOINT.TABLET ? { } : { position: 'relative', top: '150px' };
  const lastStyle = rizeWidth < BREAKPOINT.TABLET ? { } : { position: 'relative', left: '-375px' };

  return (
    <div className={styles.container}>
      <StorySection year={1977} title="EL INICIO" content={<><p> En 1977 dos jóvenes químicos y empresarios, crearon la empresa que más tarde se convertiría en ISSUE. Se conocieron trabajando en un laboratorio medicinal el cual les despertó el entusiasmo y les proporcionó el conocimiento para emprender su propio camino en la industria cosmética. </p> <p> Todo comenzó con la fabricación de dos productos en el garaje de la casa de uno de ellos en la calle Cuenca: un quitaesmalte y una cera depilatoria. Con los productos en cajas, ambos salieron a vender.  Vendiendo todo lo que producían, encontraron la oportunidad de ampliar su cartera de productos y así es como iniciaron la investigación y desarrollo del polvo decolorante que años más tarde se convertiría en el éxito que conocemos hoy día. </p></>} />
      <StorySection year={1985} title="LA COLORACIÓN" style={offsetStyle} content={<><p> Issue evoluciona vertiginosamente y lanza al mercado un producto innovador para la época que rompió paradigmas el sachet de coloración.Fue la primera tintura en formato sachet en Argentina que logró posicionar a Issue como una tintura de buena calidad, con un precio accesible para toda mujer. </p> <p> Durante la década del 90 las marcas fueron desafiadas por la “fiebre” del consumismo. La cultura de la globalización y las nuevas políticas económicas potenciaron el deseo por comprarlo “todo” y el consumidor se volvió cada vez más exigente. Issue, respondiendo a estas necesidades lanzó al mercado su primera coloración en Kit donde se incluían todos los productos y accesorios indispensables para el proceso de coloración, con el fin de enriquecer la experiencia de las consumidoras y proveerles una oferta más completa, mantenido su accesibilidad. </p></>} />
      <StorySection year={1994} title="EMPLEADOS" content={<><p> ISSUE ya contaba con más de 150 empleados, la mayoría trabajadores de dado que en aquel momento el trabajo era principalmente manual. </p></>} />
      <StorySection year={1999} title="ISSUE PROFESSIONAL COLOR" style={offsetStyle} content={<><p> Inspirándose en marcas internacionales nace Issue Professional Color con una carta de 30 tonos. El desarrollo de esta nueva unidad de negocios y del mercado profesional fue posible gracias a la colaboración de técnicos italianos que ayudaron a desarrollar la línea de coloración exclusiva para peluquerías. </p></>} />
      <StorySection year={2002} title="CRECIMIENTO" content={<><p> Apostando por la industria nacional en uno de los años más difíciles de la Argentina, Issue adquiere una nueva planta con mayor capacidad productiva para poder afrontar la fuerte demanda de sus productos. A partir de estos avances Issue logra introducirse en el mercado internacional ofreciendo su portfolio en Chile, Uruguay, Bolivia, Paraguay, Ecuador, entre otros. </p></>} />
      <StorySection year={2010} title="EL GRAN CAMBIO" style={offsetStyle} content={<><p> Godrej, una empresa familiar de origen indio y con 26 mil empleados a nivel mundial, adquiere y fusiona dos compañías argentinas: Issue y Argencos. De esta manera surge Godrej Argentina , filial que hace parte de la multinacional que anualmente factura 4.1 billones de dólares y está presente en más de 30 países. </p> <p> Desde su llegada a la Argentina, Godrej triplicó sus beneficios locales.  Este crecimiento se basa en el aumento de la capacidad productiva, la inversión tanto en tecnología e innovación en cada una de sus marcas y en el relacionamiento y planes conjuntos con cada uno de sus clientes que potencien los beneficios de ambas partes. </p></>} />
      <StorySection year={2021} title="HOY" style={lastStyle} content={<><p> Issue es una marca que fabrica más de 60 millones de unidades al año, exportando el 20% de su producción a más de 7 países de la región de Latinoamérica y a Estados Unidos. </p></>} />
    </div>
  );
}

export default NuestraHistoria;
