// Constantes de tamaño. Son iguales a las del archivo src/static/sass/_media.scss, así que
// tené cuidado si esas variables cambian
export const BREAKPOINT = {
  MOBILE_XS: 350,
  MOBILE_LG: 620,
  TABLET: 1080,
  DESKTOP_SM: 1280,
  DESKTOP_MD: 1440,
};

export const AVAILABLE_COUNTRIES = ["ar", "cl", "uy", "pe", "bo", "ec", "py", "ve"];
export const DEFAULT_COUNTRY = "ar";

export const COUNTRY = AVAILABLE_COUNTRIES.includes(
  window.location.pathname.split("/")[1]
)
  ? window.location.pathname.split("/")[1]
  : DEFAULT_COUNTRY;

// Prod
// export const DIRECTUS_BASE_URL =
//   "https://issuecolor-admin-prod" +
//   (COUNTRY !== "ar" ? `-${COUNTRY}` : "") +
//   ".noduscompany.com/test";

export const DIRECTUS_BASE_URL =`https://admin-${COUNTRY}.issuecolor.com/test`;
// export const DIRECTUS_BASE_URL =`http://issueapi_${COUNTRY}.local/test`; // para ambiente local


// Dev
//export const DIRECTUS_BASE_URL = 'https://issuecolor-admin.noduscompany.com/test';

// URL del microservicio de email
//export const EMAIL_MICROSERVICE_URL = `https://issue-mailproxy.noduscompany.com`;
export const EMAIL_MICROSERVICE_URL ='';
// Dirección de email a la que se envían los mensajes de contacto
// export const EMAIL_TO = `laureano3400@gmail.com`; // dev
// export const EMAIL_TO = `veronica.perez@godrejcp.com`; // prod
export const EMAIL_TO = {
  ar: `info@godrejlatam.com`, // prod
  cl: `antonio.martinez@godrejcp.com`, // prod
  uy: `zelene.larralde@godrejcp.com`, // prod
  pe: "perufarma@perufarma.com.pe",
  bo: "",
  ec: "info@dous.ec",
  py: "issueparaguay@recofar.com.py",
  ve: "issuevenezuela@gmail.com",
};

export const eshops = {
  ar: ["gpspharma", "farmaonline"],
  pe: ["perufarma"],
  cl: ["sallybeauty", "liquimax"],
  uy: ["tata", "farmashop"],
  bo: ["gpspharma", "farmaonline"],
  ec: ["frecuento", "medicity", "gloriasaltos"], // logo es mi comisariato, pero eshop es frecuento
  py: ["salonmarket"],
  ve: [],
};

// A la izquierda el nombre del tono según el admin y a la derecha el parámetro del tono en la url
export const urlProductUy = new Map([
  ["N-5-1", "-numero-5-1/p"],
  ["N-6-73", "-673/p"],
  ["N-7", "-1-00-u-numero-7/p"],
  ["N-9", "-1-00-u-numero-9/p"],
  ["N-8-73", "-8-73/p"],
  ["N-9-1", "-numero-9-1/p"],
  ["N-4", "-numero-4/p"],
  ["N-10-1", "-1-00-u-numero-10-1/p"],
  ["N-7-64", "-1-00-u-numero-7-64/p"],
  ["N-5-13", "-1-00-u-numero-5-13/p"],
  ["N-1A", "-1-00-u-n-1a/p"],
  ["N-1", "-numero-1/p"],
  ["N-5-6", "-num-5-6/p"],
  ["N-8", "-10mero-8/p"],
  ["N-7-11", "-711-72-ceniza/p"],
  ["N-6", "-numero-6/p"],
  ["N-7-1", "-numero-7-1/p"],
  ["N-8-3", "-1-00-u-numero-8-3/p"],
]);

export const urlProductPy = new Map([
  // Crazy colors

  ["crazy-orange", "-naranja-47g"],
  ["crazy-violet", "-violeta-47g"],
  ["crazy-pink", "-rosa-47g"],
  ["crazy-red", "-rojo-47g"],
  ["crazy-fucsia", "-fucsia-47g"],
  ["crazy-blue", "-azul-47g"],

  ["N-5-3", "issue-keratin-color-kit-n-5-3-castano-claro-dorado-2/"],
  ["N-8-3", "-n-8-3-rubio-claro-dorado/"],
  ["N-4", "-n-4-castano/"],
  ["N-6-1", "-n-6-1-rubio-oscuro-ceniza/"],
  ["N-8-73", "-n-8-73-chocolate-claro-dorado/"],
  ["N-7-1", "-n-7-1-rubio-ceniza/"],
  ["N-6-62", "-n-6-62-rojo-intenso-nacarado/"],
  ["N-6", "-n-6-rubio-oscuro/"],
  ["N-8-1", "-n-8-1-rubio-claro-ceniza/"],
  ["N-1", "-n-1-negro/"],
  ["N-7", "-n-7-rubio/"],
  ["N-8", "-n-8-rubio-claro/"],
  ["N-7-64", "-n-7-64-rubio-rojizo-cobrizo/"],
  ["N-9-1", "-n-9-1-rubio-claro-claro-ceniza/"],
  ["N-9", "-n-9-rubio-claro-claro/"],
  ["N-5", "-n-5-castano-claro/"],
  ["N-1A", "-n-1a-negro-azulado/"],
  ["N-5-13", "-n-5-13-castano-claro-chocolate/"],
  ["N-7-3", "-n-7-3-rubio-dorado/"],
  ["N-6-73", "-n-6-73-chocolate-dorado/"],
  ["N-5-1", "-n-5-1-castano-claro-ceniza-2/"],
]);

// export const FARMAONLINE_DEFAULT_URL = 'https://www.farmaonline.com/landing-issue';
// export const GPSFARMA_DEFAULT_URL = 'https://www.gpsfarma.com/ebrandshop-issue?utm_source=gpsfarma&utm_medium=ebrand&utm_campaign=issue';
