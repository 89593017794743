import React, { useEffect } from 'react';
import './FiltrosList.scss'

import { useDispatch, useSelector } from 'react-redux'
import { addFilter, removeFilter, resetFilters, selectCategoria } from '../../../redux/filtrosDuck';


const FiltrosList = (props) => {

    const {
        title = '',
        filtros = [],
        query = ''
    } = props

    const { loading } = useSelector(state => state.filtros)

    const dispatch = useDispatch()

    const HandleFilterClick = (e, filter) => {
        e.preventDefault()

        if (loading) return

        let chbx;
        if (e.target.type === 'checkbox') {
            chbx = e.target
        } else if (e.target.tagName === 'LI') {
            chbx = e.target.firstChild
        } else {
            chbx = e.target.parentElement.firstChild
        }

        filter.element = chbx
        if (!chbx.classList.contains('checked')) {
            dispatch(addFilter(filter))
        } else {
            dispatch(removeFilter(filter))
        }

    }

    // Resetear filtros (tanto en Redux como checkboxes) cuando cambia la categoría (
    // "Coloración", "Complementos", etc.)
    const categoria = useSelector(selectCategoria);

    useEffect(() => {
        // Obtener elementos DOM de los filtros, y si son checkboxes, deschequearlos
        for (let i = 0; i < filtros.length; i++) {
            const currFiltro = filtros[i];

            const currFiltroDOM = document.getElementById(currFiltro.titulo);

            if (currFiltroDOM.nodeName === 'INPUT') {
                currFiltroDOM.checked = false;
                currFiltroDOM.classList.remove('checked');
            }
        }

        // Resetear filtros de Redux
        dispatch(resetFilters());
    }, [categoria, dispatch, filtros]);


    return (
        <div className="filtros-list-container">
            <h5>{title}</h5>
            <ul>
                {filtros.map((el, index) => {
                    if (el.color) {
                        return <li
                            style={{ color: `${el.color}` }}
                            className="colorcheckbox"
                            key={(index + 1) * 9122}
                            onClick={(e) => HandleFilterClick(e, { value: el.titulo, query: query, id: (index + 1) * 9122 })}
                        >
                            <input type="checkbox" id={el.titulo} name={el.titulo} />
                            <label htmlFor={el.titulo}>{el.titulo}</label>
                        </li>
                    } else {
                        return <li
                            key={(index + 1) * 1343}
                            onClick={(e) => HandleFilterClick(e, { value: el.titulo, query: query, id: (index + 1) * 1343 })}
                        >
                            <input type="checkbox" id={el.titulo} name={el.titulo} />
                            <label htmlFor={el.titulo}>{el.titulo}</label>
                        </li>
                    }
                })}
            </ul>
        </div>
    );
}

export default FiltrosList;