import React, { useEffect, useState } from 'react';
import './ConsultaSelect.scss'


const dropdwoptions = [
    { name: 'Consulta o sugerencia', checkboxes: ['Elogios', 'Sugerencias', 'Dónde consigo sus productos', 'Otros'] },
    { name: 'Reclamo o queja', checkboxes: ['Reclamo', 'Queja', 'Otros' ] },
    { name: 'Soy cliente – Reclamo o consulta', checkboxes: ['Reclamo', 'Consulta', 'Dónde consigo sus productos', 'Otros'] },
    { name: 'Quiero vender sus productos', checkboxes: ['Sugerencias', 'Dónde consigo sus productos', 'Otros'] },
]

const ConsultaSelect = ({ updateValue }) => {

    // Opción elegida, e.g. { name: 'Consulta o sugerencia', checkboxes: [...] }
    const [option, setOption] = useState({})

    const [dropdown, setDropdown] = useState(false)

    // Checkbox activa, o mejor dicho, índice de la checkbox activa. option.checkboxes[activecheckbox] es la
    // string del checkbox activo
    const [activecheckbox, setActiveCheckbox] = useState(-1)

    // Actualizar valor cuando cambia option / activecheckbox
    useEffect(() => {
        if (option === {} || activecheckbox === -1) {
            updateValue('');
            return;
        }

        updateValue(`${option.name} - ${option.checkboxes[activecheckbox]}`);
    }, [activecheckbox, option, updateValue]);


    return (

        <div className="cs-container">
            <div className="cs-top">

                <div
                    className={`cs-top-content 
                        ${dropdown && Object.keys(option).length === 0 && 'active'}`}
                    onClick={() => { setDropdown(!dropdown); setOption({}); setActiveCheckbox(-1) }}
                >
                    <span> {Object.keys(option).length !== 0 ? `*${option.name}` : '* ¿Cómo podemos ayudarte?'}</span>

                    <div className="cs-icon-container">
                        <div className="cs-icon"></div>
                    </div>
                </div>

            </div>

            {!dropdown ? '' :
                <div className='cs-bottom'>

                    {Object.keys(option).length === 0 ?
                        <div className="cs-bottom-item cs-bottom-options">
                            <ul>
                                {dropdwoptions.map((el, i) => (
                                    <li key={(i + 1) * 630} onClick={() => setOption(el)}>{el.name}</li>
                                ))}
                            </ul>
                        </div>
                        :
                        <div className="cs-bottom-item cs-bottom-chkboxes">
                            <ul>
                                {option.checkboxes.map((el, i) => (
                                    <li
                                        key={(i + 1) * 640}
                                        onClick={() => setActiveCheckbox(i)}
                                        className={`${i === activecheckbox && 'active'}`}
                                    >
                                        <input
                                            type="checkbox"
                                            name={el}
                                        />
                                        <span>{el}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }

                </div>
            }

        </div>
    );
}

export default ConsultaSelect;