import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Demo from "../components/@ui/Demo";
import Index from "../components/Index";
import Productos from "../components/Productos";
import ProductoIndividual from "../components/ProductoIndividual";
import TutorialesPage from "../components/TutorialesPage";
import Contacto from "../components/Contacto";
import Layout from "../components/Layout";
import { categoriasroutes } from "../components/Data";
import NuestraHistoria from "../components/NuestraHistoria/NuestraHistoria";
import PreguntasFrecuentes from "../components/PreguntasFrecuentes/PreguntasFrecuentes";
import LoUltimo from "../components/LoUltimo/LoUltimo";
import AvisoLegal from "../components/AvisoLegal/AvisoLegal";
import PoliticaPrivacidad from "../components/PoliticaPrivacidad/PoliticaPrivacidad";
import { COUNTRY } from "../constants";

const Routes = () => {
  const catroutesoptions = categoriasroutes.reduce(
    (acc, cur, i) => (i === 0 ? (acc += cur) : (acc += `|${cur}`)),
    ""
  );

  return (
    <Layout>
      <Switch>
        <Route path={`/${COUNTRY}`} exact component={Index} />
        <Route path={`/${COUNTRY}/demo`} exact component={Demo} />
        <Route
          path={`/${COUNTRY}/productos`}
          exact
          render={(props) => (
            <Productos
              state={props.location.state}
              curcategory={"productos"}
              query={props.location.search}
              location={props.location}
            />
          )}
        />
        <Route
          path={`/${COUNTRY}/productos/:categorias(${catroutesoptions})`}
          exact
          render={(props) => (
            <Productos
              curcategory={props.match.params.categorias}
              location={props.location}
            />
          )}
        />
        <Route
          path={`/${COUNTRY}/productos/:id`}
          exact
          render={({ match }) => (
            <ProductoIndividual
              key={match.params.id}
              id={match.params.id}
              curcategory={match.params.categorias}
            />
          )}
        />
        <Route
          path={`/${COUNTRY}/tutoriales`}
          exact
          render={({ location }) => (
            <TutorialesPage
              state={location.state}
              curcategory={"tutoriales"}
              query={location.search}
            />
          )}
        />

        <Route
          path={`/${COUNTRY}/tutoriales/:id`}
          exact
          render={({ match }) => (
            <TutorialesPage curcategory={"tutoriales"} id={match.params.id} />
          )}
        />

        <Route path={`/${COUNTRY}/contacto`} exact component={Contacto} />

        <Route
          path={`/${COUNTRY}/nuestra-historia`}
          exact
          component={NuestraHistoria}
        />

        <Route
          path={`/${COUNTRY}/preguntas-frecuentes`}
          exact
          component={PreguntasFrecuentes}
        />

        <Route path={`/${COUNTRY}/lo-ultimo`} exact component={LoUltimo} />

        <Route path={`/${COUNTRY}/aviso-legal`} exact component={AvisoLegal} />

        <Route
          path={`/${COUNTRY}/politica-privacidad`}
          exact
          component={PoliticaPrivacidad}
        />

        {/* <Route path={`/${COUNTRY}/coloracion/issue-brillo-extremo`} exact render={() => <Redirect to='/productos/brillo-extremo' />} /> */}

        <Route
          path={`/${COUNTRY}/coloracion/issue-brillo-extremo`}
          exact
          render={() => (
            <Redirect to={`/${COUNTRY}/productos/brillo-extremo`} />
          )}
        />
      </Switch>
    </Layout>
  );
};

export default Routes;
