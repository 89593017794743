import React from 'react';
import { categorias } from '../../Data'
import './CategoriasMobile.scss'

import { useHistory } from 'react-router-dom'

import { COUNTRY } from '../../../constants.js'

const CategoriasMobile = (props) => {

    const { push } = useHistory()

    const {
        className = '',
        curcategory = ''
    } = props;

    return (
        <div className={`categorias-mobile hidden-desk ${className}`}>
            {Object.values(categorias).map((el, i) => (
                <div
                    onClick={() => push(`/${COUNTRY}/productos/${el.slug}`)}
                    key={(i + 1) * 1300}
                    className={`categoria-item-container categoriaproducto 
                    ${curcategory === el.slug ? `${el.clase}` : 'inactive'}`
                    }
                >
                    <div className="categoria-item-content fill-white-primary">
                        <h5 className='bold'>{el.nombre}</h5>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CategoriasMobile;