import React from 'react';
import './LoadingTutoriales.scss'



const LoadingTutoriales = ({ cantidad = 4 }) => {

    return (
        <div className="loadingtutoriales-container">

            {[...Array(cantidad).keys()].map(el => (
                <div key={el} className="loadingtutorial-container animate-filter">
                    <div className="loadingtutorial-fakeimg-container"></div>
                </div>
            ))}
        </div>

    )
}

export default LoadingTutoriales;