import React from 'react';
import './PageContent.scss'


const PageContent = ({ children }) => {

    return (
        <>
            <main className="page-content page-padding">
                {children[0]}
                {children[1]}
            </main>
        </>

    );
}

export default PageContent;