import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGetProductsStatus, selectLatestProducts } from '../../redux/productosDuck';
import { LoadingProducts, Message, Producto } from '../@ui';


// Cantidad máxima de "Últimos productos" a mostrar
const NUM_PRODUCTS = 4;


const UltimosProductos = () => {

  const { message } = useSelector(state => state.productos);
  const ultimosProductos = useSelector(selectLatestProducts(NUM_PRODUCTS));
  const getProductsStatus = useSelector(selectGetProductsStatus);
  const loading = getProductsStatus === 'loading';
  const [transition, setTransition] = useState(0);


  return (
    <>
    {loading && <LoadingProducts cantidad={NUM_PRODUCTS} />}
    {!loading && message && <Message message={message} />}
    {
      !loading && ultimosProductos[0] &&
      <>
        {ultimosProductos.map((el, i) => (
          <Producto
              imagen={el.imagen}
              nombre={el.nombre}
              id={el.id}
              key={el.id}
              categoria={el.categoria[0]}
              slug={el.slug}
              style={{ transform: `translate(-${transition * 120}%)` }}
              onSwipedLeft={() => {
                  if (transition < ultimosProductos.length - 2) {
                      setTransition(transition + 1);
                  }
              }}
              onSwipedRight={() => {
                  if (transition > 0) {
                      setTransition(transition - 1);
                  }
              }}
          />
        ))}
      </>
    }
    </>
  );
}

export default UltimosProductos;
