import React from 'react';

import { useSelector } from 'react-redux'
import { filterCategories } from '../../../redux/productosDuck'
import { COUNTRY } from '../../../constants.js';

import './Categorias.scss'
import {
    Button,
    SvgDB
} from '../../@ui'

import { Link } from 'react-router-dom'

const Categorias = () => {
    const data = useSelector(({ productos }) => productos.data);
    const filteredCategories = filterCategories(data)
    const cantCateg = Object.values(filteredCategories).length
    return (
        <div className="icategorias-container section-space">
            {Object.values(filteredCategories).map((el, index) => (
                <div key={(index + 1) * 8600} className={`icategorias-item-container ${cantCateg === 3 ? 'col-3' : ''} ${cantCateg === 2 ? 'col-2' : ''}`}>
                    <Link to={`/${COUNTRY}/productos/${el.slug}`}>
                        <div className="icategorias-item-content">
                            <div className="icategorias-text-container">
                                <h5 className='bold color-white-primary'>
                                    {el.nombre}
                                </h5>
                            </div>
                            <Button
                                text='VER PRODUCTOS'
                                className='border-white-primary color-white-primary categorias hover-invert'
                                textTransform='uppercase'
                            />
                            <div className="icategorias-arrow-container">
                                {SvgDB['flecha']}
                            </div>
                        </div>
                    </Link>

                </div>
            ))}


        </div>
    );
}

export default Categorias;