import React from 'react';
import './CategoriasBanner.scss'
import { categorias, categoriasroutes } from '../../Data'
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux'
import { filterCategories } from '../../../redux/productosDuck'
import { COUNTRY } from '../../../constants.js'

const CategoriasBanner = (props) => {

    const {
        className = '',
        categoriaproducto = ''
    } = props

    const data = useSelector(({ productos }) => productos.data);
    const { location: { pathname }, push } = useHistory();

    const paths = pathname.split('/')

    const catbannerdata = Object.values(categorias).map(el => {
        if (pathname === '/productos' || pathname === '/productos/') return el

        if (categoriaproducto) {
            if (categoriaproducto === el.slug) {
                return { ...el, clase: `${el.clase} active` }
            } else {
                return { ...el, clase: 'inactive' }
            }
        }

        if (paths[3] &&
            paths[3].indexOf(el.slug) !== -1) {
            return { ...el, clase: `${el.clase} active` }
        } else {
            return { ...el, clase: 'inactive' }
        }
    })

    const productPage = !categoriasroutes.includes(paths[3]) && paths[3]

    // Se eliminan las categorías sin items
    const catbannerdataFiltered = filterCategories(data, catbannerdata)

    return (
        <div className={`categorias-banner-container ${className}`}>
            {catbannerdataFiltered.map((el, i) => (
                <div
                    key={(i + 1) * 1200}
                    className={`categoria-item-container ${el.clase} ${productPage && 'products-page'}`}
                    onClick={() => push(`/${COUNTRY}/productos/${el.slug}`)}
                >
                    <div className='categoria-item-content fill-white-primary'>
                        <h5 className='color-white-primary bold'>{el.nombre}</h5>
                        {!productPage ?
                            el.clase.includes('inactive') ?
                                <img src={require('../../../static/img/icons/flecha_negra.svg')} alt='' />
                                :
                                <img src={require('../../../static/img/icons/flecha_blanca.svg')} alt='' />
                            :
                            ''
                        }
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CategoriasBanner;