import React from "react";
import InfoContainer from "../InfoContainer/InfoContainer";
import styles from "./PoliticaPrivacidad.module.scss";

import { COUNTRY } from "../../constants";

const PoliticaPrivacidad = () => {
  let content = "";
  switch (COUNTRY) {
    case "ar":
      content = (
        <div className={styles.container}>
          <div className={styles.smaller}>
            <p>
              Este sitio web (la “Web”) es operado por un miembro de Laboratorio
              Cuenca S.A. (el “LC” o “Nosotros” indistintamente), con domicilio
              en Empedrado 2435, Ciudad Autónoma de Buenos Aires, Argentina. Al
              acceder o utilizar esta Web, usted (en adelante “Usted” o el
              “Usuario”) acepta los términos de la presente Política de
              Privacidad de la Web. De esta forma, Usted acepta que podremos
              recoger, usar y revelar su información personal, tal y como se
              describe en esta Política de Privacidad. Esta Política de
              Privacidad está incorporada en, y forma parte de, los Términos y
              Condiciones que regulan el uso que en general usted haga de esta
              Web. Esta Política de Privacidad está destinada a ayudarle a
              comprender qué podemos hacer con la información que Usted nos
              proporciona. Usaremos su información sólo para los propósitos
              señalados a lo largo de este documento. Posiblemente actualicemos
              esta Política de Privacidad cada cierto tiempo. Le recomendamos
              que revise regularmente esta página web a fin de asegurarse que
              conoce la versión más reciente de la Política de Privacidad.
            </p>
            <p>
              Se informa al Usuario que como regla general no resulta ser
              necesario el ingreso de sus datos personales para navegar en los
              sitios Web de LC. En caso de ser necesario ingresar sus datos
              personales (cuando así sea requerido por el sitio) para poder
              acceder al mismo, LC utilizará y protegerá sus datos personales
              mientras usted navegue en los sitios Web de LC. En caso de
              disconformidad con la Política de Privacidad adoptada por LC, no
              deberá utilizar nuestro servicio. la utilización del servicio
              implica la aceptación y conocimiento automático de los Términos
              Generales de Uso (ver Términos Generales de Uso con link) y de la
              Política de Privacidad que a continuación se le hace saber:
            </p>
          </div>
          <ol>
            <li>
              En cumplimiento con la normativa vigente de Protección de Datos
              Personales (Ley N° 25.326 y disposiciones complementarias), LC
              dará un tratamiento adecuado a los datos personales de quienes
              utilicen los servicios brindados por LC en sus sitios Web.
            </li>
            <li>
              Toda aquella información que el Usuario ingrese voluntariamente
              durante la utilización del servicio estará bajo las normas de
              confidencialidad. Dicha información recibirá una protección
              adecuada y será utilizada sólo para las finalidades de los
              servicios que LC brinda.
            </li>
            <li>
              LC sólo requerirá a los Usuarios para su registración datos como:
              apellido, nombre, documento de identidad, fecha de nacimiento,
              dirección de correo electrónico, etc., absteniéndose de solicitar
              datos sensibles al Usuario (Art. 7 de la ley N° 25.326). LC solo
              procederá a registrar los datos personales una vez que el Usuario
              haya prestado su consentimiento libre, expreso e informado para
              los fines solicitados. Usted podrá solicitar el retiro o bloqueo
              total o parcial de su información personal de las bases de datos,
              enviando a LC un correo electrónico a info.argentina@godrejcp.com
              o una carta a Empedrado 2435, Ciudad Autónoma de Buenos Aires. Por
              favor, tenga en cuenta que la información que anuncie en o a
              través de las áreas públicas de la Web (por ejemplo, todo tipo de
              conversaciones en línea, carteleras informativas o grupos de
              discusión) son generalmente accesibles a otros terceros, quienes
              podrían copiar y usar la información, pudiendo derivarse en correo
              no solicitado por el Usuario. Los usuarios de nuestra Web deberán
              tener precaución al momento de suministrar información personal en
              las áreas públicas de la Web.
            </li>
            <li>
              El Usuario garantiza la veracidad y certeza de los datos
              personales que ingresa en los sitios Web de LC, asumiendo la
              correspondiente responsabilidad en caso de que los mismos resulten
              inexactos. LC, en su condición de destinatario de los datos, no
              asume ninguna responsabilidad en caso de existir inexactitud en
              los datos personales ingresados por el Usuario.
            </li>
            <li>
              El Usuario podrá modificar o actualizar en cualquier momento la
              información relativa a los datos personales ingresados en los
              sitios Web de LC, los cuales formarán parte de un archivo que
              contendrá su perfil. Asimismo, el Usuario podrá solicitar la
              rectificación y/o baja de sus datos personales comprometiéndose
              LC, en caso de corresponder, a realizar las gestiones tendientes a
              rectificar y/o procesar la baja en un plazo máximo de diez (10)
              días hábiles contados desde la recepción del reclamo por parte del
              Usuario.
            </li>
            <li>
              Se entenderá que el Usuario al ingresar los datos referidos
              autoriza automáticamente a LC a utilizar dichos datos para
              proveerle adecuadamente un servicio personalizado y acorde a sus
              necesidades, así como para recibir correspondencia de LC.
              Asimismo, además de los casos mencionados precedentemente, cada
              cierto tiempo podremos revelar información personal para: a.
              Nuestros proveedores de servicios y subcontratistas, incluyendo
              nuestros afiliados, que realizan funciones por nuestra cuenta o
              nos proveen servicios tales como almacenamiento y entrega;
              mercadeo y publicidad; procesamiento de datos; desarrollo de
              programas informáticos; manejo y mantenimiento de nuestra Web;
              información de tecnología y servicios de oficina; proveedores de
              servicios profesionales además de legales, contables y de
              auditoría; y de ninguna manera tales proveedores recolectarán,
              usarán o revelarán dicha información personal para cualquier otro
              propósito distinto al de realizar las funciones o proveernos los
              servicios que le requerimos o sean requeridos por ley; b. Una
              persona o entidad, incluyendo nuestros afiliados, a los cuales
              debe serles revelado para el cumplimiento de uno o varios de los
              propósitos descritos bajo el punto titulado "Propósitos para
              recolectar información personal"; c. Una persona que, bajo nuestro
              juicio razonable, es la autorizada o señalada como agente legal
              suyo, para buscar información relativa a su persona; o d.
              Cualquier tercera persona que cuente con su consentimiento o donde
              la revelación sea requerida por ley. LC podrá utilizar la
              información proporcionada por el Usuario a fin de ofrecerle un
              servicio publicidad selectiva o contenidos que puedan llegar a
              serle de interés, si es que el Usuario aceptó recibirlos.
            </li>
            <li>
              No tenemos control sobre las comunicaciones que podamos tener a
              través de correo electrónico, motivo por el cual recomendamos que
              no incluya en ellos información confidencial, de propiedad
              exclusiva o delicada. En el hipotético caso en que consideremos
              que la seguridad de su información personal que se encuentra en
              nuestra posesión o bajo nuestro control, puede encontrarse
              comprometida, podremos notificarle al respecto. Le recordamos que
              Usted es el responsable de mantener la estricta confidencialidad
              de su cuenta y su contraseña, así como de cualquier actividad
              realizada bajo el uso de las mismas. Es su única responsabilidad
              controlar la difusión y uso de su contraseña, controlar el acceso
              y el uso de su cuenta, y notificarnos de su deseo de cerrar su
              cuenta. No seremos responsables de cualquier daño o pérdida que
              surja de falta de cumplimiento con esta obligación. La información
              que proporcione el Usuario estará asegurada por una clave de
              acceso. Dicha clave será creada por el Usuario (cuando la Web se
              lo requiera) y sólo conocida por éste; siendo el Usuario el único
              responsable de mantener dicha clave de seguridad en secreto. LC no
              intentará por ningún medio obtener la clave personal de acceso.
            </li>
            <li>
              Si Usted ha permitido las comunicaciones sobre las marcas de LC,
              Usted cada cierto tiempo recibirá comunicaciones sobre marcas a
              las cuales específicamente no optó por recibir noticias. Solo le
              comunicaremos sobre aquellas marcas que creemos que puedan
              despertarle un interés particular. Cualquiera de dichas
              comunicaciones le ofrecerá la oportunidad de retirar su
              suscripción de la marca en cuestión y/o a retirar su suscripción
              de todas las comunicaciones sobre las marcas de LC. Si tiene dudas
              sobre temas de privacidad, inquietud o queja, por favor contáctese
              a través de un correo electrónico a info.argentina@godrejcp.com o
              una carta a Empedrado 2435, Ciudad Autónoma de Buenos Aires.
            </li>
            <li>
              LC ha adoptado las medidas de seguridad necesarias para proteger
              la información ingresada en sus sitios Web a fin de evitar
              adulteraciones o tratamientos no autorizados. Debido a que ninguna
              transmisión por Internet puede garantizar su íntegra seguridad, LC
              no puede asegurar que toda transmisión de datos o información que
              se realice vía Internet sea totalmente segura, razón por la cual
              el Usuario al ingresar a los sitios Web de LC acepta este riesgo,
              que declara conocer.
            </li>
            <li>
              El material incluido en los sitios Web de LC (entendiéndose como
              material a: textos, informes, artículos, datos, comentarios,
              disertaciones, gráficos, logos, iconos, imágenes y archivos de
              cualquier tipo) se encuentran protegidos por las leyes de
              propiedad intelectual. Queda prohibida su publicación,
              radiodifusión, reedición para radiodifusión o publicación y su
              redistribución directa o indirecta por cualquier medio. Queda
              prohibido su almacenamiento total o parcial en computadoras,
              excepto para uso personal y sin fines comerciales. La publicación
              de información de propiedad de terceros será de responsabilidad
              exclusiva de sus autores. LC no asume responsabilidad alguna por
              cualquier demora, inexactitud, error u omisión en el mismo o en la
              transmisión o entrega de la totalidad o parte del mismo, ni por
              los daños emergentes de tales circunstancias.
            </li>
            <li>
              La utilización de la información publicada en los sitios Web de LC
              para cualquier finalidad por parte del Usuario será bajo su
              exclusiva responsabilidad o riesgo, LC no se responsabiliza por
              los daños, de cualquier tipo ocasionados por el uso de la
              información publicada.
            </li>
            <li>
              LC no será responsable por los contenidos y/o la información
              provista en otras páginas Web a las cuales el Usuario pueda
              acceder mediante la utilización de los sitios Web de LC. Tampoco
              responderá por la autenticidad y/o veracidad de la información ni
              de los datos consignados en las mismas.
            </li>
            <li>
              Los Usuarios se obligan a hacer uso de los sitios Web de LC y de
              todos sus servicios de conformidad con la ley, los términos
              generales de uso, esta política de privacidad, la moral, las
              buenas costumbres generalmente aceptadas y el orden público,
              obligándose a abstenerse de utilizar los sitios Web de LC con
              fines ilícitos o contrario a lo aquí establecido, que de cualquier
              modo puedan dañar, inutilizar, sobrecargar o deteriorar los sitios
              Web de LC así como su reputación. Esta declaración de privacidad
              está sujeta a los términos generales de uso de LC, con lo cual
              constituye un acuerdo legal entre el Usuario y LC.
            </li>
            <li>
              Si el Usuario utiliza los sitios Web de LC, ello significa que ha
              leído, comprendido y aceptado los términos antes expuestos. En
              caso de no estar de acuerdo con ello, el Usuario no deberá
              proporcionar dato ni información alguna, ni utilizar los sitios
              Web de LC porque no está autorizado a hacerlo.
            </li>
            <li>
              LC se reserva el derecho, a su sola discreción, de modificar,
              alterar o de otra manera actualizar, esta Política de Privacidad
              en cualquier momento. Las modificaciones entrarán en vigencia
              desde el momento que se indique; en su defecto, se entenderá que
              su aplicación es inmediata. LC no limitara los derechos de los
              Usuarios en virtud de esta política de privacidad sin su
              consentimiento explícito.
            </li>
            <li>
              Esta Web utiliza cookies. Las cookies son un pequeño archivo de
              texto que un sitio web le envía a la computadora del visitante de
              dicho sitio web. El sitio podrá utilizar las cookies para todas y
              cada una de las finalidades que se incluyen a continuación, cuya
              enunciación no es taxativa sino enunciativa: (i) reconocer a los
              navegadores de los usuarios registrados, de modo que no tengan que
              registrarse en cada visita para acceder a las distintas partes del
              sitio web; (ii) monitoreo de tráfico, que incluye la dirección IP
              de acceso, tipo de buscador y sistema operativo usado, fecha y
              hora de ingreso, (iii) reconocer visitas repetidas con propósitos
              estadísticos; (iv) rastreo anónimo de interacción con publicidad
              en línea; (v) obtención de información sobre hábitos de
              navegación, entre otros. Asimismo, ayudan a mejorar o personalizar
              la experiencia del usuario en el sitio web y a monitorear el uso
              de dicho sitio a fin de realizar diversas comunicaciones que
              puedan ser de interés. Usted podrá negarse a recibir cookies y/o
              eliminar las cookies. Por favor, tenga en cuenta que, en caso de
              no aceptar el uso de cookies, la utilización del sitio puede verse
              afectada; es decir, puede que le resulte difícil o imposible el
              acceso a determinadas partes de nuestra Web.
            </li>
            <li>
              La Web puede contener enlaces o referencias a otros servidores o
              sitios web que se encuentran fuera de nuestro control. Por favor
              tenga en cuenta que no tenemos control sobre tales servidores o
              sitios web y nuestra Política de Privacidad no aplica en dichos
              sitios o servidores. Le instamos a leer las políticas de
              privacidad y los términos y condiciones de uso de tales servidores
              o sitios web a los cuales ingresa. De ser requerido por las
              jurisdicciones en las cuales conducimos nuestros negocios, esta
              Política de Privacidad será complementada con requerimientos
              legales adicionales. Nada en esta Política de Privacidad dará
              lugar a la creación o añadirá cualquier derecho o reclamo (bien
              sea legal, equitativo o de otra índole) que cualquier individuo
              pueda tener frente a la ley u otra fuente, en contra de la Bodega
              o un tercero, o contra cualquiera de sus respectivos directores,
              responsables, empleados, agentes o representantes tampoco la
              existencia o aplicación de esta Política de Privacidad impondrá o
              añadirá obligación alguna o responsabilidad sobre LC distinta a
              las que ya le corresponden por ley. El Usuario tiene prohibido
              expresamente mostrar o usar de ninguna manera las marcas propiedad
              de LC en un sitio o servidor distinto al nuestro, salvo que
              contase con nuestro consentimiento por escrito.
            </li>
          </ol>
        </div>
      );
      break;
    case "pe":
      content = (
        <div className={styles.container}>
          <div className={styles.smaller}></div>
          <ol>
            <li>
              <u>Titular del banco de datos personales:</u>
              <br />
              La presente Política de Privacidad (en adelante, “Política”)
              corresponde a Laboratorio Cuenca S.A. (una sociedad argentina
              perteneciente al Grupo Godrej) (en adelante, “Godrej”),
              identificada con CUIT Argentino N° 30-57000787-0, con domicilio en
              Empedrado No. 2435, Ciudad Autónoma de Buenos Aires, Argentina,
              C1415GIC.
            </li>
            <li>
              <u>¿Para qué serán tratados los datos personales recabados?</u>
              <br />
              Los datos personales que usted nos proporcione serán utilizados
              para las siguientes finalidades necesarias:
              <ul>
                <li>Cumplir nuestro vínculo comercial con usted</li>
                <li>
                  Ofrecerle el servicio que ha solicitado (p. ej., ofrecerle un
                  boletín informativo); o
                </li>
                <li>
                  Cumplir con requisitos legales (entre otros, facturación).
                </li>
              </ul>
              La negativa al consentimiento a las finalidades accesorias no
              implicará la imposibilidad de ejecutar las finalidades necesarias
              expuestas previamente.
            </li>
            <li>
              <u>¿Qué datos personales recabamos?</u>
              <br />
              Los datos personales que recabaremos para efectuar el tratamiento
              son los siguientes:
              <ul>
                <li>Nombres y apellidos.</li>
                <li>Correo electrónico.</li>
              </ul>
            </li>
            <li>
              <u>
                ¿Qué ocurre si se otorgan (o no) los datos personales
                mencionados líneas arriba?
              </u>
              <br />
              Si se otorgan los datos personales mencionados en el punto
              precedente, Godrej contestará a la consulta o comentario expuesto
              en la sección “contáctanos”. Adicionalmente, si se otorga
              consentimiento expreso para los fines accesorios, se efectuarán
              las acciones indicadas en dicha sección.
              <br />
              Si los datos no son entregados, no se podrá contestar a la(s)
              pregunta(s) expuesta(s) en la sección “contáctanos” ni se podrá
              entregar información sobre los productos comercializados por
              Godrej ni se cumplirán las finalidades necesarias.
            </li>
            <li>
              <u>Transferencia y destinatarios</u>
              <br />
              No aplica.
            </li>
            <li>
              <u>Banco de datos personales</u>
              <br />
              Los datos personales que usted nos proporcione serán almacenados
              en el banco de datos radicados en Argentina, en estricto
              cumplimiento con la normativa de dicho país.
            </li>
            <li>
              <u>Tiempo de conservación de los datos personales</u>
              <br />
              Godrej conservará los datos personales hasta que se cumplan las
              finalidades expuestas en el capítulo II de la presente Política.
            </li>
            <li>
              <u>Ejercicio de derechos ARCO</u>
              <br />
              Los derechos que toda persona puede ejercer ante Godrej, respecto
              del tratamiento de sus datos personales son los siguientes:
              <ul>
                <li>
                  Derecho de acceso: para obtener la información que sobre sí
                  mismo sea objeto de tratamiento en bancos de datos de
                  titularidad de Godrej, la forma en que sus datos fueron
                  recopilados, las razones que motivaron su recopilación y a
                  solicitud de quién se realizó la recopilación, así como las
                  transferencias realizadas o que se prevén hacer de los datos.
                </li>
                <li>
                  Derecho de rectificación (actualización e inclusión): para que
                  se modifiquen los datos que resulten ser parcial o totalmente
                  inexactos, incompletos, erróneos o falsos.
                </li>
                <li>
                  Derecho de cancelación (supresión): para solicitar la
                  supresión o cancelación de sus datos personales de un banco de
                  datos personales, cuando éstos hayan dejado de ser necesarios
                  o pertinentes para la finalidad para la cual hayan sido
                  recopilados; hubiere vencido el plazo establecido para su
                  tratamiento; se ha revocado su consentimiento para el
                  tratamiento; y en los demás casos en los que no están siendo
                  tratados conforme a la legislación vigente.
                </li>
                <li>
                  Derecho de oposición: para controlar y verificar que el
                  tratamiento de los datos personales del titular se enmarque en
                  las finalidades para las cuales otorgó su consentimiento.
                </li>
              </ul>
              Los titulares de los datos personales recabados pueden ejercer sus
              derechos ARCO, así como revocar su consentimiento para el uso de
              sus datos, cuando procedan, ante Laboratorio Cuenca S.A.
              remitiendo el formulario adjunto en calidad de Anexo N° 1 de la
              presente Política a la siguiente dirección de correo electrónico:
              info.argentina@godrejcp.com.
              <br />
              Recibido el formulario en la dirección física o electrónica,
              Godrej dejará constancia de su recepción y verificará el
              cumplimiento de los siguientes requisitos:
              <ul>
                <li>
                  Los nombres y apellidos del titular de los datos personales.
                </li>
                <li>
                  Documento Nacional de Identidad, pasaporte o documento
                  equivalente del titular de los datos personales, el mismo que
                  deberá ser mostrado a la presentación de su solicitud para
                  acreditar su identidad, adjuntando una copia del mismo. En
                  caso de representación, la solicitud deberá indicar el nombre
                  y Documento Nacional de Identidad o documento equivalente del
                  representante del titular de los datos personales, a la que
                  adjuntará una copia del mismo, una copia del Documento
                  Nacional de Identidad o documento equivalente de su
                  representado y el documento en el que conste el poder del
                  representante con facultades expresas para el ejercicio del
                  derecho ARCO respectivo (carta poder con firma legalizada).
                </li>
                <li>
                  Domicilio o dirección electrónica, para efectos de las
                  notificaciones.
                </li>
                <li>
                  Petición en que se concreta la solicitud. Deberá indicarse el
                  derecho que ejerce el titular de los datos personales.
                </li>
                <li>
                  Si se tratase de una solicitud de rectificación
                  (actualización, inclusión) de datos personales, el solicitante
                  deberá indicar a qué datos personales se refiere, además de
                  las modificaciones que deban realizarse a los mismos,
                  aportando la documentación que sustente su petición. La
                  solicitud podrá contener algún elemento o documento adicional
                  que coadyuve a ubicar los datos personales solicitados, así
                  como información sobre otros medios de contacto, como número
                  telefónico, que facilite la tramitación de la solicitud de
                  ejercicio de derechos ARCO.
                </li>
                <li>Fecha y firma del solicitante.</li>
              </ul>
              En caso de que la solicitud no cumpla con los requisitos exigidos,
              Godrej, en un plazo de cinco (5) días hábiles contados desde el
              día siguiente de la recepción de la solicitud, formulará las
              observaciones por incumplimiento que no puedan ser salvadas,
              invitando al titular de los datos personales a subsanarlas dentro
              de un plazo máximo de cinco (5) días hábiles. Transcurrido el
              plazo señalado sin que ocurra la subsanación se tendrá por no
              presentada la solicitud.
              <br />
              En caso de que la información proporcionada en la solicitud sea
              insuficiente o errónea de forma que no permita su atención, Godrej
              podrá requerir, dentro de los siete (7) días hábiles siguientes de
              recibida la solicitud, documentación adicional al titular de los
              datos personales para atenderla.
              <br />
              En un plazo de diez (10) días hábiles de recibido el
              requerimiento, contado desde el día siguiente de la recepción del
              mismo, el titular de datos personales acompañará la documentación
              adicional que estime pertinente para fundamentar su solicitud. En
              caso contrario, se tendrá por no presentada dicha solicitud.
              <br />
              El plazo máximo para la respuesta de Godrej ante el ejercicio del
              derecho de acceso será de veinte (20) días hábiles contados desde
              el día siguiente de la presentación de la solicitud por el titular
              de datos personales o de su subsanación. Si la solicitud fuera
              estimada y Godrej no acompañase a su respuesta la información
              solicitada, el acceso será efectivo dentro de los diez (10) días
              hábiles siguientes a dicha respuesta.
              <br />
              En el caso del ejercicio del derecho de rectificación, el plazo
              máximo de respuesta de Godrej será de diez (10) días hábiles
              contados desde el día siguiente de la presentación de la solicitud
              correspondiente o de subsanada o complementada la misma con
              información adicional, según sea el caso.
              <br />
              Los plazos que correspondan para la respuesta o la atención de los
              referidos derechos podrán ser ampliados una sola vez, y por un
              plazo igual, como máximo, siempre que las circunstancias lo
              justifiquen. La justificación de la ampliación del plazo será
              comunicada al titular del dato personal dentro del plazo que se
              pretenda ampliar.
              <br />
              Godrej denegará la solicitud para el ejercicio de los derechos
              ARCO en los siguientes supuestos:
              <ul>
                <li>
                  Si el solicitante no es el titular de los datos personales, o
                  el representante legal no se encuentra debidamente acreditado
                  para ello.
                </li>
                <li>
                  Si en los bancos de datos de Godrej no se encuentran los datos
                  personales del solicitante.
                </li>
                <li>
                  Si existe un impedimento legal, o una resolución judicial o
                  administrativa que restrinja el ejercicio de los derechos ARCO
                  al titular de los mismos.
                </li>
                <li>
                  Cuando el titular de los datos personales ya ejerció alguno de
                  sus derechos ARCO y pretende ejercerlo nuevamente sin haber
                  transcurrido el plazo que tiene Godrej para resolver su
                  solicitud.
                </li>
              </ul>
              De considerar que una solicitud no ha sido atendida en el
              ejercicio de los derechos ARCO, todo titular de datos personales
              podrá presentar cualquier reclamación ante la Autoridad Nacional
              de Datos Personales, dirigiéndose a la mesa de partes del
              Ministerio de Justicia y Derechos Humanos ubicada en la calle
              Scipión Llona N° 350, distrito de Miraflores, provincia y
              departamento de Lima, República del Perú.
            </li>
          </ol>
        </div>
      );
      break;
    case "cl":
      content = (
        <div className={styles.container}>
          <p>
            <b>Políticas de Privacidad</b>
            <br />
            <br />
            <br />
            <b>INTRODUCCIÓN</b>
            <br />
            <br />
            En Grupo Godrej nos preocupa la protección de la privacidad de todas
            las personas con las que interactuamos y nos comprometemos a actuar
            de conformidad con la normativa aplicable en materia de protección
            de datos. Todos los sitios web de Grupo Godrej que contienen esta
            Política de privacidad (en adelante, la "Política") se comprometen a
            tratar los datos personales de los usuarios de conformidad con la
            presente Política y de acuerdo con la normativa vigente. Esta
            Política describe los métodos de recopilación, uso y transferencia,
            por parte de Grupo Godrej, de los datos personales que usted nos
            facilita o que obtenemos o generamos, en el momento en que usted
            accede a nuestros sitios web, se pone en contacto con Nosotros o
            realiza una solicitud en relación con nuestros productos y/o
            servicios. Esta Política no se aplica a los recursos en línea y
            sitios de terceros a los que están vinculados los sitios web de
            Grupo Godrej y para los cuales Grupo Godrej no controla ni el
            contenido ni los procedimientos relacionados con la protección de la
            privacidad. Por lo tanto, se aconseja a los usuarios que decidan
            acceder a los sitios web mencionados que consulten los Términos y
            Condiciones y las políticas de privacidad disponibles en los sitios
            a los que accedan.
            <br />
            <br />
            <b>
              1. ¿QUÉ DATOS PERSONALES TRATAMOS DE USTED Y CÓMO SE OBTIENEN?
            </b>
            <br />
            <br />
            <b>Datos personales proporcionados por usted</b> cuando interactúa
            con el sitio web, incluidos datos de navegación, o cuando solicita
            usar los servicios ofrecidos por Grupo Godrej (por ejemplo: registro
            en cualquier área reservada, solicitudes de información a través de
            los formularios, contacto, etc.), o cuando interactúa con Grupo
            Godrej a través de cualquier otro medio (por ejemplo: mediante
            tarjetones de garantía, formularios, atención telefónica, etc.). Se
            incluyen aquellos datos adicionales que usted nos facilite durante
            la tramitación y gestión de su solicitud.
            <br />
            <br />
            La mayoría de los sitios que contienen esta política de privacidad
            son accesibles para los visitantes sin que sea necesario su
            registro. Sin embargo, para acceder a determinadas secciones del
            sitio web, o para poder beneficiarse de determinados servicios que
            ofrece Grupo Godrej, puede ser necesario que complete un
            procedimiento de registro, lo que implica completar campos con sus
            propios datos personales. El hecho de no proporcionar los datos
            solicitados en los campos obligatorios (datos necesarios), conlleva
            que no se permita su registro, y, en consecuencia, no podrá acceder
            al área reservada o no podrá beneficiarse del servicio. Respecto el
            resto de datos no obligatorios (datos voluntarios), usted es libre
            de facilitarlos o no, basándose su tratamiento en el consentimiento
            de fecha más reciente que nos haya otorgado.
            <br />
            <br />
            <b>Datos de categorías especiales</b> (por ejemplo, datos de salud),
            Grupo Godrej tratará dichos datos en atención al consentimiento
            otorgado por usted, así como para todos los asuntos relacionados con
            los informes de reacciones adversas, para cumplir las obligaciones
            derivadas de las leyes o reglamentos, o para cumplir con las
            obligaciones contractuales o precontractuales derivadas de la
            contratación de proveedores de bienes y/o servicios.
            <br />
            <br />
            <b>Datos derivados de la navegación por nuestros sitios web.</b> Si
            navega por nuestros sitios web, se recopilará y tratará de forma
            automática, únicamente información no confidencial sobre usted. En
            particular, no se recopilará ninguna información relacionada con su
            salud cuando navegue por páginas públicas en nuestros sitios web.
            Sin embargo, se tratará su información personal en la medida
            necesaria para entregar el contenido público que nos solicite, por
            ejemplo, formateándolo para su navegador. También se tratará su
            información personal para satisfacer nuestros intereses legítimos
            con el objetivo de proteger la seguridad de los sistemas de nuestro
            sitio web y medir las audiencias de los diversos tipos de contenidos
            proporcionados. Para ello Grupo Godrej utiliza, por ejemplo,
            registros de servidor web, direcciones IP, balizas web, complementos
            sociales o pluguins, cookies, etc.
            <br />
            <br />
            Así como aquellos otros datos a cuyo tratamiento estemos de otro
            modo legitimados conforme la normativa vigente.
            <br />
            <br />
            <b>
              2. Los sitios de Grupo Godrej están dirigidos a un público adulto.
              Por lo tanto, Grupo Godrej no recopilará voluntariamente datos de
              identificación de personas menores de edad.
            </b>
            <br />
            <br />
            <b>
              3. ¿POR QUÉ MOTIVO GRUPO GODREJ TRATA SUS DATOS PERSONALES Y CÓMO?
            </b>
            <br />
            <br />
            Grupo Godrej puede procesar sus datos personales para permitirle
            usar los servicios y funcionalidades presentes en el sitio web y
            optimizar su funcionamiento, gestionar las solicitudes recibidas,
            para el registro del usuario a cualquier área reservada, así como
            todos aquellos tratamientos necesarios para dar cumplimiento a su
            solicitud, servicio, consulta o adquisición y poder realizar su
            seguimiento.
            <br />
            <br />
            Para poder enviarle, incluso por medios electrónicos, comunicaciones
            relativas a novedades sobre el producto o servicio que usted ha
            adquirido u otros productos similares o para poderle realizar
            encuestas de satisfacción, para mejorar el servicio prestado, en
            base a nuestro interés legítimo y conforme lo permitido por la
            normativa aplicable. Usted puede oponerse a recibir este tipo de
            comunicaciones, en cualquier momento, bien a través de la opción de
            darse de baja en cada comunicación, a través de las direcciones
            postal y electrónica indicadas.
            <br />
            <br />
            Además, con su consentimiento expreso, para enviarle comunicaciones
            para permitirle opinar, recibir consejos e información de utilidad y
            sobre productos y servicios distribuidos por el Grupo Godrej
            (incluidos productos y servicios de socios comerciales
            seleccionados). Usted puede revocar su consentimiento en cualquier
            momento, a través de las direcciones postal y electrónica indicadas.
            <br />
            <br />
            Grupo Godrej también puede tratar sus datos personales con el fin de
            cumplir con las obligaciones derivadas de las leyes, reglamentos y
            legislación comunitaria.
            <br />
            <br />
            Sus datos personales, incluyéndose sus datos de categoría especial,
            también podrían tratarse para la gestión y el cumplimiento de la
            normativa vigente.
            <br />
            <br />
            <b>4. CESIONES A TERCEROS Y TRANSFERENCIAS INTERNACIONALES</b>
            <br />
            <br />
            No se cederán sus datos a terceros sin su consentimiento, salvo
            cuando sea necesario para poder atender a las finalidades del
            tratamiento, conforme lo exija la legislación aplicable, los
            tribunales y/o las autoridades públicas. Es por ello que, en caso de
            resultar necesario, sus datos podrán ser cedidos a Administraciones
            u Organismos Públicos, empresas de auditoria, empresas fabricantes
            de los productos de terceros que Grupo Godrej distribuye o le
            facilita, etc.
            <br />
            <br />
            Sus datos pueden ser cedidos a las empresas que conforman el Grupo
            Godrej, para fines administrativos internos y de gestión, siempre
            que dicha cesión resulte necesaria para cumplir con las finalidades
            de tratamiento de sus datos personales relacionadas con la gestión
            de su solicitud o de su relación con Grupo Godrej; así como, a los
            prestadores de servicios con los que Grupo Godrej tiene firmados los
            correspondientes contratos aplicando todas las garantías y
            salvaguardas necesarias para preservar su privacidad.
            <br />
            <br />
            En ocasiones, precisamos ceder sus datos a empresas fabricantes de
            productos distribuidos por Grupo Godrej, o empresas que colaboran
            con Grupo Godrej en la fabricación de un producto, para poder
            cumplir con la garantía propia del producto y conforme las
            obligaciones legales existentes. Sus datos también podrán ser
            cedidos a las autoridades o registros públicos competentes cuando
            ello se exija conforme la normativa vigente.
            <br />
            <br />
            En caso de que Grupo Godrej precise realizar otras comunicaciones de
            sus datos personales, en caso de ser necesario, previamente le
            solicitará su consentimiento.
            <br />
            <br />
            <b>5. TRANSFERENCIAS INTERNACIONALES</b>
            <br />
            <br />
            Grupo Godrej le informa que podría contratar servicios de
            proveedores tecnológicos ubicados en países que no disponen de
            normativa equivalente a la Chilena (“Terceros Países”). Asimismo, en
            caso que el fabricante del producto se encuentre en Terceros Países,
            Grupo Godrej podrá compartir sus datos personales únicamente si
            resultara necesario para cumplir con las obligaciones derivadas de
            la garantía del producto, o derivado de las obligaciones legales a
            las que Grupo Godrej se encuentre sujeta conforme la legislación
            aplicable. En todo caso, Grupo Godrej cumplirá con todos los
            requisitos establecidos por la normativa de protección de datos,
            aplicando a la transferencia de sus datos todas las garantías y
            salvaguardas necesarias para preservar su privacidad.
            <br />
            <br />
            Para más información sobre las garantías a su privacidad en relación
            con dichas transferencias internacionales, comuníquese por medio de
            la dirección electrónica indicadas.
            <br />
            <br />
            <b>6. DURANTE CUÁNTO TIEMPO GUARDAREMOS SUS DATOS</b>
            <br />
            <br />
            Sus datos se tratarán únicamente en la medida necesaria para el
            logro de los fines indicados en esta Política y se mantendrán
            durante el período estrictamente necesario para ello. En cualquier
            caso, sus datos personales se conservarán mientras se mantenga su
            relación con Nosotros y, tras la finalización de dicha relación por
            cualquier causa, durante los plazos de prescripción legales que sean
            de aplicación. En este supuesto, se tratarán a los solos efectos de
            acreditar el cumplimiento de nuestras obligaciones legales o
            contractuales. Finalizados dichos plazos de prescripción, sus datos
            serán eliminados o, alternativamente, anonimizados.
            <br />
            <br />
            <b>7. CÓMO PROTEGEMOS LA SEGURIDAD DE SUS DATOS</b>
            <br />
            <br />
            Grupo Godrej adopta las medidas técnicas y organizativas adecuadas
            para proteger su información personal contra la destrucción
            accidental o ilícita, pérdida, alteración, divulgación no autorizada
            o acceso a los datos personales transmitidos, almacenados o tratados
            de cualquier otro modo.
            <br />
            <br />
            <b>8. EJERCICIO DE SUS DERECHOS</b>
            <br />
            <br />
            En cualquier momento, usted puede ejercer sus derechos, mediante
            comunicación a través de las siguientes direcciones que figura en
            las Definiciones del presente documento.
            <br />
            <br />
            Usted siempre puede elegir si comparte o no sus datos personales con
            Grupo Godrej. Si usted decide no proporcionar sus datos personales,
            oponerse al tratamiento de los datos, o revocar el consentimiento al
            tratamiento ya realizado, Grupo Godrej respetará esta decisión, sin
            perjuicio de las obligaciones legales a las que está sujeta. Sin
            embargo, esto podría imposibilitar que Grupo Godrej atender a su
            solicitud, así como la imposibilidad de que pueda utilizar los
            servicios y productos ofrecidos por Grupo Godrej.
            <br />
            <br />
            <b>Recopilación de información</b>
            <br />
            <br />
            Existen dos métodos generales utilizados por Grupo Godrej para
            recopilar su información en línea:
            <br />
            <br />
            <b>La información que obtenemos</b>
            <br />
            <br />
            <ul>
              <li>
                Información personal identificable: Puede visitar nuestros
                sitios web sin proporcionar ningún tipo de información personal.
                Sólo recopilaremos su información personal identificable (por
                ejemplo nombre, dirección, número de teléfono, dirección de
                correo electrónico u otra información identificable) cuando
                usted decida proporcionárnosla. Asimismo, podemos obtener la
                información sobre su salud que usted nos proporcione
                respondiendo a nuestras preguntas o encuestas.
              </li>
              <li>
                Información adicional: En algunos casos también eliminamos los
                identificadores personales de los datos que nos facilita y los
                guardamos en forma adicional. Podemos combinar estos datos con
                otra información para elaborar información estadística anónima y
                agregada (por ejemplo, número de visitantes, nombre del dominio
                de origen del proveedor de servicios de Internet), que nos
                resultará de utilidad para mejorar nuestros productos y
                servicios.
              </li>
            </ul>
            <b>Información obtenida automáticamente</b>
            <br />
            <br />
            Nosotros, juntamente con algunos proveedores externos con los que
            colaboramos, recibimos automáticamente cierto tipo de información
            siempre que usted interactúa con nosotros, ya sea a través de
            nuestros sitios o de las comunicaciones a través del correo
            electrónico. Las tecnologías automáticas que empleamos incluyen, por
            ejemplo, los informes del servidor web/direcciones IP, cookies, web
            beacons (píxeles invisibles) y aplicaciones y herramientas de
            contenido de terceros.
            <br />
            <br />
            <i>Informes del servidor web/direcciones IP</i>. Una dirección IP es
            un número que se le asigna a su ordenador siempre que usted se
            conecta a Internet. La identificación de los ordenadores en Internet
            se realiza a través de las direcciones IP, que permiten que los
            ordenadores y los servidores se reconozcan y se comuniquen. Grupo
            Godrej capta las direcciones IP para llevar a cabo la administración
            del sistema y enviar información agregada a filiales, socios
            empresariales y/o proveedores para que realicen análisis del sitio y
            revisiones del funcionamiento del sitio web.
            <br />
            <br />
            <i>Cookies</i>. Una cookie es un fragmento de información que se
            instala automáticamente en el disco duro de su ordenador al acceder
            a determinados sitios web. Las cookies únicamente identifican su
            navegador al servidor. Nos permiten almacenar información en el
            servidor para facilitarle una experiencia web más grata y realizar
            análisis del sitio y revisar el funcionamiento del sitio web. La
            mayor parte de los navegadores web están configurados para aceptar
            cookies, aunque se puede configurar el navegador para que rechace
            todas las cookies o indique cuando se envía una cookie. Tenga en
            cuenta, sin embargo, que algunas secciones de nuestros sitios puede
            que no funcionen correctamente si se rechazan las cookies.
            <br />
            <br />
            <i>Web Beacons o Balizas de la web</i>. En ciertas páginas web o
            correos electrónicos, Grupo Godrej puede utilizar una tecnología
            común de Internet llamada web beacon (también conocida como
            "etiqueta de acción"; o "tecnología de etiquetas píxel"). Los web
            beacons ayudan a analizar la eficacia de los sitios web mediante el
            cálculo, por ejemplo, del número de visitantes a un determinado
            sitio o cuántos visitantes hicieron clic en los elementos clave de
            un sitio.
            <br />
            <br />
            Los web beacons, cookies y demás tecnologías de seguimiento web no
            obtienen automáticamente información personalmente identificable.
            Únicamente si nos envía de manera voluntaria información
            personalmente identificable, como por ejemplo registrándose o
            enviando correos electrónicos, pueden emplearse estas tecnologías de
            seguimiento automático para suministrar información adicional sobre
            su uso de los sitios web y/o correos electrónicos interactivos, con
            objeto de mejorar su utilidad.
            <br />
            <br />
            <i>Servicios</i>: Podemos proporcionarle servicios en base a las
            aplicaciones y las herramientas de terceros contenidas en algunos
            sitios de Grupo Godrej, como por ejemplo Google Maps o QUARTAL FLIFE
            Estos terceros pueden recibir automáticamente cierto tipo de
            información siempre que usted interactúa con nosotros a través de
            las aplicaciones y herramientas de terceros.
            <br />
            <br />
            <b>Sus alternativas</b>
            <br />
            <br />
            Usted dispone de varias opciones en cuanto a la utilización de
            nuestros sitios web. Puede decidir no enviar ninguna información
            personalmente identificable, simplemente no rellenando ningún
            formulario o campos de datos en nuestros sitios y no utilizando
            ninguno de los servicios personalizados disponibles. Si decide
            enviar datos personales, tiene derecho a visualizar y corregir sus
            datos en cualquier momento, accediendo a la aplicación. Algunos
            sitios puede que soliciten su autorización para determinados empleos
            de la información, siendo posible por su parte consentirlos o
            rechazarlos. Si opta por servicios o comunicaciones particulares,
            como el boletín de e-noticias (e-newsletter), podrá darse de baja en
            el momento en que lo desee, siguiendo las instrucciones incluidas en
            cada comunicación. Si decide darse de baja en un servicio o
            comunicación, trataremos de eliminar su información lo antes
            posible, aunque puede que necesitemos información adicional antes de
            poder procesar su solicitud.
            <br />
            <br />
            Como ya se ha mencionado, si desea impedir que las cookies hagan el
            seguimiento anónimamente cuando usted navega por nuestros sitios,
            puede configurar su navegador para que rechace todas las cookies o
            indique cuando se envía una cookie.
            <br />
            <br />
            <b>Seguridad</b>
            <br />
            <br />
            Grupo Godrej emplea tecnologías y medidas de seguridad, normativas y
            otros procedimientos para proteger sus datos personales frente a
            cualquier acceso no autorizado, empleo inapropiado, divulgación,
            pérdida o destrucción. Para garantizar la confidencialidad de sus
            datos, Grupo Godrej utiliza además cortafuegos estándar y protección
            de contraseñas. Sin embargo, es responsabilidad personal asegurarse
            de que el ordenador que se utiliza esté adecuadamente protegido
            contra software malicioso, como troyanos, virus informáticos y
            programas gusano. Debe ser consciente de que sin medidas de
            seguridad adecuadas (por ejemplo, configuración segura del
            navegador, software antivirus actualizado, software cortafuegos
            personal, no utilizar software de fuentes dudosas) existe el riesgo
            de que los datos y contraseñas utilizados para proteger el acceso a
            sus datos puedan ser desvelados a terceros no autorizado.
            <br />
            <br />
            <b>Utilización de los datos</b>
            <br />
            <br />
            Grupo Godrej, incluyendo filiales, divisiones y grupos en todo el
            mundo y/o empresas subcontratadas para realizar servicios en nuestro
            nombre, empleará la información personalmente identificable que
            usted haya decidido enviarnos de acuerdo con sus solicitudes. Nos
            hacemos cargo del control y de la responsabilidad del empleo de esta
            información. Algunos de estos datos puede que se almacenen o
            procesen en ordenadores ubicados en otras jurisdicciones, como por
            ejemplo Estados Unidos, cuya legislación de protección de datos
            puede ser diferente a la de la jurisdicción donde usted reside. En
            estos casos, nos aseguraremos de que se implementan las protecciones
            apropiadas para que se solicite al procesador de datos en ese país
            que mantenga protecciones sobre los datos que sean equivalentes a
            las aplicadas en el país donde usted vive.
            <br />
            <br />
            También nos servirá de ayuda para personalizar ciertas
            comunicaciones sobre servicios y promociones que podrían ser de su
            interés. Por ejemplo, podríamos analizar el sexo o la edad de los
            visitantes en relación con una medicación particular o el estado de
            una enfermedad, y emplear este análisis de datos agregados
            internamente o compartirlo con otros.
            <br />
            <br />
            <b>Enlaces con otros sitios</b>
            <br />
            <br />
            Todas las marcas de fábrica mencionadas son propiedad de un miembro
            del Grupo Godrej, o un miembro del Grupo Godrej tiene autorización
            para utilizarlas.
            <br />
            <br />
            <b>Declaración de Privacidad para niños</b>
            <br />
            <br />
            Nuestros sitios web están dirigidos a un público adulto. No
            recopilamos información personalmente identificable de personas que
            sepamos que son menores de 13 años sin el consentimiento previo y
            verificable de su representante legal. Dicho representante legal
            tiene derecho, tras previa solicitud, a consultar la información
            proporcionada por el niño y/o a requerir que se suprima.
            <br />
            <br />
            <b>Información adicional sobre sitios web</b>
            <br />
            <br />
            Si un sitio web tuviera provisiones particulares relativas a la
            privacidad que difieran de las establecidas en este documento, esas
            provisiones se facilitarán en la página donde se recopile la
            información personalmente identificable.
            <br />
            <br />
            <b>
              Nota a los usuarios de sitios web empresariales o profesionales
            </b>
            <br />
            <br />
            Si mantiene una relación empresarial o profesional con Grupo Godrej,
            podemos utilizar la información que nos facilite en nuestros sitios,
            incluidos aquellos destinados específicamente a usuarios
            empresariales y profesionales, para responder a sus solicitudes y
            desarrollar una relación empresarial con usted y con las entidades a
            las que representa. También podemos compartir esa información con
            terceros que actúen en nuestro nombre.
            <br />
            <br />
            <b>Actualizaciones de la Declaración de Privacidad</b>
            <br />
            <br />
            Periódicamente, Grupo Godrej puede revisar esta Declaración de
            Privacidad en línea. Cualquier cambio en esta Declaración de
            Privacidad se comunicará inmediatamente en esta página. El acceso
            continuado a nuestros sitios, después de recibir aviso de un cambio
            en nuestra Declaración de Privacidad, indicará su consentimiento al
            empleo de la información enviada, de acuerdo con la Declaración de
            Privacidad enmendada de Grupo Godrej.
            <br />
            <br />
            <b>Cómo ponerse en contacto con Grupo Godrej</b>
            <br />
            <br />
            Para cualquier consulta, o si desea que Grupo Godrej modifique o
            suprima su perfil, póngase en contacto con nosotros por escrito
            (correo postal) a la dirección que figura en las Definiciones del
            presente documento.
          </p>
        </div>
      );
      break;
    case "uy":
      content = (
        <div className={styles.container}>
          <p>
            Este sitio web (la “Web”) es operado por un miembro de Laboratorio
            Cuenca S.A. (el “LC” o “Nosotros” indistintamente), con domicilio en
            Empedrado 2435, Ciudad Autónoma de Buenos Aires, Argentina
            (C1417GIC). En Uruguay, el Distribuidor de los productos es Deciral
            S.A., con domicilio en Osvaldo Cruz 5398, Montevideo, Uruguay. Al
            acceder o utilizar esta Web, usted (en adelante “Usted” o el
            “Usuario”) acepta los términos de la presente Política de Privacidad
            de la Web. De esta forma, Usted acepta que podremos recoger, usar y
            revelar su información personal, tal y como se describe en esta
            Política de Privacidad. Esta Política de Privacidad está incorporada
            en, y forma parte de, los Términos y Condiciones que regulan el uso
            que en general usted haga de esta Web. Esta Política de Privacidad
            está destinada a ayudarle a comprender qué podemos hacer con la
            información que Usted nos proporciona. Usaremos su información sólo
            para los propósitos señalados a lo largo de este documento.
            Posiblemente actualicemos esta Política de Privacidad cada cierto
            tiempo. Le recomendamos que revise regularmente esta página web a
            fin de asegurarse que conoce la versión más reciente de la Política
            de Privacidad.
          </p>
          <p>
            Se informa al Usuario que como regla general no resulta ser
            necesario el ingreso de sus datos personales para navegar en los
            sitios Web de LC. En caso de ser necesario ingresar sus datos
            personales (cuando así sea requerido por el sitio) para poder
            acceder al mismo, LC utilizará y protegerá sus datos personales
            mientras usted navegue en los sitios Web de LC. En caso de
            disconformidad con la Política de Privacidad adoptada por LC, no
            deberá utilizar nuestro servicio. la utilización del servicio
            implica la aceptación y conocimiento automático de los Términos
            Generales de Uso (ver Términos Generales de Uso con link) y de la
            Política de Privacidad que a continuación se le hace saber:
          </p>
          <p>
            <ol>
              <li>
                En cumplimiento con la normativa vigente de Protección de Datos
                Personales (Ley N° 18.331 y disposiciones complementarias), LC
                dará un tratamiento adecuado a los datos personales de quienes
                utilicen los servicios brindados por LC en sus sitios Web.
              </li>
              <li>
                Toda aquella información que el Usuario ingrese voluntariamente
                durante la utilización del servicio estará bajo las normas de
                confidencialidad. Dicha información recibirá una protección
                adecuada y será utilizada sólo para las finalidades de los
                servicios que LC brinda.
              </li>
              <li>
                LC sólo requerirá a los Usuarios para su registración datos
                como: apellido, nombre, documento de identidad, fecha de
                nacimiento, dirección de correo electrónico, etc., absteniéndose
                de solicitar datos sensibles al Usuario (Art. 18 de la ley N°
                18.331). LC solo procederá a registrar los datos personales una
                vez que el Usuario haya prestado su consentimiento libre,
                expreso e informado para los fines solicitados. Usted podrá
                solicitar el retiro o bloqueo total o parcial de su información
                personal de las bases de datos, enviando a LC un correo
                electrónico a info.argentina@godrejcp.com o una carta a Deciral
                S.A., con domicilio en Osvaldo Cruz 5398, Montevideo, Uruguay.
                Por favor, tenga en cuenta que la información que anuncie en o a
                través de las áreas públicas de la Web (por ejemplo, todo tipo
                de conversaciones en línea, carteleras informativas o grupos de
                discusión) son generalmente accesibles a otros terceros, quienes
                podrían copiar y usar la información, pudiendo derivarse en
                correo no solicitado por el Usuario. Los usuarios de nuestra Web
                deberán tener precaución al momento de suministrar información
                personal en las áreas públicas de la Web.
              </li>
              <li>
                El Usuario garantiza la veracidad y certeza de los datos
                personales que ingresa en los sitios Web de LC, asumiendo la
                correspondiente responsabilidad en caso de que los mismos
                resulten inexactos. LC, en su condición de destinatario de los
                datos, no asume ninguna responsabilidad en caso de existir
                inexactitud en los datos personales ingresados por el Usuario.
              </li>
              <li>
                El Usuario podrá modificar o actualizar en cualquier momento la
                información relativa a los datos personales ingresados en los
                sitios Web de LC, los cuales formarán parte de un archivo que
                contendrá su perfil. Asimismo, el Usuario podrá solicitar la
                rectificación y/o baja de sus datos personales comprometiéndose
                LC, en caso de corresponder, a realizar las gestiones tendientes
                a rectificar y/o procesar la baja en un plazo máximo de cinco
                (5) días hábiles contados desde la recepción del reclamo por
                parte del Usuario.
              </li>
              <li>
                Se entenderá que el Usuario al ingresar los datos referidos
                autoriza automáticamente a LC a utilizar dichos datos para
                proveerle adecuadamente un servicio personalizado y acorde a sus
                necesidades, así como para recibir correspondencia de LC.
                Asimismo, además de los casos mencionados precedentemente, cada
                cierto tiempo podremos revelar información personal para: a.
                Nuestros proveedores de servicios y subcontratistas, incluyendo
                nuestros afiliados, que realizan funciones por nuestra cuenta o
                nos proveen servicios tales como almacenamiento y entrega;
                mercadeo y publicidad; procesamiento de datos; desarrollo de
                programas informáticos; manejo y mantenimiento de nuestra Web;
                información de tecnología y servicios de oficina; proveedores de
                servicios profesionales además de legales, contables y de
                auditoría; y de ninguna manera tales proveedores recolectarán,
                usarán o revelarán dicha información personal para cualquier
                otro propósito distinto al de realizar las funciones o
                proveernos los servicios que le requerimos o sean requeridos por
                ley; b. Una persona o entidad, incluyendo nuestros afiliados, a
                los cuales debe serles revelado para el cumplimiento de uno o
                varios de los propósitos descritos bajo el punto titulado
                "Propósitos para recolectar información personal"; c. Una
                persona que, con el debido poder, es la autorizada o señalada
                como representante legal suyo, para buscar información relativa
                a su persona; o d. Cuando la revelación sea requerida por ley.
                LC podrá utilizar la información proporcionada por el Usuario a
                fin de ofrecerle un servicio publicidad selectiva o contenidos
                que puedan llegar a serle de interés, si es que el Usuario
                aceptó recibirlos.
              </li>
              <li>
                No tenemos control sobre las comunicaciones que podamos tener a
                través de correo electrónico, motivo por el cual recomendamos
                que no incluya en ellos información confidencial, de propiedad
                exclusiva o delicada. En el hipotético caso en que consideremos
                que la seguridad de su información personal que se encuentra en
                nuestra posesión o bajo nuestro control, puede encontrarse
                comprometida, podremos notificarle al respecto. Le recordamos
                que Usted es el responsable de mantener la estricta
                confidencialidad de su cuenta y su contraseña, así como de
                cualquier actividad realizada bajo el uso de las mismas. Es su
                única responsabilidad controlar la difusión y uso de su
                contraseña, controlar el acceso y el uso de su cuenta, y
                notificarnos de su deseo de cerrar su cuenta. No seremos
                responsables de cualquier daño o pérdida que surja de falta de
                cumplimiento con esta obligación. La información que proporcione
                el Usuario estará asegurada por una clave de acceso. Dicha clave
                será creada por el Usuario (cuando la Web se lo requiera) y sólo
                conocida por éste; siendo el Usuario el único responsable de
                mantener dicha clave de seguridad en secreto. LC no intentará
                por ningún medio obtener la clave personal de acceso.
              </li>
              <li>
                Si Usted ha permitido las comunicaciones sobre las marcas de LC,
                Usted cada cierto tiempo recibirá comunicaciones sobre marcas a
                las cuales específicamente no optó por recibir noticias. Solo le
                comunicaremos sobre aquellas marcas que creemos que puedan
                despertarle un interés particular. Cualquiera de dichas
                comunicaciones le ofrecerá la oportunidad de retirar su
                suscripción de la marca en cuestión y/o a retirar su suscripción
                de todas las comunicaciones sobre las marcas de LC. Si tiene
                dudas sobre temas de privacidad, inquietud o queja, por favor
                contáctese a través de un correo electrónico a
                info.argentina@godrejcp.com o una carta a Deciral S.A., con
                domicilio en Osvaldo Cruz 5398, Montevideo, Uruguay
              </li>
              <li>
                LC ha adoptado las medidas de seguridad necesarias para proteger
                la información ingresada en sus sitios Web a fin de evitar
                adulteraciones o tratamientos no autorizados. Debido a que
                ninguna transmisión por Internet puede garantizar su íntegra
                seguridad, LC no puede asegurar que toda transmisión de datos o
                información que se realice vía Internet sea totalmente segura,
                razón por la cual el Usuario al ingresar a los sitios Web de LC
                acepta este riesgo, que declara conocer.
              </li>
              <li>
                El material incluido en los sitios Web de LC (entendiéndose como
                material a: textos, informes, artículos, datos, comentarios,
                disertaciones, gráficos, logos, iconos, imágenes y archivos de
                cualquier tipo) se encuentran protegidos por las leyes de
                propiedad intelectual. Queda prohibida su publicación,
                radiodifusión, reedición para radiodifusión o publicación y su
                redistribución directa o indirecta por cualquier medio. Queda
                prohibido su almacenamiento total o parcial en computadoras,
                excepto para uso personal y sin fines comerciales. La
                publicación de información de propiedad de terceros será de
                responsabilidad exclusiva de sus autores. LC no asume
                responsabilidad alguna por cualquier demora, inexactitud, error
                u omisión en el mismo o en la transmisión o entrega de la
                totalidad o parte del mismo, ni por los daños emergentes de
                tales circunstancias.
              </li>
              <li>
                La utilización de la información publicada en los sitios Web de
                LC para cualquier finalidad por parte del Usuario será bajo su
                exclusiva responsabilidad o riesgo, LC no se responsabiliza por
                los daños, de cualquier tipo ocasionados por el uso de la
                información publicada.
              </li>
              <li>
                LC no será responsable por los contenidos y/o la información
                provista en otras páginas Web a las cuales el Usuario pueda
                acceder mediante la utilización de los sitios Web de LC. Tampoco
                responderá por la autenticidad y/o veracidad de la información
                ni de los datos consignados en las mismas.
              </li>
              <li>
                Los Usuarios se obligan a hacer uso de los sitios Web de LC y de
                todos sus servicios de conformidad con la ley, los términos
                generales de uso, esta política de privacidad, la moral, las
                buenas costumbres generalmente aceptadas y el orden público,
                obligándose a abstenerse de utilizar los sitios Web de LC con
                fines ilícitos o contrario a lo aquí establecido, que de
                cualquier modo puedan dañar, inutilizar, sobrecargar o
                deteriorar los sitios Web de LC así como su reputación. Esta
                declaración de privacidad está sujeta a los términos generales
                de uso de LC, con lo cual constituye un acuerdo legal entre el
                Usuario y LC.
              </li>
              <li>
                Si el Usuario utiliza los sitios Web de LC, ello significa que
                ha leído, comprendido y aceptado los términos antes expuestos.
                En caso de no estar de acuerdo con ello, el Usuario no deberá
                proporcionar dato ni información alguna, ni utilizar los sitios
                Web de LC porque no está autorizado a hacerlo.
              </li>
              <li>
                LC se reserva el derecho, a su sola discreción, de modificar,
                alterar o de otra manera actualizar, esta Política de Privacidad
                en cualquier momento. Las modificaciones entrarán en vigencia
                desde el momento que se indique; en su defecto, se entenderá que
                su aplicación es inmediata. LC no limitara los derechos de los
                Usuarios en virtud de esta política de privacidad sin su
                consentimiento explícito.
              </li>
              <li>
                Esta Web utiliza cookies. Las cookies son un pequeño archivo de
                texto que un sitio web le envía a la computadora del visitante
                de dicho sitio web. El sitio podrá utilizar las cookies para
                todas y cada una de las finalidades que se incluyen a
                continuación, cuya enunciación no es taxativa sino enunciativa:
                (i) reconocer a los navegadores de los usuarios registrados, de
                modo que no tengan que registrarse en cada visita para acceder a
                las distintas partes del sitio web; (ii) monitoreo de tráfico,
                que incluye la dirección IP de acceso, tipo de buscador y
                sistema operativo usado, fecha y hora de ingreso, (iii)
                reconocer visitas repetidas con propósitos estadísticos; (iv)
                rastreo anónimo de interacción con publicidad en línea; (v)
                obtención de información sobre hábitos de navegación, entre
                otros. Asimismo, ayudan a mejorar o personalizar la experiencia
                del usuario en el sitio web y a monitorear el uso de dicho sitio
                a fin de realizar diversas comunicaciones que puedan ser de
                interés. Usted podrá negarse a recibir cookies y/o eliminar las
                cookies. Por favor, tenga en cuenta que, en caso de no aceptar
                el uso de cookies, la utilización del sitio puede verse
                afectada; es decir, puede que le resulte difícil o imposible el
                acceso a determinadas partes de nuestra Web.
              </li>
              <li>
                La Web puede contener enlaces o referencias a otros servidores o
                sitios web que se encuentran fuera de nuestro control. Por favor
                tenga en cuenta que no tenemos control sobre tales servidores o
                sitios web y nuestra Política de Privacidad no aplica en dichos
                sitios o servidores. Le instamos a leer las políticas de
                privacidad y los términos y condiciones de uso de tales
                servidores o sitios web a los cuales ingresa. De ser requerido
                por las jurisdicciones en las cuales conducimos nuestros
                negocios, esta Política de Privacidad será complementada con
                requerimientos legales adicionales. Nada en esta Política de
                Privacidad dará lugar a la creación o añadirá cualquier derecho
                o reclamo (bien sea legal, equitativo o de otra índole) que
                cualquier individuo pueda tener frente a la ley u otra fuente,
                en contra de la Bodega o un tercero, o contra cualquiera de sus
                respectivos directores, responsables, empleados, agentes o
                representantes tampoco la existencia o aplicación de esta
                Política de Privacidad impondrá o añadirá obligación alguna o
                responsabilidad sobre LC distinta a las que ya le corresponden
                por ley. El Usuario tiene prohibido expresamente mostrar o usar
                de ninguna manera las marcas propiedad de LC en un sitio o
                servidor distinto al nuestro, salvo que contase con nuestro
                consentimiento por escrito.
              </li>
            </ol>
          </p>
        </div>
      );
      break;

    case "bo":
      content = (
        <div className={styles.container}>
          <b>POLITICA DE PRIVACIDAD</b>
          <br />
          <br />
          <p>
            Laboratorio Cuenca S.A. (en adelante, “LC” o “Nosotros”,
            indistintamente), es la entidad responsable de la recopilación,
            tratamiento y uso de los datos personales de los usuarios del portal
            web
            <a href="www.issuecolor.com"> www.issuecolor.com</a> (en adelante,
            la “Web”) así como los datos personales que fueren proveídas por
            otro medios o por los distintos canales habilitados por LC, o los
            que fueren recopilados automáticamente a través de cookies u otras
            tecnologías. Al acceder o utilizar esta Web, usted (en adelante
            “Usted” o el “Usuario”) acepta los términos de la presente Política
            de Privacidad de la Web (en adelante, la “Política de Privacidad”)
            destinados a proteger la confidencialidad y privacidad de los datos
            proporcionados por Usted en cumplimiento de las normativas
            aplicables. Esta Política de Privacidad está incorporada en, y forma
            parte de, los Términos y Condiciones que regulan el uso que en
            general Usted haga de esta Web.
          </p>
          <p>
            De esta forma, Usted acepta que podremos recoger y usar su
            información personal, e incluso revelar la que no fuera información
            sensible, tal y como se describe en esta Política de Privacidad.
            Esta Política de Privacidad está destinada a ayudarle a comprender
            qué podemos hacer con la información que Usted nos proporciona.
            Usaremos su información sólo para los propósitos señalados a lo
            largo de este documento. Posiblemente actualicemos esta Política de
            Privacidad cada cierto tiempo. Le recomendamos que revise
            regularmente esta página web a fin de asegurarse que conoce la
            versión más reciente de la Política de Privacidad.
          </p>
          <p>
            En cumplimiento con la Constitución Política del Estado y la
            normativa vigente de protección de datos personales, LC dará un
            tratamiento adecuado a los datos personales de quienes utilicen los
            servicios brindados por LC en sus sitios Web
          </p>
          <p>
            En caso de disconformidad con la Política de Privacidad adoptada por
            LC, no deberá utilizar nuestro servicio. La utilización del servicio
            implica la aceptación y conocimiento automático de los Términos y
            Condiciones y de la Política de Privacidad que a continuación se le
            hace saber:
          </p>
          <ol>
            <li>
              <b> ¿Cuándo y qué datos personales recopilamos sobre usted?</b>
            </li>
            <p>
              Se informa al Usuario que como regla general no resulta ser
              necesario el ingreso de sus datos personales para navegar en los
              sitios Web de LC. En caso de ser necesario ingresar sus datos
              personales (cuando así sea requerido por el sitio) para poder
              acceder al mismo, LC utilizará y protegerá sus datos personales
              mientras usted navegue en los sitios Web de LC.
            </p>
            <p>
              LC sólo requerirá a los Usuarios para su registro datos como:
              apellido, nombre, documento de identidad, fecha de nacimiento,
              dirección de correo electrónico, etc., absteniéndose de solicitar
              datos sensibles al Usuario.
            </p>
            <p>
              LC sólo procederá a registrar los datos personales una vez que el
              Usuario haya prestado su consentimiento libre, expreso e informado
              para los fines solicitados. Usted podrá solicitar el retiro o
              bloqueo total o parcial de su información personal de las bases de
              datos, enviando a LC un correo electrónico a
              info.argentina@godrejcp.com o una carta a Empedrado 2345, Ciudad
              Autónoma de Buenos Aires. Por favor, tenga en cuenta que la
              información que anuncie en o a través de las áreas públicas de la
              Web (por ejemplo, todo tipo de conversaciones en línea, carteleras
              informativas o grupos de discusión) son generalmente accesibles a
              otros terceros, quienes podrían copiar y usar la información,
              pudiendo derivarse en correo no solicitado por el Usuario. Los
              usuarios de nuestra Web deberán tener precaución al momento de
              suministrar información personal en las áreas públicas de la Web.
            </p>
            <p>
              El Usuario garantiza la veracidad y certeza de los datos
              personales que ingresa en los sitios Web de LC, asumiendo la
              correspondiente responsabilidad en caso de que los mismos resulten
              inexactos o falsos. LC, en su condición de destinatario de los
              datos, no asume ninguna responsabilidad en caso de existir
              inexactitud en los datos personales ingresados por el Usuario.
            </p>
            <li>
              <b>
                ¿Qué tratamiento, uso y finalidad damos a los datos
                recolectados?
              </b>
            </li>
            <p>
              Se entenderá que el Usuario al ingresar los datos referidos
              autoriza automáticamente a LC a utilizar dichos datos para
              proveerle adecuadamente una atención personalizada y acorde a sus
              necesidades, así como para recibir correspondencia, publicidad o
              comunicaciones electrónicas de LC. LC podrá utilizar la
              información proporcionada por el Usuario a fin de ofrecerle un
              servicio de información selectiva o contenidos que puedan llegar a
              serle de interés, si es que el Usuario aceptó recibirlos.
            </p>
            <p>
              Toda aquella información que el Usuario ingrese voluntariamente
              durante la utilización del servicio estará bajo las normas de
              confidencialidad. Dicha información recibirá una protección
              adecuada y será utilizada sólo para las finalidades de los
              servicios que LC brinda.
            </p>
            <p>
              Por favor, tenga en cuenta que la información que anuncie en o a
              través de las áreas públicas de la Web (por ejemplo, todo tipo de
              conversaciones en línea, carteleras informativas o grupos de
              discusión) son generalmente accesibles a otros terceros, quienes
              podrían copiar y usar la información, pudiendo derivarse en
              correos no solicitados por el Usuario sobre los cuales LC no tiene
              control o responsabilidad. Los usuarios de nuestra Web deberán
              tener precaución al momento de suministrar información personal en
              las áreas públicas de la Web.
            </p>
            <li>
              <b>
                ¿En qué casos podrá LC compartir los datos del Usuario con
                terceros?
              </b>
            </li>
            <p>
              Cada cierto tiempo podremos revelar información personal a
              terceros para los siguientes fines o destinatarios:
            </p>
            <ul>
              <li>
                a. Nuestros proveedores de servicios y subcontratistas,
                incluyendo nuestros afiliados, que realizan funciones por
                nuestra cuenta o nos proveen servicios tales como almacenamiento
                y entrega; mercadeo y publicidad; procesamiento de datos;
                desarrollo de programas informáticos; manejo y mantenimiento de
                nuestra Web; información de tecnología y servicios de oficina;
                proveedores de servicios profesionales además de legales,
                contables y de auditoría; y de ninguna manera tales proveedores
                recolectarán, usarán o revelarán dicha información personal para
                cualquier otro propósito distinto al de realizar las funciones o
                proveernos los servicios que le requerimos o sean requeridos por
                ley;{" "}
              </li>
              <li>
                b. Una persona o entidad, incluyendo nuestros afiliados, a los
                cuales debe serles revelado para el cumplimiento de uno o varios
                de los propósitos descritos bajo el punto titulado "¿Qué
                tratamiento, uso y finalidad damos a los datos recolectados?";{" "}
              </li>
              <li>
                c. Una persona que, bajo nuestro juicio razonable, es la
                autorizada o señalada como agente legal suyo, para buscar o
                acceder a información relativa a su persona; o,
              </li>
              <li>
                d. Cualquier tercera persona que cuente con su consentimiento o
                donde la revelación sea requerida por ley.
              </li>
            </ul>
            <li>
              <b>
                ¿Cuáles son los derechos de modificación o consulta o respecto
                al tratamiento de sus datos que tienen los Usuarios?
              </b>
            </li>
            <p>
              Usted podrá solicitar la consulta, rectificación, el retiro o
              bloqueo total o parcial de su información personal de las bases de
              datos, enviando a LC un correo electrónico a
              info.argentina@godrejcp.com o una carta a Empedrado 2435, Ciudad
              Autónoma de Buenos Aires, República Argentina, solicitando
              expresamente tal extremo.
            </p>
            <p>
              El Usuario podrá modificar o actualizar en cualquier momento la
              información relativa a los datos personales ingresados en los
              sitios Web de LC, los cuales formarán parte de un archivo que
              contendrá su perfil. Asimismo, el Usuario podrá solicitar la
              rectificación y/o baja de sus datos personales comprometiéndose
              LC, en caso de corresponder, a realizar las gestiones tendientes a
              rectificar y/o procesar la baja en un plazo máximo de diez (10)
              días hábiles contados desde la recepción del reclamo por parte del
              Usuario.
            </p>
            <li>
              <b>
                ¿Qué cuidados debe tomar el Usuario con los datos
                proporcionados?
              </b>
            </li>
            <p>
              LC no tiene control sobre las comunicaciones que pueda recibir a
              través de correo electrónico, y en ningún caso solicitará
              información confidencial, de propiedad exclusiva o sensible por
              este medio, motivo por el cual recomendamos que no incluya en
              ellos información confidencial, de propiedad exclusiva o sensible.
              En el hipotético caso en que consideremos que la seguridad de su
              información personal que se encuentra en nuestra posesión o bajo
              nuestro control, puede encontrarse comprometida, podremos
              notificarle al respecto.
            </p>
            <p>
              Le recordamos que Usted es el responsable de mantener la estricta
              confidencialidad de su cuenta y su contraseña, así como de
              cualquier actividad realizada bajo el uso de las mismas. Es su
              única responsabilidad controlar la difusión y uso de su
              contraseña, controlar el acceso y el uso de su cuenta, y
              notificarnos de su deseo de cerrar su cuenta. No seremos
              responsables de cualquier daño o pérdida que surja de falta de
              cumplimiento con esta obligación. La información que proporcione
              el Usuario estará asegurada por una clave de acceso. Dicha clave
              será creada por el Usuario (cuando la Web se lo requiera) y sólo
              conocida por éste; siendo el Usuario el único responsable de
              mantener dicha clave de seguridad en secreto. LC no intentará por
              ningún medio obtener la clave personal de acceso.
            </p>
            <p>
              LC ha adoptado las medidas de seguridad necesarias para proteger
              la información ingresada en sus sitios Web a fin de evitar
              adulteraciones o tratamientos no autorizados. Debido a que ninguna
              transmisión por Internet puede garantizar su íntegra seguridad, LC
              no puede asegurar que toda transmisión de datos o información que
              se realice vía Internet sea totalmente segura, razón por la cual
              el Usuario al ingresar a los sitios Web de LC acepta este riesgo,
              que declara conocer.
            </p>
            <li>
              <b>
                ¿Qué comunicaciones electrónicas o físicas podrá recibir de LC o
                sus afiliadas?
              </b>
            </li>
            <p>
              Si Usted ha permitido las comunicaciones sobre las marcas de LC,
              Usted ha consentido de manera genérica a las comunicaciones de
              todas nuestras marcas por lo que cada cierto tiempo,
              adicionalmente a la marca elegida, podrá recibir comunicaciones
              sobre marcas a las cuales Usted específicamente no optó por
              recibir noticias. Solo le comunicaremos sobre aquellas marcas que
              creemos que puedan despertarle un interés particular. Cualquiera
              de dichas comunicaciones le ofrecerá la oportunidad en el cuerpo
              de la misma comunicación, de retirar su suscripción de la marca en
              cuestión y/o a retirar su suscripción de todas las comunicaciones
              sobre las marcas de LC. El material incluido en los sitios Web de
              LC (entendiéndose como material a: textos, informes, artículos,
              datos, comentarios, disertaciones, gráficos, logos, iconos,
              imágenes y archivos de cualquier tipo) se encuentran protegidos
              por las leyes de propiedad intelectual. Queda prohibida su
              publicación, radiodifusión, reedición para radiodifusión o
              publicación y su redistribución directa o indirecta por cualquier
              medio. Queda prohibido su almacenamiento total o parcial en
              computadoras, excepto para uso personal y sin fines comerciales.
              La publicación de información de propiedad de terceros será de
              responsabilidad exclusiva de sus autores. LC no asume
              responsabilidad alguna por cualquier demora, inexactitud, error u
              omisión en el mismo o en la transmisión o entrega de la totalidad
              o parte del mismo, ni por los daños emergentes de tales
              circunstancias. El Usuario tiene prohibido expresamente mostrar o
              usar de ninguna manera las marcas propiedad de LC en un sitio o
              servidor distinto al nuestro, salvo que contase con nuestro
              consentimiento por escrito.
            </p>
            <p>
              La utilización de la información publicada en los sitios Web de LC
              para cualquier finalidad por parte del Usuario será bajo su
              exclusiva responsabilidad o riesgo, LC no se responsabiliza por
              los daños, de cualquier tipo ocasionados por el uso de la
              información publicada.
            </p>
            <b>¿Cuáles son las obligaciones de los Usuarios?</b>
            <br />
            <p>
              Además del cumplimiento con los Términos y Condiciones y este
              Aviso de Privacidad, los Usuarios se obligan a hacer uso de los
              sitios Web de LC y de todos sus servicios de conformidad con la
              ley, la moral, las buenas costumbres generalmente aceptadas y el
              orden público, obligándose a abstenerse de utilizar los sitios Web
              de LC con fines ilícitos o contrarios a lo aquí establecido, que
              de cualquier modo puedan dañar, inutilizar, sobrecargar o
              deteriorar los sitios Web de LC así como su reputación.{" "}
            </p>
            <p>
              Si el Usuario utiliza los sitios Web de LC, ello significa que ha
              leído, comprendido y aceptado los términos antes expuestos. En
              caso de no estar de acuerdo con ellos, el Usuario deberá
              abstenerse de proporcionar dato o información alguna, y sobre todo
              deberá abstenerse de utilizar los sitios Web de LC no estando
              autorizado a hacerlo en tal evento.
            </p>
            <li>
              {" "}
              <b>
                Casos en los que LC o la Web actúan como proveedores de enlace.
              </b>
              <p>
                La Web contiene enlaces o referencias a otros servidores o
                sitios web que se encuentran fuera de nuestro control. Por favor
                tenga en cuenta que no tenemos control sobre tales servidores o
                sitios web y nuestra Política de Privacidad no aplica en dichos
                sitios o servidores. Le instamos a leer las políticas de
                privacidad y los términos y condiciones de uso de tales
                servidores o sitios web a los cuales ingresa. De ser requerido
                por las jurisdicciones en las cuales conducimos nuestros
                negocios, esta Política de Privacidad será complementada con
                requerimientos legales adicionales. LC no será responsable por
                los contenidos y/o la información provista en otras páginas Web
                a las cuales el Usuario pueda acceder mediante la utilización de
                los sitios Web de LC. Tampoco responderá por la autenticidad y/o
                veracidad de la información ni de los datos consignados en las
                mismas.
              </p>
            </li>
            <li>
              <b> Cookies u otras tecnologías de recolección de información.</b>
            </li>
            <p>
              Esta Web utiliza cookies. Las cookies son un pequeño archivo de
              texto que un sitio web le envía a la computadora del visitante de
              dicho sitio web. El sitio podrá utilizar las cookies para todas y
              cada una de las finalidades que se incluyen a continuación, cuya
              enunciación no es taxativa sino enunciativa: (i) reconocer a los
              navegadores de los usuarios registrados, de modo que no tengan que
              registrarse en cada visita para acceder a las distintas partes del
              sitio web; (ii) monitoreo de tráfico, que incluye la dirección IP
              de acceso, tipo de buscador y sistema operativo usado, fecha y
              hora de ingreso, (iii) reconocer visitas repetidas con propósitos
              estadísticos; (iv) rastreo anónimo de interacción con publicidad
              en línea; (v) obtención de información sobre hábitos de
              navegación, entre otros. Asimismo, ayudan a mejorar o personalizar
              la experiencia del usuario en el sitio web y a monitorear el uso
              de dicho sitio a fin de realizar diversas comunicaciones que
              puedan ser de interés. Usted podrá negarse a recibir cookies y/o
              eliminar las cookies. Por favor, tenga en cuenta que, en caso de
              no aceptar el uso de cookies, la utilización del sitio puede verse
              afectada; es decir, puede que le resulte difícil o imposible el
              acceso a determinadas partes de nuestra Web.
            </p>
            <li>
              <b> Modificación del Aviso de Privacidad.</b>
            </li>
            <p>
              LC se reserva el derecho, a su sola discreción, de modificar,
              alterar o de otra manera actualizar, esta Política de Privacidad
              en cualquier momento. Las modificaciones entrarán en vigencia
              desde el momento que se indique; en su defecto, se entenderá que
              su aplicación es inmediata. LC no limitara los derechos de los
              Usuarios en virtud de esta política de privacidad sin su
              consentimiento explícito.
            </p>
            <li>
              <b> Disposiciones finales.</b>
            </li>
            <p>
              Nada en esta Política de Privacidad dará lugar a la creación o
              añadirá cualquier derecho o reclamo (bien sea legal o de otra
              índole) que cualquier individuo pueda tener frente a la ley u otra
              fuente, en contra de LC o un tercero, o contra cualquiera de sus
              respectivos directores, responsables, empleados, agentes o
              representantes tampoco la existencia o aplicación de esta Política
              de Privacidad impondrá o añadirá obligación alguna o
              responsabilidad sobre LC distinta a las que ya le corresponden por
              ley.
            </p>
            <li>
              <b> Contacto.</b>
            </li>
            <p>
              Si tiene dudas sobre temas de privacidad, inquietud o queja, por
              favor contáctese a través de un correo electrónico a
              info.argentina@godrejcp.com o por carta a Empedrado 2435, Ciudad
              Autónoma de Buenos Aires.
            </p>
            <p>
              Los datos de contacto de Laboratorio Cuenca S.A. responsable de
              esta Web son los siguientes:
            </p>
            <ul>
              <li>Denominación social: Laboratorio Cuenca S.A.</li>
              <li>
                Dirección electrónica de contacto: info.argentina@godrejcp.com
              </li>
              <li>Número de teléfono de contacto: +54 9 11 4501 9900</li>
              <li>
                Domicilio: Empedrado 2435, Ciudad Autónoma de Buenos Aires,
                República Argentina.
              </li>
            </ul>
          </ol>
        </div>
      );
      break;
    case "ec":
      content = (
        <div className={styles.container}>
          <b>POLÍTICA DE PRIVACIDAD</b>
          <br />
          <br />
          <p>
            Este sitio web (la “Web”) es operado por un miembro de Laboratorio
            Cuenca S.A. (el “LC” o “Nosotros” indistintamente). Al acceder o
            utilizar esta Web, usted (en adelante “Usted” o el “Usuario”) acepta
            los términos de la presente Política de Privacidad de la Web. De
            esta forma, Usted acepta que podremos recoger, usar y revelar su
            información personal, tal y como se describe en esta Política de
            Privacidad. Esta Política de Privacidad está incorporada en, y forma
            parte de, los Términos y Condiciones que regulan el uso que en
            general usted haga de esta Web. Esta Política de Privacidad está
            destinada a ayudarle a comprender qué podemos hacer con la
            información que Usted nos proporciona. Usaremos su información sólo
            para los propósitos señalados a lo largo de este documento.
            Posiblemente actualicemos esta Política de Privacidad cada cierto
            tiempo. Le recomendamos que revise regularmente esta página web a
            fin de asegurarse que conoce la versión más reciente de la Política
            de Privacidad.
          </p>
          <p>
            Se informa al Usuario que como regla general no resulta ser
            necesario el ingreso de sus datos personales para navegar en los
            sitios Web de LC. En caso de ser necesario ingresar sus datos
            personales (cuando así sea requerido por el sitio) para poder
            acceder al mismo, LC utilizará y protegerá sus datos personales
            mientras usted navegue en los sitios Web de LC y de forma posterior,
            de acuerdo con los parámetros establecidos en la presente Política
            de Privacidad. En caso de disconformidad con la Política de
            Privacidad adoptada por LC, no deberá utilizar nuestro servicio. La
            utilización del servicio implica la aceptación y conocimiento
            automático de los Términos Generales de Uso (ver Términos Generales
            de Uso con link) y de la Política de Privacidad que a continuación
            se le hace saber:
          </p>
          <ol>
            <li>
              LC dará un tratamiento adecuado a los datos personales de quienes
              utilicen los servicios brindados por LC en sus sitios Web, de
              acuerdo a las normas de la Ley de Comercio Electrónico, Firmas
              Electrónicas y Mensajes de Datos y otras que resultaren aplicables
              en la República del Ecuador.
            </li>
            <li>
              Toda aquella información que el Usuario ingrese voluntariamente
              durante la utilización del servicio estará bajo las normas de
              confidencialidad. Dicha información recibirá una protección
              adecuada y será utilizada sólo para las finalidades de los
              servicios que LC brinda.
            </li>
            <li>
              LC sólo requerirá a los Usuarios para su registración datos como:
              apellido, nombre, documento de identidad, fecha de nacimiento,
              dirección de correo electrónico, etc., absteniéndose de solicitar
              datos sensibles al Usuario. LC solo procederá a registrar los
              datos personales una vez que el Usuario haya prestado su
              consentimiento libre, expreso e informado para los fines
              solicitados. Usted podrá solicitar el retiro o bloqueo total o
              parcial de su información personal de las bases de datos, enviando
              a LC un correo electrónico a [insertar correo electrónico de
              Ecuador]. Por favor, tenga en cuenta que la información que
              anuncie en o a través de las áreas públicas de la Web (por
              ejemplo, todo tipo de conversaciones en línea, carteleras
              informativas o grupos de discusión) son generalmente accesibles a
              otros terceros, quienes podrían copiar y usar la información,
              pudiendo derivarse en correo no solicitado por el Usuario. Los
              usuarios de nuestra Web deberán tener precaución al momento de
              suministrar información personal en las áreas públicas de la Web.
            </li>
            <li>
              El Usuario garantiza la veracidad y certeza de los datos
              personales que ingresa en los sitios Web de LC, asumiendo la
              correspondiente responsabilidad en caso de que los mismos resulten
              inexactos. LC, en su condición de destinatario de los datos, no
              asume ninguna responsabilidad en caso de existir inexactitud en
              los datos personales ingresados por el Usuario.
            </li>
            <li>
              El Usuario podrá modificar o actualizar en cualquier momento la
              información relativa a los datos personales ingresados en los
              sitios Web de LC, los cuales formarán parte de un archivo que
              contendrá su perfil. Asimismo, el Usuario podrá solicitar la
              rectificación y/o baja de sus datos personales comprometiéndose
              LC, en caso de corresponder, a realizar las gestiones tendientes a
              rectificar y/o procesar la baja en un plazo máximo de diez (10)
              días hábiles contados desde la recepción del reclamo por parte del
              Usuario.
            </li>
            <li>
              Se entenderá que el Usuario al ingresar los datos referidos
              autoriza automáticamente a LC a utilizar dichos datos para
              proveerle adecuadamente un servicio personalizado y acorde a sus
              necesidades, así como para recibir correspondencia de LC.
              Asimismo, además de los casos mencionados precedentemente, cada
              cierto tiempo podremos revelar información personal para: a.
              Nuestros proveedores de servicios y subcontratistas, compañías
              distribuidoras en ciertos territorios, incluyendo nuestros
              afiliados, que realizan funciones por nuestra cuenta o nos proveen
              servicios tales como almacenamiento y entrega; mercadeo y
              publicidad; procesamiento de datos; desarrollo de programas
              informáticos; manejo y mantenimiento de nuestra Web; información
              de tecnología y servicios de oficina; proveedores de servicios
              profesionales además de legales, contables y de auditoría; y de
              ninguna manera tales proveedores recolectarán, usarán o revelarán
              dicha información personal para cualquier otro propósito distinto
              al de realizar las funciones o proveernos los servicios que le
              requerimos o sean requeridos por ley; b. Una persona o entidad,
              incluyendo nuestros afiliados, a los cuales debe serles revelado
              para el cumplimiento de uno o varios de los propósitos descritos
              bajo el punto titulado "Propósitos para recolectar información
              personal"; c. Una persona que, bajo nuestro juicio razonable, es
              la autorizada o señalada como agente legal suyo, para buscar
              información relativa a su persona; o d. Cualquier tercera persona
              que cuente con su consentimiento o donde la revelación sea
              requerida por ley. LC podrá utilizar la información proporcionada
              por el Usuario a fin de ofrecerle un servicio publicidad selectiva
              o contenidos que puedan llegar a serle de interés, si es que el
              Usuario aceptó recibirlos.
            </li>
            <li>
              No tenemos control sobre las comunicaciones que podamos tener a
              través de correo electrónico, motivo por el cual recomendamos que
              no incluya en ellos información confidencial, de propiedad
              exclusiva o delicada. En el hipotético caso en que consideremos
              que la seguridad de su información personal que se encuentra en
              nuestra posesión o bajo nuestro control, puede encontrarse
              comprometida, podremos notificarle al respecto. Le recordamos que
              Usted es el responsable de mantener la estricta confidencialidad
              de su cuenta y su contraseña, así como de cualquier actividad
              realizada bajo el uso de las mismas. Es su única responsabilidad
              controlar la difusión y uso de su contraseña, controlar el acceso
              y el uso de su cuenta, y notificarnos de su deseo de cerrar su
              cuenta. No seremos responsables de cualquier daño o pérdida que
              surja de falta de cumplimiento con esta obligación. La información
              que proporcione el Usuario estará asegurada por una clave de
              acceso. Dicha clave será creada por el Usuario (cuando la Web se
              lo requiera) y sólo conocida por éste; siendo el Usuario el único
              responsable de mantener dicha clave de seguridad en secreto. LC no
              intentará por ningún medio obtener la clave personal de acceso.
            </li>
            <li>
              Si Usted ha permitido las comunicaciones sobre las marcas de LC,
              Usted cada cierto tiempo recibirá comunicaciones sobre marcas a
              las cuales específicamente no optó por recibir noticias. Solo le
              comunicaremos sobre aquellas marcas que creemos que puedan
              despertarle un interés particular. Cualquiera de dichas
              comunicaciones le ofrecerá la oportunidad de retirar su
              suscripción de la marca en cuestión y/o a retirar su suscripción
              de todas las comunicaciones sobre las marcas de LC. Si tiene dudas
              sobre temas de privacidad, inquietud o queja, por favor contáctese
              a través de un correo electrónico a [insertar correo electrónico
              de Ecuador].
            </li>
            <li>
              LC ha adoptado las medidas de seguridad razonables para proteger
              la información ingresada en sus sitios Web a fin de evitar
              adulteraciones o tratamientos no autorizados. Debido a que ninguna
              transmisión por Internet puede garantizar su íntegra seguridad, LC
              no puede asegurar que toda transmisión de datos o información que
              se realice vía Internet sea totalmente segura, razón por la cual
              el Usuario al ingresar a los sitios Web de LC acepta este riesgo,
              que declara conocer.
            </li>
            <li>
              El material incluido en los sitios Web de LC (entendiéndose como
              material a: textos, informes, artículos, datos, comentarios,
              disertaciones, gráficos, logos, iconos, imágenes y archivos de
              cualquier tipo) se encuentran protegidos por las leyes de
              propiedad intelectual. Queda prohibida su publicación,
              radiodifusión, reedición para radiodifusión o publicación y su
              redistribución directa o indirecta por cualquier medio. Queda
              prohibido su almacenamiento total o parcial en computadoras,
              excepto para uso personal y sin fines comerciales. La publicación
              de información de propiedad de terceros será de responsabilidad
              exclusiva de sus autores. LC no asume responsabilidad alguna por
              cualquier demora, inexactitud, error u omisión en el mismo o en la
              transmisión o entrega de la totalidad o parte del mismo, ni por
              los daños emergentes de tales circunstancias.
            </li>
            <li>
              La utilización de la información publicada en los sitios Web de LC
              para cualquier finalidad por parte del Usuario será bajo su
              exclusiva responsabilidad o riesgo, LC no se responsabiliza por
              los daños, de cualquier tipo ocasionados por el uso de la
              información publicada.
            </li>
            <li>
              LC no será responsable por los contenidos y/o la información
              provista en otras páginas Web a las cuales el Usuario pueda
              acceder mediante la utilización de los sitios Web de LC. Tampoco
              responderá por la autenticidad y/o veracidad de la información ni
              de los datos consignados en las mismas.
            </li>
            <li>
              Los Usuarios se obligan a hacer uso de los sitios Web de LC y
              deontent = (<div className={styles.container}></div>
              todos sus servicios de conformidad con la ley, los términos
              generales de uso, esta política de privacidad, la moral, las
              buenas costumbres generalmente aceptadas y el orden público,
              obligándose a abstenerse de utilizar los sitios Web de LC con
              fines ilícitos o contrario a lo aquí establecido, que de cualquier
              modo puedan dañar, inutilizar, sobrecargar o deteriorar los sitios
              Web de LC así como su reputación. Esta declaración de privacidad
              está sujeta a los términos generales de uso de LC, con lo cual
              constituye un acuerdo legal entre el Usuario y LC.
            </li>
            <li>
              Si el Usuario utiliza los sitios Web de LC, ello significa que ha
              leído, comprendido y aceptado los términos antes expuestos. En
              caso de no estar de acuerdo con ello, el Usuario no deberá
              proporcionar dato ni información alguna, ni utilizar los sitios
              Web de LC porque su uso está condicionado a esta aceptación.
            </li>
            <li>
              LC se reserva el derecho, a su sola discreción, de modificar,
              alterar o de otra manera actualizar, esta Política de Privacidad
              en cualquier momento y publicar cualquier cambio en la página Web.
              Las modificaciones entrarán en vigencia desde el momento que se
              indique; en su defecto, se entenderá que su aplicación es
              inmediata. LC no limitara los derechos de los Usuarios en virtud
              de esta política de privacidad sin su consentimiento explícito.
            </li>
            <li>
              Esta Web utiliza cookies. Las cookies son un pequeño archivo de
              texto que un sitio web le envía a la computadora del visitante de
              dicho sitio web. El sitio podrá utilizar las cookies para todas y
              cada una de las finalidades que se incluyen a continuación, cuya
              enunciación no es taxativa sino enunciativa: (i) reconocer a los
              navegadores de los usuarios registrados, de modo que no tengan que
              registrarse en cada visita para acceder a las distintas partes del
              sitio web; (ii) monitoreo de tráfico, que incluye la dirección IP
              de acceso, tipo de buscador y sistema operativo usado, fecha y
              hora de ingreso, (iii) reconocer visitas repetidas con propósitos
              estadísticos; (iv) rastreo anónimo de interacción con publicidad
              en línea; (v) obtención de información sobre hábitos de
              navegación, entre otros. Asimismo, ayudan a mejorar o personalizar
              la experiencia del usuario en el sitio web y a monitorear el uso
              de dicho sitio a fin de realizar diversas comunicaciones que
              puedan ser de interés. Usted podrá negarse a recibir cookies y/o
              eliminar las cookies. Por favor, tenga en cuenta que, en caso de
              no aceptar el uso de cookies, la utilización del sitio puede verse
              afectada; es decir, puede que le resulte difícil o imposible el
              acceso a determinadas partes de nuestra Web.
            </li>
            <li>
              La Web puede contener enlaces o referencias a otros servidores o
              sitios web que se encuentran fuera de nuestro control. Por favor
              tenga en cuenta que no tenemos control sobre tales servidores o
              sitios web y nuestra Política de Privacidad no aplica en dichos
              sitios o servidores. Le instamos a leer las políticas de
              privacidad y los términos y condiciones de uso de tales servidores
              o sitios web a los cuales ingresa.. Nada en esta Política de
              Privacidad dará lugar a la creación o añadirá cualquier derecho o
              reclamo (bien sea legal, equitativo o de otra índole) que
              cualquier individuo pueda tener frente a la ley u otra fuente, en
              contra de la Bodega o un tercero, o contra cualquiera de sus
              respectivos directores, responsables, empleados, agentes o
              representantes tampoco la existencia o aplicación de esta Política
              de Privacidad impondrá o añadirá obligación alguna o
              responsabilidad sobre LC distinta a las que ya le corresponden por
              ley. El Usuario tiene prohibido expresamente mostrar o usar de
              ninguna manera las marcas propiedad de LC en un sitio o servidor
              distinto al nuestro, salvo que contase con nuestro consentimiento
              por escrito.
            </li>
          </ol>
        </div>
      );
      break;
    case "py":
      content = (
        <div className={styles.container}>
          <b>POLITICA DE PRIVACIDAD</b>
          <br />
          <br />

          <p>
            Laboratorio Cuenca S.A. (en adelante, “LC” o “Nosotros”,
            indistintamente), es la entidad responsable de la recopilación,
            tratamiento y uso de los datos personales de los usuarios del portal
            web
            <a href="www.issuecolor.com"> www.issuecolor.com</a> (en adelante,
            la “Web”) así como los datos personales que fueren proveídas por
            otro medios o por los distintos canales habilitados por LC, o los
            que fueren recopilados automáticamente a través de cookies u otras
            tecnologías. Al acceder o utilizar esta Web, usted (en adelante
            “Usted” o el “Usuario”) acepta los términos de la presente Política
            de Privacidad de la Web (en adelante, la “Política de Privacidad”)
            destinados a proteger la confidencialidad y privacidad de los datos
            proporcionados por Usted en cumplimiento de las normativas
            aplicables. Esta Política de Privacidad está incorporada en, y forma
            parte de, los Términos y Condiciones que regulan el uso que en
            general Usted haga de esta Web.
          </p>
          <p>
            De esta forma, Usted acepta que podremos recoger y usar su
            información personal, e incluso revelar la que no fuera información
            sensible, tal y como se describe en esta Política de Privacidad.
            Esta Política de Privacidad está destinada a ayudarle a comprender
            qué podemos hacer con la información que Usted nos proporciona.
            Usaremos su información sólo para los propósitos señalados a lo
            largo de este documento. Posiblemente actualicemos esta Política de
            Privacidad cada cierto tiempo. Le recomendamos que revise
            regularmente esta página web a fin de asegurarse que conoce la
            versión más reciente de la Política de Privacidad.
          </p>
          <p>
            En caso de disconformidad con la Política de Privacidad adoptada por
            LC, no deberá utilizar nuestro servicio. La utilización del servicio
            implica la aceptación y conocimiento automático de los Términos y
            Condiciones y de la Política de Privacidad que a continuación se le
            hace saber:
            <ol>
              <li>
                <b> ¿Cuándo y qué datos personales recopilamos sobre usted?</b>
              </li>
              <p>
                Se informa al Usuario que como regla general no resulta ser
                necesario el ingreso de sus datos personales para navegar en los
                sitios Web de LC. En caso de ser necesario ingresar sus datos
                personales (cuando así sea requerido por el sitio) para poder
                acceder al mismo, LC utilizará y protegerá sus datos personales
                mientras usted navegue en los sitios Web de LC.{" "}
              </p>
              <p>
                LC sólo requerirá a los Usuarios datos como: apellido, nombre,
                documento de identidad, fecha de nacimiento, dirección de correo
                electrónico, entre otros datos, absteniéndose de solicitar datos
                sensibles al Usuario (Art. 7 de la ley N° 25.326 (argentina); y,
                Art. 3. B) de la Ley Nº 6534 (paraguaya) y Art. 6. E) de la Ley
                Nº 4868 (paraguaya). La Web habitualmente no recolecta datos
                crediticios, y aún si los recolectare, en ningún caso se
                recolectarán datos crediticios sin un consentimiento previo y
                expreso del Usuario.
              </p>
              <p>
                El Usuario garantiza la veracidad y certeza de los datos
                personales que ingresa en los sitios Web de LC, asumiendo la
                correspondiente responsabilidad en caso de que los mismos
                resulten inexactos o falsos. LC, en su condición de destinatario
                de los datos, no asume ninguna responsabilidad en caso de
                existir inexactitud en los datos personales ingresados por el
                Usuario.
              </p>
              <li>
                <b>
                  {" "}
                  ¿Qué tratamiento, uso y finalidad damos a los datos
                  recolectados?
                </b>
              </li>

              <p>
                En cumplimiento con la normativa vigente de Protección de Datos
                Personales (en Argentina, Ley N° 25.326 y disposiciones
                complementarias; y, en Paraguay, Ley Nº 6534 de Protección de
                Datos Personales Crediticios, disposiciones complementarias y
                normativas que en el futuro las complementen o reemplacen), LC
                dará un tratamiento adecuado a los datos personales de quienes
                utilicen los servicios brindados por LC en sus sitios Web.
              </p>
              <p>
                Se entenderá que el Usuario al ingresar los datos referidos
                autoriza automáticamente a LC a utilizar dichos datos para
                proveerle adecuadamente una atención personalizada y acorde a
                sus necesidades, así como para recibir correspondencia,
                publicidad o comunicaciones electrónicas de LC. LC podrá
                utilizar la información proporcionada por el Usuario a fin de
                ofrecerle un servicio de información selectiva o contenidos que
                puedan llegar a serle de interés, si es que el Usuario aceptó
                recibirlos.
              </p>
              <p>
                Toda aquella información que el Usuario ingrese voluntariamente
                durante la utilización del servicio estará bajo las normas de
                confidencialidad. Dicha información recibirá una protección
                adecuada y será utilizada sólo para las finalidades de los
                servicios que LC brinda.
              </p>
              <p>
                Por favor, tenga en cuenta que la información que anuncie en o a
                través de las áreas públicas de la Web (por ejemplo, todo tipo
                de conversaciones en línea, carteleras informativas o grupos de
                discusión) son generalmente accesibles a otros terceros, quienes
                podrían copiar y usar la información, pudiendo derivarse en
                correos no solicitados por el Usuario sobre los cuales LC no
                tiene control o responsabilidad. Los usuarios de nuestra Web
                deberán tener precaución al momento de suministrar información
                personal en las áreas públicas de la Web.
              </p>
              <li>
                <b>
                  {" "}
                  ¿En qué casos podrá LC compartir los datos del Usuario con
                  terceros?
                </b>
              </li>

              <p>
                Cada cierto tiempo podremos revelar información personal a
                terceros para los siguientes fines o destinatarios:{" "}
              </p>
              <p>
                a. Nuestros proveedores de servicios y subcontratistas,
                incluyendo nuestros afiliados, que realizan funciones por
                nuestra cuenta o nos proveen servicios tales como almacenamiento
                y entrega; mercadeo y publicidad; procesamiento de datos;
                desarrollo de programas informáticos; manejo y mantenimiento de
                nuestra Web; información de tecnología y servicios de oficina;
                proveedores de servicios profesionales además de legales,
                contables y de auditoría; y de ninguna manera tales proveedores
                recolectarán, usarán o revelarán dicha información personal para
                cualquier otro propósito distinto al de realizar las funciones o
                proveernos los servicios que le requerimos o sean requeridos por
                ley;{" "}
              </p>

              <p>
                b. Una persona o entidad, incluyendo nuestros afiliados, a los
                cuales debe serles revelado para el cumplimiento de uno o varios
                de los propósitos descritos bajo el punto titulado "¿Qué
                tratamiento, uso y finalidad damos a los datos recolectados?";
              </p>
              <p>
                c. Una persona que, bajo nuestro juicio razonable, es la
                autorizada o señalada como agente legal suyo, para buscar o
                acceder a información relativa a su persona; o,
              </p>
              <p>
                d. Cualquier tercera persona que cuente con su consentimiento o
                donde la revelación sea requerida por ley.
              </p>

              <li>
                <b>
                  ¿Cuáles son los derechos de modificación o consulta o respecto
                  al tratamiento de sus datos que tienen los Usuarios?
                </b>
              </li>

              <p>
                Usted podrá solicitar la consulta, rectificación, el retiro o
                bloqueo total o parcial de su información personal de las bases
                de datos, enviando a LC un correo electrónico a
                info.argentina@godrejcp.com o una carta a Empedrado 2435, Ciudad
                Autónoma de Buenos Aires, República Argentina, solicitando
                expresamente tal extremo.
              </p>

              <p>
                El Usuario podrá modificar o actualizar en cualquier momento la
                información relativa a los datos personales ingresados en los
                sitios Web de LC, los cuales formarán parte de un archivo que
                contendrá su perfil. Asimismo, el Usuario podrá solicitar la
                rectificación y/o baja de sus datos personales comprometiéndose
                LC, en caso de corresponder, a realizar las gestiones tendientes
                a rectificar y/o procesar la baja en un plazo máximo de diez
                (10) días hábiles contados desde la recepción del reclamo por
                parte del Usuario.
              </p>

              <li>
                <b>
                  ¿Qué cuidados debe tomar el Usuario con los datos
                  proporcionados?
                </b>
              </li>

              <p>
                LC no tiene control sobre las comunicaciones que pueda recibir a
                través de correo electrónico, y en ningún caso solicitará
                información confidencial, de propiedad exclusiva o sensible por
                este medio, motivo por el cual recomendamos que no incluya en
                ellos información confidencial, de propiedad exclusiva o
                sensible. En el hipotético caso en que consideremos que la
                seguridad de su información personal que se encuentra en nuestra
                posesión o bajo nuestro control, puede encontrarse comprometida,
                podremos notificarle al respecto.
              </p>

              <p>
                Le recordamos que Usted es el responsable de mantener la
                estricta confidencialidad de su cuenta y su contraseña, así como
                de cualquier actividad realizada bajo el uso de las mismas. Es
                su única responsabilidad controlar la difusión y uso de su
                contraseña, controlar el acceso y el uso de su cuenta, y
                notificarnos de su deseo de cerrar su cuenta. No seremos
                responsables de cualquier daño o pérdida que surja de falta de
                cumplimiento con esta obligación. La información que proporcione
                el Usuario estará asegurada por una clave de acceso. Dicha clave
                será creada por el Usuario (cuando la Web se lo requiera) y sólo
                conocida por éste; siendo el Usuario el único responsable de
                mantener dicha clave de seguridad en secreto. LC no intentará
                por ningún medio obtener la clave personal de acceso.
              </p>

              <p>
                LC ha adoptado las medidas de seguridad necesarias para proteger
                la información ingresada en sus sitios Web a fin de evitar
                adulteraciones o tratamientos no autorizados. Debido a que
                ninguna transmisión por Internet puede garantizar su íntegra
                seguridad, LC no puede asegurar que toda transmisión de datos o
                información que se realice vía Internet sea totalmente segura,
                razón por la cual el Usuario al ingresar a los sitios Web de LC
                acepta este riesgo, que declara conocer.
              </p>

              <li>
                <b>
                  ¿Qué comunicaciones electrónicas o físicas podrá recibir de LC
                  o sus afiliadas?
                </b>
              </li>

              <p>
                Si Usted ha permitido las comunicaciones sobre las marcas de LC,
                Usted ha consentido de manera genérica a las comunicaciones de
                todas nuestras marcas por lo que cada cierto tiempo,
                adicionalmente a la marca elegida, podrá recibir comunicaciones
                sobre marcas a las cuales Usted específicamente no optó por
                recibir noticias. Solo le comunicaremos sobre aquellas marcas
                que creemos que puedan despertarle un interés particular.
                Cualquiera de dichas comunicaciones le ofrecerá la oportunidad
                en el cuerpo de la misma comunicación, de retirar su suscripción
                de la marca en cuestión y/o a retirar su suscripción de todas
                las comunicaciones sobre las marcas de LC. El material incluido
                en los sitios Web de LC (entendiéndose como material a: textos,
                informes, artículos, datos, comentarios, disertaciones,
                gráficos, logos, iconos, imágenes y archivos de cualquier tipo)
                se encuentran protegidos por las leyes de propiedad intelectual.
                Queda prohibida su publicación, radiodifusión, reedición para
                radiodifusión o publicación y su redistribución directa o
                indirecta por cualquier medio. Queda prohibido su almacenamiento
                total o parcial en computadoras, excepto para uso personal y sin
                fines comerciales. La publicación de información de propiedad de
                terceros será de responsabilidad exclusiva de sus autores. LC no
                asume responsabilidad alguna por cualquier demora, inexactitud,
                error u omisión en el mismo o en la transmisión o entrega de la
                totalidad o parte del mismo, ni por los daños emergentes de
                tales circunstancias. El Usuario tiene prohibido expresamente
                mostrar o usar de ninguna manera las marcas propiedad de LC en
                un sitio o servidor distinto al nuestro, salvo que contase con
                nuestro consentimiento por escrito.
              </p>

              <p>
                La utilización de la información publicada en los sitios Web de
                LC para cualquier finalidad por parte del Usuario será bajo su
                exclusiva responsabilidad o riesgo, LC no se responsabiliza por
                los daños, de cualquier tipo ocasionados por el uso de la
                información publicada.
              </p>

              <p>
                <b>¿Cuáles son las obligaciones de los Usuarios? </b>
                Además del cumplimiento con los Términos y Condiciones y este
                Aviso de Privacidad, los Usuarios se obligan a hacer uso de los
                sitios Web de LC y de todos sus servicios de conformidad con la
                ley, la moral, las buenas costumbres generalmente aceptadas y el
                orden público, obligándose a abstenerse de utilizar los sitios
                Web de LC con fines ilícitos o contrarios a lo aquí establecido,
                que de cualquier modo puedan dañar, inutilizar, sobrecargar o
                deteriorar los sitios Web de LC así como su reputación.
              </p>
              <p>
                Si el Usuario utiliza los sitios Web de LC, ello significa que
                ha leído, comprendido y aceptado los términos antes expuestos.
                En caso de no estar de acuerdo con ellos, el Usuario deberá
                abstenerse de proporcionar dato o información alguna, y sobre
                todo deberá abstenerse de utilizar los sitios Web de LC no
                estando autorizado a hacerlo en tal evento.
              </p>
              <li>
                <b>
                  Casos en los que LC o la Web actúan como proveedores de
                  enlace.
                </b>
              </li>
              <p>
                La Web contiene enlaces o referencias a otros servidores o
                sitios web que se encuentran fuera de nuestro control. Por favor
                tenga en cuenta que no tenemos control sobre tales servidores o
                sitios web y nuestra Política de Privacidad no aplica en dichos
                sitios o servidores. Le instamos a leer las políticas de
                privacidad y los términos y condiciones de uso de tales
                servidores o sitios web a los cuales ingresa. De ser requerido
                por las jurisdicciones en las cuales conducimos nuestros
                negocios, esta Política de Privacidad será complementada con
                requerimientos legales adicionales. LC no será responsable por
                los contenidos y/o la información provista en otras páginas Web
                a las cuales el Usuario pueda acceder mediante la utilización de
                los sitios Web de LC. Tampoco responderá por la autenticidad y/o
                veracidad de la información ni de los datos consignados en las
                mismas.
              </p>

              <li>
                <b>
                  Cookies u otras tecnologías de recolección de información.
                </b>
              </li>

              <p>
                Esta Web utiliza cookies. Las cookies son un pequeño archivo de
                texto que un sitio web le envía a la computadora del visitante
                de dicho sitio web. El sitio podrá utilizar las cookies para
                todas y cada una de las finalidades que se incluyen a
                continuación, cuya enunciación no es taxativa sino enunciativa:
                (i) reconocer a los navegadores de los usuarios registrados, de
                modo que no tengan que registrarse en cada visita para acceder a
                las distintas partes del sitio web; (ii) monitoreo de tráfico,
                que incluye la dirección IP de acceso, tipo de buscador y
                sistema operativo usado, fecha y hora de ingreso, (iii)
                reconocer visitas repetidas con propósitos estadísticos; (iv)
                rastreo anónimo de interacción con publicidad en línea; (v)
                obtención de información sobre hábitos de navegación, entre
                otros. Asimismo, ayudan a mejorar o personalizar la experiencia
                del usuario en el sitio web y a monitorear el uso de dicho sitio
                a fin de realizar diversas comunicaciones que puedan ser de
                interés. Usted podrá negarse a recibir cookies y/o eliminar las
                cookies. Por favor, tenga en cuenta que, en caso de no aceptar
                el uso de cookies, la utilización del sitio puede verse
                afectada; es decir, puede que le resulte difícil o imposible el
                acceso a determinadas partes de nuestra Web.
              </p>

              <li>
                <b>Modificación del Aviso de Privacidad.</b>
              </li>

              <p>
                LC se reserva el derecho, a su sola discreción, de modificar,
                alterar o de otra manera actualizar, esta Política de Privacidad
                en cualquier momento. Las modificaciones entrarán en vigencia
                desde el momento que se indique; en su defecto, se entenderá que
                su aplicación es inmediata. LC no limitara los derechos de los
                Usuarios en virtud de esta política de privacidad sin su
                consentimiento explícito.
              </p>

              <li>
                <b>Disposiciones finales.</b>
              </li>

              <p>
                Nada en esta Política de Privacidad dará lugar a la creación o
                añadirá cualquier derecho o reclamo (bien sea legal o de otra
                índole) que cualquier individuo pueda tener frente a la ley u
                otra fuente, en contra de LC o un tercero, o contra cualquiera
                de sus respectivos directores, responsables, empleados, agentes
                o representantes tampoco la existencia o aplicación de esta
                Política de Privacidad impondrá o añadirá obligación alguna o
                responsabilidad sobre LC distinta a las que ya le corresponden
                por ley.
              </p>
              <li>
                <b> Contacto.</b>
              </li>

              <p>
                Si tiene dudas sobre temas de privacidad, inquietud o queja, por
                favor contáctese a través de un correo electrónico a
                <a href="info.argentina@godrejcp.com">
                  {" "}
                  info.argentina@godrejcp.com
                </a>{" "}
                o por carta a Empedrado 2435, Ciudad Autónoma de Buenos Aires.
              </p>
              <p>
                Los datos de contacto de Laboratorio Cuenca S.A. responsable de
                esta Web son los siguientes:
              </p>
              <ul>
                <li>Denominación social: Laboratorio Cuenca S.A.</li>
                <li>
                  Dirección electrónica de contacto:{" "}
                  <a href="info.argentina@godrejcp.com">
                    {" "}
                    info.argentina@godrejcp.com
                  </a>{" "}
                </li>

                <li>Número de teléfono de contacto: +54 9 11 4501 9900</li>
                <li>
                  Domicilio: Empedrado 2435, Ciudad Autónoma de Buenos Aires,
                  República Argentina.
                </li>
              </ul>
            </ol>
          </p>
        </div>
      );
      break;
    case "ve":
      content = (
        <div className={styles.container}>
          <div className={styles.smaller}>
            <p>
              Este sitio web (la “Web”) es operado por un miembro de Laboratorio
              Cuenca S.A. (el “LC” o “Nosotros” indistintamente), con domicilio
              en Empedrado 2435, Ciudad Autónoma de Buenos Aires, Argentina. Al
              acceder o utilizar esta Web, usted (en adelante “Usted” o el
              “Usuario”) acepta los términos de la presente Política de
              Privacidad de la Web. De esta forma, Usted acepta que podremos
              recoger, usar y revelar su información personal, tal y como se
              describe en esta Política de Privacidad. Esta Política de
              Privacidad está incorporada en, y forma parte de, los Términos y
              Condiciones que regulan el uso que en general usted haga de esta
              Web. Esta Política de Privacidad está destinada a ayudarle a
              comprender qué podemos hacer con la información que Usted nos
              proporciona. Usaremos su información sólo para los propósitos
              señalados a lo largo de este documento. Posiblemente actualicemos
              esta Política de Privacidad cada cierto tiempo. Le recomendamos
              que revise regularmente esta página web a fin de asegurarse que
              conoce la versión más reciente de la Política de Privacidad.
            </p>
            <p>
              Se informa al Usuario que como regla general no resulta ser
              necesario el ingreso de sus datos personales para navegar en los
              sitios Web de LC. En caso de ser necesario ingresar sus datos
              personales (cuando así sea requerido por el sitio) para poder
              acceder al mismo, LC utilizará y protegerá sus datos personales
              mientras usted navegue en los sitios Web de LC. En caso de
              disconformidad con la Política de Privacidad adoptada por LC, no
              deberá utilizar nuestro servicio. la utilización del servicio
              implica la aceptación y conocimiento automático de los Términos
              Generales de Uso (ver Términos Generales de Uso con link) y de la
              Política de Privacidad que a continuación se le hace saber:
            </p>
          </div>
          <ol>
            <li>
              En cumplimiento con la normativa vigente de Protección de Datos
              Personales (Ley N° 25.326 y disposiciones complementarias), LC
              dará un tratamiento adecuado a los datos personales de quienes
              utilicen los servicios brindados por LC en sus sitios Web.
            </li>
            <li>
              Toda aquella información que el Usuario ingrese voluntariamente
              durante la utilización del servicio estará bajo las normas de
              confidencialidad. Dicha información recibirá una protección
              adecuada y será utilizada sólo para las finalidades de los
              servicios que LC brinda.
            </li>
            <li>
              LC sólo requerirá a los Usuarios para su registración datos como:
              apellido, nombre, documento de identidad, fecha de nacimiento,
              dirección de correo electrónico, etc., absteniéndose de solicitar
              datos sensibles al Usuario (Art. 7 de la ley N° 25.326). LC solo
              procederá a registrar los datos personales una vez que el Usuario
              haya prestado su consentimiento libre, expreso e informado para
              los fines solicitados. Usted podrá solicitar el retiro o bloqueo
              total o parcial de su información personal de las bases de datos,
              enviando a LC un correo electrónico a info.argentina@godrejcp.com
              o una carta a Empedrado 2435, Ciudad Autónoma de Buenos Aires. Por
              favor, tenga en cuenta que la información que anuncie en o a
              través de las áreas públicas de la Web (por ejemplo, todo tipo de
              conversaciones en línea, carteleras informativas o grupos de
              discusión) son generalmente accesibles a otros terceros, quienes
              podrían copiar y usar la información, pudiendo derivarse en correo
              no solicitado por el Usuario. Los usuarios de nuestra Web deberán
              tener precaución al momento de suministrar información personal en
              las áreas públicas de la Web.
            </li>
            <li>
              El Usuario garantiza la veracidad y certeza de los datos
              personales que ingresa en los sitios Web de LC, asumiendo la
              correspondiente responsabilidad en caso de que los mismos resulten
              inexactos. LC, en su condición de destinatario de los datos, no
              asume ninguna responsabilidad en caso de existir inexactitud en
              los datos personales ingresados por el Usuario.
            </li>
            <li>
              El Usuario podrá modificar o actualizar en cualquier momento la
              información relativa a los datos personales ingresados en los
              sitios Web de LC, los cuales formarán parte de un archivo que
              contendrá su perfil. Asimismo, el Usuario podrá solicitar la
              rectificación y/o baja de sus datos personales comprometiéndose
              LC, en caso de corresponder, a realizar las gestiones tendientes a
              rectificar y/o procesar la baja en un plazo máximo de diez (10)
              días hábiles contados desde la recepción del reclamo por parte del
              Usuario.
            </li>
            <li>
              Se entenderá que el Usuario al ingresar los datos referidos
              autoriza automáticamente a LC a utilizar dichos datos para
              proveerle adecuadamente un servicio personalizado y acorde a sus
              necesidades, así como para recibir correspondencia de LC.
              Asimismo, además de los casos mencionados precedentemente, cada
              cierto tiempo podremos revelar información personal para: a.
              Nuestros proveedores de servicios y subcontratistas, incluyendo
              nuestros afiliados, que realizan funciones por nuestra cuenta o
              nos proveen servicios tales como almacenamiento y entrega;
              mercadeo y publicidad; procesamiento de datos; desarrollo de
              programas informáticos; manejo y mantenimiento de nuestra Web;
              información de tecnología y servicios de oficina; proveedores de
              servicios profesionales además de legales, contables y de
              auditoría; y de ninguna manera tales proveedores recolectarán,
              usarán o revelarán dicha información personal para cualquier otro
              propósito distinto al de realizar las funciones o proveernos los
              servicios que le requerimos o sean requeridos por ley; b. Una
              persona o entidad, incluyendo nuestros afiliados, a los cuales
              debe serles revelado para el cumplimiento de uno o varios de los
              propósitos descritos bajo el punto titulado "Propósitos para
              recolectar información personal"; c. Una persona que, bajo nuestro
              juicio razonable, es la autorizada o señalada como agente legal
              suyo, para buscar información relativa a su persona; o d.
              Cualquier tercera persona que cuente con su consentimiento o donde
              la revelación sea requerida por ley. LC podrá utilizar la
              información proporcionada por el Usuario a fin de ofrecerle un
              servicio publicidad selectiva o contenidos que puedan llegar a
              serle de interés, si es que el Usuario aceptó recibirlos.
            </li>
            <li>
              No tenemos control sobre las comunicaciones que podamos tener a
              través de correo electrónico, motivo por el cual recomendamos que
              no incluya en ellos información confidencial, de propiedad
              exclusiva o delicada. En el hipotético caso en que consideremos
              que la seguridad de su información personal que se encuentra en
              nuestra posesión o bajo nuestro control, puede encontrarse
              comprometida, podremos notificarle al respecto. Le recordamos que
              Usted es el responsable de mantener la estricta confidencialidad
              de su cuenta y su contraseña, así como de cualquier actividad
              realizada bajo el uso de las mismas. Es su única responsabilidad
              controlar la difusión y uso de su contraseña, controlar el acceso
              y el uso de su cuenta, y notificarnos de su deseo de cerrar su
              cuenta. No seremos responsables de cualquier daño o pérdida que
              surja de falta de cumplimiento con esta obligación. La información
              que proporcione el Usuario estará asegurada por una clave de
              acceso. Dicha clave será creada por el Usuario (cuando la Web se
              lo requiera) y sólo conocida por éste; siendo el Usuario el único
              responsable de mantener dicha clave de seguridad en secreto. LC no
              intentará por ningún medio obtener la clave personal de acceso.
            </li>
            <li>
              Si Usted ha permitido las comunicaciones sobre las marcas de LC,
              Usted cada cierto tiempo recibirá comunicaciones sobre marcas a
              las cuales específicamente no optó por recibir noticias. Solo le
              comunicaremos sobre aquellas marcas que creemos que puedan
              despertarle un interés particular. Cualquiera de dichas
              comunicaciones le ofrecerá la oportunidad de retirar su
              suscripción de la marca en cuestión y/o a retirar su suscripción
              de todas las comunicaciones sobre las marcas de LC. Si tiene dudas
              sobre temas de privacidad, inquietud o queja, por favor contáctese
              a través de un correo electrónico a info.argentina@godrejcp.com o
              una carta a Empedrado 2435, Ciudad Autónoma de Buenos Aires.
            </li>
            <li>
              LC ha adoptado las medidas de seguridad necesarias para proteger
              la información ingresada en sus sitios Web a fin de evitar
              adulteraciones o tratamientos no autorizados. Debido a que ninguna
              transmisión por Internet puede garantizar su íntegra seguridad, LC
              no puede asegurar que toda transmisión de datos o información que
              se realice vía Internet sea totalmente segura, razón por la cual
              el Usuario al ingresar a los sitios Web de LC acepta este riesgo,
              que declara conocer.
            </li>
            <li>
              El material incluido en los sitios Web de LC (entendiéndose como
              material a: textos, informes, artículos, datos, comentarios,
              disertaciones, gráficos, logos, iconos, imágenes y archivos de
              cualquier tipo) se encuentran protegidos por las leyes de
              propiedad intelectual. Queda prohibida su publicación,
              radiodifusión, reedición para radiodifusión o publicación y su
              redistribución directa o indirecta por cualquier medio. Queda
              prohibido su almacenamiento total o parcial en computadoras,
              excepto para uso personal y sin fines comerciales. La publicación
              de información de propiedad de terceros será de responsabilidad
              exclusiva de sus autores. LC no asume responsabilidad alguna por
              cualquier demora, inexactitud, error u omisión en el mismo o en la
              transmisión o entrega de la totalidad o parte del mismo, ni por
              los daños emergentes de tales circunstancias.
            </li>
            <li>
              La utilización de la información publicada en los sitios Web de LC
              para cualquier finalidad por parte del Usuario será bajo su
              exclusiva responsabilidad o riesgo, LC no se responsabiliza por
              los daños, de cualquier tipo ocasionados por el uso de la
              información publicada.
            </li>
            <li>
              LC no será responsable por los contenidos y/o la información
              provista en otras páginas Web a las cuales el Usuario pueda
              acceder mediante la utilización de los sitios Web de LC. Tampoco
              responderá por la autenticidad y/o veracidad de la información ni
              de los datos consignados en las mismas.
            </li>
            <li>
              Los Usuarios se obligan a hacer uso de los sitios Web de LC y de
              todos sus servicios de conformidad con la ley, los términos
              generales de uso, esta política de privacidad, la moral, las
              buenas costumbres generalmente aceptadas y el orden público,
              obligándose a abstenerse de utilizar los sitios Web de LC con
              fines ilícitos o contrario a lo aquí establecido, que de cualquier
              modo puedan dañar, inutilizar, sobrecargar o deteriorar los sitios
              Web de LC así como su reputación. Esta declaración de privacidad
              está sujeta a los términos generales de uso de LC, con lo cual
              constituye un acuerdo legal entre el Usuario y LC.
            </li>
            <li>
              Si el Usuario utiliza los sitios Web de LC, ello significa que ha
              leído, comprendido y aceptado los términos antes expuestos. En
              caso de no estar de acuerdo con ello, el Usuario no deberá
              proporcionar dato ni información alguna, ni utilizar los sitios
              Web de LC porque no está autorizado a hacerlo.
            </li>
            <li>
              LC se reserva el derecho, a su sola discreción, de modificar,
              alterar o de otra manera actualizar, esta Política de Privacidad
              en cualquier momento. Las modificaciones entrarán en vigencia
              desde el momento que se indique; en su defecto, se entenderá que
              su aplicación es inmediata. LC no limitara los derechos de los
              Usuarios en virtud de esta política de privacidad sin su
              consentimiento explícito.
            </li>
            <li>
              Esta Web utiliza cookies. Las cookies son un pequeño archivo de
              texto que un sitio web le envía a la computadora del visitante de
              dicho sitio web. El sitio podrá utilizar las cookies para todas y
              cada una de las finalidades que se incluyen a continuación, cuya
              enunciación no es taxativa sino enunciativa: (i) reconocer a los
              navegadores de los usuarios registrados, de modo que no tengan que
              registrarse en cada visita para acceder a las distintas partes del
              sitio web; (ii) monitoreo de tráfico, que incluye la dirección IP
              de acceso, tipo de buscador y sistema operativo usado, fecha y
              hora de ingreso, (iii) reconocer visitas repetidas con propósitos
              estadísticos; (iv) rastreo anónimo de interacción con publicidad
              en línea; (v) obtención de información sobre hábitos de
              navegación, entre otros. Asimismo, ayudan a mejorar o personalizar
              la experiencia del usuario en el sitio web y a monitorear el uso
              de dicho sitio a fin de realizar diversas comunicaciones que
              puedan ser de interés. Usted podrá negarse a recibir cookies y/o
              eliminar las cookies. Por favor, tenga en cuenta que, en caso de
              no aceptar el uso de cookies, la utilización del sitio puede verse
              afectada; es decir, puede que le resulte difícil o imposible el
              acceso a determinadas partes de nuestra Web.
            </li>
            <li>
              La Web puede contener enlaces o referencias a otros servidores o
              sitios web que se encuentran fuera de nuestro control. Por favor
              tenga en cuenta que no tenemos control sobre tales servidores o
              sitios web y nuestra Política de Privacidad no aplica en dichos
              sitios o servidores. Le instamos a leer las políticas de
              privacidad y los términos y condiciones de uso de tales servidores
              o sitios web a los cuales ingresa. De ser requerido por las
              jurisdicciones en las cuales conducimos nuestros negocios, esta
              Política de Privacidad será complementada con requerimientos
              legales adicionales. Nada en esta Política de Privacidad dará
              lugar a la creación o añadirá cualquier derecho o reclamo (bien
              sea legal, equitativo o de otra índole) que cualquier individuo
              pueda tener frente a la ley u otra fuente, en contra de la Bodega
              o un tercero, o contra cualquiera de sus respectivos directores,
              responsables, empleados, agentes o representantes tampoco la
              existencia o aplicación de esta Política de Privacidad impondrá o
              añadirá obligación alguna o responsabilidad sobre LC distinta a
              las que ya le corresponden por ley. El Usuario tiene prohibido
              expresamente mostrar o usar de ninguna manera las marcas propiedad
              de LC en un sitio o servidor distinto al nuestro, salvo que
              contase con nuestro consentimiento por escrito.
            </li>
          </ol>
        </div>
      );
      break;
    default:
      content = "";
  }

  return <InfoContainer content={content} />;
};

export default PoliticaPrivacidad;
