/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { SvgDB } from "../../@ui";
import MenuProductos from "./MenuProductos";

import { useHistory } from "react-router-dom";

import MenuMobile from "./MenuMobile";

import { useSelector } from "react-redux";

import { MenuData } from "./MenuData";
import Veil from "../../@ui/Veil/Veil";
import { BREAKPOINT } from "../../../constants";
import useResizeWidth from "../../../hooks/useResizeWidth";
import useProducts from "../../../hooks/useProducts";

import { COUNTRY } from "../../../constants.js";
import { eshopsByCountry } from "../../Data/index.js";

const Header = () => {

  const rizeWidth = useResizeWidth();
  const history = useHistory();
  const { push } = history;
  const busquedaContainer = useRef(null);
  const busquedaCrossContainer = useRef(null);
  const [pathname, setPathname] = useState("");
  const [menumobile, setMenuMobile] = useState(false);
  const [menuproductos, setMenuProductos] = useState(false);
  const lupaIcon = useRef(null);

  // Actualizar pathname cuando cambia URL
  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const HandleClick = (open, close) => {
    document.querySelector(`.${open}`).style.display = "flex";
    document.querySelector(`.${close}`).style.display = "none";
  };

  const { data, productsData } = useProducts();
  const [menuitems, setMenuItems] = useState({});
  const [completedmenu, setCompletedMenu] = useState(false);
  const { loading, message } = useSelector((state) => state.productos);

  const kitColors = [
    {
      slug: "keratin-color-kit",
      color: "color yellow"
    },
    {
      slug: 'kit-issue-sin-amoniaco',
      color: "color green-light"
    },
    {
      slug: "kit-eco-keratina",
      color: "color yellow"
    },
    {
      slug: "kit-decolorante-issue",
      color: "color green-strong"
    },
    {
      slug: "issue-kit-decolorante-tonalizador",
      color: "color green-strong"
    },
    {
      slug: "kit-brillo-extremo",
      color: "color red"
    },
  ];

  
  useEffect(() => {
    let isMounted = true;

    if (!completedmenu && !loading && !message && productsData.length > 0  ) {

      const coloracion = {
        "Coloración Sin Amoniaco":
          COUNTRY !== "py" &&
          COUNTRY !== "ec"
            ? [{ nombre: "Sin Amoniaco", class: "title color green-light" }]
            : [],
        "Coloración Semi Permanente": [
          { nombre: "Semi Permanente", class: "title color yellow" },
        ],
        "Coloración Permanente": [
          { nombre: "Permanente", class: "title color red" },
        ],
        "Coloracion Eco Keratina":
        COUNTRY !== "cl" &&
        COUNTRY !== "pe" ?
        [
          { nombre: "Eco Keratina", class: "title color yellow" },
        ] : [],
      };

      const complementos = {
        "Kit Decolorante Sin Amoniaco": [],
        "Polvos Decolorantes": [
          { nombre: "Polvos Decolorantes", class: "title color green-strong" },
        ],
        Activador: [],
        "Ablandador de canas": [],
      };


    const listProductsByCategory = ({products=[], categoryName='', subCategories={}}) => {

      let productos = [];

      switch (categoryName) {

        //!COLORACION
        case "Coloración":
          products.map((producto, i )=> {
            for (let i = 0; i < producto.categoria.length; i++){
              if(producto.categoria[i].nombre === categoryName){
                if (subCategories[producto.tipo_de_producto]) {
                  const prodcopy = { ...producto };
                  subCategories[producto.tipo_de_producto].push(prodcopy);
                }
              }
            }
          });
          break;

        //?COMPLEMENTOS
        case "Complementos":
          products.map(producto => {
            for (let i = 0; i < producto.categoria.length; i++){
              if(producto.categoria[i].nombre === categoryName){

                if ( producto.nombre.startsWith("Polvo") ) {
                  subCategories["Polvos Decolorantes"].push(producto);
                }

                if ( producto.slug === "kit-issue-sin-amoniaco" ){
                  subCategories["Kit Decolorante Sin Amoniaco"].push(producto);
                }

                if ( producto.slug === "activador-en-crema" ) {
                  producto.class = "title color green-strong";
                  subCategories["Activador"].push(producto);
                }
                
                if ( producto.slug === "oxigenta-en-crema" ) {
                  producto.class = "title color green-strong";
                  subCategories["Activador"].push(producto);
                }

                if ( producto.slug === "ablandador-de-canas" ) {
                  producto.class = "title color green-strong";
                  subCategories["Ablandador de canas"].push(producto);
                }

                if ( producto.slug === "kit-decolorante-issue" ) {
                  subCategories["Polvos Decolorantes"].push(producto);
                }
            }
            }
          });
        break;
        
        //*KITS
        case "Kits":          
          kitColors.map(kits => {
            products.map(producto => {
              for (let i = 0; i < producto.categoria.length; i++){
                if(producto.categoria[i].nombre === categoryName){
                  if (producto.slug === kits.slug){
                    const prodcopy = { ...producto };
                    prodcopy.class = kits.color;
                    MenuData[categoryName].productos.push(prodcopy);
                  }
                }
              }
            })
          })
        break;
      
        default:
          break;
      }
    
    
      return productos;
    }
     
    listProductsByCategory({
      products:productsData,
      categoryName:"Coloración",
      subCategories:coloracion
    });

    listProductsByCategory({
      products:productsData,
      categoryName:"Complementos",
      subCategories:complementos,
    });


    listProductsByCategory({
      products:productsData,
      categoryName:"Kits",
      subCategories:MenuData,
    });
    
      // Ordenar a mano algunos items: orden_custom es un int que indica el ordenamiento relativo del item,
      // el cual es de menor a mayor (i.e. el item con orden_custom 0 aparece antes que el item
      // con orden_custom 1)
      coloracion["Coloración Permanente"] = coloracion[
        "Coloración Permanente"
      ].sort((a, b) => a.orden_custom - b.orden_custom);

      coloracion["Coloracion Eco Keratina"] = coloracion[
        "Coloracion Eco Keratina"
      ].sort((a, b) => a.orden_custom - b.orden_custom);

      complementos["Polvos Decolorantes"] = complementos[
        "Polvos Decolorantes"
      ].sort((a, b) => a.orden_custom - b.orden_custom);

      MenuData["Kits"].productos = MenuData["Kits"].productos.sort(
        (a, b) => a.orden_menu_kits - b.orden_menu_kits
      );

      MenuData["Coloración"].productos.push(
        ...coloracion["Coloración Permanente"],
        ...coloracion["Coloracion Eco Keratina"],
        ...coloracion["Coloración Sin Amoniaco"],
        ...coloracion["Coloración Semi Permanente"],
      );
      MenuData["Complementos"].productos.push(
        ...complementos["Kit Decolorante Sin Amoniaco"],
        ...complementos["Polvos Decolorantes"],
        ...complementos["Ablandador de canas"],
        ...complementos["Activador"]
      );

      //!!ACA ARRANCA CUIDADO DEL CABELLO ICT-33

      const listarProductosCuidadoDelCabello = (products=[], categoryName='') => {

        let productos = [];
      
        products.map(producto => {
          if(producto.categoria[0].nombre === categoryName){
            productos.push({
              nombre: (
                <Link
                  onClick={() => setMenuProductos(false)}
                  to={`/${COUNTRY}/productos/${producto.slug}`}
                >
                  {producto.nombre}
                </Link>
              ),
            })
          }
        });
      
        return productos;
      }

      MenuData["Cuidado del cabello"] = {
        nombre: "Cuidado del cabello",
        slug: "cuidado-del-cabello",
        productos: listarProductosCuidadoDelCabello(productsData, "Cuidado del cabello")
      }            

      setCompletedMenu(true);
      setMenuItems(Object.values(MenuData));
    }

    return () => { isMounted = false };
  }, [completedmenu, productsData, loading, message]);

  const searchinput = useRef(null);
  const HandleSeachSubmit = (e) => {
    e.preventDefault();

    const value = searchinput.current.value;

    if (!searchinput || !value) return;

    searchinput.current.value = "";

    HandleClick("lupa", "busqueda-container");

    push({
      pathname: "/productos",
      search: `?query=${value}`,
      state: { search: value },
    });
  };

  // Ocultar input de búsqueda si se clickeó cualquier otra cosa distinta de la lupa
  useEffect(() => {
    function hideBusquedaOnOutsideClick(event) {
      // Si el click fue en la lupita o en el botón de cerrar, no hacer nada
      if (lupaIcon.current.contains(event.target)) {
        return;
      }

      if (busquedaCrossContainer.current.contains(event.target)) {
        HandleClick("lupa", "busqueda-container");
        return;
      }

      const isClickInside = busquedaContainer.current.contains(event.target);

      if (!isClickInside) {
        const display = busquedaContainer.current.style.display;

        if (display === "flex") {
          HandleClick("lupa", "busqueda-container");
        }
      }
    }

    document.addEventListener("click", hideBusquedaOnOutsideClick);

    // limpiar event listener cuando se desmonta el componente
    return () =>
      document.removeEventListener("click", hideBusquedaOnOutsideClick);
  }, []);

  // Ocultar input de búsqueda si se desplieg el menú de productos
  useEffect(() => {
    if (menuproductos) {
      HandleClick("lupa", "busqueda-container");
    }
  }, [menuproductos]);

  const eshops = eshopsByCountry[COUNTRY];

  return (
    <>
      <MenuMobile
        completedmenu={completedmenu}
        menuitems={menuitems}
        menumobile={menumobile}
        setMenuMobile={setMenuMobile}
      />

      <Veil enabled={menuproductos} />

      <div className="flex navbar" style={{ position: "sticky", top: "0" }}>
        <div className="logo">
          <div
            className="mobile-hamburguer-container"
            onClick={() => setMenuMobile(!menumobile)}
          >
            <div className="mobile-hamburguer-content">
              <div className="ham-1"></div>
              <div className="ham-2"></div>
              <div className="ham-3"></div>
            </div>
          </div>

          <Link to={`/${COUNTRY}/`}>{SvgDB["logo"]}</Link>
        </div>
        <div className="menu">
          <ul className="flex">
            <li
              onMouseEnter={() => setMenuProductos(true)}
              className={`navbar-item ${
                (menuproductos || pathname === "/productos") && "active"
              }`}
            >
              <Link
                onClick={() => setMenuProductos(false)}
                to={`/${COUNTRY}/productos`}
              >
                Productos
              </Link>
            </li>
            <li
              className={`navbar-item ${
                pathname === "/tutoriales" && "active"
              }`}
            >
              <Link to={`/${COUNTRY}/tutoriales`}>Tutoriales</Link>
            </li>
            {rizeWidth > BREAKPOINT.TABLET && (
              <li className="navbar-item">
                <a href="https://contacto.godrejlatam.com/" target="_blank" rel="noopener noreferrer">
                  Contacto
                </a>
              </li>
            )}
            <li onClick={() => HandleClick("busqueda-container", "lupa")}>
              <div className="lupa-container">
                <div
                  ref={lupaIcon}
                  onClick={() => HandleClick("busqueda-container", "lupa")}
                  className="lupa"
                >
                  {SvgDB["lupa"]}
                </div>

                <div className="busqueda-container" ref={busquedaContainer}>
                  <form onSubmit={HandleSeachSubmit}>
                    <button className="busqueda-svg-container">
                      {SvgDB["lupa"]}
                    </button>

                    <input
                      placeholder="Búsqueda aquí"
                      type="text"
                      ref={searchinput}
                    />
                  </form>

                  <div
                    onClick={() => HandleClick("lupa", "busqueda-container")}
                    className="cross busqueda-cross-container"
                    ref={busquedaCrossContainer}
                  ></div>
                </div>
              </div>
            </li>
            <li className={`${COUNTRY !== "bo" && COUNTRY !== "ve" ? "dark" : "hide-bol"}`}>
              <a style={{ color: "white" }}>eShop</a>
              <div className="eshop-dropdown">
                {eshops.map((eshop, index) => (
                  <a
                    key={index}
                    href={eshop.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="dropdown-item">
                      <img
                        className={`${
                          COUNTRY === "py" ? "salonmarket-logo" : ""
                        }`}
                        style={eshop.extraStyles}
                        src={eshop.img}
                        alt=""
                      />
                    </div>
                  </a>
                ))}
              </div>
            </li>
          </ul>
        </div>
        {!menuproductos ? (
          ""
        ) : (

          <MenuProductos
            completedmenu={completedmenu}
            menuitems={menuitems}
            setMenuProductos={setMenuProductos}
          />
        )}
      </div>
    </>
  );
};

export default Header;
