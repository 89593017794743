// Este archivo contiene funciones de uso general

// Scrollea suavemente hasta el elemento con id `elementId`; `topOffset` es un offset
// opcional para dejar espacio por encima (útil para que por ejemplo un header no te tape
// el elemento)
export function scrollTo(elementId, topOffset = 0) {
  const element = document.getElementById(elementId);
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - topOffset;
  
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
}
 
// Scrollea suavamente hasta la parte superior de la pantalla
export function scrollToTop() {
  window.scrollTo({ behavior: 'smooth', top: 0 });
}

// Toma una string de tono (e.g. N-5-13) y la devuelve parseada en nombre y prefijo.
// Ejemplos:
// getToneNameAndPrefix('N-5-13') -> { name: '5', prefix: '13' }
// getToneNameAndPrefix('N-1') -> { name: '1', prefix: '' }
// getToneNameAndPrefix('crazy-yellow') -> { name: 'yellow', prefix: '' }
export function getToneNameAndPrefix(nombreTono) {

  // N-1, N-2, etc. found[1] son los dígitos
  let found = nombreTono.match(/^N-(\d+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: '' };
  }

  // N-1A, etc. found[1] son los dígitos, found[2] las letras
  found = nombreTono.match(/^N-(\d+)([A-Z]+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: `${found[2]}` };
  }

  // N-5-13, etc. found[1] es el primer grupo de dígitos, found[2] el segundo
  found = nombreTono.match(/^N-(\d+)-(\d+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: `${found[2]}` };
  }

  // SA-1, etc. found[1] son los dígitos
  found = nombreTono.match(/^SA-(\d+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: '' };
  }

  // SA-6-32, etc. found[1] es el primer grupo de dígitos, found[2] el segundo
  found = nombreTono.match(/^SA-(\d+)-(\d+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: `${found[2]}` };
  }

  // dec-gris, dec-rubio, etc. found[1] es 'gris', 'rubio', etc.
  found = nombreTono.match(/^dec-([a-z]+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: '' };
  }

  // crazy-yellow, crazy-blue, etc. found[1] es 'yellow', 'blue', etc.
  found = nombreTono.match(/^crazy-([a-z]+)$/);

  if (found) {
    return { name: `${found[1]}`, prefix: '' };
  }

  return nombreTono;
}

// Elimina diacríticos excepto la ñ
export function eliminarDiacriticos(texto) {
  return texto
      .normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
      .normalize();
}

export function sanitizeName(name) {
  return eliminarDiacriticos(name).toLowerCase().trim();
}
