import React from 'react';
import './LoadingProducts.scss'



const LoadingProducts = ({ cantidad = 3 }) => {

    return (
        <div className="loadingproducts-container">

            {[...Array(cantidad).keys()].map(el => (
                <div key={el} className={`loadingproduct-container animate-filter no-m-${cantidad}`}>
                    <div className="loadingproduct-fakeimg-container"></div>
                    <div className="loadingproduct-faketext-container"></div>
                </div>
            ))}
        </div>

    )
}

export default LoadingProducts;