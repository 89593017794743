import React, { useEffect } from 'react';
import { scrollToTop } from '../../utils';
import styles from './LoUltimo.module.scss';
import mainSpot from '../../static/img/loultimo/mainspot-preview.jpg';
import madreHija from '../../static/img/loultimo/madre-hija.jpg';
import pareja from '../../static/img/loultimo/pareja.jpg';
import bicicleta from '../../static/img/loultimo/bicicleta.jpg';
import crazyColors from '../../static/img/loultimo/bg-crazy-colors.jpg';
import senora from '../../static/img/loultimo/senora.jpg';
import ImageModalVideo from '../ImageModalVideo/ImageModalVideo';
import UltimosProductos from '../UltimosProductos/UltimosProductos';


// FIXME: Los videos y previews de este componente deberían levantarse del backend
const LoUltimo = () => {

  useEffect(() => {
    scrollToTop();
  }, []);


  return (
    <div className={styles.container}>
      <div className={styles.mainSpotContainer}>
        <ImageModalVideo
          src={mainSpot}
          alt="main-spot"
          videoId="V3SEjP8oOZo"
        />
        <div className={styles.textContainer}>
          <h1>Relaciones</h1>
          <p>A lo largo de nuestra vida pasamos por muchas relaciones. Algunas más largas, otras más cortas. Algunas nos hacen llorar y casi todas nos hacen reir. Tenemos relaciones que cambian todo el tiempo, por eso creamos un producto para cada etapa de la vida. Porque de todas las relaciones, la única que dura para siempre es la que tenemos con nuestro pelo.</p>
        </div>
      </div>

      <div className={styles.otherVideosContainer}>
        <ImageModalVideo
          src={madreHija}
          alt="madre-hija"
          videoId="zbzDBBMCtIo"
        />

        <ImageModalVideo
          src={pareja}
          alt="pareja"
          videoId="DjFtWEi9DWE"
        />

        <ImageModalVideo
          src={bicicleta}
          alt="bicicleta"
          videoId="3nrCvrN2Qxc"
        />
      </div>

      <div className={styles.productsContainer}>
        <UltimosProductos />
      </div>

      <div className={styles.cambiarContainer}>
        <div className={styles.cambiarTextContainer}>
          <h1>Cambiar todo el tiempo</h1>
          <p><span className={styles.highlight}>Issue Crazy Colors</span> le da lugar a la historia de una generación.</p>
          <p>Una generación con ganas de cambiar todo el tiempo, de hablar menos y de hacer más. Hacen lo que quieren, cuando quieren, por sus propios medios, sin pedir opiniones ni dar explicaciones.</p>
          <p><span className={styles.highlight}>#NosPintaCambiar</span> es un mensaje, una voz para esta generación de gente auténtica que llega dispuesta a cambiar todo, todo el tiempo.</p>
          <p>Porque ellos son así, hacen lo que quieren, porque lo sienten, porque les pinta.</p>

          <div className={styles.conocerMasContainer}>
            <h2><a href="https://www.issuecrazycolors.com/" target="_blank" rel="noopener noreferrer">Conocer más</a></h2>
            <div className={styles.conocerMasBar} />
          </div>
        </div>

        <ImageModalVideo
          src={crazyColors}
          alt="crazy-colors"
          videoId="6aJsLTOsy_s"
        />
      </div>

      <div className={styles.testeadoContainer}>
        <ImageModalVideo
          src={senora}
          alt="señora"
          videoId="NTQE-cJE2_M"
        />

        <div className={styles.testeadoTextContainer}>
          <h1>Testeado en todo eso que nos pasa</h1>
          <p>Issue entiende a las mujeres como solo una amiga puede hacerlo. Su motor es lograr que ellas tengan una relación de complicidad con su pelo, saludable y positiva, que las motive y empodere.</p>
          <p>Cuando una mujer busca un cambio de color en el pelo sin duda está relacionado con su estado de ánimo ya que el pelo es el reflejo de todo eso que les pasa.</p>
          <p>“Eso que nos pasa” conecta con las mujeres como solo una amiga puede hacerlo.</p>
          <p>Eso que nos pasa es eso que nos pasa a todas.</p>
          <span><a href="https://twitter.com/hashtag/esoquenospasa" target="_blank" rel="noopener noreferrer" className={styles.hashtag}>#EsoQueNosPasa</a></span>
        </div>
      </div>
    </div>
  );
}

export default LoUltimo;
