import React from 'react';
import './LoadingMenu.scss'

const LoadingMenu = () => {
    return (
        <div className="loadingmenu-container animate-filter">


            <div className="loadingmenu-content">

                <div className="loadingmenu__left">

                    {[...Array(4).keys()].map(el => (
                        <div key={el} className="loading-column">
                            <div className="loading-li"></div>
                            <div className="loading-li"></div>
                            <div className="loading-li"></div>
                            <div className="loading-li"></div>
                        </div>
                    ))}

                </div>

                <div className="loadingmenu__right">
                    <div className="loadingmenu-img"></div>
                </div>

            </div>


        </div>
    );
}

export default LoadingMenu;