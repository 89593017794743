import React from "react";
import { BannerCategory } from "../../@ui";

import { COUNTRY } from "../../../constants";

const bannerdata = {
  ar: [
    [
      {
        title: "Complementos",
        titleClassName: "color-dark medium",
        descrClassName: "color-dark",
        line: true,
        description:
          "Prepará tu pelo para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos, los activadores en crema y el ablandador de canas Issue.",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/PolvosDecolorantes_vertical.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/PolvosDecolorantes_vertical.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/PolvosDecolorantes_horizontal.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/PolvosDecolorantes_horizontal.webp"),
        },
      },
    ],
    [
      {
        //title: 'Coloración Permanente Sín Amoníaco',
        title: "Brillo Extremo",
        titleClassName: "color-dark line bold",
        descrClassName: "color-dark",
        description: "Ahora podés lucir tu color con un brillo deslumbrante!  Nuestra coloración Brillo Extremo con aceite de Argán refuerza la fibra capilar durante la coloración. ",
        // description:
        //   "La coloración en crema Issue, con su exclusiva fórmula, combina colores radiantes de larga duración, con 100% de cobertura de canas. Conocé nuestras líneas permanentes, sin amoníaco, semi permanente & fantasía.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Brilloextremo_Mobile.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Brilloextremo_Mobile.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/Brilloextremo_Desktop.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/Brilloextremo_Desktop.webp"),
        },
      },
    ],
    [
      {
        //title: 'La decoloración que estabas necesitando',
        title: "Crazy Colors",
        titleClassName: "color-purple-dark line",
        descrClassName: "color-purple-dark",
        description:"Animate a los colores fantasía! Si te pinta cambiar, tenemos seis tonos para que juegues con tu pelo.",
        // description:
        //   "Prepará tu pelo para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos, los activadores en crema y el ablandador de canas Issue.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Complementos_Mobile.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Complementos_Mobile.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/Complementos_Desktop.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/Complementos_Desktop.webp"),
        },
      },
    ],
    [
      {
        //title: 'Issue Kit 3D GLOSS',
        title: "KITS",
        titleClassName: "color-orange-light line",
        descrClassName: "color-orange-light",
        description: "Coloración en crema con tratamiento MIX y POST color. Con keratina vegetal, más resistencia y más restauración.",
        // description:
        //   "Los kits de Issue incluyen todo lo que necesitás para tu coloración. Conocé nuestras cartas de colores y animate a cambiar de look!",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Ecokeratina_Mobile.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Ecokeratina_Mobile.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/Ecokeratina_Desktop.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/Ecokeratina_Desktop.webp"),
        },
      },
    ],
    [
      {
        //title: 'Tratamiento con Keratina',
        title: "CUIDADO DEL CABELLO",
        titleClassName: "color-orange-light line",
        descrClassName: "color-orange-light",
        description:
          "La línea de cuidado del cabello de Issue ofrece los mejores tratamientos que reparan el cabello, brindándole mayor suavidad, resistencia al quiebre y con un brillo único.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Tratamientos_Mobile.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Tratamientos_Mobile.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/Tratamientos_Desktop.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/Tratamientos_Desktop.webp"),
        },
      },
    ],
  ],
  pe: [
    [
      {
        title: "Nuestros Productos",
        titleClassName: "color-grey-primary medium",
        descrClassName: "color-grey-primary",
        line: true,
        description:
          "La belleza del color combinada con la fuerza de su duración, ofreciendo la mejor calidad al mejor precio.",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-general-productos.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-general-productos.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-General-productos.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-General-productos.webp"),
        },
      },
    ],
    [
      {
        //title: 'Coloración Permanente Sín Amoníaco',
        title: "COLORACIÓN",
        titleClassName: "color-white-primary line bold",
        descrClassName: "color-white-primary",
        description:
          "Tenemos un producto para cada tipo de cabello. Encuentra el que más te guste y consigue colores brillantes y duraderos a un precio que te conviene.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Coloración-mobile_Peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/mobile-Coloración.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/coloracion-desktop_Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-coloracion.webp'),
        },
      },
    ],
    [
      {
        //title: 'La decoloración que estabas necesitando',
        title: "COMPLEMENTOS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Prepara tu cabello para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos y los oxidantes en crema Issue.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Decoloración-mobile_Peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/mobile-Decoloración.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/decoloracion-desktop-Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-decoloracion.webp'),
        },
      },
    ],
    [
      {
        //title: 'Issue Kit 3D GLOSS',
        title: "KITS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Los kits de Issue incluyen todo lo que necesitás para tu coloración. Conocé nuestras cartas de colores y animate a cambiar de look!",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-kits.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-kits.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-kit.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-kit.webp"),
        },
      },
    ],
    [
      {
        //title: 'Tratamiento con Keratina',
        title: "CUIDADO DEL CABELLO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "La línea de cuidado del cabello de Issue ofrece el mejor tratamiento que repara el cabello, brindándole mayor suavidad y resistencia al quiebre.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/cuidado de cabello-moblie_peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/cuidado-de-cabello-desktop_Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-tratamiento.webp'),
        },
      },
    ],
  ],
  cl: [
    [
      {
        title: "Nuestros Productos",
        titleClassName: "color-grey-primary medium",
        descrClassName: "color-grey-primary",
        line: true,
        description:
          "La belleza del color combinada con la fuerza de su duración, ofreciendo la mejor calidad al mejor precio.",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-general-productos.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-general-productos.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-General-productos.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-General-productos.webp"),
        },
      },
    ],
    [
      {
        //title: 'Coloración Permanente Sín Amoníaco',
        title: "COLORACIÓN",
        titleClassName: "color-white-primary line bold",
        descrClassName: "color-white-primary",
        description:
          "La coloración en crema Issue, con su exclusiva fórmula, combina los colores radiantes de larga duración, con 100% de cobertura de canas. Conoce nuestras lineas permanentes, semi permanente & fantasía.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Coloración-mobile_Peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/mobile-Coloración.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/coloracion-desktop_Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-coloracion.webp'),
        },
      },
    ],
    [
      {
        //title: 'La decoloración que estabas necesitando',
        title: "COMPLEMENTOS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Prepara tu cabello para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos y los oxidantes en crema Issue.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Decoloración-mobile_Peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/mobile-Decoloración.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/decoloracion-desktop-Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-decoloracion.webp'),
        },
      },
    ],
    [
      {
        //title: 'Issue Kit 3D GLOSS',
        title: "KITS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Los kits de Issue incluyen todo lo que necesitás para tu coloración. Conocé nuestras cartas de colores y animate a cambiar de look!",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-kits.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-kits.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-kit.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-kit.webp"),
        },
      },
    ],
    [
      {
        //title: 'Tratamiento con Keratina',
        title: "CUIDADO DEL CABELLO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "La línea de cuidado del cabello de Issue ofrece el mejor tratamiento que repara el cabello, brindándole mayor suavidad y resistencia al quiebre.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/cuidado de cabello-moblie_peru.png"),
          // mobileWebp: require('../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp'),
          desktop: require("../../../static/img/cabecerasimagenes/cuidado-de-cabello-desktop_Peru.png"),
          // desktopWebp: require('../../../static/img/cabecerasimagenes/desktop-tratamiento.webp'),
        },
      },
    ],
  ],
  uy: [
    [
      {
        title: "Nuestros Productos",
        titleClassName: "color-grey-primary medium",
        descrClassName: "color-grey-primary",
        line: true,
        description:
          "Con PRE y POST color con Aloe Vera cuida tu pelo antes, durante y después de la tintura.",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-general-productos.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-general-productos.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-General-productos.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-General-productos.webp"),
        },
      },
    ],
    [
      {
        //title: 'Coloración Permanente Sín Amoníaco',
        title: "COLORACIÓN",
        titleClassName: "color-white-primary line bold",
        descrClassName: "color-white-primary",
        description:
          "La coloración en crema Issue, con su exclusiva fórmula, combina colores radiantes de larga duración, con 100% de cobertura de canas. Conocé nuestras líneas permanentes, sin amoníaco, semi permanente & fantasía.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-Coloración.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-Coloración.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-coloracion.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-coloracion.webp"),
        },
      },
    ],
    [
      {
        //title: 'La decoloración que estabas necesitando',
        title: "COMPLEMENTOS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Prepará tu pelo para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos, los activadores en crema y el ablandador de canas Issue.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-Decoloración.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-Decoloración.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-decoloracion.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-decoloracion.webp"),
        },
      },
    ],
    [
      {
        //title: 'Issue Kit 3D GLOSS',
        title: "KITS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Los kits de Issue incluyen todo lo que necesitás para tu coloración. Conocé nuestras cartas de colores y animate a cambiar de look!",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-kits.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-kits.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-kit.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-kit.webp"),
        },
      },
    ],
    [
      {
        //title: 'Tratamiento con Keratina',
        title: "CUIDADO DEL CABELLO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "La línea de cuidado del cabello de Issue ofrece los mejores tratamientos que reparan el cabello, brindándole mayor suavidad, resistencia al quiebre y con un brillo único.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.webp"),
        },
      },
    ],
    [
      {
        title: "SIN AMONIACO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Descubre nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.webp"),
        },
      },
    ],
  ],
  ve: [
    [
      {
        title: "Nuestros Productos",
        titleClassName: "color-grey-primary medium",
        descrClassName: "color-grey-primary",
        line: true,
        description:
          "La belleza del color combinada con la fuerza de su duración, ofreciendo la mejor calidad al mejor precio.",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-general-productos.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-general-productos.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-General-productos.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-General-productos.webp"),
        },
      },
    ],
    [
      {
        //title: 'Coloración Permanente Sín Amoníaco',
        title: "COLORACIÓN",
        titleClassName: "color-white-primary line bold",
        descrClassName: "color-white-primary",
        description:
          "La coloración en crema Issue, con su exclusiva fórmula, combina colores radiantes de larga duración, con 100% de cobertura de canas. Conoce nuestras líneas permanentes, sin amoníaco, semi permanente & fantasía.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-Coloración.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-Coloración.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-coloracion.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-coloracion.webp"),
        },
      },
    ],
    [
      {
        //title: 'La decoloración que estabas necesitando',
        title: "COMPLEMENTOS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Prepara tu cabello para la coloración con nuestros polvos decolorantes ideales para todo tipo de cabellos, los activadores en crema y el ablandador de canas Issue.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-Decoloración.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-Decoloración.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-decoloracion.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-decoloracion.webp"),
        },
      },
    ],
    [
      {
        //title: 'Issue Kit 3D GLOSS',
        title: "KITS",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Los kits de Issue incluyen todo lo que necesitas para tu coloración. Conoce nuestras cartas de colores y anímate a cambiar de look!",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/mobile-kits.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/mobile-kits.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-kit.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-kit.webp"),
        },
      },
    ],
    [
      {
        //title: 'Tratamiento con Keratina',
        title: "CUIDADO DEL CABELLO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "La línea de cuidado del cabello de Issue ofrece los mejores tratamientos que reparan el cabello, brindándole mayor suavidad, resistencia al quiebre y con un brillo único.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.webp"),
        },
      },
    ],
    [
      {
        title: "SIN AMONIACO",
        titleClassName: "color-white-primary line",
        descrClassName: "color-white-primary",
        description:
          "Descubre nuestra nueva coloración permanente creada con ingredientes naturales. Ideal para cubrir canas.",
        subtitle: "Nuestros Productos",
        imagenes: {
          mobile: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.png"),
          mobileWebp: require("../../../static/img/cabecerasimagenes/Tratamiento-moblie.webp"),
          desktop: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.png"),
          desktopWebp: require("../../../static/img/cabecerasimagenes/desktop-tratamiento.webp"),
        },
      },
    ],
  ],
};

const categoriasdic = {
  productos: 0,
  coloracion: 1,
  complementos: 2,
  kits: 3,
  "cuidado-del-cabello": 4,
};

const Banner = ({ curcategory }) => {
  return (
    <>
      {bannerdata[COUNTRY] &&
        bannerdata[COUNTRY][categoriasdic[curcategory]] &&
        bannerdata[COUNTRY][categoriasdic[curcategory]].map((el, index) => (
          <BannerCategory
            key={(index + 1) * 9300}
            title={el.title}
            titleClassName={el.titleClassName}
            descrClassName={el.descrClassName}
            line={el.line}
            description={el.description}
            subtitle={el.subtitle}
            imagenes={el.imagenes}
          />
        ))}
    </>
  );
};

export default Banner;
