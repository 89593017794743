import React from 'react';
import './Video.scss'
import {
    SvgDB,
} from '../../../@ui'



const Video = (props) => {

    const {
        bg = '',
        nombre = '',
        placeholder = '',
        onClick = null,
        slug = '',
        HandleShareClick
    } = props

    return (
        <div className="video-container">
            <div className="video-content">
                <div className="video-title-container">
                    <h6>{nombre}</h6>
                    <div className="video-svg-container" onClick={() => HandleShareClick(slug)}>
                        {SvgDB['share']}
                    </div>
                </div>
                <div className="video-video-container" onClick={onClick}>
                    <div className="video">
                        {placeholder && <img src={placeholder.src} alt={placeholder.nombre} />}
                    </div>
                </div>
                <div className={`video-color-div ${bg}`}></div>
            </div>
        </div>
    );
}

export default Video;