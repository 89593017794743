// Validación de todos los inputs de la web

const NAME_MIN_LENGTH = 3;

// Devuelve true si la string sólo contiene números, falso otherwise
export function isNumberStr(str) {
  const regex = /^\d+$/;
  return regex.test(str);
}

export function isValidTipoConsulta(tipoConsulta) {
  if (!tipoConsulta || tipoConsulta === '') {
    return false;
  }
  return true;
}

export function isValidNombre(nombre) {
  if (nombre.length < NAME_MIN_LENGTH) {
    return false;
  }

  // Nombres válidos: solamente pueden contener letras (incluyendo acentos y ñ) y espacios
  const found = nombre.match(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/);

  return found !== null;
}

export function isValidApellido(apellido) {
  return isValidNombre(apellido);
}

export function isValidCodigoArea(codigoArea) {
  return isNumberStr(codigoArea);
}

export function isValidTelefono(telefono) {
  return isNumberStr(telefono);
}

export function isValidEmail(email) {
  if (!email.includes('@')) {
    return false;
  }

  const regexp = /^[a-zA-Z0-9_\-@.]{5,255}$/;
  return regexp.test(email);
}

export function isValidDireccion(direccion) {
    // FIXME: Implementame
  return direccion.length >= 5;
}

export function isValidLocalidad(localidad) {
  return localidad.length >= NAME_MIN_LENGTH;
}

export function isValidProvincia(provincia) {
  return provincia.length >= NAME_MIN_LENGTH;
}

export function isValidMensaje(mensaje) {
  // FIXME: Implementame
  return mensaje.length >= 5;
}
