import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../utils";
import styles from "./PreguntasFrecuentes.module.scss";

import { COUNTRY } from "../../constants.js";

// Caja colapsable con texto
const CollapsibleBox = ({ title, content, last = false }) => {
  const [collapsed, setCollapsed] = useState(true);

  const onBtnClick = () => {
    setCollapsed(!collapsed);
  };

  const collapedStyle = last
    ? `${styles.collapsed} ${styles.collapsedLast}`
    : styles.collapsed;

  return (
    <div className={styles.collapsibleBoxContainer}>
      <span className={styles.title}>{title}</span>
      <span
        onClick={onBtnClick}
        style={{
          marginLeft: "10px",
          fontSize: "3em",
          cursor: "pointer",
          float: "right",
          marginTop: "-14px",
        }}
      >
        {collapsed ? "+" : "-"}
      </span>
      <span className={styles.bar}></span>
      <div className={`${collapsed ? collapedStyle : styles.uncollapsed}`}>
        {content}
      </div>
    </div>
  );
};

const PreguntasFrecuentes = () => {
  // TODO: pasar cl a neutro
  const contentByCountry = {
    ar: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue 3D Gloss?",
        content: (
          <>
            <p>
              Sí, la coloración Issue 3D Gloss, tanto en su formato Kit como
              Sachet, es una coloración permanente que contiene amoníaco y no se
              va con los lavados. Antes de aplicarla, se debe realizar la
              "Prueba de Sensibilidad" 48 hs antes, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve el Pre-Color?",
        content: (
          <>
            <p>
              Para lograr un mejor color, te recomendamos que antes de teñirte
              te apliques el Pre-Color, que prepara tu pelo, haciendo que el
              color dure más, reparando y fortaleciéndolo. Recordá que no debes
              enjuagarlo, ya que la tintura se debe aplicar sobre el mismo.
              Podés encontrarlo en el Super Color Pack Issue Keratin y en el Kit
              Keratin de Issue.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo uso el Pre-Color?",
        content: (
          <>
            <p>
              Antes de la coloración, te recomendamos usar el Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicalo sobre el cabello
              seco y sin lavar previamente. Comenzá por las puntas y luego
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Dejalo actuar mientras mezclás la Crema Colorante con el
              Activador. Te recomendamos que peines tu cabello para desenredarlo
              antes de aplicar la coloración. Acuérdate, no requiere enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve Tratamiento de Shock de Keratina?",
        content: (
          <>
            <p>
              Una vez que ya te tinturaste, y te lavaste el pelo, aplica el
              Tratamiento de Shock de Keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. Recuerda,
              el Tratamiento de Shock de Keratina lo encuentras en el Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
      {
        title: "¿Para qué uso el potenciador de brillo?",
        last: true,
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el El
              Potenciador de Brillo de Issue 3D Gloss, que actúa como
              Post-Color. Dejalo actuar entre 5 y 10 minutos, y luego enjuagalo.
              Te va a ayudar a sellar las cutículas del cabello, dejando el pelo
              con un brillo intenso, resaltando tu color por más tiempo. Es
              importante destacar que un pomo Potenciador de Brillo rinde hasta
              tres aplicaciones. Encontralo en el kit de Issue 3D Gloss.
            </p>
          </>
        ),
      },
    ],
    uy: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue 3D Gloss?",
        content: (
          <>
            <p>
              Sí, la coloración Issue 3D Gloss, tanto en su formato Kit como
              Sachet, es una coloración permanente que contiene amoníaco y no se
              va con los lavados. Antes de aplicarla, se debe realizar la
              "Prueba de Sensibilidad" 48 hs antes, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve el Pre-Color?",
        content: (
          <>
            <p>
              Para lograr un mejor color, te recomendamos que antes de teñirte
              te apliques el Pre-Color, que prepara tu pelo, haciendo que el
              color dure más, reparando y fortaleciéndolo. Recordá que no debes
              enjuagarlo, ya que la tintura se debe aplicar sobre el mismo.
              Podés encontrarlo en el Super Color Pack Issue Keratin y en el Kit
              Keratin de Issue.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo uso el Pre-Color?",
        content: (
          <>
            <p>
              Antes de la coloración, te recomendamos usar el Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicalo sobre el cabello
              seco y sin lavar previamente. Comenzá por las puntas y luego
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Dejalo actuar mientras mezclás la Crema Colorante con el
              Activador. Te recomendamos que peines tu cabello para desenredarlo
              antes de aplicar la coloración. Acuérdate, no requiere enjuague.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Para qué sirve la Máscara con keratina y el bálsamo Post-Color?",
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el bálsamo
              Post-Color o la Máscara con keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. <br />{" "}
              Recordá, el bálsamo Post-Color lo encontrás en el Super Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
      {
        title: "¿Para qué uso el potenciador de brillo?",
        last: true,
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el El
              Potenciador de Brillo de Issue 3D Gloss, que actúa como
              Post-Color. Dejalo actuar entre 5 y 10 minutos, y luego enjuagalo.
              Te va a ayudar a sellar las cutículas del cabello, dejando el pelo
              con un brillo intenso, resaltando tu color por más tiempo. Es
              importante destacar que un pomo Potenciador de Brillo rinde hasta
              tres aplicaciones. Encontralo en el kit de Issue 3D Gloss.
            </p>
          </>
        ),
      },
    ],
    pe: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verifica que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepara la mezcla justo antes de usarla, y aplícala
                inmediatamente. Recuerda que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protege tu ropa y sácate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, puedo teñirme el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Para qué sirve la Máscara con keratina y el bálsamo Post-Color?",
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el bálsamo
              Post-Color o la Máscara con keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. <br />{" "}
              Recordá, el bálsamo Post-Color lo encontrás en el Super Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
    ],
    bo: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve el Pre-Color?",
        content: (
          <>
            <p>
              Para lograr un mejor color, te recomendamos que antes de teñirte
              te apliques el Pre-Color, que prepara tu pelo, haciendo que el
              color dure más, reparando y fortaleciéndolo. Recordá que no debes
              enjuagarlo, ya que la tintura se debe aplicar sobre el mismo.
              Podés encontrarlo en el Super Color Pack Issue Keratin y en el Kit
              Keratin de Issue.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo uso el Pre-Color?",
        content: (
          <>
            <p>
              Antes de la coloración, te recomendamos usar el Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicalo sobre el cabello
              seco y sin lavar previamente. Comenzá por las puntas y luego
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Dejalo actuar mientras mezclás la Crema Colorante con el
              Activador. Te recomendamos que peines tu cabello para desenredarlo
              antes de aplicar la coloración. Acuérdate, no requiere enjuague.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Para qué sirve la Máscara con keratina y el bálsamo Post-Color?",
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el bálsamo
              Post-Color o la Máscara con keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. <br />{" "}
              Recordá, el bálsamo Post-Color lo encontrás en el Super Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
    ],

    ec: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve Tratamiento de Shock de Keratina?",
        content: (
          <>
            <p>
              Una vez que ya te tinturaste, y te lavaste el pelo, aplica el
              Tratamiento de Shock de Keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. Recuerda,
              el Tratamiento de Shock de Keratina lo encuentras en el Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
    ],

    py: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve el Pre-Color?",
        content: (
          <>
            <p>
              Para lograr un mejor color, te recomendamos que antes de teñirte
              te apliques el Pre-Color, que prepara tu pelo, haciendo que el
              color dure más, reparando y fortaleciéndolo. Recordá que no debes
              enjuagarlo, ya que la tintura se debe aplicar sobre el mismo.
              Podés encontrarlo en el Super Color Pack Issue Keratin y en el Kit
              Keratin de Issue.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo uso el Pre-Color?",
        content: (
          <>
            <p>
              Antes de la coloración, te recomendamos usar el Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicalo sobre el cabello
              seco y sin lavar previamente. Comenzá por las puntas y luego
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Dejalo actuar mientras mezclás la Crema Colorante con el
              Activador. Te recomendamos que peines tu cabello para desenredarlo
              antes de aplicar la coloración. Acuérdate, no requiere enjuague.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Para qué sirve la Máscara con keratina y el bálsamo Post-Color?",
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el bálsamo
              Post-Color o la Máscara con keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. <br />{" "}
              Recordá, el bálsamo Post-Color lo encontrás en el Super Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
    ],

    cl: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verifica que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepara la mezcla justo antes de usarla, y aplícala
                inmediatamente. Recuerda que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protege tu ropa y sácate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, puedo teñirme el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },
      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },
    ],
    ve: [
      {
        title: "¿Qué tengo que tener en cuenta antes de teñirme?",
        content: (
          <>
            <p>
              Antes de teñirte, es importante que tomes las precauciones
              adecuadas para tener la mejor experiencia con tu color.
            </p>
            <ul>
              <li>
                Asegúrate de hacerte la Prueba de Sensibilidad 48hs antes de
                teñirte.
              </li>
              <li>
                Verificá que la cantidad de sobres de coloración sea adecuada al
                largo de tu pelo.
              </li>
              <li>
                Prepará la mezcla justo antes de usarla, y aplicala
                inmediatamente. Recordá que una vez mezclada la tintura con el
                activador, no es posible guardarla.
              </li>
              <li>
                Protegé tu ropa y sacate tus joyas y/o accesorios para evitar
                que entren en contacto con la tintura, ya que normalmente las
                manchas no se pueden quitar.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "Me hice una permanente/alisado, ¿me puedo teñir?",
        content: (
          <>
            <p>
              Después de una permanente o un alisado te aconsejamos esperar
              mínimo 2 semanas antes de aplicar la coloración. De lo contrario,
              el resultado del color puede ser demasiado intenso o no quedar
              uniforme.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Si estoy embarazada o durante la lactancia, me puedo teñir el pelo?",
        content: (
          <>
            <p>
              Si estás pensando en teñirte y estás embarazada o amamantando, te
              recomendamos consultarlo antes con tu médico obstetra.
            </p>
          </>
        ),
      },

      {
        title: "Soy celíaca, ¿puedo usar sus productos?",
        content: (
          <>
            <p>
              Si eres celíaca, te recomendamos que consultes a tu médico y que
              lleves el envase para que pueda ver los ingredientes y te
              aconseje. Nuestros productos no han sido analizados en cuanto a
              contenido de TACC.
            </p>
          </>
        ),
      },

      {
        title:
          "Soy alérgica a algunos alimentos y medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Si eres alérgica, te recomendamos que le consultes a tu médico
              antes de teñirte y que lleves el envase para que pueda ver los
              ingredientes y te aconseje. Además, acuérdate que siempre debes
              realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
              como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title:
          'Soy alérgica pero ya he usado otras coloraciones en el pasado ¿puedo teñirme con "esta marca"?',
        content: (
          <>
            <p>
              Por más que ya te hayas teñido en otro momento con tipo de
              coloraciones, ten en cuenta que marcas diferentes pueden contener
              ingredientes distintos que provoquen reacción alérgica.
            </p>{" "}
            Si eres alérgica, te recomendamos que le consultes a tu médico antes
            de teñirte y que lleves el envase para que pueda ver los
            ingredientes y te aconseje. Además, acuérdate que siempre debes
            realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte, tal
            como se indica en el envase.
          </>
        ),
      },

      {
        title: "Estuve bajo tratamiento con medicamentos ¿puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos que consultes a tu médico, y que lleves el envase
              para que pueda ver los ingredientes y te aconseje. Si te permite
              teñirte, acuérdate que siempre debes realizar la "Prueba de
              Sensibilidad" 48 hs antes de teñirte, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Qué tienen que ver los tatuajes temporales con henna negra, los tatuajes black out y las alergias a coloraciones o tinturas para el cabello?",
        content: (
          <>
            <p>
              Todos estos tipos de tatuajes pueden generarte sensibilidad, y al
              aplicar la tintura puedes tener una reacción alérgica. Si tienes
              tatuajes, te recomendamos que consultes a tu médico, y acuérdate
              de realizar la "Prueba de Sensibilidad" 48 hs antes de teñirte.
            </p>
          </>
        ),
      },

      {
        title:
          "El médico dijo que no puedo teñirme si el producto contiene iodo. ¿La tintura tiene iodo?",
        content: (
          <>
            <p>
              No utilizamos IODO para elaborar nuestras tinturas. En el envase
              figura el listado de ingredientes por si deseas consultar con tu
              médico.
            </p>
          </>
        ),
      },

      {
        title: "¿En qué lugar del cuerpo puedo teñirme?",
        content: (
          <>
            <p>
              Te recomendamos utilizar la tintura solamente en el cabello. No se
              recomienda utilizarla para teñir los vellos de las cejas, pestañas
              ni otras áreas corporales (brazos, piernas, bigote, etc). No
              aplicar si el cuero cabelludo presenta irritación o cualquier otra
              condición anormal.
            </p>
          </>
        ),
      },

      {
        title: "¿El largo de mi pelo, influye en la tintura?",
        content: (
          <>
            <p>
              Dependiendo el largo de tu pelo, será la cantidad de tintura que
              tengas que usar. Te recomendamos que, si tu pelo sobrepasa la
              altura de los hombros, uses 2 o más unidades de coloración.
            </p>
          </>
        ),
      },

      {
        title: "¿Puedo cerrar el sobre y volver a utilizarlo?",
        content: (
          <>
            <p>
              Si todavía no lo mezclaste con el activador, sí. Cierra el sobre
              con un par de vueltas, apretado bien, tratando de que no quede
              aire dentro y poner un clip para sujetarlo. Se puede mantener así
              hasta 30 días, cuando hagas el retoque de raíces.
            </p>{" "}
            Te recordamos que, si ya mezclaste el sobre de coloración con el del
            activador, debes aplicarlo inmediatamente, y esa mezcla no puedes
            guardarla para utilizarla después.
          </>
        ),
      },

      {
        title: "¿Puedo utilizar el producto luego de su fecha de vencimiento?",
        content: (
          <>
            <p>Una vez vencido, recomendamos no utilizar el producto.</p>
          </>
        ),
      },

      {
        title: "¿Cómo me hago la Prueba de Sensibilidad?",
        content: (
          <>
            <p>
              Mezcla unas gotas de activador con igual cantidad de la coloración
              en un recipiente no metálico y aplica la mezcla en el pliegue
              interior del brazo. Deja actuar 45 minutos y enjuaga. Si pasadas
              las 48hs no presenta irritación, picazón o enrojecimiento, puedes
              aplicar el producto. Acuérdate de cerrar herméticamente los sobres
              de coloración y el activador, por separado hasta el momento de
              uso.
            </p>
          </>
        ),
      },

      {
        title:
          "No soy alérgica, ¿me tengo que hacer la prueba de sensibilidad igual?",
        content: (
          <>
            <p>
              Sí, más allá de que no seas alérgica, y que vengas usando el
              producto, siempre debes realizar la "Prueba de Sensibilidad" 48 hs
              antes de teñirte, tal como se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Crazy Colors?",
        content: (
          <>
            <p>
              No, la coloración Issue Crazy Colors es semipermanente, eso quiere
              decir que no contiene amoníaco y que se va con los lavados. No
              debe mezclarse con activador. La coloración se aplica directamente
              sobre el cabello.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue 3D Gloss?",
        content: (
          <>
            <p>
              Sí, la coloración Issue 3D Gloss, tanto en su formato Kit como
              Sachet, es una coloración permanente que contiene amoníaco y no se
              va con los lavados. Antes de aplicarla, se debe realizar la
              "Prueba de Sensibilidad" 48 hs antes, tal como se indica en el
              envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Tiene amoníaco la tintura Issue Keratin?",
        content: (
          <>
            <p>
              Sí, la coloración Issue Keratin, tanto en su formato Kit, Color
              Pack, Super Color Pack y Sachet, es una coloración permanente que
              contiene amoníaco y no se va con los lavados. Antes de aplicarla,
              se debe realizar la "Prueba de Sensibilidad" 48 hs antes, tal como
              se indica en el envase.
            </p>
          </>
        ),
      },

      {
        title: "¿Cuánto tiempo dura Issue Crazy Colors?",
        content: (
          <>
            <p>
              El color puede durar entre 7 y 30 lavados aproximadamente.
              Dependiendo de tu tipo de pelo, tu color de base y el tiempo que
              dejes actuar el producto, el resultado de color puede llegar a
              variar. Tené en cuenta que la intensidad del color se irá yendo
              con cada lavado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo se aplica Issue Crazy Colors?",
        content: (
          <>
            <p>
              Para lograr el mejor resultado de color, aconsejamos decolorar el
              pelo antes de teñirlo. Para conocer cómo, prestá atención a las
              instrucciones que se encuentran a continuación. Si ya lo tienes
              decolorado, puedes aplicar directamente el sachet de coloración.
              Si tienes el pelo virgen, te recomendamos comprar el Polvo
              Decolorante Issue. Recordá que puedes comprar el Kit Issue Crazy
              Colors, que incluye todos los componentes necesarios para lograr
              el mejor resultado de color.
            </p>
            <p>
              Para empezar, ten a mano un reloj, guantes, una toalla vieja o
              capa plástica para proteger tu ropa, un bowl no metálico y un
              pincel. Los decolorantes y coloraciones pueden producir reacciones
              alérgicas en personas sensibles. Por eso te recomendamos que 48 hs
              antes de usar los productos, realices la Prueba de Sensibilidad y
              de mecha, para evaluar si tienes sensibilidad en la piel o si tu
              cabello está quebradizo o dañado. Para aplicar este producto, te
              recomendamos seguir los siguientes pasos:
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 1: PRUEBA DE SENSIBILIDAD Y DE MECHA:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  POLVO DECOLORANTE + ACTIVADOR:
                </span>{" "}
                prepará un poco de la mezcla siguiendo las instrucciones de uso
                del polvo decolorante. Aplicá en un mechón y piel detrás de la
                oreja; dejalo 30 minutos y enjuaga. Si a las 48 hs notás
                irritación de la piel, picazón, enrojecimiento en la zona de
                aplicación o quiebre del cabello, no apliques el producto.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  COLORACIÓN SEMI PERMANENTE ISSUE CRAZY COLORS:
                </span>{" "}
                Limpiá con alcohol una pequeña superficie en la parte interior
                del antebrazo y aplica un poco del producto. Enjuagalo después
                de 45 min., si al cabo de 48 horas no presenta irritación,
                picazón ni enrojecimiento, puedes aplicar el producto. Recordá
                cerrar herméticamente el sachet hasta el momento de su uso.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 2: DECOLORACIÓN DE TU BASE:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  MEZCLA:
                </span>{" "}
                Prepará la mezcla de Polvo Decolorante Issue con el Activador en
                Crema Issue 20 volúmenes dentro de un recipiente no metálico.
                Mezcla con un pincel hasta conseguir una consistencia homogénea.
                Si no querés utilizar todo el sobre, recordá seguir las
                proporciones de mezcla (cada 20 g de polvo, 47 ml de Activador).
              </li>
              <li>
                Aplicá la mezcla sobre cabellos secos y no lavados,
                cuidadosamente con pincel en las zonas a decolorar.En caso de:
                Cabellos vírgenes: comenzá la decoloración por largos y puntas,
                sin tocar las raíces. Deja actuar de 20 a 30 minutos teniendo en
                cuenta la calidad del cabello. Luego, aplica en las raíces hasta
                obtener una decoloración uniforme. Retoque de raíces: Aplicá el
                decolorante en raíces sin tocar las zonas del cabello
                previamente decoloradas.
              </li>
              <li>
                <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  TIEMPO DE EXPOSICIÓN:
                </span>{" "}
                El Polvo Decolorante Issue tiene una acción decolorante gradual
                desde su aplicación hasta los 60 minutos, según la intensidad
                que se quieras conseguir.
              </li>
            </ol>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 3: LAVADO
            </p>
            <p>
              Lavá bien tu cabello con agua tibia para quitar todos los restos
              de la mezcla decolorante. Secá suavemente con una toalla hasta
              escurrir bien el exceso de agua.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 4: COLORACIÓN
            </p>
            <p>
              Sobre el cabello limpio y secado con toalla, aplica tu color
              elegido de Issue Crazy Colors. Aplicá con pincel sin tocar la
              raíz, peiná para obtener una coloración pareja y uniforme. Deja
              actuar entre 10 y 20 minutos, dependiendo de la duración que
              quieras lograr.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              PASO 5: FINAL
            </p>
            <p>
              Enjuagá bien los cabellos hasta quitar el resto de color. Puede
              aplicarse acondicionador en caso de cabellos secos o muy porosos.
              Para terminar, sécalo, peinalo y disfrutá tu nuevo look.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo sé cuál es mi color actual?",
        content: (
          <>
            <p>
              Para conocer tu tono actual te recomendamos que solicites en el
              comercio donde compres la tintura, una carta de colores, donde
              puedas comparar un mechón de tu pelo con las mechas de la misma.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo puedo elegir mi tono?",
        content: (
          <>
            <p>
              El tono a obtener dependerá de tu base, ya que el resultado final
              es una combinación del color aplicado y tu color actual. El mismo
              tono puede verse distinto en dos personas diferentes. Para saber
              cómo te va a quedar, prestá atención al costado del kit, o pedí la
              carta de colores y mirá la guía de color, donde podrás tener una
              referencia aproximada del resultado.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo cambio de tono?",
        content: (
          <>
            <p>
              Si querés cambiar tu color de pelo, debes tener en cuenta los
              siguientes consejos:
            </p>{" "}
            <ul>
              {" "}
              <li>
                No te olvides de realizar siempre la prueba de sensibilidad,
                48hs antes.
              </li>{" "}
              <li>
                Te recomendamos ir modificando tu color de a poco, para lograr
                mejores resultados y cuidar tu pelo.{" "}
              </li>{" "}
              <li>
                Te recomendamos no oscurecer más de dos tonos al mismo tiempo.
              </li>{" "}
              <li>Te recomendamos aclarar de a un tono a la vez. </li>{" "}
              <li>
                Si querés aclarar tu pelo, posiblemente necesites decolorarlo
                antes de aplicar el color.
              </li>{" "}
              <li>
                Si es la primera vez que te vas a teñir, primero prepará una
                mezcla de coloración y aplicala en los largos, dejalo actuar 20
                minutos, y luego prepará otra mezcla para aplicar en las raíces,
                dejalo actuar entre 30 y 35 minutos. Al próximo mes, puedes
                aplicarlo sólo en las raíces.
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración en todo el cabello?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla </h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>
              4. Aplicación en todo el cabello: este método es recomendable si:
            </h5>{" "}
            <ul>
              {" "}
              <li>
                Es la primera vez en aplicar una coloración permanente.
              </li>{" "}
              <li>No hay raíz de coloración anterior.</li>{" "}
              <li>En el caso de cabellos sin coloración (vírgenes).</li>{" "}
            </ul>{" "}
            <p>
              Aplicar a partir de 2cm/0.8in de la raíz extendiendo a puntas.
              Dejar actuar 20 minutos. Luego aplicar en raíces con un tiempo de
              pose de 20 minutos.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración </h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title: "¿Cómo me aplico la coloración para retocarme las raíces?",
        content: (
          <>
            <p>
              Seguí los siguientes pasos para poder aplicarte la coloración, ya
              sea la primera vez o no que te teñís. Recordá que 48hs antes debes
              hacerte la Prueba de Sensibilidad.
            </p>{" "}
            <h5>1. Pre Coloración </h5>{" "}
            <p>
              Antes de la coloración, te recomendamos usar un Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicá el Pre-Color sobre el
              cabello seco y sin lavar previamente. Comenzá por las puntas y
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Deja actuar mientras se mezcla la Crema Colorante con el
              Activador. Te recomendamos peinar el cabello para desenredarlo. No
              es necesario que lo enjuagues.
            </p>{" "}
            <h5>2. Preparación previa</h5>{" "}
            <p>
              Antes de empezar la aplicación, colocate los guantes y una toalla
              o capa sobre los hombros, para proteger la ropa de posibles
              salpicaduras y evitar manchas. Tené a mano un reloj para controlar
              el tiempo de exposición. Aplicá la mezcla sobre el cabello con el
              Pre-Color. En caso que no hayas aplicado el Pre-Color, puedes
              aplicar la mezcla de coloración sobre el cabello seco y sin
              necesidad de lavarlo previamente.
            </p>{" "}
            <h5>3. Preparación de la mezcla</h5>{" "}
            <p>
              Abrí el sobre de Coloración en Crema Issue y volcá todo su
              contenido en un recipiente no metálico. Agregá el sobre de
              Activador en Crema Issue. Mezcla hasta obtener una consistencia
              totalmente homogénea y cremosa. Finalmente, tomá un pincel y
              empezá la aplicación inmediatamente.
            </p>{" "}
            <h5>4. Retoque de raíces: este método es recomendable para:</h5>{" "}
            <ul>
              {" "}
              <li>Cabellos teñidos en los que se notan las raíces.</li>{" "}
              <li>Puntas del cabello están más oscuras, dañadas, o porosas.</li>{" "}
            </ul>{" "}
            <p>
              Aplicar 2/3 partes de la mezcla únicamente en las raíces,
              separando el cabello en pequeños mechones raya a raya (usar los
              guantes). Utilizar un peine como ayuda para realizar la separación
              en el cuero cabelludo. Masajear la mezcla con la yema de los dedos
              para asegurar una buena distribución del producto. Dejar actuar 30
              minutos. Luego aplicar el resto de la preparación en la parte
              restante del cabello y dejarla actuar 10 minutos más.
            </p>{" "}
            <h5>5. Lavado</h5>{" "}
            <p>
              Enjuagar abundantemente con agua hasta eliminar la crema por
              completo, luego lavar con shampoo y enjuagar.
            </p>{" "}
            <h5>6. Post Coloración</h5>{" "}
            <p>
              Secar con toalla. Aplicar el Post-Color, el Tratamiento
              Potenciador de Brillo Issue 3D Gloss o la máscara con keratina
              masajeando de medios a puntas. Dejar actuar 5 minutos. Enjuagar
              con abundante agua tibia.
            </p>{" "}
            <h5>7. Secar y peinar como de costumbre.</h5>
          </>
        ),
      },

      {
        title:
          "¿Qué hago con mi pelo mientras espero que la coloración haga efecto?",
        content: (
          <>
            <p>
              Durante la aplicación, te recomendamos que dejes tu pelo suelto.
              Este es un momento en el cual tu pelo está más débil, hacer
              rodetes, atarlo con gomitas o clips, o colocar cofias o toallas
              podría cortarlo o quebrarlo.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me decoloro el pelo?",
        content: (
          <>
            <p>
              Decolorar no es un proceso fácil, con lo cual, si no estás segura,
              te recomendamos que antes le pidas ayuda a un experto. Los pasos a
              seguir son: mezclá el contenido del Polvo Decolorante Issue con el
              doble de activador en crema 20 volúmenes (por ejemplo, si colocás
              20gr del polvo decolorante, serán 40ml de activador) dentro de un
              recipiente de no metálico, con un pincel hasta crear una mezcla
              homogénea. Aplicá sobre el cabello seco y no lavado, con el pincel
              en las zonas a decolorar. Dejalo actuar hasta 60 minutos,
              asegurándote de controlarlo cada 10 minutos, en caso que se
              decolore antes. Eso dependerá de la calidad del cabello y la
              intensidad que se desees conseguir. Enjuagalo hasta quitar por
              completo la mezcla, y lavalo con shampoo. Acondicionar el cabello
              con la Máscara de Keratina Issue, y enjuagalo.
            </p>
          </>
        ),
      },

      {
        title:
          "¿Cómo me aplico la coloración si tengo el pelo muy seco, dañado o poroso?",
        content: (
          <>
            <p>
              Te recomendamos que apliques la coloración primero en la raíz, y
              extender a puntas sólo en los últimos 5 a 10 minutos. De lo
              contrario, el resultado puede ser más intenso o más oscuro de lo
              esperado según el tono elegido.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo me limpio la tintura?",
        content: (
          <>
            <p>
              Cuando te estés tiñendo, ten cuidado de no manchar paredes,
              cortinas, ropa, toalla, ya que las coloraciones tienen mucha
              afinidad con los tejidos y superficies porosas. Te recomendamos
              usar toallas viejas o blancas para poder lavarlas con jabón y un
              poco de lavandina.
            </p>{" "}
            <p>
              La cara: la piel se puede limpiar con un algodón con shampoo o con
              una esponjita con shampoo y agua tibia. Lo ideal es tratar de no
              mancharse porque la piel tiene keratina igual que el cabello. Un
              tip es ponerse vaselina sólida en el borde de la cara donde nace
              el cabello, esa grasa evitara que se adhieran demasiado las
              manchas.
            </p>{" "}
            <p>
              La ropa: es posible que las manchas de la ropa no se puedan
              quitar. Lo más conveniente es utilizar ropa y toallas viejas para
              protegerse de las manchas, o una capa plástica. Tratar de no tocar
              con el cabello empapado en tintura ningún mueble, ropa u objetos.
            </p>{" "}
            <p>
              El lavatorio: el lavatorio y azulejos son en general esmaltados,
              con lo cual la tintura no debería adherirse, se limpia con esponja
              y cualquier producto apto para limpieza de baños, en el caso de
              los materiales porosos, como el cemento de las juntas de azulejos,
              es un poco más complejo quitarlas.
            </p>{" "}
            <p>
              Las manos y uñas: aconsejamos el uso de guantes durante todo el
              proceso de teñido, desde la preparación del color hasta incluso
              cuando enjuagues el cabello para quitar el producto. Si te manchás
              las manos lavalas con agua, jabón y una esponja, las mismas
              deberían desaparecer.{" "}
            </p>
          </>
        ),
      },

      {
        title:
          "Accidentalmente me entró la mezcla de coloración y activador en el ojo/boca, ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagá la zona con agua fría y consultá a un médico
              inmediatamente. En caso de ingestión accidental no provocar
              vómito, consultar al médico.
            </p>
          </>
        ),
      },

      {
        title:
          "Un producto me dio reacción alérgica o de irritación en la piel. ¿Qué debo hacer?",
        content: (
          <>
            <p>
              Debés quitar inmediatamente el producto del cabello con agua fría,
              y consultar con el médico.
            </p>{" "}
            <h5>CONSULTAS Y EMERGENCIAS</h5>{" "}
            <ul>
              {" "}
              <li>Hospital Posadas: 0-800-333-0160</li>{" "}
              <li>Hospital de Niños R. Gutiérrez: 0-800-444-8694</li>{" "}
              <li>
                Hospital Pediatría “Sor María Ludovica” (La Plata):
                0-800-222-9911
              </li>{" "}
            </ul>
          </>
        ),
      },

      {
        title:
          "No soy alérgica y vengo usando esta tintura hace años, pero me causó una irritación. ¿qué hago?",
        content: (
          <>
            <p>
              Enjuagate el cabello inmediatamente con agua fría y dirigite a la
              guardia médica más cercana. Es importante que NO uses ningún tipo
              de producto adicional (shampoo, acondicionador, detergente, aloe
              vera, etc) durante y luego del enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve el Pre-Color?",
        content: (
          <>
            <p>
              Para lograr un mejor color, te recomendamos que antes de teñirte
              te apliques el Pre-Color, que prepara tu pelo, haciendo que el
              color dure más, reparando y fortaleciéndolo. Recordá que no debes
              enjuagarlo, ya que la tintura se debe aplicar sobre el mismo.
              Podés encontrarlo en el Super Color Pack Issue Keratin y en el Kit
              Keratin de Issue.
            </p>
          </>
        ),
      },

      {
        title: "¿Cómo uso el Pre-Color?",
        content: (
          <>
            <p>
              Antes de la coloración, te recomendamos usar el Pre-Color, para
              preparar el cabello antes de teñirlo. Aplicalo sobre el cabello
              seco y sin lavar previamente. Comenzá por las puntas y luego
              distribuilo en todo el cabello. No es necesario aplicar en las
              raíces. Dejalo actuar mientras mezclás la Crema Colorante con el
              Activador. Te recomendamos que peines tu cabello para desenredarlo
              antes de aplicar la coloración. Acuérdate, no requiere enjuague.
            </p>
          </>
        ),
      },

      {
        title: "¿Para qué sirve Tratamiento de Shock de Keratina?",
        content: (
          <>
            <p>
              Una vez que ya te tinturaste, y te lavaste el pelo, aplica el
              Tratamiento de Shock de Keratina, déjalo actuar entre 5 y 10
              minutos y luego enjuágalo. Acuérdate de aplicarlo, ya que te va a
              ayudar a sellar las cutículas del cabello, dejándolo suave y
              brillante, y con un color más vibrante por más tiempo. Recuerda,
              el Tratamiento de Shock de Keratina lo encuentras en el Color Pack
              Issue Keratin y en el Kit Keratin de Issue.
            </p>
          </>
        ),
      },
      {
        title: "¿Para qué uso el potenciador de brillo?",
        last: true,
        content: (
          <>
            <p>
              Una vez que ya te teñiste, y te lavaste el pelo, aplica el El
              Potenciador de Brillo de Issue 3D Gloss, que actúa como
              Post-Color. Dejalo actuar entre 5 y 10 minutos, y luego enjuagalo.
              Te va a ayudar a sellar las cutículas del cabello, dejando el pelo
              con un brillo intenso, resaltando tu color por más tiempo. Es
              importante destacar que un pomo Potenciador de Brillo rinde hasta
              tres aplicaciones. Encontralo en el kit de Issue 3D Gloss.
            </p>
          </>
        ),
      },
    ],
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={styles.container}>
      {contentByCountry[COUNTRY].map((pregunta, index) => (
        <CollapsibleBox
          key={index}
          title={pregunta.title}
          last={pregunta.last}
          content={pregunta.content}
        />
      ))}
    </div>
  );
};

export default PreguntasFrecuentes;
