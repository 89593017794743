import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { scrollToTop } from '../../../utils';
import {
    Button,
    Input,
} from '../../@ui';
import Thanks from '../Thanks';
import ConsultaSelect from './ConsultaSelect';
import './Form.scss';
import {
    isValidApellido,
    isValidCodigoArea,
    isValidNombre,
    isValidTelefono,
    isValidEmail,
    isValidDireccion,
    isValidLocalidad,
    isValidMensaje,
    isValidProvincia,
    isValidTipoConsulta,
} from '../../../validation';
import ProvinciaSelect from './ProvinciaSelect';
import {
    EMAIL_MICROSERVICE_URL,
    EMAIL_TO,
    COUNTRY
} from '../../../constants';


const EMAIL_SUBJECT = `Issue Color - Mensaje de contacto`;


const Form = () => {

    const [sent, setSent] = useState(false);
    const [tipoConsulta, setTipoConsulta] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [codigoArea, setCodigoArea] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [direccion, setDireccion] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [provincia, setProvincia] = useState('');
    const [mensaje, setMensaje] = useState('');


    // Arma el body del email con los datos ingresados por el usuario
    const buildEmailBody = () => {
        const body = `
            <b>Tipo de consulta:</b> ${tipoConsulta}<br>
            <b>Nombre:</b> ${nombre} ${apellido}<br>
            <b>Teléfono:</b> ${codigoArea} - ${telefono}<br>
            <b>E-mail:</b> <a href="mailto:${email}?subject=Issue Color - Respuesta contacto">${email}</a><br>
            <b>Dirección:</b> ${direccion}, ${localidad}, ${provincia}<br><br>
            <b>Mensaje:</b> ${mensaje}`;

        return body;
    }

    const onSubmit = async (event) => {
        // Evita que se recargue la página innecesariamente, ya que vamos a enviar la data con axios
        event.preventDefault();

        if (!isValidTipoConsulta(tipoConsulta)) {
            toast.error(`Tipo de consulta inválido!`);
            return;
        }

        if (!isValidNombre(nombre)) {
            toast.error(`Nombre inválido!`);
            return;
        }

        if (!isValidApellido(apellido)) {
            toast.error(`Apellido inválido!`);
            return;
        }

        if (!isValidCodigoArea(codigoArea)) {
            toast.error(`Código de área inválido!`);
            return;
        }

        if (!isValidTelefono(telefono)) {
            toast.error(`Teléfono inválido!`);
            return;
        }

        if (!isValidEmail(email)) {
            toast.error(`Email inválido!`);
            return;
        }

        if (!isValidDireccion(direccion)) {
            toast.error(`Dirección inválida!`);
            return;
        }

        if (!isValidLocalidad(localidad)) {
            toast.error(`Localidad inválida!`);
            return;
        }

        if (!isValidProvincia(provincia)) {
            toast.error(`Provincia inválida!`);
            return;
        }

        if (!isValidMensaje(mensaje)) {
            toast.error(`Mensaje inválido!`);
            return;
        }

        setSent(true);
        scrollToTop();

        // Si el tipo de consulta en Peru es por venta se utiliza un email diferente
        const email_to = COUNTRY === 'pe' && tipoConsulta.includes('vender')
            ? 'ventas@perufarma.com.pe' : EMAIL_TO[COUNTRY]

        try {
            await axios.post(`${EMAIL_MICROSERVICE_URL}/mail`, {
                to: email_to,
                subject: EMAIL_SUBJECT,
                body: buildEmailBody(),
            });
        } catch (error) {
            console.error(`Error al enviar email: ${error}`);
            toast.error(`Ocurrió un error al enviar tu mensaje`);
        }
    }

    return (
        <div className="cont-form-container">

            <div className="cont-form-content">

                {sent ?
                    <Thanks />
                    :
                    <div className="cont-form-align">

                        <div className="cont-form-top">

                            <div className="cont-title">
                                <p>{COUNTRY === "ve" ? 'Completa' : 'Completá'} el formulario y nos pondremos en contacto a la brevedad:</p>
                            </div>

                            <div className="cont-line-container">
                                <div className="cont-actual-line"></div>
                            </div>

                        </div>

                        <div className="cont-form-main">

                            <form onSubmit={onSubmit}>

                                <ConsultaSelect updateValue={(value) => setTipoConsulta(value)} />

                                <Input
                                    value={nombre}
                                    updateValue={(value) => setNombre(value)}
                                    name="* Nombre"
                                    type="text"
                                />
                                <Input
                                    value={apellido}
                                    updateValue={(value) => setApellido(value)}
                                    name="* Apellido"
                                    type="text"
                                />
                                <Input
                                    value={codigoArea}
                                    updateValue={(value) => setCodigoArea(value)}
                                    name="* Código de área"
                                    type="text"
                                />
                                <Input
                                    value={telefono}
                                    updateValue={(value) => setTelefono(value)}
                                    name="* Número de teléfono"
                                    type="text"
                                />
                                <Input
                                    value={email}
                                    updateValue={(value) => setEmail(value)}
                                    name="* E-mail"
                                    type="text"
                                />
                                <Input
                                    value={direccion}
                                    updateValue={(value) => setDireccion(value)}
                                    name="Dirección"
                                    type="text"
                                />
                                <Input
                                    value={localidad}
                                    updateValue={(value) => setLocalidad(value)}
                                    name="* Localidad"
                                    type="text"
                                />

                                <div className="form-select-container">
                                    <ProvinciaSelect updateValue={(value) => setProvincia(value)} />

                                    <div className="form-icon-container">
                                        <div className="form-icon"></div>
                                    </div>
                                </div>

                                <Input
                                    value={mensaje}
                                    updateValue={(value) => setMensaje(value)}
                                    name="* Mensaje"
                                    type="text"
                                />


                                <div className="button-container">
                                    <Button text='ENVIAR' className='bg-green-dark color-white-primary fixed-size' />
                                </div>

                            </form>

                        </div>

                    </div>
                }

            </div>
        <ToastContainer />
        </div>
    );
}

export default Form;
