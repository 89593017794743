import React from 'react';

import { ReactComponent as LogoWhite } from '../../../static/img/logo_white.svg'
import { ReactComponent as Logo } from '../../../static/img/logo.svg'

import { ReactComponent as Facebook } from '../../../static/img/social/facebook.svg'
import { ReactComponent as Instagram } from '../../../static/img/social/instagram.svg'
import { ReactComponent as Youtube } from '../../../static/img/social/youtube.svg'

import { ReactComponent as Whatsapp } from '../../../static/img/social/whatsapp.svg'

import { ReactComponent as Flecha } from '../../../static/img/icons/flecha.svg'
import { ReactComponent as Lupa } from '../../../static/img/icons/lupa.svg'

import send from '../../../static/img/icons/send.svg'
import share from '../../../static/img/icons/share.svg'
import { useHistory } from 'react-router-dom';


export const LogoWithPush = ({ hideMenu }) => {

    const history = useHistory();

    return (
        <Logo onClick={() => {
            if (hideMenu) {
                hideMenu();
            }
            history.push('/');
        }} />
    );
}


const SvgDB = {
    logo: (() => (
        <LogoWithPush />
    ))(),
    logo_white: (() => (
        <LogoWhite />
    ))(),
    facebook: (() => (
        <Facebook />
    ))(),
    instagram: (() => (
        <Instagram />
    ))(),
    whatsapp: (() => (
        <Whatsapp />
    ))(),
    youtube: (() => (
        <Youtube />
    ))(),
    flecha: (() => (
        <Flecha />
    ))(),
    lupa: (() => (
        <Lupa />
    ))(),
    send: (() => (
        <img src={send} alt="" />
    ))(),
    share: (() => (
        <img src={share} alt="" />
    ))(),
}


export default SvgDB
