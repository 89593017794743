import React, { Fragment } from 'react';
import './MenuMobile.scss'
import {
    MenuItem,
    LoadingMenuMobile
} from '../../../@ui'

import { useHistory } from 'react-router-dom';
import { LogoWithPush } from '../../../@ui/Svg';


const MenuMobile = (props) => {

  const {
    menumobile,
    setMenuMobile,
    completedmenu = false,
    menuitems = []
  } = props

  const { push } = useHistory();

  return (
    <div className={`menu-mobile-container ${menumobile && 'active'}`}>
        {!completedmenu && <LoadingMenuMobile setMenuMobile={setMenuMobile} />}
        {completedmenu && menuitems &&
          <div className={`menu-mobile-content `}>

            <div className="menu-mobile-header-container">
                <LogoWithPush hideMenu={() => setMenuMobile(false)} />

                <div
                  className="cross menu-cross-container"
                  onClick={() => setMenuMobile(false)}
                ></div>
            </div>

              <div className="menu-mobile-list-container">

                <MenuItem
                    key={6202}
                    title="Productos"
                    onClick={() => {
                      push('/productos');
                      setMenuMobile(false);
                    }}
                    productos={true}
                />
                {menuitems.map((el, index) => (
                  <Fragment key={index}>
                    {
                      el.productos.length > 0 && (
                        <MenuItem
                          key={(index + 1) * 6200}
                          title={el.nombre}
                          dropdown={el.productos}
                          className={el.clase}
                          productos={true}
                          setActive={setMenuMobile}
                        />
                      )
                    }
                  </Fragment>
                )
                )}

                <MenuItem
                  key={6203}
                  title="Tutoriales"
                  onClick={() => {
                    push('/tutoriales');
                    setMenuMobile(false);
                  }}
                  productos={true}
                />

                <MenuItem
                  key={6204}
                  title="Contacto"
                  onClick={() => {
                      setMenuMobile(false);
                      push('/contacto');
                  }}
                />

              </div>
          </div>
        }
    </div>
  );
}

export default MenuMobile;