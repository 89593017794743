
export const MenuData = {
    
    'Coloración': { 
        nombre: 'Coloración', 
        slug: 'coloracion',
        productos: [],
        clase: 'color-orange-light'
    },
    'Complementos': { 
        nombre: 'Complementos',
        slug: 'complementos',
        productos: [],
        clase: ' color-green-strong'
    },
    'Kits': { 
        nombre: 'Kits',
        slug: 'kits',
        productos: [],
        clase: ' color-yellow-light'
    }
}