import React from 'react';
import './FiltrosActivos.scss'
import { Button } from '../index.js'

import { useSelector, useDispatch } from 'react-redux'
import { removeFilter, removeAllFilters } from '../../../redux/filtrosDuck'

const FiltrosActivos = () => {

    const { filtrosactivos } = useSelector(state => state.filtros)

    const dispatch = useDispatch()

    return (
        <>
            {filtrosactivos &&
                <div className="filtros-activos-container">

                    {filtrosactivos.length > 0 &&
                        <Button
                            text='Eliminar filtros'
                            className='bg-white-primary color-grey-primary border-grey-light bold btn-delete'
                            textTransform='capitalize'
                            onClick={() => dispatch(removeAllFilters())}
                        />
                    }

                    <div className="filtros-activos-content">
                        {filtrosactivos.map((el, index) => (
                            <div className="filtro-item-container" key={(index + 1) * 8100}>
                                <Button
                                    text={el.value}
                                    className='bg-grey-primarylight color-grey-primary medium btn-delete-sm'
                                    textTransform='capitalize'
                                    onClick={() => dispatch(removeFilter(el))}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default FiltrosActivos;