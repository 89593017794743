import React, { useEffect, useState } from 'react';
import './Banner.scss';
import './Banner.css';
import {
    Button,
    ButtonCarrousel,
    ComprarPopUp
} from '../../@ui';
import { bannerContent } from '../../Data';
import { useSwipeable } from 'react-swipeable';
import styles from '../../../components/@ui/Veil/Veil.module.scss';
import { useHistory } from 'react-router';

import { COUNTRY } from '../../../constants.js';

const ENABLE_AUTOSLIDE = true;
const AUTOSLIDE_INTERVAL_SECONDS = 4;


const Banner = () => {

    const filteredBannerContent = bannerContent[COUNTRY]

    const history = useHistory();
    const [buypopup, setBuyPopup] = useState(false);
    const [transition, setTransition] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);

    // Links a las páginas de compra del producto actual mostrado en el banner
    const [currentProductLinks, setCurrentProductLinks] = useState({
        gpspharma: 'https://www.gpsfarma.com',
        farmaonline: 'https://www.farmaonline.com',
    });

    // Actualizar links de producto cuando cambia el slide
    useEffect(() => {
        setCurrentProductLinks({
            gpspharma: filteredBannerContent[currentImage].gpspharma,
            farmaonline: filteredBannerContent[currentImage].farmaonline,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImage]);

    const swipeable = useSwipeable({
        onSwipedLeft: () => {
            changeSlides(currentImage + 1);
        },
        onSwipedRight: () => {
            changeSlides(currentImage - 1);
        },
        trackMouse: true,
    });

    const changeSlides = (n) => {
        let to = n;

        if (n > filteredBannerContent.length - 1) {
            to = 0;
        } else if (n < 0) {
            to = filteredBannerContent.length - 1;
        }

        setTransition(to);
        setCurrentImage(to);
    }

    useEffect(() => {
        if (ENABLE_AUTOSLIDE) {
            const interval = setInterval(() => {
                changeSlides(currentImage + 1);
            }, AUTOSLIDE_INTERVAL_SECONDS * 1000);
    
            return () => {
                clearInterval(interval);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImage]);


    return (
        <>
            <ComprarPopUp
                active={buypopup}
                setActive={setBuyPopup}
                gpspharma={currentProductLinks.gpspharma}
                farmaonline={currentProductLinks.farmaonline}
            />
            <div className="banner-container">
                <div className="banner-buttons-container">
                    {filteredBannerContent.map((el, i) => (
                        <ButtonCarrousel
                            key={(i + 1) * 8400}
                            active={transition === i}
                            className='color-white-primary button-item'
                            onClick={() => {
                                changeSlides(i);
                            }}
                        />

                    ))}
                </div>

                <div className="banner-img-container" {...swipeable} >
                    {filteredBannerContent.map((el, i) => (
                        <div
                            key={(i + 1) * 8500}
                            style={{ transform: `translate(-${transition * 100}%)` }}
                            className="img-container"
                        >
                            <div className="banner-content">
                                <h1 className={` ${ el.nombreColor }`}>
                                    {el.nombre}
                                </h1>
                                <p className={` ${el.descripcionColor } `}>
                                    {el.descripcion}
                                </p>
                                <Button
                                    text={`${el.boton.nombre}`}
                                    className={`${el.boton.border} ${el.boton.color_text} big ${el.boton.bg}` }
                                    onClick={() => {
                                        if (el.boton.nombre === 'COMPRÁ') {
                                            setBuyPopup(true);
                                        }
                                        else {
                                            history.push(el.boton.link);
                                        }
                                    }}
                                />
                            
                            </div>
                            <picture>
                                <source media="(min-width: 1080px)" srcSet={el.imagenes.desktopWebp} type="image/webp" />
                                <source media="(min-width: 1080px)" srcSet={el.imagenes.desktop} />
                                <source media="(min-width: 620px)" srcSet={el.imagenes.ipadWebp} type="image/webp" />
                                <source media="(min-width: 620px)" srcSet={el.imagenes.ipad} />
                                <source srcSet={el.imagenes.mobileWebp} type="image/webp" />
                                <img src={el.imagenes.mobile} draggable={false} alt={el.nombre}
                                    style={{ userSelect: 'none' }}/>
                            </picture>
                            <div className={styles.veil} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Banner;