import React from 'react';
import './LoadingMenuMobile.scss'

const LoadingMenuMobile = ({ setMenuMobile }) => {

    return (
        <div className="lmmobile-container" onClick={() => setMenuMobile(false)}>
            <div className="lmmobile-content">
                <div className="llmobile-li"></div>
                <div className="llmobile-li"></div>
                <div className="llmobile-li"></div>
                <div className="llmobile-li"></div>
                <div className="llmobile-li"></div>
            </div>
        </div>
    );
}

export default LoadingMenuMobile;